import React, { useEffect, useState } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../components/Portlet";
import {
  Row,
  Col,
  FormLabel,
  FormControl,
  Form,
  Tabs,
  Tab,
  Button
} from "react-bootstrap";
import CustomDatePicker from "../../../components/customDatePicker";
import Select from "react-select";
import DescriptionsBlock from "./descriptionsBlock";
import PaxesBlock from "./paxesBlock";
import ImagesBlock from "./imagesBlock";
import ApiSelect from "../../base/components/forms/apiSelect";
import RemoveLink from "../../air/airBooking/components/removeLink";
import _ from "lodash";
import { FieldArray } from "formik";
import TripItemHotelRoom from "./tripHotelRoom";
import FastInput from "../../../components/FastInput";
import { useFieldArray } from "react-hook-form";
import HookDatePicker from "../../../components/hookDatePicker";
import HotelDatesModal from "./hotelDatesModal";
import HotelText from "../trip/components/tripFormParts/buttons/hotelText";
import Duplicate from "../trip/components/tripFormParts/buttons/duplicate";
import DocumentsBlock from "./documentsBlock";
import HotelRoomGroup from "./hotelRoomGroup";
import { transferPointType } from "../../../helpers/enums";
import LocationPicker from "../../../components/locationPicker";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import FormHookSelect from "../../air/airBooking/components/formHookSelect";
import FormLocation from "../../base/components/forms/formLocation";
import FormHookDatePicker from "../../base/components/forms/formHookDatePicker";
import HotelRooms from "./hotelRooms";
import CurrencyPicker from "../../base/components/forms/currencySelector";
import HotelRoomGroups from "./hotelRoomGroups";
import IconSelect from "./IconSelect";

const TripHotelForm = ({ offer, item, index, prefix, formController, activ, oneElement }) => {
  const {
    register,
    formState: { errors },
    formState,
    control,
    watch,
    setValue,
    getValues
  } = formController;
  let classNames = "form-control";
  if (_.get(errors, `${prefix}.driveTime`)) {
    classNames += " is-invalid";
  }
  const [paxes, setPaxes] = useState([]);
  useEffect(() => {
    setPaxes(getValues().paxes);
  }, [getValues]);


  console.log("hotel item", item);
  return (
    <>
      <input
        type={"hidden"}
        {...register(`${prefix}.type`)}
        defaultValue={"hotel"}
      />
      <input
        type={"hidden"}
        {...register(`${prefix}._id`)}
        defaultValue={item._id}
      />

      <Row>
        <Col xs={3}>
          <FormLabel>Название отеля</FormLabel>
          <FormHookInput
            defaultValue={item.name}
            register={register(`${prefix}.name`, { required: true })}
            className={classNames}
            errors={errors}
          />
        </Col>

        <Col xs={3}>
          <FormLabel>Адрес</FormLabel>
          <FormHookInput
            defaultValue={item.address}
            register={register(`${prefix}.address`, { required: true })}
            className={classNames}
            errors={errors}
          />
        </Col>
        <Col xs={3}>
          <FormLabel>Booking.com</FormLabel>
          <FormHookInput
            defaultValue={item.bookingComLink}
            register={register(`${prefix}.bookingComLink`)}
          />
        </Col>
        <Col xs={3}>
          <FormLabel>Валюта</FormLabel>
          <CurrencyPicker
            value={item.currency}
            formController={formController}
            name={`${prefix}.currency`}
            className={classNames}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={2}>
          <FormLabel>Рейтинг</FormLabel>
          <FormHookInput
            defaultValue={item.rating}
            register={register(`${prefix}.rating`, { min: 1, max: 10 })}
            type={"text"}
            errors={errors}
          />
        </Col>
        <Col xs={2}>
          <FormLabel>Звездность</FormLabel>
          <FormHookSelect
            register={register(`${prefix}.stars`, { min: 1, max: 5 })}
            defaultValue={item.stars}
            errors={errors}
          >
            {Array.from(Array(6).keys()).map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
          </FormHookSelect>
        </Col>
        <Col xs={2}>
          <FormLabel>Телефон</FormLabel>
          <FormHookInput
            defaultValue={item.phone}
            register={register(`${prefix}.phone`)}
          />
        </Col>
        <Col xs={3}>
          <FormLabel>Дата начала</FormLabel>
          <FormHookDatePicker
            formController={formController}
            dateRules={{ required: true }}
            date={item.startDate}
            dateName={`${prefix}.startDate`}
            timeRules={{ required: true }}
            time={item.startTime}
            timeName={`${prefix}.startTime`}
            errors={errors}
          />
        </Col>
        <Col xs={3}>
          <FormLabel>Дата окончания</FormLabel>
          <FormHookDatePicker
            formController={formController}
            dateRules={{ required: true }}
            date={item.endDate}
            dateName={`${prefix}.endDate`}
            timeRules={{ required: true }}
            time={item.endTime}
            timeName={`${prefix}.endTime`}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={2}>
          <FormLabel>Иконка</FormLabel>
          <IconSelect
            defaultValue={item.icon}
            prefix={prefix}
            control={control}
          />
        </Col>
        <Col xs={4}>
          <FormLabel>Местоположение</FormLabel>
          <FormLocation
            point={item}
            prefix={prefix}
            formController={formController}
            addressName={`${prefix}.address`}
            errors={errors}
          />
        </Col>
        {offer && <Col xs={1}>
          <FormLabel>Порядок</FormLabel>
          <FormHookInput
            defaultValue={item.order}
            type={"number"}
            register={register(`${prefix}.order`)}
          />
        </Col>}
        <Col xs={2} style={{ paddingTop: 25 }}>
          <HotelDatesModal
            prefix={prefix}
            formController={formController}
            index={index}
          />
        </Col>
        <Col xs={2} style={{ paddingTop: 25 }}>
          {!offer && <HotelText formController={formController} index={index} /> }
        </Col>
        <Col xs={4} style={{ paddingTop: 25 }}>
          <Form.Check
            type={"checkbox"}
            label={"Прятать цену"}
            {...register(`${prefix}.hidePrice`)}
            defaultChecked={item.hidePrice}
          />

          <Form.Check
            type={"checkbox"}
            {...register(`${prefix}.hideElement`)}
            label={"Спрятать элемент"}
            defaultChecked={item.hideElement}
          />
        </Col>
      </Row>

      <DescriptionsBlock
        title={"отеля"}
        descriptions={item.descriptions}
        formController={formController}
        prefix={`${prefix}.descriptions`}
      />
      <DocumentsBlock
        formController={formController}
        documents={item.documents}
        prefix={`${prefix}.documents`}
      />
      <ImagesBlock
        formController={formController}
        prefix={`${prefix}.photos`}
        photos={item.photos}
        minLength={1}
        active={activ}
      />
      {/*<ImagesBlock*/}
      {/*  images={item.photos}*/}
      {/*  formik={formik}*/}
      {/*  prefix={`${prefix}.photos`}/>*/}
      <br />
      {offer ? (
        <HotelRoomGroups
          formController={formController}
          prefix={`${prefix}.roomGroups`}
          hotelPrefix={`${prefix}`}
          bookingComLink={item.bookingComLink}
          index={1}
          availableRooms={item.availableRooms}
          groups={item.roomGroups}
          activ={activ}
        />
      ) : (
        <>
          <HotelRooms
            prefix={`${prefix}`}
            dataPrefix={`${prefix}.rooms`}
            formController={formController}
            availableRooms={item.availableRooms}
            bookingComLink={item.bookingComLink}
            index={1}
            rooms={item.rooms}
            activ={activ}
          />
        </>
      )}
    </>
  );
};

export default TripHotelForm;
