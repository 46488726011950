import React, {useState} from 'react';
import {Portlet, PortletBody, PortletHeader} from "../../../components/Portlet";
import FinderCountTableForm from "./finderCountTableForm";
import {Table} from "react-bootstrap";
import _ from "lodash";
import {Link} from "react-router-dom";
import numberFormat from "../../../helpers/numberFormat";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import ItemsTable from "./itemsTable";

const PaxToCounterparty = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState()
  const getInfo = (data) =>{
    setLoading(true)
    axios.post('/api/counerpartiesFinder/byPaxName', data)
      .then(({data})=>{
        console.log('axios',data)
        setData(data.table)
      })
      .catch(sayError)
      .finally(()=>setLoading(false))
  }
  return (
    <Portlet>
      <PortletHeader title={'Поиск контрагентов по пассажирам'}/>
      <PortletBody>
        <FinderCountTableForm cb={getInfo} loading={loading}/>
        {data ? <ItemsTable data={data} autoShow/> :
          'Заполните форму выше, чтобы получить информацию'}
      </PortletBody>
    </Portlet>
  );
};

export default PaxToCounterparty;