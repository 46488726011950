import React, { useState } from "react";
import {
  RacoonListRoute,
  ReviseGetRoute,
  ReviseItemGetRoute,
  TripCreateRoute,
  TripEditRoute
} from "../../../routesConstants";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import { Link } from "react-router-dom";
import DataTable from "../../../../components/dataTable";
import PortletHeaderSearch from "../../../base/components/forms/portlerHeaderSearch";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import {
  DeleteItem,
  LoadList,
  StartProcess
} from "../../../../redux/actions/base";
import RemoveLink from "../../../air/airBooking/components/removeLink";
import reviseItemTypes from "../../helpers/reviseItemTypes";
import moment from "moment";
import _ from "lodash";
import CreateReviseButton from "../../components/createReviseButton";

const ReviseListPage = props => {
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const columns = [
    {
      Header: "Даты",
      accessor: "startDate",
      Cell: ({ value, row }) => {
        return (
          <Link to={ReviseGetRoute + row.original.id}>
            {moment(row.original.startDate).format("DD.MM.YYYY")}-
            {moment(row.original.endDate).format("DD.MM.YYYY")}
          </Link>
        );
      }
    },
    {
      Header: "Коментарий",
      accessor: "comment"
    }
  ];

  const [query, setQuery] = useState("");
  return (
    <>
      <div className={"kt-grid__item"}>
        <Portlet>
          <PortletHeader
            title={"Список отчетов"}
            toolbar={
              <PortletHeaderToolbar>
                <CreateReviseButton />
              </PortletHeaderToolbar>
            }
          />
          <PortletBody>
            <DataTable
              columns={columns}
              url={`/api/racoon/revise`}
              refetch={refetch}
            />
          </PortletBody>
        </Portlet>
      </div>
    </>
  );
};

export default connect(() => ({}), { LoadList, StartProcess, DeleteItem })(
  ReviseListPage
);
