import React, {useState} from 'react';
import {Portlet, PortletHeader, PortletBody} from "../../../../components/Portlet";
import TemplateForm from "./templateForm";
import {Tab, Tabs} from "react-bootstrap";
import _ from 'lodash'

const VarInfoTable = ({template, setter}) => {


  const [tabIndex, setTabIndex] = useState("global");

  const titleClick= (data, field) => {
    setter(template[field]+ ' {' + data+ '}',field );
  }
  const renderTable = (data, field) => {
    return <table className={'table'}>
      <tbody>
      {_.chunk(Object.keys(data), 2).map((items, index) => {
        return <tr key={index}>
          {items.map((item)=> {
            return <>
              <td>
                {item.split(',').map((name, index) => <b key={index} onClick={() => titleClick(name,field)}>{'{'}{name}{'}'} <br/></b>)}
              </td>

              <td>{data[item]}</td>
            </>
          })}
        </tr>
      })}
      </tbody>
    </table>
  }
  const renderDatesTable = () => {
    return <table className={'table'}>
      <tbody>
      {_.chunk(Object.keys(datesData), 3).map((items, index) => {
        return <tr key={index}>
          {items.map((item)=> {
            return <>
              <td>
                <b key={index}>{item} <br/></b>
              </td>

              <td>{datesData[item]}</td>
            </>
          })}
        </tr>
      })}
      </tbody>
    </table>
  }
  return  <Portlet>
    <PortletHeader title={"Название и описание переменных"}/>
    <PortletBody>
      <Tabs
        variant="pills"
        id="controlled-tab-example"
        activeKey={tabIndex}
        onSelect={key => setTabIndex(key)}
      >
        <Tab eventKey="global" title="Глобальные">
          {renderTable(globalData, 'mainTemplate')}
          <p>
            <b onClick={() => titleClick('Trans RU="русский" EN="english"','mainTemplate' )}>{'{'}Trans RU="русский" EN="english"{'}'}<br/></b>
            <b onClick={() => titleClick('if="TPT"}{TPT}{/if','mainTemplate' )}>{'{'}if="TPT"{'}{TPT}{/if}'}<br/></b>
          </p>
        </Tab>
        <Tab eventKey="route" title="Маршрут">
          {renderTable(routeData, 'routeTemplate')}
        </Tab>
        <Tab eventKey="segments" title="Сегменты">
          {renderTable(segmentData, 'segmentTemplate')}
        </Tab>
        <Tab eventKey="dates" title="Даты">
          <p>Пример: <b>DD MMM YYYY</b> - 14 Jul 2015</p>
          {renderDatesTable(datesData)}
        </Tab>
      </Tabs>
    </PortletBody>
  </Portlet>
}

const globalData = {
  'TT': 'Маршруты',
  'DPC': 'Код вылета',
  'APC': 'Код прилета',
  'TTD,TTD="формат"': 'Дата расписания',
  'DC': 'Город вылета',
  'AC': 'Город прилета',
  'CMT': 'Комментарий',
  'DA': 'Аэропорт вылета',
  'AA': 'Аэропорт прилета',
  'NL': 'Новая строка',
  'EL': 'Пустая строка',
}
const routeData = {
  II: 'Номер',
  AA: 'Аэропорт прилета',
  TT: 'Время в пути',
  AC: 'Город прилета',
  APC: 'Код аэропорта прилета',
  DA: 'Аэропорт вылета',
  DPC: 'Код аэропорта вылета',
  DC: 'Город вылета',
  'DT,DT="формат"': 'Дата и время вылета',
  SGM: 'Сегменты',
  'AT,AT="формат"': 'Дата и время прилета',
  'DOF="Формат" sep="."': 'Дни по которым летает рейс',
};

const segmentData = {
  FN: 'Номер рейса',
  DC: 'Город вылета',
  AC: 'Город прилета',
  'DT,DT="формат"': 'Дата вылета',
  DA: 'Аэропорт вылета',
  'AT,AT="формат"': 'Дата прилета',
  AA: 'Аэропорт прилета',
  DPC: 'Код вылета',
  TT: 'Время в пути',
  APC: 'Код прилета',
  CT: 'Время стыковки',
  CTD: 'Время стыковки',
  SAC: 'Перевозчик',
  DTR: 'Терминал вылета',
  OAC: 'Оператор',
  ATR: 'Терминал прилета',
  DM: 'Модификатор вылета (+1)',
  ALO: 'Оператор (название)',
  ALS: 'Перевозчик (название)',
  APL: 'Самолет',
}

const datesData = {
  D: 'число(1)',
  M: 'месяц (7)',
  'h/hh': 'часы (12)',
  DD: 'число(01)',
  MM: 'месяц (07)',
  'H/HH': 'часы(24)',
  dd: 'число(Fr)',
  MMM: 'месяц(Jul)',
  'm/mm': 'минуты(15)',
  dddd: 'число(Friday)',
  MMMM: 'месяц(July)',
  'a/A': '(am/pm)',
  YY: 'год(15)',
  YYYY: 'год(2015)',
}
export default VarInfoTable;
