import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import Loader from "../../../components/loader";
import axios from "../../../helpers/axios";
import {Table} from "reactstrap";
import {TableCell, TableRow} from "@material-ui/core";
import moment from "moment";
import CopyDownloadLink from "./CopyDownloadLink";

const ShowAllDocButton = ({id}) => {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()
  useEffect(() => {
    if(show && !data){
      axios.post(`/api/racoon/revise/item/douments/${id}`)
        .then(({data})=>{
          setData(data)
          console.log('data', data)
          setLoading(false)
        })
        .catch(e=>{
          console.log('err',e)
          window.alert('Что то пошло не так, перезагрузите страницу')
        })
    }
  }, [show]);
  return (
    <>
      <button className={"btn btn-primary btn-sm m-2"} onClick={()=>setShow(true)}>
        Все документы
      </button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Список всех документов</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? <Loader/> : data.documents.map((type,index)=>{
            return <div key={index} className={index===0 ?'mb-4':''}>
              <h5>{type.name}</h5>
              {type.documents.map((d,i)=>{
                return <div key={i} className={'mb-3'}>
                  <div className={'d-flex gap-5 align-items-center'}>
                    <div>
                      {moment(d.addedAt).format('DD.MM.YY HH:mm')}<br/>
                      {d.addedBy}
                    </div>
                    <div>
                      <CopyDownloadLink url={d.url}/>
                      <a download={d.url}
                         href={d.url}
                         target={"_blank"}
                         rel={'unsafe-url'}
                      >
                        {d.originalName}
                      </a>
                    </div>
                  </div>
                  {type.documents.length>1 && i+1 ===type.documents.length && <hr/>}
                </div>
                // <Table>
                //   <TableRow>
                //     <TableCell>{moment(d.addedAt).format('DD.MM.YY HH:mm')}</TableCell>
                //     <TableCell rowSpan={2}>
                //       <CopyDownloadLink url={d.url}/>
                //       <a download={d.url}
                //          href={d.url}
                //          target={"_blank"}
                //          rel={'unsafe-url'}
                //       >
                //         {d.originalName}
                //       </a></TableCell>
                //   </TableRow>
                //   <TableRow>
                //     <TableCell>{d.addedBy}</TableCell>
                //   </TableRow>
                // </Table>
              })}

            </div>
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShow(false)}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
      
    </>
  );
};

export default ShowAllDocButton;