import React, {useState} from 'react';
import BlockUi from "@availity/block-ui";
import DocumentsForm from "../components/documentsForm";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import {useNavigate} from "react-router";
import {DocumentsListRoute} from "../../routesConstants";

const documentTempData = {
  firstName:'',
  lastName:'',
  middleName:'',
  documentNumber:'',
  expirationDate: '',
  documentType:'passport',
  photos: []
}
const DocumentsCreatePage = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const onSubmit = (data) =>{
    setLoading(true)
    console.log('submit', data)
    let sendingInfo = {...data}
    sendingInfo.photos = data.photos.map(l=>(l.url))
    axios.put('/api/documents/', sendingInfo)
      .then(()=>{
        navigate(DocumentsListRoute)
      })
      .catch(sayError)
      .finally(()=>setLoading(true))
  }
  return (
    <BlockUi blocking={loading}>
      <DocumentsForm data={documentTempData}
                     onSubmit={onSubmit}
                     title={'Создать новый документ'}/>
    </BlockUi>
  );
};

export default DocumentsCreatePage;