import React, {useState} from 'react';
import {Button, Modal, Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import BlockUi from "@availity/block-ui";
import {useForm} from "react-hook-form";
import {Col} from "reactstrap";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import HookDatePicker from "../../../components/hookDatePicker";
import {banksLogo} from "./banksLogo";
import FormHookDatePicker from "../../base/components/forms/formHookDatePicker";
import moment from "moment";
import numberFormat from "../../../helpers/numberFormat";
import axios from "../../../helpers/axios";

const CreditCard = ({card, cb}) => {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const formController = useForm()
  const {handleSubmit,
    register,
    reset,
    formState:{errors}} = formController
  const onSubmit = (data) =>{
    const sendData = {
      transactionDate : `${moment(data.date, 'DD MMM YYYY').format('DD.MM.YYYY')} ${data.time}`,
      cardId: card.id,
      message: data.message,
      balanceChange: +data.balanceChange
    }
    axios.put('/api/bankCards/transactions', sendData)
      .then(()=>{
        setShow(false)
        cb()
        reset()
      })
      .catch(e=> {
        window.alert('Что то не так')
        console.log('err', e)
    })
  }
  return (
    <>
      <div className={'creditCard'} onClick={()=>setShow(true)}>
        <div className={'bank'}>
          <div>.... {card.cardNumber}</div>
          <div style={{width: '30px', height: '30px'}}>{banksLogo[card.bank] || card.bank}</div>
        </div>
        <div className={'name'}>{card.user}</div>
        <div className={'bank plus'}>
        <div style={{fontWeight:'bold'}}>{numberFormat(card.balance)}</div>
          <i className={"la la-plus"}/>
        </div>
      </div>
      <Modal show={show} onHide={()=>setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Новая транзакция по .... {card.cardNumber} {card.user}</Modal.Title>
        </Modal.Header>
        <BlockUi blocking={loading} >
          <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
            <Modal.Body>
              <Row className={"mb-2"}>
                <Form.Label column sm="3">
                  Сумма
                </Form.Label>
                <Col sm={9}>
                  <FormHookInput type={'number'}
                    register={register("balanceChange", {
                      required: true,
                      minLength: 1,
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row className={"mb-2"}>
                <Form.Label column sm="3">
                  Комментарий
                </Form.Label>
                <Col sm={9}>
                  <FormHookInput
                    register={register("message", {
                      required: true,
                      minLength: 5,
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>
            <Row className={"mb-2"}>
              <Form.Label column sm="3">
                Дата
              </Form.Label>
              <Col sm={9}>
                <FormHookDatePicker
                  formController={formController}
                  dateRules={{ required: true }}
                  dateName={`date`}
                  timeRules={{ required: true }}
                  timeName={`time`}
                  errors={errors}
                />
              </Col>
            </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setShow(false)}>
                Закрыть
              </Button>
              <Button
                variant="primary"
                type={'submit'}
              >Сохранить</Button>
            </Modal.Footer>
          </form>
        </BlockUi>
      </Modal>
    </>

  );
};

export default CreditCard;