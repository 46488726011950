import React, {useState} from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import GoogleMapReact from 'google-map-react';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  geocodeByLatLng,
  getLatLng
}  from 'react-google-places-autocomplete';
import _ from "lodash";



const LocationPicker = (props) => {
  const {lat , lon, onSubmit, size} = props;
  const [mapState, setMapState] = useState(
    {
      center: {
        lat: lat,
        lng: lon
      },
      zoom: 11
    }
  );
  const [currentLocation, setCurrentLocation] = useState({lat, lon})
  const [show, setShow] = useState(false);

  const handleClose = () => {
    geocodeByLatLng({ lat: currentLocation.lat, lng: currentLocation.lon })
      .then(results => {
        const address = _.find(results, (r)=>r.types.indexOf("street_address")!==-1);

        onSubmit({...currentLocation, address:address?address.formatted_address:null})
        setShow(false);
      })
      .catch(error => console.error(error));
  }
  const handleShow = () => setShow(true);

  const mapOnClick = ({x, y, lat, lng, event}) => {
    setCurrentLocation({lat: lat, lon:lng})
  }
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value.value.description);
    const latLng = await getLatLng(results[0]);
    setCurrentLocation({lat: latLng.lat, lon:latLng.lng})
    setMapState({
      center: {
        lat: latLng.lat,
        lng: latLng.lng
      },
      zoom: 11
    })
  };
  return <>
    <Button size={size?'sm':size}  onClick={handleShow}>
      <FontAwesomeIcon icon={faMapMarkedAlt} />
    </Button>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Выбор места</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        lat - {currentLocation.lat}, lon - {currentLocation.lon}
        <GooglePlacesAutocomplete
          selectProps={{
            autocompleteValue,
            onChange: handleSelect
          }}
        />
        <div style={{ height: '400px', width: '100%' }}>
          <GoogleMapReact
            onClick={mapOnClick}
            // bootstrapURLKeys={{
            //   key: 'AIzaSyBLBN9tbTIECOu6-Tu5BXUnSoYqr83fkrk',
            //   libraries:['places'],
            // }}
            defaultCenter={{
              lat: 59.95,
              lng: 30.33
            }}
            defaultZoom={mapState.zoom}
            center={mapState.center.lat?mapState.center: undefined}
          >
            <div
              style={{width: '30px', height: '40px', position: 'relative',top: -40, left: -15,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'center',
                textAlign: 'center'}}
              lat={currentLocation.lat}
              lng={currentLocation.lon}>

              <FontAwesomeIcon icon={faMapMarkerAlt}
                               style={{color: '#de4229',fontSize: 30}}/>
            </div>
          </GoogleMapReact>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Закрыть
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Сохранить
        </Button>
      </Modal.Footer>
    </Modal>
  </>
}

export default LocationPicker
