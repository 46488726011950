import React from 'react'
import clsx from "clsx";
import {Button} from "react-bootstrap";

const LoadingButton = ({title, loading, disabled, onClick, type,size,variant})=><Button
  type={type?type:"submit"}
  size={size}
  variant={variant||"primary"}
  disabled={disabled}
  className={` ${clsx(
    {
      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
    }
  )}`}
  onClick={onClick}
>
  {title}
</Button>

export default LoadingButton;
