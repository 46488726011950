import React, {useEffect, useState} from 'react';
import {PortletBody, PortletFooter, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import {Button, Col, Form} from "react-bootstrap";
import clsx from "clsx";
import {Row} from "reactstrap";
import {useForm} from "react-hook-form";
import Papa from "papaparse";
import {googleParse, oneParser} from "./parserCvs";


const kayar = [
  {name:"input1cKayarSell", label:'1C Kayar продажи'},
  {name:'input1cKayarRefund', label: '1C Kayar возвраты'},
]
const google = [
  {name:'inputGoogleDoc', label: 'Google Doc'}
]
const aruana = [
  {name:"input1cAruanaSell", label:'1C Aruana продажи'},
  {name:'input1cAruanaRefund', label: '1C Aruana возвраты'},
]
const RenderParseInputs = ({type, cb}) => {
  const [loading, setLoading] = useState(false)
  const [cvsText, setCvsText] = useState({})
  const [last, setLast] = useState(false)
  const { control,
    register,
    handleSubmit, reset
  } = useForm();
  let renderItems;
  if(type ==='gk'){
    renderItems = [...google, ...kayar]
  } else if (type ==='ga'){
    renderItems = [...google, ...aruana]
  } else {
    renderItems = [...kayar, ...aruana]
  }

  const onSubmit =  (data) => {
    Object.keys(data).map((key,i)=>{
      let file = data[key][0]
      if(file){
        Papa.parse(file, {
          complete: function (results) {
            // console.log("Finished:", results.data);
            setCvsText(text =>({...text, [key]:results.data}))
            i+1 === Object.keys(data).length && setLast(true)
          }
        })}
    })
  }
  useEffect(() => {
    if(last){
      cb(oneParser(cvsText, type))
    }
  }, [last]);
  useEffect(() => {
    setLast(false)
    setCvsText({})
    reset()
  }, [type]);
  console.log('cvsText',cvsText)

  return (<>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PortletBody>
          <Row className={'align-items-end'}>
            {renderItems.map((item,i)=>{
              return <Col key={i}>
                <Form.Label>{item.label}</Form.Label>
                <Form.Control
                  type={"file"}
                  {...register(item.name, {required: true})}
                />
              </Col>
            })}
          </Row>
        </PortletBody>
        <PortletFooter>
          <Button
            type={"submit"}
            className={` ${clsx({
              "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
            })}`}
          >
            Сравнить
          </Button>
        </PortletFooter>
      </form>
    </>

  );
};

export default RenderParseInputs;