import React, {useState} from 'react';
import {Portlet, PortletHeader} from "../../../../components/Portlet";
import TemplateForm from "../components/templateForm";
import {connect} from "react-redux";
import {CreateItem} from "../../../../redux/actions/base";
import {AirBookingTemplateListRoute} from "../../../routesConstants";
import VarInfoTable from "../components/varInfoTable";


const AirBookingTemplateCreatePage = ({CreateItem}) =>{

  const callback = (values, cb)=>{
    CreateItem('/api/parsers/airBooking', values, cb, AirBookingTemplateListRoute )
  }
  return <>
    <TemplateForm onSubmit={callback} title={"Создание шаблона"}/>
  </>
}

export default connect(null, {CreateItem})(AirBookingTemplateCreatePage)
