import React from "react";
import { InputGroup, FormControl, Col } from "react-bootstrap";
import LocationPicker from "../../../../components/locationPicker";
import FormHookInput from "../../../air/airBooking/components/formHookInput";

const FormLocation = ({
  point,
  prefix,
  formController,
  addressName,
  errors
}) => {
  const { register, setValue } = formController;
  return (
    <InputGroup className="mb-12">
      <FormHookInput
        defaultValue={point.latitude}
        errors={errors}
        register={register(`${prefix}.latitude`, { min: -90, max: 90 })}
      />
      <FormHookInput
        defaultValue={point.longitude}
        errors={errors}
        register={register(`${prefix}.longitude`, { min: -180, max: 180 })}
      />
      {/*<InputGroup.Append>*/}
      <LocationPicker
        lat={point.latitude}
        lon={point.longitude}
        size={undefined}
        onSubmit={data => {
          setValue(`${prefix}.longitude`, parseFloat(data.lon));
          setValue(`${prefix}.latitude`, parseFloat(data.lat));
          console.log("data", data);
          console.log("addressName", addressName);
          if (addressName) {
            setValue(addressName, data.address);
          }
        }}
      />
      {/*</InputGroup.Append>*/}
    </InputGroup>
  );
};

export default FormLocation;
