import React, { useEffect } from "react";
import { Button, Col, FormLabel, Row } from "react-bootstrap";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import PhotoInput from "../trip/components/tripFormParts/photoInput";
import RemoveLink from "../../air/airBooking/components/removeLink";
import { useController, useFieldArray } from "react-hook-form";
import FormHookDatePicker from "../../base/components/forms/formHookDatePicker";
import TimeInput from "./TimeInput";
import ImagesBlock from "./imagesBlock";
import MEDitor from "@uiw/react-md-editor";
import IconSelect from "./IconSelect";

const TripEtcTimeLine = ({ formController, prefix, active }) => {
  const {
    register,
    formState: { errors },
    formState,
    control
  } = formController;

  const timelineControl = useFieldArray({
    control,
    name: `${prefix}`
  });
  const timelineTemplate = {
    photos: []
  };
  const addTimeline = () => {
    timelineControl.append({ ...timelineTemplate });
  };
  return (
    <>
      <br />
      <br />
      <h5>
        Таймлайн{" "}
        <Button size={"sm"} onClick={() => addTimeline()}>
          <i className={"fa fa-plus"}></i>
        </Button>
      </h5>
      {timelineControl.fields.map((timeline, index) => {
        return (
          <React.Fragment key={index}>
            <Row>
              <Col xs={3}>
                <FormLabel>Дата</FormLabel>
                <FormHookDatePicker
                  formController={formController}
                  dateRules={{ required: true }}
                  date={timeline.startDate}
                  dateName={`${prefix}[${index}].startDate`}
                  timeRules={{ required: true }}
                  time={timeline.startTime}
                  timeName={`${prefix}[${index}].startTime`}
                  errors={errors}
                />
              </Col>
              <Col xs={4}>
                <FormLabel>Название</FormLabel>
                <FormHookInput
                  defaultValue={timeline.title}
                  register={register(`${prefix}[${index}].title`, { required: true })}
                  errors={errors}
                />
              </Col>
              <Col xs={2}>
                <FormLabel>Иконка</FormLabel>
                <IconSelect
                  defaultValue={timeline.icon}
                  prefix={prefix}
                  control={control}
                />
              </Col>
              <Col xs={2}>
                <RemoveLink
                  onClick={() => timelineControl.remove(index)}
                  title={"таймлайн"}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} data-color-mode="light">
                <TimelineTextBlock
                  name={`${prefix}[${index}].description`}
                  control={formController.control}
                  value={timeline.description}
                />
              </Col>
            </Row>
            <ImagesBlock
              formController={formController}
              prefix={`${prefix}[${index}].photos`}
              photos={timeline.photos}
              etcTimeLine={true}
              active={active}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default TripEtcTimeLine;

const TimelineTextBlock = ({ name, control, value }) => {
  const {
    field: { onChange, onBlur, value: defValue, ref },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields }
  } = useController({
    name: name,
    control: control,
    defaultValue: value
  });
  return <MEDitor height={200} value={defValue} onChange={onChange} />;
};
