import React, {useState} from 'react';
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import {useDispatch} from "react-redux";
import {addCheck, addIgnore} from "../../../redux/reducers/tickets";
import _ from 'lodash'
import AddToReduxForm from "./addToReduxForm";
import {Col, Row} from "reactstrap";

const AddLastNameButton = ({cb}) => {
  const dispatch = useDispatch()

  const onSubmitIgnore = (data) =>{
    dispatch(addIgnore(_.trim(data.lastName)))
    cb()
  }
  const onSubmitCheck = (data) =>{
    dispatch(addCheck(_.trim(data.lastName)))
    cb()
  }
  return (
    <Navbar expand="lg" className="bg-body-tertiary" sticky="bottom">
      <Container >
        <Row style={{width:'100%'}}>
          <Col xs={6}>
            <AddToReduxForm onSubmit={onSubmitCheck} label={'Нужно проверить.'}/>
          </Col>
          <Col xs={6}>
            <AddToReduxForm onSubmit={onSubmitCheck} label={'Игнорировать.'}/>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default AddLastNameButton;