import {Portlet, PortletBody, PortletHeader} from "../../../components/Portlet";
import {Table} from "reactstrap";
import React from "react";

const ResultTable = ({resultCheck}) =>{
  return <Portlet>
    <PortletHeader title={resultCheck.checksPassed}/>
    <PortletBody>
      <Table striped bordered hover>
        <thead>
        <tr>
          <th>Путь</th>
          <th>Ожидается</th>
          <th>Пришло</th>
          <th>Соответствует</th>
        </tr>
        </thead>
        <tbody>
        {resultCheck.result.map((res, i)=>{
          return <tr key={i}>
            <td>{res.propertyToCheck}</td>
            <td>{res.expectedValue}</td>
            <td>{res.actualValue || ''}</td>
            <td valign={"center"} align={'center'}>
              {res.valid ?
                <i className={"fa fa-check-circle text-success"} /> :
                <i className={"fa fa-times-circle text-danger"} />}
            </td>
          </tr>
        })}
        </tbody>
      </Table>
    </PortletBody>
  </Portlet>
}
export default ResultTable