import React from 'react';
import ApiSelect from "./apiSelect";
import {Col} from "react-bootstrap";

const CountrySelect = ({cb,value})=>{
  return <ApiSelect
    value={value}
    url={"/api/locations/countries/searchInput"}
    cb={cb}
  />
}

export default CountrySelect