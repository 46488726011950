import React from "react";
import {
  Portlet,
  PortletBody,
  PortletFooter,
  PortletHeader
} from "../../../../components/Portlet";
import { connect } from "react-redux";
import { CreateItem } from "../../../../redux/actions/base";
import {
  TripUsersRoute
} from "../../../routesConstants";
import * as yup from "yup";
import FormRow from "../../../base/components/forms/formRow";
import {Button, Col, Form, Row} from "react-bootstrap";
import LoadingButton from "../../../base/components/loadingButton";
import { Formik } from "formik";
import {useForm} from "react-hook-form";
import FormHookInput from "../../../air/airBooking/components/formHookInput";

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .default(""),
  lastName: yup
    .string()
    .required()
    .default(""),
  email: yup
    .string()
    .email()
    .required()
    .default(""),
  password: yup
    .string()
    .required()
    .default(""),
  active: yup.boolean().default(true),
  dontSendEmail: yup.boolean().default(false),
  language: yup
    .string()
    .required()
    .default("en")
});
const TripUserCreatePage = ({ CreateItem })=> {
  return (
    <>
      <div className={"row"}>
        <div className="col-xl-6">
          <Portlet>
            <PortletHeader title={"Создание пользователя"} />
            <Formik
              initialValues={validationSchema.cast()}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => {
                console.log("form sent");
                setSubmitting(true);

                CreateItem(
                  "/api/trips/users",
                  values,
                  () => {
                    setSubmitting(false);
                  },
                  TripUsersRoute
                );
              }}
            >
              {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue
                }) => {
                console.log(values);
                return (
                  <form noValidate={true} onSubmit={handleSubmit}>
                    <PortletBody>
                      <FormRow
                        value={values.firstName}
                        name={"firstName"}
                        title={"Имя"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />
                      <FormRow
                        value={values.lastName}
                        name={"lastName"}
                        title={"Фамилия"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />
                      <FormRow
                        value={values.password}
                        name={"password"}
                        title={"Пароль"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />
                      {/*<FormRow*/}
                      {/*  value={values.phone}*/}
                      {/*  name={"phone"}*/}
                      {/*  title={"Телефон (+11111111111)"}*/}
                      {/*  errors={errors}*/}
                      {/*  handleChange={handleChange}*/}
                      {/*  touched={touched}*/}
                      {/*/>*/}
                      {/*<FormRow*/}
                      {/*  value={values.telegramUsername}*/}
                      {/*  name={"telegramUsername"}*/}
                      {/*  title={"Имя пользователя в телеграмме"}*/}
                      {/*  errors={errors}*/}
                      {/*  handleChange={handleChange}*/}
                      {/*  touched={touched}*/}
                      {/*/>*/}
                      <FormRow
                        value={values.email}
                        name={"email"}
                        title={"Почта"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />
                      {/*<Col md={{ span: 10, offset: 2 }}>*/}
                      {/*  <div*/}
                      {/*    className="alert alert-secondary offset-sm-3"*/}
                      {/*    role="alert"*/}
                      {/*  >*/}
                      {/*    <div className="alert-text">*/}
                      {/*      На указанную почту будет отправленно письмо с*/}
                      {/*      инструкцией о сайте и как задать пароль*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*</Col>*/}
                      <Form.Group as={Row}>
                        <Form.Label column sm="2">
                          Язык
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Check
                            value={"ru"}
                            name={"language"}
                            type={"radio"}
                            label={`Русский`}
                            id={`User`}
                            checked={values.language === "ru"}
                            onChange={handleChange}
                            touched={touched}
                          />
                          <Form.Check
                            value={`en`}
                            name={"language"}
                            type={"radio"}
                            label={`English`}
                            id={`Employee`}
                            checked={values.language === "en"}
                            onChange={handleChange}
                            touched={touched}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Col sm={{ span: 10, offset: 2 }}>
                          <Form.Check
                            name={"active"}
                            type={"checkbox"}
                            id={`active`}
                            label={"Активирован"}
                            checked={values.active}
                            onChange={handleChange}
                            touched={touched}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Col sm={{ span: 10, offset: 2 }}>
                          <Form.Check
                            name={"dontSendEmail"}
                            type={"checkbox"}
                            id={`active`}
                            label={"Не отправлять письмо"}
                            checked={values.dontSendEmail}
                            onChange={handleChange}
                            touched={touched}
                          />
                        </Col>
                      </Form.Group>
                    </PortletBody>
                    <PortletFooter>
                      <Row>
                        <Col md={{ span: 10, offset: 2 }}>
                          <LoadingButton
                            title={"Сохранить"}
                            disabled={isSubmitting}
                            loading={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </PortletFooter>
                  </form>
                );
              }}
            </Formik>
          </Portlet>
        </div>
      </div>
    </>
  );
};
// const TripUserCreatePage = ({ CreateItem })=> {
//   const {
//     register,
//     formState: { errors },
//     handleSubmit,
//   } = useForm();
//   const onSubmit = (values) =>{
//     console.log('value', values)
//     // CreateItem(
//     //               "/api/trips/users",
//     //               values,
//     //               () => {
//     //                 console.log('123');
//     //               },
//     //               TripUsersRoute
//     //             );
//   }
//   return (
//     <>
//       <Portlet>
//         <Form onSubmit={handleSubmit(onSubmit)}>
//           <PortletHeader title={'Редактирование пользователя'}/>
//           <PortletBody>
//             <Form.Group as={Row}>
//               <Form.Label column sm="2">
//                 Имя
//               </Form.Label>
//               <Col sm={10}>
//                 <FormHookInput
//                   register={register(`firstName`, {
//                     required: true,
//                     minLength: 2
//                   })}
//                   errors={errors}
//                 />
//               </Col>
//             </Form.Group>
//             <Form.Group as={Row}>
//               <Form.Label column sm="2">
//                 Фамилия
//               </Form.Label>
//               <Col sm={10}>
//                 <FormHookInput
//                   register={register(`lastName`, {
//                     required: true,
//                     minLength: 2
//                   })}
//                   errors={errors}
//                 />
//               </Col>
//             </Form.Group>
//             <Form.Group as={Row}>
//               <Form.Label column sm="2">
//                 Email
//               </Form.Label>
//               <Col sm={10}>
//                 <FormHookInput
//                   register={register(`email`, {
//                     required: true,
//                     minLength: 2
//                   })}
//                   errors={errors}
//                   type={'email'}
//                 />
//               </Col>
//             </Form.Group>
//             <Form.Group as={Row}>
//               <Form.Label column sm="2">
//                 Пароль
//               </Form.Label>
//               <Col sm={10}>
//                 <FormHookInput
//                   register={register(`password`, {
//                     required: true,
//                     minLength: 2
//                   })}
//                   errors={errors}
//                 />
//               </Col>
//             </Form.Group>
//             <Form.Group as={Row}>
//               <Form.Label column sm="2">
//                 Язык
//               </Form.Label>
//               <Col sm={10}>
//                 <Form.Check
//                   register={register('language')}
//                   type={"radio"}
//                   label={`Русский`}
//                   id={`ru`}
//                 />
//                 <Form.Check
//                   register={register('language')}
//                   type={"radio"}
//                   label={`English`}
//                   id={`en`}
//                   checked={'en'}
//                 />
//               </Col>
//             </Form.Group>
//             <Form.Group as={Row}>
//               <Col sm={{ span: 10, offset: 2 }}>
//                 <Form.Check
//                   register={register('active')}
//                   name={"active"}
//                   type={"checkbox"}
//                   id={`active`}
//                   label={"Активирован"}
//                   values={"active"}
//
//                 />
//               </Col>
//             </Form.Group>
//             <Form.Group as={Row}>
//               <Col sm={{ span: 10, offset: 2 }}>
//                 <Form.Check
//                   name={"dontSendEmail"}
//                   type={"checkbox"}
//                   id={`active`}
//                   label={"Не отправлять письмо"}
//                 />
//               </Col>
//             </Form.Group>
//           </PortletBody>
//           <PortletFooter>
//             <Row>
//               <Col md={{ span: 10, offset: 2 }}>
//                 <Button type="submit">Создать пользователя</Button>
//               </Col>
//             </Row>
//           </PortletFooter>
//         </Form>
//       </Portlet>
//     </>
//   );
// };
export default connect(null, { CreateItem })(TripUserCreatePage);
