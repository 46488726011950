import FormHookDatePicker from "../../base/components/forms/formHookDatePicker";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import moment from "moment/moment";
import DatePicker from "react-datepicker";

const ChangeDate = ({values, set}) =>{
  const formController = useForm()
  const {watch} = formController
  useEffect(()=>{
    console.log('watch',watch())
    const time = {}
    time.dateFrom = moment(watch().dateFrom,'DD.MM.YYYY').toDate()
    time.dateTo = moment(watch().dateTo,'DD.MM.YYYY').toDate()
    console.log('watchTime',time)
    set(time)
  },[watch('dateFrom'),watch('dateTo')])
  return <form className={'d-flex'}>
    <FormHookDatePicker
      formController={formController}
      dateRules={{ required: true }}
      date={values.dateFrom}
      dateName={`dateFrom`}
      timeRules={{}}
      time={"00:00"}
      timeName={``}
    />
    <FormHookDatePicker
      formController={formController}
      dateRules={{ required: true }}
      date={values.dateTo}
      dateName={`dateTo`}
      timeRules={{}}
      time={"00:00"}
      timeName={``}
    />
  </form>
}
export default ChangeDate