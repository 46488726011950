import React from 'react'
import {PortletBody, PortletFooter} from "../../../../components/Portlet";
import {Formik} from "formik";
import {Col, Form, Row} from "react-bootstrap";
import LoadingButton from "../../../base/components/loadingButton";
import * as yup from "yup";
import FormRow from "../../../base/components/forms/formRow";
import { connect } from "react-redux";
import {LoadList} from "../../../../redux/actions/base";
import CountrySelect from "../../../base/components/forms/countrySelect";

const CountryForm  = ({data, onSubmit, className, LoadList}) =>{
  const validationSchema = yup.object().shape({
    name: yup.string().default(""),
    nameEn: yup.string().required().default(""),
    code: yup.string().default(""),
    stateCode: yup.string().default(""),
    country: yup.string().required().default(""),
    lat: yup.number().nullable().default(null),
    lon: yup.number().nullable().default(null),
  });
  const initialValues = data?data: validationSchema.cast()
  return <div className={className}>
    <Formik initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              onSubmit(values, () => {
                setSubmitting(false);
              })
            }}
    >
      {( {values,
           errors,
           touched,
           handleChange,
           handleSubmit,
           isSubmitting,
           setFieldValue
      }) => {
        console.log(errors)
        console.log(values)
        return <form noValidate={true} onSubmit={handleSubmit}>

          <PortletBody>
            <FormRow value={values.name}
                     name={'name'}
                     title={"Название города"}
                     errors={errors}
                     handleChange={handleChange}
                     touched={touched}
            />
            <FormRow value={values.nameEn}
                     name={'nameEn'}
                     title={"Название En"}
                     errors={errors}
                     handleChange={handleChange}
                     touched={touched}
            />
            <FormRow value={values.code}
                     name={'code'}
                     title={"Код города"}
                     errors={errors}
                     handleChange={handleChange}
                     touched={touched}
            />
            <FormRow value={values.stateCode}
                     name={'stateCode'}
                     title={"Код штата"}
                     errors={errors}
                     handleChange={handleChange}
                     touched={touched}
            />
            <FormRow value={values.lat}
                     name={'lat'}
                     title={"lat"}
                     errors={errors}
                     handleChange={handleChange}
                     touched={touched}
                     type={'number'}
            />
            <FormRow value={values.lon}
                     name={'lon'}
                     title={"lon"}
                     errors={errors}
                     handleChange={handleChange}
                     touched={touched}
                     type={'number'}
            />
            <Form.Group as={Row}>
              <Form.Label column sm="2">Страна</Form.Label>
              <Col sm={10}>
                <CountrySelect cb={(val)=>setFieldValue("country",val)}
                               value={values.country} />
              </Col>
            </Form.Group>
          </PortletBody>
          <PortletFooter>
            <Row>
              <Col md={{ span: 10, offset: 2 }}>
                <LoadingButton title={"Сохранить"} disabled={isSubmitting} loading={isSubmitting}/>
              </Col>
            </Row>
          </PortletFooter>
        </form>
      }}
    </Formik>
  </div>
}
export default connect(null, {LoadList})(CountryForm);