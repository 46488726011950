

export const showCount = (array) =>{
  let countTrue =0
  let countFalse = 0
  array.forEach(i=>{
    if(i.total>0){
      countTrue+= 1
    }else{
      countFalse+=1
    }
  })
  return `${countTrue}${countFalse>0 ? `-${countFalse}`:''}`
}