import React, {useState} from 'react';
import LocationPicker from "../../../components/locationPicker";


const MarkUpPage = () => {

  const [location, setLocation] = useState({lat:1, lon:1})

  return <div className="row">
    <div className="col-xl-6">
      <div className="row row-full-height">
        <div className="col-sm-12 col-md-12 col-lg-6">
          hello123 - {location.lat} = {location.lon}
          <LocationPicker lat={location.lat}
                          lon={location.lon}
                          onSubmit={setLocation}
          />
        </div>
      </div>
    </div>
  </div>
};
export default MarkUpPage;
