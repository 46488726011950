import React, {useEffect, useState} from 'react';
import {Row} from "reactstrap";
import {Form} from "react-bootstrap";
import DateToFromPicker from "../../../touristServices/components/dateToFromPicker";
import SearchSelect from "../../../../components/searchSelect";
import axios from "../../../../helpers/axios";

const FindOptions = ({query, setQuery, tickets}) => {
  const [users, setUsers] = useState()
  useEffect(() => {
    axios.post('/api/trips/users/searchInput')
      .then(({data})=>{
        console.log('data', data)
        setUsers(data.data.map(u=>({label:u.label,value:u.value._id})))
      })
      .catch(e=>{
        console.log('err',e)
        window.alert(e.response.data.message[0])
      })
  }, []);
  if(!users){
    return <></>
  }
  return (
    <div className={'d-flex gap-2'}>
      <div style={{position: 'relative', width: 200}}>
        <label className={'absolutLabel'}>Имя</label>
        <Form.Control
          onChange={(e) => setQuery(q => ({...q, name: e.target.value}))}
          className={'form-control'}
          placeholder="Поиск..."
        />
      </div>
      {!tickets && <DateToFromPicker label={'Даты с-по'} setOptions={(v) => {
        setQuery(q => ({...q, execDate: v}))
      }}/>}
      <div style={{width: 250}}>
        <SearchSelect
          onChange={(v) => {
            setQuery(q => ({...q, tourUser: v}))
          }}
          defaultValue={query.tourUser}
          label={"Видно для туриста"}
          array={[{value: '', label: 'Все'}, ...users]}/>
      </div>
    </div>
  );
};

export default FindOptions;