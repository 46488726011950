import React from 'react'
import clsx from "clsx";

function AuthButton (props) {

  const {loading, disabled, title} = props;

  return <button
    id="kt_login_signin_submit"
    type="submit"
    disabled={disabled}
    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
      {
        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
      }
    )}`}
    style={{paddingRight: loading?"3.5rem":"2.5rem"}}
  >
    {title?title: "Войти"}
  </button>
}

export default AuthButton;