import React, {useEffect, useState} from 'react';
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import BlockUi from "@availity/block-ui";
import RateHawkDay from "./RateHawkDay";
import moment from "moment";
import {Table} from "react-bootstrap";
import ChangeDate from "./changeDate";

const UnidentifiedCash = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(false)
  const [values, setValues] = useState({
    dateFrom: moment().subtract(7, 'days').toDate(),
    dateTo:moment().toDate()})

  useEffect(() => {
    setLoading(true)
    axios.post("/api/dashboard/unidentifiedAtCashGoogle",{
      startDate:moment(values.dateFrom).format('DD.MM.YYYY'),
      endDate:moment(values.dateTo).format('DD.MM.YYYY')
    })
      .then(({data})=>{
        setData(data.data)
        setLoading(false)
      })
      .catch(sayError)
  }, [values]);

  return (
    <BlockUi blocking={loading}>
      <Portlet>
        <PortletHeader title={'Нераспознанные платежи за последние 7 дней'}
                       toolbar={data &&
                         <PortletHeaderToolbar>
                           <div className={'d-flex align-items-center gap-4'}>
                             <ChangeDate values={values} set={setValues}/>
                             <b className={'text-danger kt-font-xl'}>{data.length}</b>
                           </div>

                         </PortletHeaderToolbar>}/>
        {data && <PortletBody>
          {show ?
            <div>
              <Table bordered>
                <thead>
                <tr>
                  <th>Имя</th>
                  <th>Дата</th>
                  <th>Сумма</th>
                </tr>
                </thead>
                <tbody>
                {data.map((str, i) => {
                  return <tr key={i}>
                    <td>{str.name}</td>
                    <td>{str.date}</td>
                    <td>{str.total}</td>
                  </tr>
                })}
                </tbody>
              </Table>
              <div className={'link-info'}
                   onClick={() => setShow(!show)}>Скрыть
              </div>
            </div>
            :
            <div className={'link-info'}
                 onClick={() => setShow(!show)}>Показать подробности</div>}
        </PortletBody>}
      </Portlet>
    </BlockUi>
  );
};

export default UnidentifiedCash;