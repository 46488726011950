import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Formik} from "formik";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {TextField} from "@material-ui/core";
import AuthButton from "../components/authButton";
import queryString from 'query-string';
import {StartProcess} from "../../../redux/actions/base";
import {useLocation} from "react-router";


function ForgotPassword(props) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const location = useLocation()

  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };
  let params = queryString.parse(location.search);
  console.log(params);
  return (
    <>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              Восстановление пароля
            </h3>
          </div>
            <Formik
              initialValues={{
                email: params.email,
              }}
              onSubmit={(values, { setStatus, setSubmitting, setErrors }) => {
                setSubmitting(true);
                enableLoading();
                props.StartProcess('/api/auth/resetPassword', (resp)=>{
                  setSubmitting(false);
                  if(resp.data.success){
                    setSuccess(true)
                  }else{
                    setFormErrors(resp.data.message)
                  }
                  disableLoading();

                }, {email:values.email})
              }}
            >
              {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }) => (
                <form
                  noValidate={true}
                  autoComplete="off"
                  className="kt-form"
                  onSubmit={handleSubmit}
                >

                  {success?
                    <>
                      <div className="form-group">
                        <p>Вам было отправленно письмо на почту {values.email} для восстановления пароля.</p>
                      </div>
                      <div className="kt-login__actions">
                        <Link
                          to="/auth/login"
                          className="kt-link kt-login__link-forgot"
                        >
                          Назад
                        </Link>
                      </div>
                    </>
                    :
                    <>
                      <div className="form-group">
                        <div>
                          <p className="kt-font-danger kt-font-bold">{formErrors}</p>
                        </div>
                        <TextField
                          type="email"
                          label="Почта"
                          margin="normal"
                          className="kt-width-full"
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          helperText={touched.email && errors.email}
                          error={Boolean(touched.email && errors.email)}
                        />
                      </div>
                      <div className="kt-login__actions">
                        <Link
                          to="/auth/login"
                          className="kt-link kt-login__link-forgot"
                        >
                          Назад
                        </Link>

                        <AuthButton title={'Отправить'} loading={loading} isSubmitting={isSubmitting}/>
                      </div>
                    </>
                  }
                </form>
              )}
            </Formik>
        </div>
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    null,
    {StartProcess}
  )(ForgotPassword)
);
