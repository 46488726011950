import React, { useState } from 'react';
import Select from 'react-select';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import _ from 'lodash'
import {Controller, useForm} from "react-hook-form";

const IconSelect = ({onChange, defaultValue, control, prefix}) =>{
  const iconList = _.uniqBy(Object.keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon]), 'iconName');
  const newArr = iconList.map((item,index)=>{
    return {'label' : item.iconName, 'value' : `fa-solid fa-${item.iconName}`}
  })
  const formatOptionLabel = ({ value, label, customAbbreviation }) => (
    <div style={{ display: "flex" }}>
      <div style={{ marginRight: "10px", color: "red" }}>
        <i className={`fa fa-${label}`} style={{color: 'black'}}></i>
      </div>
      <div>{label}</div>
    </div>
  );

  return <Controller
    control={control}
    name={`${prefix}.icon`}
    render={({
               field: { onChange, onBlur, value, name, ref },
               fieldState: { invalid, isTouched, isDirty, error },
               formState,
             }) => (
      <Select options={newArr}
              onChange={(val)=>onChange(val.value)}
              defaultValue={_.find(newArr,{value: defaultValue})}
              formatOptionLabel={formatOptionLabel}/>
    )}
  />
}
export default IconSelect