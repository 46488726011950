import React from "react";
import UserProfile from "../../../modules/base/components/userProfile";

export default class Topbar extends React.Component {
  render() {
    return (
      <div className="kt-header__topbar">
        <UserProfile showAvatar={true} showHi={true} showBadge={true} />
      </div>
    );
  }
}
