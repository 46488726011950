import React, {useEffect, useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Form from "react-bootstrap/esm/Form";
import BlockUi from "@availity/block-ui";
import {connect} from "react-redux";
import {CreateItem} from "../../../redux/actions/base";
import axios from "../../../helpers/axios";

const ParserSendMess = (props) =>{
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [parseMess ,setParseMess]=useState('')
  const [error, setError] = useState(null)
  const handleClose = () => {
    setIsVisible(false);
    setParseMess('')
    setError('')
  }
  const handleShow = () => setIsVisible(true);
  const sendParseMess = ()=>{
    const send = {
      parseText: props.parseText,
      message: parseMess,
      parseType: props.type
    }
    if (props.parseText === ''){
      setError('Вы не парсили никакой текст. Окно закроется через 3 секунды')
      setTimeout(handleClose, 3000)
    } else if(parseMess.length< 5){
      setError('Пожалуйста введите сообщение, не короче 5 символов')
    } else {
      setLoading(true)
      axios
        .put('/api/parseCheck', send)
        .then(()=>{
        alert('Ваше сообщение отправлено!')
        handleClose()
      }).catch(()=>alert('Что-то пошло не так'))
        .finally(()=>setLoading(false))
    }

    console.log('data', send)

  }
  return <div className={'parserSendMess'}
              onClick={handleShow}>
    <i className="fa fa-bug" />
    <Modal isOpen={isVisible}
           toggle={() => {
             setIsVisible(!isVisible);
           }}>
      <ModalHeader>
        Введите сообщение
      </ModalHeader>
      <BlockUi blocking={loading}>
        <ModalBody>
          <Form.Control as="textarea"
                        rows="10"
                        onChange={(newVal) => setParseMess(newVal.target.value)}
          />
          {error && <div>{error}</div>}
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={handleClose}>
            Закрыть
          </Button>
          <Button variant="primary" onClick={sendParseMess}>
            Отправить сообщение
            {
              loading ?
                <span><i className="fa fa-spinner fa-spin"></i></span>
                :
                null
            }
          </Button>
        </ModalFooter>
      </BlockUi>
    </Modal>
  </div>
}
export default connect(null, { CreateItem })(ParserSendMess)
