import Select from "react-select";
import _ from "lodash";
import {Controller} from "react-hook-form";
import React, {useEffect} from "react";
import moment from "moment";

const ReviceSelector = ({name, control, options})=>{
  useEffect(()=>{},[options])
  console.log('options', options)
  const reviceColor = (nums) =>{
    if (nums.reduce((a, b) => a + b, 0) === 0){
      return '#B7F46E'
    }
    return '#FFC373'
  }
  const newOptions = options.map(item=>{
    return {value: item.id,
      label: ` ${item.name && `${item.name}`} 
    ${item.numberResult !== undefined ? `${item.numberResult.join('/')}`:''} 
    ${item.createdDate || item.createdBy ? 'Создано:': ''}
    ${item.createdDate !== undefined ? moment(item.createdDate).format("DD.MM.YY HH:mm"): ''}
    ${item.createdBy !== undefined ? item.createdBy: ''} `,
    nums: item.numberResult.slice(1)}
  })
  newOptions.push({value: '', label: 'Сверка не выбрана', nums: []})


  const formatOptionLabel = ({ label, nums }) => {
    return (
    <div style={{display:'flex', alignItems:'center'}}>
      <i className="fa fa-circle"
         aria-hidden="true"
         style={nums.length !== 0 ? {color:`${reviceColor(nums)}`, marginRight: '5px', marginBottom: '2px'}:{display: 'none'}}></i>
      <div>{label}</div>

    </div>
  )};
  console.log('newOptions', newOptions[0])
  return<Controller name={name}
                    control={control}
                    render={({
                               field: { onChange, onBlur, value, name, ref },
                               fieldState: { invalid, isTouched, isDirty, error },
                               formState,
                             })=>(
                        <Select
                          options={newOptions}
                          value={_.find(newOptions, {value})}
                          onChange={val => {
                            onChange(val.value);
                          }}
                          formatOptionLabel={formatOptionLabel}

                        />
                    )}/>
}
export default ReviceSelector