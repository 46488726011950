import React, { useState, useEffect } from "react";
import { Button, Col, FormLabel, Modal, Row } from "react-bootstrap";
import CustomDatePicker from "../../../../../../components/customDatePicker";
import moment from "moment";
import _ from "lodash";

const HotelText = ({ formController, index }) => {
  const [formatedText, setFormatedText] = useState(false);
  const [show, setShow] = useState(false);

  function declOfNum(n, titles) {
    return titles[
      n % 10 == 1 && n % 100 != 11
        ? 0
        : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
        ? 1
        : 2
    ];
  }

  const handleClose = () => setShow(false);
  const handleShow = () => {
    const formData = formController.getValues();
    const hotel = formData.items[index];
    let text = "";
    text += hotel.name + "\n";
    const checkInDates = hotel.rooms.map(d =>
      moment(d.startDate, "DD MMM YYYY")
    );
    text += moment.min(checkInDates).format("DD.MM");
    const checkOutDates = hotel.rooms.map(d =>
      moment(d.endDate, "DD MMM YYYY")
    );
    text += " - " + moment.max(checkOutDates).format("DD.MM") + "\n";
    const roomsTitles = ["номер", "номера", "номеров"];
    const roomsLength = hotel.rooms.length;
    text += roomsLength + " " + declOfNum(roomsLength, roomsTitles) + ", ";
    let paxes = [];
    hotel.rooms.forEach(i => {
      paxes.push(i.mainPax);
      paxes = paxes.concat(i.otherPaxes);
    });
    paxes = _.uniq(paxes);
    const paxTitles = ["гость", "гостя", "гостей"];
    text += paxes.length + " " + declOfNum(paxes.length, paxTitles) + "\n\n";
    hotel.rooms.forEach((room, i) => {
      const numberTitles = {
        1: "ый",
        2: "ой",
        3: "ий",
        4: "ый",
        5: "ый",
        6: "ой",
        7: "ой",
        8: "ой",
        9: "ый",
        10: "ый"
      };
      text +=
        "" +
        (i + 1) +
        numberTitles[i + 1] +
        " (" +
        moment(room.startDate).format("DD.MM") +
        " - " +
        moment(room.endDate).format("DD.MM") +
        ")\n";
      text += room.name + "\n---\n";
      const roomPaxes = [room.mainPax].concat(room.otherPaxes);
      roomPaxes.forEach((pax, index) => {
        const paxId = _.find(formData.paxes, { tempId: pax });
        if (index != 0) {
          text += ", ";
        }
        text += paxId.lastName + " " + paxId.firstName;
      });
      text += "\n\n";
    });
    setShow(true);
    setFormatedText(text);
  };

  return (
    <>
      <Button onClick={handleShow}>Текстом</Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Отель текстом для вотсапа</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} style={{ whiteSpace: "pre-wrap" }}>
              {formatedText}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HotelText;
