import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GetItem } from "../../../../redux/actions/base";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import _ from "lodash";
import ReviseItemRender from "../../components/reviseItemRender";
import reviseItemTypes from "../../helpers/reviseItemTypes";
import { useParams } from "react-router-dom";
import CheckedReviseButton from "../../components/CheckedReviseButton";
import ShowAllDocButton from "../../components/showAllDocButton";

const ReviseItemPage = props => {
  const [item, setItem] = useState();

  const { id } = useParams();
  useEffect(() => {
    props.GetItem("/api/racoon/revise/item", id, ({ data }) => {
      setItem(data.revise);
      console.log("item", data);
    });
  }, []);
  return (
    <>
      <Portlet>
        <PortletHeader
          title={_.find(reviseItemTypes, { value: item?.reviseType })?.name}
          toolbar={item && <PortletHeaderToolbar>
            <ShowAllDocButton id={id} />
            <CheckedReviseButton id={id} checked={item.checked} url={'/api/racoon/revise/item/toggle/'}/>
          </PortletHeaderToolbar> }
        />
        <PortletBody>
          {item ? (
            <>
              <h3 className={"mb-3 mt-3"}>Сохраненная сверка</h3>
              <ReviseItemRender revise={item} />
            </>
          ) : (
            "Загрузка"
          )}
        </PortletBody>
      </Portlet>
    </>
  );
};

export default connect(null, { GetItem })(ReviseItemPage);
