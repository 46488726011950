import { Route, Routes } from "react-router";
import AirplaneList from "./pages/list";
import AirplaneEditPage from "./pages/edit";
import React from "react";
import { AirplaneEditRoute, AirplaneListRoute } from "../../routesConstants";
import ErrorHandler from "../../base/components/errorHandler";
import AirplaneCreatePage from "./pages/create";

export default function() {
  return (
    <Routes>
      <Route path={"/"} exact={true} element={<AirplaneList />} />
      <Route path={"edit/:id"} exact={true} element={<AirplaneEditPage/>} />
      <Route path={"create"} exact={true} element={<AirplaneCreatePage/>} />
    </Routes>
  );
}
