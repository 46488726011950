import React, { useEffect } from "react";
import { Button, Col, Form, FormLabel, Row } from "react-bootstrap";
import TextPaxes from "../trip/components/tripFormParts/buttons/textPaxes";
import ParsePaxesButton from "../trip/components/tripFormParts/buttons/parsePaxesButton";
import { useFieldArray } from "react-hook-form";
import { ages, titles, documentTypes } from "../../../helpers/enums";
import RemoveLink from "../../air/airBooking/components/removeLink";
import PhotoInput from "../trip/components/tripFormParts/photoInput";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import FormHookSelect from "../../air/airBooking/components/formHookSelect";
import UploadDocs from "./uploadDocs";
import {deep_value} from "../../../helpers/formHelpers";

const DocumentsBlock = ({ documents, formController, prefix }) => {
  const {
    control,
    register,
    setValue,
    formState: { errors }
  } = formController;
  const documentsControl = useFieldArray({
    control,
    name: `${prefix}`
  });
  const { fields, remove, append } = documentsControl;

  useEffect(
    () =>
      !documentsControl.fields.length && documents && documents.length
        ? append(documents)
        : () => {},
    []
  );
  const addDocument = () => {
    append({
      type: documentTypes[0],
      _id: null
    });
  };
  const removeDocument = index => {
    remove(index);
  };

  return (
    <>
      <br />
      <h5>
        Документы{" "}
        <Button size={"sm"} onClick={() => addDocument()}>
          <i className={"fa fa-plus"}></i>
        </Button>{" "}
      </h5>
      {fields.map((document, index) => {
        return (
          <Row key={document.id}>
            <Col xs={3}>
              <FormLabel>Название</FormLabel>
              <FormHookInput
                defaultValue={document.name}
                register={register(`${prefix}[${index}].name`, {
                  required: true,
                  minLength: 2
                })}
                errors={errors}
              />
            </Col>
            <Col xs={3}>
              <FormLabel>Тип</FormLabel>
              <FormHookSelect
                register={register(`${prefix}[${index}].type`, {
                  required: true
                })}
                defaultValue={document.type}
              >
                {documentTypes.map((item, i) => (
                  <option value={item} key={i}>
                    {item}
                  </option>
                ))}
              </FormHookSelect>
            </Col>
            <Col xs={3}>
              <UploadDocs docs={document.url}
                          folder={'tripsOffersDocs'}
                          formController={formController}
                          name={`${prefix}[${index}].url`}
                          title={'Файл'}/>
            </Col>
            <Col xs={3}>
              <RemoveLink
                onClick={() => removeDocument(index)}
                title={"документ"}
              />
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default DocumentsBlock;
