import React from 'react';
import ApiSelect from "./apiSelect";

const CitySelect = ({cb,value})=>{
  return <ApiSelect
    value={value}
    url={"/api/locations/cities/searchInput"}
    cb={cb}
  />
}

export default CitySelect