import { Route, Routes } from "react-router";
import React from "react";
import {
  AirBookingTemplateRoute,
  ParsersCheckRoute,
  TimeTableTemplateRoute
} from "../routesConstants";
import AirBookingTemplateRouter from "./airBooking/routes";
import TimeTableTemplateRouter from "./timeTable/routes";
import ParsersCheckRouter from "./parserCheck/routes";

export default function() {
  return (
    <Routes>
      <Route path={"airBooking/*"} element={<AirBookingTemplateRouter />} />
      <Route path={"timeTable/*"} element={<TimeTableTemplateRouter />} />
      <Route path={"check/*"} element={<ParsersCheckRouter />} />
    </Routes>
  );
}
