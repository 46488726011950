import React, { useState } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../components/Portlet";
import DataTable from "../../../components/dataTable";
import { Link } from "react-router-dom";
import PortletHeaderSearch from "../../base/components/forms/portlerHeaderSearch";
import { UserCreateRoute, UserEditRoute } from "../../routesConstants";


function UserListPage() {
  const columns = [
    {
      Header: "Email",
      accessor: "user",
      Cell: ({ value, row }) => (
        <Link to={UserEditRoute + row.original._id}>{value}</Link>
      )
    },
    {
      Header: "Имя",
      accessor: "firstName"
    },
    {
      Header: "Фамилия",
      accessor: "lastName"
    },
    {
      Header: "Активен",
      accessor: "active",
      Cell: ({ value, row }) => (
        <>
          {value ? (
            <i className={"fa fa-check-circle text-success"} />
          ) : (
            <i className={"fa fa-times-circle text-danger"} />
          )}
        </>
      )
    }
  ];

  const [query, setQuery] = useState("");
  return (
    <>
      <Portlet>
        <PortletHeader
          title={"Список пользователей"}
          toolbar={
            <PortletHeaderToolbar>
              <PortletHeaderSearch
                value={query}
                cb={v => {
                  setQuery(v);
                }}
              />
              <Link
                to={UserCreateRoute}
                className="btn btn-sm btn-brand btn-elevate btn-icon-sm"
              >
                <i className="la la-plus" />
                Добавить
              </Link>
            </PortletHeaderToolbar>
          }
        />
        <PortletBody>
          <DataTable search={query} url={"/api/users"} columns={columns} />
        </PortletBody>
      </Portlet>
    </>
  );
}

export default UserListPage;
