import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import ApiService from "../../../services/apiService";
import {Link} from "react-router-dom";

const UploadDocuments = ({cb, docs,folder, onlylink}) => {
  const [loading,setLoading]=useState(false)
  const [uploadStatus, setUploadStatus] = useState(docs);

  const uploadDocs = async (files) =>{
    console.log(files[0])
    let i = 0
    const fileArray = []
    setLoading(true)
    while (i < files.length) {
      const data = new FormData();
      data.append("file", files[i])
      folder && data.append("folder", folder)
      const resp = await ApiService.post("/api/misc/cloudStorageUpload", data);
      fileArray.push({
        name: files[i].name,
        url: resp.data.fileUrl,
        originalName: files[i].name,
        uploaded: true
      })
      i++;
    }
    setLoading(false)
    setUploadStatus(fileArray)
  }
  useEffect(() => {
    if (
      uploadStatus &&
      uploadStatus.length &&
      uploadStatus.every(u => u.uploaded)
    ){
      cb && cb(uploadStatus.map(s=>{
        delete s.uploaded
        return s
      }))
    }
  }, [uploadStatus]);
  return (
    <>
      <Row className={'mt-3'}>
        <Col>
          <Form.Control
            type={"file"}
            disabled={loading}
            multiple
            onChange={(e)=>{
              uploadDocs(e.target.files)
            }}
          />
        </Col>
        {loading && <div><i className="fa fa-spinner fa-spin"></i></div>}
      </Row>
      {(uploadStatus.length>0 && !onlylink) && <p>Загружено: {uploadStatus.map(d=>(<Link to={d.url} target={'_blank'}>{d.name}</Link>))}</p>}
      {/*{onlylink && <p>Загружено: {uploadStatus.map(d => (<Link to={d} target={'_blank'}>{d}</Link>))}</p>}*/}
    </>

  );
};

export default UploadDocuments;