import React from 'react';
import {Button, Row, Col} from "react-bootstrap";

const AvailableRoomsBlock = ({rooms, addRoom})=> {
  return <>
    <h5>Доступные номера</h5>
    <Row>
      <Col xs={12}>
        {rooms.map((item, index) => {
          return <Button onClick={()=>addRoom(item)} key={index} style={{marginRight: 5, marginBottom: 5}} variant="primary">{item.name}</Button>
        })}
      </Col>
    </Row>
  </>
}

export default AvailableRoomsBlock;
