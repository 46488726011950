import React, {useState} from 'react';
import {Link} from "react-router-dom";
import numberFormat from "../../../helpers/numberFormat";

const PaxTableItem = ({autoShow,pax}) => {
  const [show, setShow] = useState(autoShow)
  return (
    <>
      <tr>
        <th colSpan={5} onClick={()=>setShow(!show)} style={{cursor:'pointer'}}>
          <div className={'d-flex justify-content-between'}>
            <div style={{marginLeft: '50px'}}>{pax[0].pax}</div>
            {show ? <i className="fa fa-arrow-up"/> : <i className="fa fa-arrow-down"/>}
          </div>

        </th>
      </tr>
      {pax.map((t, i) => {
        return <tr key={i} style={{display: show ? 'table-row' : 'none'}}>
          <td><Link to={t.baseURL + t.id} target={'_blank'}>{t.ticket}</Link></td>
          <td>{t.issueDate}</td>
          <td>{t.pax}</td>
          <td>{numberFormat(t.total)}</td>
          <td>{t.buyer}</td>
        </tr>
      })}
    </>
  );
};

export default PaxTableItem;