import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  buildId : null
}

const buildInfoSlice = createSlice({
  name: 'build',
  initialState,
  reducers:{
    setBuildId: (state, action)=> {state.buildId = action.payload}
  }
})
const {actions, reducer} = buildInfoSlice

export default reducer

export const {setBuildId} = actions
