import React, { useState, useEffect } from "react";
import { Button, Col, FormLabel, Modal, Row } from "react-bootstrap";
import CustomDatePicker from "../../../../../../components/customDatePicker";
import AsyncSelect from "react-select/async";
import { connect } from "react-redux";
import { SearchInput } from "../../../../../../redux/actions/base";
import _ from 'lodash'
import { useFieldArray } from "react-hook-form";

const CopyButton = ({ fieldArray, tripId, formController, SearchInput, type }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { setValue, getValues, control } = formController;
  const onInputStart = (param1, showResults) => {
    if (param1.length > 2) {
      SearchInput(
        "/api/trips/copyElementByName",
        res => {
          if(type){
            const filteredArray = _.filter(res.data.result, {value:{type:type}})
            showResults(filteredArray)
          }else{
            showResults(res.data.result)
          }

          console.log('list', res.data.result)
        },
        { name: param1, tripId }
      );
    }
  };
  const { fields, append, prepend, remove, swap, move, insert } = fieldArray;

  const onSelect = item => {
    const newTrip = getValues();
    append(item.value);
    console.log("copiedItem", item.value);
    //tripSetter(newTrip)
    //append(item.value);
    handleClose();
  };

  return (
    <>
      <Button size={"sm"} onClick={handleShow}>
        Поиск
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Копирование элемента</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <AsyncSelect
                placeholder={"Поиск по названию"}
                value={null}
                onChange={onSelect}
                loadOptions={onInputStart}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { SearchInput })(CopyButton);
