import {Chart} from "react-chartjs-2";
import React from "react";

const TicketDiagramm = ({color, data}) =>{
  const pieChartData = {
    labels: data.suppliers.map(s =>(s.supplierName)) || [],
    datasets: [{
      data: data.suppliers.map(s =>(s.quantity)) || [],
      label: "Продано билетов",
      backgroundColor: color,
      hoverBackgroundColor: ['#af465c', '#2a7eb6', '#b28f3c', '#5bb73c']
    }]
  };
  return <div style={{width:150, height:150,}}>
    <Chart type='doughnut' data={pieChartData} options={{
      plugins: {
        legend: {
          display: false,
        },
      },
    }}/>
  </div>
}
export default TicketDiagramm