import {createBrowserHistory} from "history";
import createSagaMiddleware from "redux-saga";
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import {createLogger} from "redux-logger";
import {store, rootReducer, history, routerMiddleware} from "./reducers";
import rootSaga from "./sagas";
import {persistReducer, persistStore} from "redux-persist";
import {createReduxHistoryContext, reachify} from "redux-first-history";
import {createWouterHook} from "redux-first-history/wouter";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["builder", "base", "misc"]
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const logger = createLogger({
  predicate: (getState, action) =>
    action.type.indexOf("persist/") === -1 &&
    action.type.indexOf("builder/") === -1 &&
    action.type.indexOf("@@router") === -1
});

export const reachHistory = reachify(history);
//if you use wouter

const newStore = () => {
  let store = createStore(
    persistedReducer,
    compose(
      applyMiddleware(
        routerMiddleware, // for dispatching history actions
        logger,
        sagaMiddleware
      )
    )
  );
  let persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);
  return {store, persistor};
};

const newItem = newStore()

export default newItem;
