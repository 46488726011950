import moment from "moment";


moment.locale('ru', {
  monthsShort : {
    // по CLDR именно "июл." и "июн.", но какой смысл менять букву на точку ?
    format: 'янв_февр_мар_апр_мая_июня_июля_авг_сент_окт_нояб_дек'.split('_'),
    standalone: 'янв_февр_март_апр_май_июнь_июль_авг_сент_окт_нояб_дек'.split('_')
  },
})

Object.size = function (obj) {
  var size = 0, key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) size++;
  }
  return size;
};
Number.prototype.toMoney = function (decimals, decimal_sep, thousands_sep) {
  var n = this,
    c = isNaN(decimals) ? 2 : Math.abs(decimals), //if decimal is zero we must take it, it means user does not want to show any decimal
    d = decimal_sep || '.', //if no decimal separator is passed we use the dot as default decimal separator (we MUST use a decimal separator)

    /*
    according to [http://stackoverflow.com/questions/411352/how-best-to-determine-if-an-argument-is-not-sent-to-the-javascript-function]
    the fastest way to check for not defined parameter is to use typeof value === 'undefined'
    rather than doing value === undefined.
    */
    t = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep, //if you don't want to use a thousands separator you can pass empty string as thousands_sep value

    sign = (n < 0) ? '-' : '',

    //extracting the absolute value of the integer part of the number and converting to string
    i = parseInt(n = Math.abs(n).toFixed(c)) + '',

    j = ((j = i.length) > 3) ? j % 3 : 0;
  return sign + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '');
}

moment.locale("EN");

export const convertDate = function(codeBlock, param, date, language) {
  if (typeof param.param === "undefined") {
    param.param = "DD MMM YYYY HH:mm";
  }
  return moment.utc(date, 'DD.MM.YYYY HH:mm').locale(language).format(param.param);
};

export const getMapNames = function(obj) {
  var rr = "";
  var iteration = 1;
  for (var i = 0, keys = Object.keys(obj); i < keys.length; i++) {
    rr += keys[i];
    if (iteration != Object.size(obj)) {
      rr += "|";
    }
    iteration++;
  }
  return rr;
};
export const getNormalValue = function(val) {
  if (val == "undefined" || val == null || val == "00:00") {
    return "";
  }
  return val;
};
export const takeParams = function(template, possibleVars) {
  var m;
  var result = [];
  var re = new RegExp("{(" + possibleVars + ")(\=\"([^{}\"]+)\")?.*?}", "gi");
  while ((m = re.exec(template)) !== null) {
    if (m.index === re.lastIndex) {
      re.lastIndex++;
    }
    var item = {
      capture: m[0],
      code: m[1],
      param: m[3],
      settings: {}
    }
    var res = / ([A-Z]+)(="([^"]*)")?/gi;
    var strs = m[0];
    var ms;

    while ((ms = res.exec(strs)) !== null) {
      if (ms.index === res.lastIndex) {
        res.lastIndex++;
      }
      item.settings[ms[1]] = ms[3];
    }
    result.push(item);
  }
  return result;
};
export const convertTrans = function(text, language) {
  var matches = text.match(/{Trans RU="([^{}]+)" EN="([^{}]+)"}/gi);
  if (matches != null) {
    for (var i = 0; i < matches.length; i++) {

      var replacer = "$1";
      if (language === "en") {
        replacer = "$2";
      }
      var replace = matches[i].replace(/{Trans RU="([^{}]+)" EN="([^{}]+)"}/gi, replacer);
      text = text.replace(matches[i], replace);
    }
  }
  return text;
};
export const replaceIfs = function(pattern, subject, map) {
  var finds = pattern.match(/{if="([A-Z]{1,3})"}([\S\s]+?){\/if}/ig);

  if (finds != null) {
    for (var g = 0; g < finds.length; g++) {
      var gpoint = finds[g].replace(/{if="([A-Z]{1,3})"}([\S\s]+?){\/if}/i, '$1');
      var val = "";
      if (!(gpoint in map)) {
        continue;
      }
      if (typeof map[gpoint] == "function") {
        val = map[gpoint](subject);
      }
      if (val != "" && val != null) {
        var gpoint2 = finds[g].replace(/{if="([A-Z]{1,3})"}([\S\s]+?){\/if}/i, '$2');
        pattern = pattern.replace(finds[g], gpoint2);
      } else {
        pattern = pattern.replace(finds[g], "");
      }
    }
  }
  return pattern;
};
export const convertBase = (template, data, patternMap, language) => {
  let result = template;
  const snaps = takeParams(result, getMapNames(patternMap));
  for (let j = 0; j < snaps.length; j++) {
    result = result.replace(snaps[j].capture, getNormalValue( patternMap[snaps[j].code](data, snaps[j])));
  }
  result = convertTrans(result, language);
  result = replaceEl(result)
  return result;
}

const replaceEl = (result)=>{
  result = result.replace( /(\{NL\})+/g,' \n');

 // result = result.replace(/^\s+|\s+$/g, '').replace(/\n\s*\n/g, '\n').replace(/\n*\{NL\}\n*/g, '\n');
  var re = /(\{EL\})+/g;
  var m;
  var matches = [];
  while ((m = re.exec(result)) !== null) {
    if (m.index === re.lastIndex) {
      re.lastIndex++;
    }
    matches.push(m[0]);
  }
  matches.sort(function (a, b) {
    return b.length - a.length;
  });
  for (var i = 0; i < matches.length; i++) {
    var captureTimes = matches[i].length / 4;
    result = result.replace(matches[i], (new Array(captureTimes + 1)).join("\n"));
  }
  return result
};
