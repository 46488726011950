import React, {useState} from 'react';
import {connect, useSelector} from "react-redux";
import {StartProcess} from "../../../redux/actions/base";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import Select from "react-select";
import RenderParseInputs from "../components/RenderParseInputs";
import RenderRacoonTickets from "../components/RenderRacoonTickets";



const TicketsParser = props => {
  const [type, setType] = useState(null)
  const [data, setData] = useState(null)
  console.log('data', data)

  return (
    <div>
      <Paper elevation={0} style={{ marginBottom: 15 }}>
        <Breadcrumbs aria-label="Breadcrumb" style={{ padding: 15 }}>
          <Typography color="textPrimary">Сверки по билетам</Typography>
        </Breadcrumbs>
      </Paper>
      <Portlet>
        <PortletHeader title={"Варианты"} toolbar={
          <PortletHeaderToolbar>
            <div style={{width:250}}>
              <Select
                options={[
                  {value: 'gk',label:'Google - Kayar'},
                  {value: 'ak',label:'Aruana - Kayar'},
                  {value: 'ga',label:'Google - Aruana'},
                ]}
                onChange={(val)=> {
                  console.log('select',val)
                  setType(val.value)
                  setData(null)
                }}
              />
            </div>

          </PortletHeaderToolbar>
          } />
          {(type && !data) ?
            <RenderParseInputs type={type} cb={(data)=>{setData(data)}}/>:
            <PortletBody>{data ? <RenderRacoonTickets data={data} type={type}/> :'Пожалуйста, выберите тип сверки'}</PortletBody>}
      </Portlet>
    </div>
  );
};

export default connect(null, {StartProcess})(TicketsParser);