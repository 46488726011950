import { Route, Routes } from "react-router";
import TemplateList from "./pages/list";
import AirBookingTemplateCreatePage from "./pages/create";
import AirBookingTemplateEditPage from "./pages/edit";
import TimeTableParsePage from "./pages/parse";
import React from "react";
import {
  TimeTableTemplateParseRoute,
  TimeTableTemplateListRoute,
  TimeTableTemplateCreateRoute,
  TimeTableTemplateEditRoute
} from "../../routesConstants";
import ErrorHandler from "../../base/components/errorHandler";

export default function() {
  return (
    <ErrorHandler>
      <Routes>
        <Route path={"list"} exact={true} element={<TemplateList />} />
        <Route path={"create"} element={<AirBookingTemplateCreatePage />} />
        <Route path={"parse"} element={<TimeTableParsePage />} />
        <Route
          path={"edit/:id"}
          exact={true}
          element={<AirBookingTemplateEditPage />}
        />
      </Routes>
    </ErrorHandler>
  );
}
