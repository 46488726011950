import _ from 'lodash'

function removeElement(obj, pathToArr, index){
  // I don't know of a better way to clone, according to flob, this doesn't play well with React
  //const clone = JSON.parse(JSON.stringify(obj));
  const clone = _.clone(obj);
  const arr = _.get(clone, pathToArr);
  arr.splice(index, 1);
  return clone;
}
function deep_value(obj, path, def) {

  /**
   * If the path is a string, convert it to an array
   * @param  {String|Array} path The path
   * @return {Array}             The path array
   */
  var stringToPath = function (path) {

    // If the path isn't a string, return it
    if (typeof path !== 'string') return path;

    // Create new array
    var output = [];

    // Split to an array with dot notation
    path.split('.').forEach(function (item, index) {

      // Split to an array with bracket notation
      item.split(/\[([^}]+)\]/g).forEach(function (key) {

        // Push to the new array
        if (key.length > 0) {
          output.push(key);
        }

      });

    });

    return output;

  };

  // Get the path as an array
  path = stringToPath(path);

  // Cache the current object
  var current = obj;

  // For each item in the path, dig into the object
  for (var i = 0; i < path.length; i++) {

    // If the item isn't found, return the default (or null)
    if (!current[path[i]]) return def;

    // Otherwise, update the current  value
    current = current[path[i]];

  }

  return current;

};

export {
  removeElement,
  deep_value
}
