import React, {useState} from 'react';
import LoadingButton from "../../base/components/loadingButton";
import axios from "../../../helpers/axios";
import { saveAs } from 'file-saver'


const DownloadButton = ({info,edit}) => {
  const [loading, setLoading] = useState(false)
  const getFile = () =>{
    const neededInfo = {}
    const howMuch =(array, key) =>{
      const newArray = []
      array.forEach(t=>{
        if(t.tableData.length >0){
          newArray.push(t)
        }
      })
      if(newArray.length>0){
        neededInfo[key] = newArray
      }
    }
    Object.keys(info).forEach(key=>{
      howMuch(info[key], key)
    })
    console.log("neededInfo",neededInfo)
    setLoading(true)

    axios.post("/api/salesRegister/generateXlsx", neededInfo,{responseType:'blob'})
      .then((response) => {
        const name = edit? `${info.fromOneC[0].header}.xlsx`: `${info.fromOneC[0].header.counterparty} ${info.fromOneC[0].header.date}.xlsx`
        saveAs(response.data, name);
      })
      .catch(e=>{
        console.log('err',e)
        window.alert(e.response.data.message[0])
      }).finally(()=>setLoading(false))
    // console.log(info)
  }
  return (
    <LoadingButton
      title={"Скачать файл Exel"}
      type={'button'}
      disabled={!info}
      loading={loading}
      onClick={getFile}
    />
  );
};

export default DownloadButton;