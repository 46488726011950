import React from 'react';
import {Col, Row} from "react-bootstrap";
import moment from "moment/moment";
import ReloadReviseButton from "./reloadReviseButton";
import CopyDownloadLink from "./CopyDownloadLink";

const ReviseDocumentWrapper = ({revise,children,withoutFils}) => {
  return (
    <Row>
      <Col>
        <p>
          Создал - {revise.createdBy} <br />
          Создано - {moment(revise.createdDate).format("DD.MM.YYYY")} <br />
          Даты - {moment(revise.startDate).format("DD.MM.YYYY")} -{" "}
          {moment(revise.endDate).format("DD.MM.YYYY")}
          <br />
          <div className={'d-flex justify-content-between'}>
            <div>
              <b>Сверки</b> <br/>
              <p>Сверки: "Аруана и Каяр","Сайт и Каяр","Каяр и Яна" загружаются напрямую из 1с(для них файлы не загружаются)</p>
              {children}
            </div>
            {!withoutFils &&
              <div style={{textAlign: 'right'}}>
                Чтобы скачать файл(кликнув по файлу) нужно:<br/>
                - Открыть настройки браузера;<br/>
                - Вбить в строку поиска "Небезопасный контент"<br/>
                - В разделе "Разрешить сайтам показывать небезопасный контент"<br/> вбить наш сайт:
                https://kayar-backend-dev.herokuapp.com/
              </div>
            }

          </div>
        </p>
      </Col>
    </Row>
  );
};

export default ReviseDocumentWrapper;