import React from 'react';
import moment from "moment";
import _ from 'lodash'
import numberFormat from "../../../helpers/numberFormat";

const RateHawkDay = ({info,label}) => {
  const total = _.sumBy(info, 'toPay')
  return (
    <div>
      <div style={{padding:'1rem 25px',
        backgroundColor:label.color}} className={'d-flex justify-content-between align-items-center'}>
        <b>{label.name}</b>
        <div>
          Отелей: {info.length}, Сумма: {numberFormat(total)} KZT
        </div>
      </div>
      {info.map((item,index)=>{
        const margin = index===0 ? '1rem 0 1rem 0' : index === info.length-1 ? '0 0 1rem 0' : '0'
        return <div key={index} style={{padding:'0 25px', margin}}>
          {item.hotel}<br/>
          {item.startDate.slice(0, 5)}-{item.endDate.slice(0, 5)} {item.pax}<br/>
          <div className={'d-flex justify-content-between align-items-center'}>
            <div>{numberFormat(item.toPay)} KZT ({moment().locale("ru").to(moment.utc(item.paymentDue))})</div>
            <div>Оплатить до: {moment.utc(item.paymentDue).format('DD.MM.YYYY HH:mm')}</div>
          </div>
          {index !== info.length-1 && <hr/>}
        </div>
      })}
    </div>
  );
};

export default RateHawkDay;