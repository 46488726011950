import axios from "axios";

const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
axiosInstance.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axiosInstance.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axiosInstance.defaults.headers.post["Access-Control-Allow-Methods"] =
  "GET, POST, PUT, DELETE, OPTIONS, PATCH";
axiosInstance.defaults.headers.post["Access-Control-Allow-Headers"] =
  "Content-Type, Authorization";
// axiosInstance.defaults.headers.common["Authorization"] =
//   localStorage.getItem("jwtToken");


export default axiosInstance;