import React, { useEffect, useMemo, useState } from "react";
import {
  Tab,
  Tabs,
  Row,
  Col,
  FormLabel,
  Form,
  FormControl
} from "react-bootstrap";
import CustomDatePicker from "../../../components/customDatePicker";
import RemoveLink from "../../air/airBooking/components/removeLink";
import FastInput from "../../../components/FastInput";
import { useFieldArray, Controller, useController } from "react-hook-form";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import MEDitor from "@uiw/react-md-editor";
import _ from "lodash";

const DescriptionsBlock = ({ descriptions, formController, prefix, title }) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors }
  } = formController;
  const [tabIndex, setTabIndex] = useState();

  const descTemplate = {
    name: "Decription",
    text: "",
    showOnlyTreeLines: false,
    dontHideText: false
  };
  const { fields, append, replace, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: `${prefix}`
    }
  );
  useEffect(() => {
    if (!fields.length && descriptions && descriptions.length) {
      replace(descriptions);
    }
  }, [descriptions]);

  const addPax = () => {
    append({ ...descTemplate });
    setTabIndex(`desc${fields.length}`);
  };
  const tabClick = key => {
    if (key === "add") {
      addPax();
    } else {
      setTabIndex(key);
    }
  };

  const getDescriptionName = (desc, index) => {
    const { name } = desc;
    if (name) {
      return name;
    }
    return `Описание ${index + 1}`;
  };

  const removeDescription = index => {
    console.log("index removed", index);
    remove(index);
    if (fields.length - 1) {
      setTabIndex(`desc${fields.length - 2}`);
    }
  };
  return (
    <>
      <br />
      <h5>Текстовые блоки {title}</h5>
      <Tabs
        id="controlled-tab-example"
        activeKey={tabIndex}
        onSelect={key => tabClick(key)}
      >
        {fields.map((desc, index) => {
          if (desc === undefined) return null;
          return (
            <Tab
              eventKey={`desc${index}`}
              key={desc.id}
              tabClassName={_.get(errors, `${prefix}[${index}]`) ? 'tab-err' : null}
              title={getDescriptionName(desc, index)}
            >
              <DescriptionBlock
                prefix={`${prefix}[${index}]`}
                errors={errors}
                register={register}
                desc={desc}
                formController={formController}
              />

              <RemoveLink
                onClick={() => removeDescription(index)}
                title={"описание"}
              />
            </Tab>
          );
        })}
        <Tab eventKey={`add`} title={<i className={"fa fa-plus"} />} />
      </Tabs>
    </>
  );
};

const DescriptionBlock = ({
  register,
  prefix,
  desc,
  errors,
  formController
}) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields }
  } = useController({
    name: `${prefix}.text`,
    control: formController.control,
    rules: { required: true },
    defaultValue: desc.text
  });
  return (
    <>
      <Row>
        <Col xs={6}>
          <FormLabel>Название</FormLabel>
          <FormHookInput
            register={register(`${prefix}.name`, {
              required: true,
              minLength: 2
            })}
            defaultValue={desc.name}
            errors={errors}
          />
        </Col>

        <Col xs={6}>
          <br />
          <Form.Check
            type={"checkbox"}
            label={"Показывать первые строчки (3)"}
            defaultChecked={desc.showOnlyTreeLines}
            {...register(`${prefix}.showOnlyTreeLines`)}
          />
          <Form.Check
            type={"checkbox"}
            defaultChecked={desc.dontHideText}
            {...register(`${prefix}.dontHideText`)}
            label={"Не прятать описание"}
          />
        </Col>
      </Row>
      <div data-color-mode="light">
        <MEDitor height={200} value={value} onChange={onChange} />
      </div>
    </>
  );
};

export default DescriptionsBlock;
