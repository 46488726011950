import React, {useEffect, useState} from 'react';
import axios from "../helpers/axios";
import _ from "lodash";
import Select from "react-select";

const SearchSelect = ({defaultValue, array, onChange,label, hasError, style,isMulti}) => {
  const plusStyle = style ||{}
  const defaultVal = isMulti ?
    defaultValue.map(r=>(_.find(array,{'value': r})))
    : _.find(array, {'value': defaultValue})
  const formatOptionLabel = ({ value, label,  }) => (
    <div style={{ display: "flex" }}>
      {label}
    </div>
  );

  return (
    <div style={{position: 'relative'}}>
      <label className={'absolutLabel'}>{label}</label>
      <Select options={array}
              onChange={(val) => {
                console.log('test',val)
                isMulti ? onChange(val.map(v=>(v.value))) :onChange(val.value)
              }}
              defaultValue={defaultVal}
              isMulti={isMulti}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: hasError ? '#FD397A' : '#e2e5ec',
                  ...plusStyle
                }),
                menu:provided => {
                  return {...provided, zIndex: '10000'};
                },
              }}
              formatOptionLabel={formatOptionLabel}/>
    </div>

  );
};

export default SearchSelect;