import React from 'react';
import {Row, Tab, Table, Tabs} from "react-bootstrap";
import {showCount} from "../../../helpers/showCount";
import numberFormat from "../../../helpers/numberFormat";
import moment from "moment";

const SiteKayarRender = ({data}) => {
  return (
    <>
        <Row>
          <Tabs defaultActiveKey="correct">
            <Tab
              eventKey="correct"
              title={`Правильные (${data.correct.length})`}
            >
              <Table striped hover>
                {renderHeader()}
                {renderStandartBody(data.correct)}
              </Table>
            </Tab>
            <Tab
              eventKey="noSite"
              title={`Есть в Kayar но нет на сайте (${showCount(data.siteMissing)})`}
            >
              <Table striped hover>
                {renderHeader()}
                {renderStandartBody(data.siteMissing)}
              </Table>
            </Tab>
            <Tab
              eventKey="noKayar"
              title={`Есть на сайте но нет в Kayar (${showCount(data.kayarMissing)})`}
            >
              <Table striped hover>
                {renderHeader()}
                {renderStandartBody(data.kayarMissing)}
              </Table>
            </Tab>
            <Tab
              eventKey="diff"
              title={`Не соответствуют (${showCount(data.diff)})`}
            >
              <Table striped bordered hover>
                <thead>
                <tr>
                  <td>Номер</td>
                  <td>Выписан</td>
                  <td className={'text-center'}>КРС Поставщика<br/>Сайт/Каяр</td>
                  <td className={'text-center'}>КРС Покупателя<br/>Сайт/Каяр</td>
                  <td className={'text-center'}>КРС Kayar<br/>Сайт/Каяр</td>
                  <td className={'text-center'}>Итог<br/>Сайт/Каяр</td>
                </tr>
                </thead>
                <tbody>
                {data.diff.map((u, index) => {
                  return (
                    <tr key={index}>
                      <td>
                      {u.airline} {u.ticketNumber}
                      </td>
                      <td >{moment(u.issueDate).format('DD.MM.YYYY')}</td>
                      <td>{renderValue(u.krsSeller)}</td>
                      <td>{renderValue(u.krsBuyer)}</td>
                      <td>{renderValue(u.krsKayar)}</td>
                      <td>{renderValue(u.total)}</td>
                    </tr>
                  );
                })}
                {/*<tr>*/}
                {/*  <td>*/}
                {/*    <b>Итого</b>*/}
                {/*  </td>*/}
                {/*  <td></td>*/}
                {/*  <td>*/}
                {/*    <b>*/}
                {/*      {numberFormat(*/}
                {/*        data.diff*/}
                {/*          .map(u => u.oneCTotal)*/}
                {/*          .reduce((a, b) => a + b, 0)*/}
                {/*      )}*/}
                {/*    </b>*/}
                {/*  </td>*/}
                {/*  <td>*/}
                {/*    <b>*/}
                {/*      {numberFormat(*/}
                {/*        data.diff.map(u => u.total).reduce((a, b) => a + b, 0)*/}
                {/*      )}*/}
                {/*    </b>*/}
                {/*  </td>*/}
                {/*</tr>*/}
                </tbody>
              </Table>
            </Tab>
          </Tabs>
        </Row>
    </>
  );
};

export default SiteKayarRender;

const renderHeader = () => {
  return <thead>
  <tr>
    <td>Номер</td>
    <td>Выписан</td>
    <td>КРС поставщика</td>
    <td>КРС Покупателя</td>
    <td>КРС Kayar</td>
    <td>Итого</td>
  </tr>
  </thead>
}

const renderStandartBody = (array) =>{
  return <tbody>
  {array.map((u, index) => {
    return (
      <tr key={index}>
        <td>
          {u.airline} {u.ticketNumber}{u.refund && '-B'}
        </td>
        <td>{moment(u.issueDate).format('DD.MM.YYYY')}</td>
        <td>{numberFormat(u.krsSeller)}</td>
        <td>{numberFormat(u.krsBuyer)}</td>
        <td>{numberFormat(u.krsKayar)}</td>
        <td>{numberFormat(u.total)}</td>
      </tr>
    );
  })
  }
  <tr>
    <td colSpan={5}>
      <b>Итого</b>
    </td>
    <td>
      <b>
        {numberFormat(
          array
            .map(u => u.total)
            .reduce((a, b) => a + b, 0)
        )}
      </b>
    </td>
  </tr>
  </tbody>

}

const renderValue = (value) =>{
  if(typeof value !== 'object'){
    return <div className={'text-center'}>{numberFormat(value)}</div>
  }else{
    // return `${numberFormat(value.site)}/${numberFormat(value.kayar)}`
    return <div className={'d-flex justify-content-between'} style={{padding:'0 20px'}}>
      <div>{numberFormat(value.site)}</div>
      <div>{numberFormat(value.kayar)}</div>
    </div>
  }
}