import { Route, Routes } from "react-router";
import React from "react";
import {
  AirportRoute,
  CityRoute,
  CountryRoute,
  CountrySlug
} from "../routesConstants";
import CountryRouter from "./countries/routes";
import CityRouter from "./city/router";
import AirportRouter from "./airport/router";
import CountryList from "./countries/pages/list";
import ErrorHandler from "../base/components/errorHandler";

export default () => {
  return (
    <ErrorHandler>
      <Routes>
        <Route path={"countries/*"} element={<CountryRouter />} />
        <Route path={"city/*"} element={<CityRouter />} />
        <Route path={"airport/*"} element={<AirportRouter />} />
      </Routes>
    </ErrorHandler>
  );
};
