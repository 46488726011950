import React from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/esm/Form";
import moment from "moment";
import RemoveLink from "./removeLink";
import { Controller } from "react-hook-form";
import FormHookSelect from "./formHookSelect";
import FormHookInput from "./formHookInput";

const AirBookingTicketSegment = ({
  prefix,
  formController,
  ticketSegment,
  segments,
  removeSegment
}) => {
  const {
    register,
    formState: { errors }
  } = formController;
  return (
    <Row className={"segmentContainer align-items-center"}>
      <Col xs={3}>
        <FormHookSelect
          errors={errors}
          register={register(`${prefix}.segment`, { required: true })}
          defaultValue={ticketSegment.segment}
        >
          {segments.map((segment, index) => {
            return (
              <option key={index} value={segment.tempId}>
                {segment.departure.airport} - {segment.arrival.airport} (
                {moment(segment.departure.date, 'DD.MM.YYYY').format("DD MMM")})
              </option>
            );
          })}
        </FormHookSelect>
      </Col>
      <Col xs={2}>
        <Form.Control
          placeholder={"Место"}
          onChange={e => {
            e.target.value = ("" + e.target.value).toUpperCase();
          }}
          {...register(`${prefix}.seat`)}
          defaultValue={ticketSegment.seat}
        />
      </Col>
      <Col xs={"auto"}>
        <RemoveLink onClick={() => removeSegment()} title={"сегмент"} />
      </Col>
    </Row>
  );
};

export default AirBookingTicketSegment;
