import React, {useState} from 'react';
import {typeList} from "../pages/revise/reviseSortPage";
import {Button, Col, Form, Modal} from "react-bootstrap";

import ReviseItemsCheck from "./reviseItemsCheck";
import moment from "moment";

const AddReviseItem = ({targetWeek, targetMonth, targetYear, type, reload}) => {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const otherInfo = {
    targetMonth: moment(targetMonth, 'MM').format('M'),
    targetWeek,
    targetYear,
    type
  }
   const allInputsInfo = (str) =>{
    if(str === "silk-kayar"){
      return {
        url: '/api/racoon/pdfParse/checkSilkKayar',
        inputs: [
          {title: 'Сверка от Silk', registerName:'silkFile'},
        ],
        ...otherInfo
      }
    } else if(str === "aruana-kayar"){
      return {
        url: '/api/racoon/pdfParse/checkAruanaKayar',
        inputs: [
        ],
        ...otherInfo
      }
    }else if(str === "kayar-site"){
      return {
        url: '/api/racoon/pdfParse/checkKayarSite',
        inputs: [
        ],
        ...otherInfo
      }
    }else if(str === "iata-aruana"){
      return {
        url: '/api/racoon/pdfParse/checkIataAruana',
        inputs: [
          {title: 'Сверка от Iata', registerName: 'iataFile'},
        ],
        ...otherInfo
      }
    }else {
      return {
        url: '/api/racoon/pdfParse/checkKayarYana',
        inputs: [
        ],
        ...otherInfo
      }
    }

  }
  return (
    <>
      <div className={'d-flex justify-content-between align-items-center'} style={{cursor:'pointer'}} onClick={()=>setShow(true)}>
        {typeList[type]}<i className="la la-plus"/>
      </div>
      <Modal show={show}  dialogClassName="modal-90w" onHide={() => setShow(false)}>
        <ReviseItemsCheck options={allInputsInfo(type)} close={()=>setShow(false)} reload={()=>{
          reload()
          setShow(false)
        }}/>
      </Modal>
    </>
  );
};

export default AddReviseItem;