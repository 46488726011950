import {Controller} from "react-hook-form";
import Select from "react-select";
import _ from "lodash";
import React, {useEffect, useState} from "react";

const CheckTypeSelect = ({defaultValue, control, index, values,name}) =>{

  const [val, setVal] = useState(_.find(values, {value: defaultValue}))
  useEffect(()=>{},[val])
  return <Controller
    control={control}
    name={name}
    render={({
               field: { onChange, onBlur, value, name, ref },
               fieldState: { invalid, isTouched, isDirty, error },
               formState,
             }) => (
      <Select
              value={val}
              options={values}
              onChange={(val)=> {
                console.log('val', val)
                setVal(_.find(values, {value: val.value}))
                onChange(val.value)
              }}

      />
    )}
  />

}
export default CheckTypeSelect