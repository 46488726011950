import React, {useState} from 'react';
import {Button, Carousel, Modal} from "react-bootstrap";
import Form from "react-bootstrap/Form";

const Galery = ({photos}) => {
  const [index, setIndex] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  console.log(photos)
  return (
    <div>
        {photos.map((p,i)=>{
          return <div key={i}
                      style={{width:"100%", marginBottom:'0.5rem', maxHeight:'400px', cursor:'pointer'}}
                      onClick={()=>{
                        handleSelect(i)
                        handleShow()
                      }}>
            <img style={{width:'100%', height:"inherit", maxHeight:'400px', objectFit:'cover'}} src={p.url} alt={p.url} />
          </div>
        })}

      <Modal show={show} dialogClassName="modal-90w" contentClassName="modal-height" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Галерея</Modal.Title>
        </Modal.Header>
        <Modal.Body >
            <Carousel activeIndex={index}  data-bs-theme="dark" slide={false} onSelect={handleSelect}>
              {photos.map((p,i)=>{
                return <Carousel.Item key={i} >
                  <div style={{height: '70vh', width: '100%'}}>
                    <img
                      style={{objectFit: 'contain', width: 'inherit', height:'inherit'}}
                      src={p.url}
                      alt="First slide"
                    />
                  </div>
                </Carousel.Item>
              })}
            </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Galery;