import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  DeleteItem,
  GetItem,
  UpdateItem
} from "../../../../redux/actions/base";
import { useParams } from "react-router-dom";
import { TimeTableTemplateListRoute } from "../../../routesConstants";
import TemplateForm from "../components/templateForm";
import axios from "axios";

const TimeTableTemplateEditPage = props => {
  const [template, setTemplate] = useState(null);
  const [loader, setLoader] = useState(false);

  const { id } = useParams();
  useEffect(() => {
    if (template === null) {
      setLoader(true);
      props.GetItem("/api/parsers/timeTable", id, res => {
        setTemplate(res.data.data);
        setLoader(false);
      });
    }
  }, [template, id, props]);
  const callback = (values, cb) => {
    props.UpdateItem(
      "/api/parsers/timeTable/" + id,
      values,
      cb,
      TimeTableTemplateListRoute
    );
  };
  const deleteAction = () => {
    setLoader(true);
    props.DeleteItem(
      "/api/parsers/timeTable/" + id,
      () => {},
      TimeTableTemplateListRoute
    );
  };
  return (
    <>
      <TemplateForm
        onSubmit={callback}
        data={template}
        title={"Редактирование шаблона"}
      />
    </>
  );
};

export default connect(null, { GetItem, UpdateItem, DeleteItem })(
  TimeTableTemplateEditPage
);
