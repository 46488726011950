import React, { useState } from "react";
import { Row, InputGroup, FormControl, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { StartProcess } from "../../../redux/actions/base";

const BookingComButton = ({ StartProcess, setter }) => {
  const [pressed, setPressed] = useState(false);
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState("");

  const loadHotel = () => {
    setLoading(true);
    const cb = ({ data }) => {
      console.log(data);
      if (data.success) {
        setter(data.hotelData);
      } else {
        alert("Проблема при загрузке отеля");
      }
      setLoading(false);
    };
    StartProcess("/api/trips/parseBookingCom", cb, { link });
  };
  if (pressed) {
    return (
      <InputGroup>
        <FormControl
          placeholder="Ссылка"
          value={link}
          onChange={text => {
            setLink(text.target.value);
          }}
        />
        <Button disabled={!link.length || loading} size={"sm"} onClick={loadHotel}>
          {loading ? "Загружаем" : "Загрузить"}
        </Button>
      </InputGroup>
    );
  }
  return (
    <div>
      <Button size={"sm"}  onClick={() => setPressed(true)}>
        Booking.com
      </Button>
    </div>
  );
};

export default connect(null, { StartProcess })(BookingComButton);
