import React, { useCallback, useState } from "react";
import {
  Col,
  Form,
  FormControl,
  FormLabel,
  Row,
  Tab,
  Tabs,
  Button
} from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import RemoveLink from "../../air/airBooking/components/removeLink";
import { FieldArray } from "formik";
import { titles, ages } from "../../../helpers/enums";
import _ from "lodash";
import FastInput from "../../../components/FastInput";
import { useFieldArray } from "react-hook-form";
import TextPaxes from "../trip/components/tripFormParts/buttons/textPaxes";
import ParsePaxesButton from "../trip/components/tripFormParts/buttons/parsePaxesButton";
import PaxForm from "./paxForm";

const PaxesBlock = ({
  prefix,
  paxes,
  formController,
  paxArray,
  hideButtons
}) => {
  const [refresh, setRefresh] = useState(false);
  const {
    register,
    formState,
    watch,
    control,
    setValue,
    getValues
  } = formController;
  const { fields, append, prepend, remove, swap, move, insert } = paxArray;
  const paxTemplate = {
    title: titles[0],
    firstName: "",
    lastName: "",
    age: ages[0],
    tempId: "",
    tickets: []
  };
  console.log("pax - fields", fields);
  const addPax = (title, lastName, firstName) => {
    const newPax = {
      ...paxTemplate,
      title: title ? title : titles[0],
      firstName,
      lastName
    };

    const getObjectId = (
      m = Math,
      d = Date,
      h = 16,
      s = s => m.floor(s).toString(h)
    ) =>
      s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));

    let newTempId = getObjectId();
    while (_.find(paxes, { tempId: newTempId })) {
      newTempId = getObjectId();
    }
    newPax.tempId = newTempId;
    append(newPax);
  };
  const removePax = index => {
    remove(index);
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    // console.log("dragIndex,hoverIndex", dragIndex, hoverIndex);
    // const paxes = _.get(getValues(), prefix);
    // console.log("paxes", paxes);
    // paxes[dragIndex] = paxes.splice(hoverIndex, 1, paxes[dragIndex])[dragIndex];
    // console.log("newPaxes", paxes);
    // setValue(prefix, paxes);
    swap(dragIndex, hoverIndex);
  }, []);
  return (
    <>
      <br />
      <h5>
        Пассажиры{" "}
        <Button size={"sm"} onClick={() => addPax()}>
          <i className={"fa fa-plus"}></i>
        </Button>{" "}
        {hideButtons ? null : (
          <>
            <TextPaxes formController={formController} type={"text"} />{" "}
            <TextPaxes formController={formController} type={"avia"} />{" "}
            <ParsePaxesButton formController={formController} addPax={addPax} />
          </>
        )}
      </h5>
      <Row>
        <Col xs={2}>
          <FormLabel>Обращение</FormLabel>
        </Col>
        <Col xs={3}>
          <FormLabel>Фамилия</FormLabel>
        </Col>
        <Col xs={3}>
          <FormLabel>Имя</FormLabel>
        </Col>
        <Col xs={2}>
          <FormLabel>Возраст</FormLabel>
        </Col>
      </Row>
      <DndProvider backend={HTML5Backend}>
        {fields.map((pax, index) => {
          return (
            <PaxForm
              key={index}
              pax={pax}
              formController={formController}
              prefix={`${prefix}[${index}]`}
              moveCard={moveCard}
              index={index}
              removePax={() => removePax(index)}
            />
          );
        })}
      </DndProvider>
      {!fields.length ? (
        <>
          <input
            type={"text"}
            style={{ display: "none" }}
            defaultValue={""}
            {...register(`${prefix}ss`, { required: true })}
          />

          {formState.isSubmitted ? (
            <p className={"text-danger"}>Минимум 1 пассажир</p>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default PaxesBlock;
