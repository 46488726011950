import React, {useEffect} from "react";
import UpcomingTickets from "../components/upcomingTickets";
import IssuedTicketsForDay from "../components/issuedTicketsForDay";
import {Col, Row} from "react-bootstrap";
import ErrorHandler from "../../base/components/errorHandler";
import ParseCheckSector from "../components/parseCheckSector";
import ApiTestTable from "../components/ApiTestTable";
import { useNavigate } from "react-router";
import {useSelector} from "react-redux";
import {TouristServicesListRoute, TripListRoute} from "../../routesConstants";
import RateHawk from "../components/RateHawk";
import HotelReservation from "../components/HotelReservation";
import UnidentifiedCash from "../components/unidentifiedCash";

const LandingPage = () => {
  const { roles } = useSelector(state => state.auth.user);
  const navigate = useNavigate()
  useEffect(() => {
    if(
      roles.indexOf("employee") === -1 && roles.indexOf("toursUser") !== -1){
      navigate(TripListRoute)
    } else if(
      roles.indexOf("employee") === -1 && roles.indexOf("tourServicesUser") !== -1
    ){
      navigate(TouristServicesListRoute)
    }
  }, []);
  if(
    roles.indexOf("employee") === -1 &&( roles.indexOf("toursUser") !== -1 || roles.indexOf("tourServicesUser") !== -1  || roles.length === 0)){
    return <></>
  }

  return (
    <ErrorHandler>
      <Row>
        <Col xs={6}>
          <UnidentifiedCash/>
          <IssuedTicketsForDay />
          <ParseCheckSector/>
          <ApiTestTable/>
        </Col>
        <Col xs={6}>
          <RateHawk/>
          <HotelReservation/>
          {/*<UpcomingTickets/>*/}
        </Col>
      </Row>
    </ErrorHandler>
  );
};
export default LandingPage;
