 const testTimeTable = {
  "airlines": {
    "DV": "SCAT Airlines",
      "KC": "Air Astana"
  },
   departureCode: 'ALA',
   arrivalCode: 'GUW',
   "date": "2020-07-29T12:05:00.000Z",
  "airports": {
    "ALA": {
      "ru": {
        "name": 'Аэропорт Алматы',
          "cityName": "Алматы"
      },
      "en": {
        "name": "Almaty Airport",
          "cityName": "Almaty"
      }
    },
    "GUW": {
      "ru": {
        "name": 'Аэропорт Атырау',
        "cityName": "Атырау"
      },
      "en": {
        "name": "Atyrau Airport",
        "cityName": "Atyrau"
      }
    },
    "NQZ": {
      "ru": {
        "name": "Международный аэропорт Нурсултан Назарбаев",
          "cityName": "Нур-Султан"
      },
      "en": {
        "name": "Astana Nursultan Nazarbayev Airport",
          "cityName": "Astana"
      }
    }
  },"routes": [
       {
         "segments": [
           {
             "flightNumber": "703",
             "departure": {
               "airport": "ALA",
               "date": "2020-07-29T12:05:00.000Z"
             },
             "arrival": {
               "airport": "NQZ",
               "date": "2020-07-29T13:55:00.000Z"
             },
             "airplane": "",
             "travelTime": 6600000,
             "connectionTime": 4200000,
             "airline": "DV"
           },
           {
             "flightNumber": "765",
             "departure": {
               "airport": "NQZ",
               "date": "2020-07-29T15:05:00.000Z",
               "terminal": "0"
             },
             "arrival": {
               "airport": "GUW",
               "date": "2020-07-29T16:15:00.000Z"
             },
             "airplane": "",
             "travelTime": 7800000,
             "connectionTime": 0,
             "airline": "DV"
           }
         ],
         "flightTime": 18600000,
         "daysOfFlight": [
           3
         ],
         "departure": {
           "airport": "ALA",
           "date": "2020-07-29T12:05:00.000Z"
         },
         "arrival": {
           "airport": "GUW",
           "date": "2020-07-29T16:15:00.000Z"
         }
       },
       {
         "segments": [
           {
             "flightNumber": "733",
             "departure": {
               "airport": "ALA",
               "date": "2020-07-29T18:30:00.000Z"
             },
             "arrival": {
               "airport": "NQZ",
               "date": "2020-07-29T20:15:00.000Z"
             },
             "airplane": "",
             "travelTime": 6300000,
             "connectionTime": 3300000,
             "airline": "DV"
           },
           {
             "flightNumber": "983",
             "departure": {
               "airport": "NQZ",
               "date": "2020-07-29T21:10:00.000Z",
               "terminal": "2"
             },
             "arrival": {
               "airport": "GUW",
               "date": "2020-07-29T22:40:00.000Z"
             },
             "airplane": "",
             "travelTime": 9000000,
             "connectionTime": 0,
             "airline": "KC"
           }
         ],
         "flightTime": 18600000,
         "daysOfFlight": [
           5
         ],
         "departure": {
           "airport": "ALA",
           "date": "2020-07-29T18:30:00.000Z"
         },
         "arrival": {
           "airport": "GUW",
           "date": "2020-07-29T22:40:00.000Z"
         }
       },
       {
         "segments": [
           {
             "flightNumber": "995",
             "departure": {
               "airport": "ALA",
               "date": "2020-07-29T17:45:00.000Z"
             },
             "arrival": {
               "airport": "NQZ",
               "date": "2020-07-29T19:30:00.000Z"
             },
             "airplane": "",
             "travelTime": 6300000,
             "connectionTime": 4200000,
             "airline": "KC"
           },
           {
             "flightNumber": "983",
             "departure": {
               "airport": "NQZ",
               "date": "2020-07-29T20:40:00.000Z",
               "terminal": "2"
             },
             "arrival": {
               "airport": "GUW",
               "date": "2020-07-29T22:10:00.000Z"
             },
             "airplane": "",
             "travelTime": 9000000,
             "connectionTime": 0,
             "airline": "KC"
           }
         ],
         "flightTime": 19500000,
         "daysOfFlight": [
           4
         ],
         "departure": {
           "airport": "ALA",
           "date": "2020-07-29T17:45:00.000Z"
         },
         "arrival": {
           "airport": "GUW",
           "date": "2020-07-29T22:10:00.000Z"
         }
       },
       {
         "segments": [
           {
             "flightNumber": "853",
             "departure": {
               "airport": "ALA",
               "date": "2020-07-29T11:40:00.000Z"
             },
             "arrival": {
               "airport": "NQZ",
               "date": "2020-07-29T13:20:00.000Z"
             },
             "airplane": "",
             "travelTime": 6000000,
             "connectionTime": 6300000,
             "airline": "KC"
           },
           {
             "flightNumber": "765",
             "departure": {
               "airport": "NQZ",
               "date": "2020-07-29T15:05:00.000Z",
               "terminal": "0"
             },
             "arrival": {
               "airport": "GUW",
               "date": "2020-07-29T16:15:00.000Z"
             },
             "airplane": "",
             "travelTime": 7800000,
             "connectionTime": 0,
             "airline": "DV"
           }
         ],
         "flightTime": 20100000,
         "daysOfFlight": [
           2,
           3,
           4,
           5,
           7
         ],
         "departure": {
           "airport": "ALA",
           "date": "2020-07-29T11:40:00.000Z"
         },
         "arrival": {
           "airport": "GUW",
           "date": "2020-07-29T16:15:00.000Z"
         }
       },
       {
         "segments": [
           {
             "flightNumber": "995",
             "departure": {
               "airport": "ALA",
               "date": "2020-07-29T17:45:00.000Z"
             },
             "arrival": {
               "airport": "NQZ",
               "date": "2020-07-29T19:30:00.000Z"
             },
             "airplane": "",
             "travelTime": 6300000,
             "connectionTime": 6000000,
             "airline": "KC"
           },
           {
             "flightNumber": "983",
             "departure": {
               "airport": "NQZ",
               "date": "2020-07-29T21:10:00.000Z",
               "terminal": "2"
             },
             "arrival": {
               "airport": "GUW",
               "date": "2020-07-29T22:40:00.000Z"
             },
             "airplane": "",
             "travelTime": 9000000,
             "connectionTime": 0,
             "airline": "KC"
           }
         ],
         "flightTime": 21300000,
         "daysOfFlight": [
           5
         ],
         "departure": {
           "airport": "ALA",
           "date": "2020-07-29T17:45:00.000Z"
         },
         "arrival": {
           "airport": "GUW",
           "date": "2020-07-29T22:40:00.000Z"
         }
       },
       {
         "segments": [
           {
             "flightNumber": "953",
             "departure": {
               "airport": "ALA",
               "date": "2020-07-29T15:40:00.000Z"
             },
             "arrival": {
               "airport": "NQZ",
               "date": "2020-07-29T17:25:00.000Z"
             },
             "airplane": "",
             "travelTime": 6300000,
             "connectionTime": 10500000,
             "airline": "KC"
           },
           {
             "flightNumber": "983",
             "departure": {
               "airport": "NQZ",
               "date": "2020-07-29T20:20:00.000Z",
               "terminal": "2"
             },
             "arrival": {
               "airport": "GUW",
               "date": "2020-07-29T21:50:00.000Z"
             },
             "airplane": "",
             "travelTime": 9000000,
             "connectionTime": 0,
             "airline": "KC"
           }
         ],
         "flightTime": 25800000,
         "daysOfFlight": [
           1
         ],
         "departure": {
           "airport": "ALA",
           "date": "2020-07-29T15:40:00.000Z"
         },
         "arrival": {
           "airport": "GUW",
           "date": "2020-07-29T21:50:00.000Z"
         }
       }
     ],
}

export default testTimeTable