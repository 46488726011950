import React, {useState,useEffect} from "react";
import {Link} from "react-router-dom";
import {Formik} from "formik";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {TextField} from "@material-ui/core";
import {Login as LoginAction, ResetUserPassword} from '../../../redux/actions/auth'
import AuthButton from "../components/authButton";
import {StartProcess} from "../../../redux/actions/base";
import {useLocation, useParams} from "react-router";


function SetPassword(props) {
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const params = useParams()
  const location = useLocation()
  console.log('location',location)
  const token = params.token
  const firstLogin = location.pathname.indexOf('setPassword') !== -1;

  useEffect(()=>{
    if(!firstLogin){
      props.StartProcess(`/api/auth/resetPasswordCheck/${token}`, (resp)=>{
        console.log(resp);
        setSuccess(true);
        setFormErrors(resp.data.message);
      })
    }
  }, []);

  return (
    <>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              {firstLogin?'Укажите пароль' : 'Изменение пароля'}
            </h3>
          </div>
          <div>
            <p className="kt-font-danger kt-font-bold">{formErrors}</p>
          </div>
          {dataLoading && !firstLogin?
            <div className={"kt-form d-flex justify-content-center"}>
              <div className="kt-spinner kt-spinner--md kt-spinner--info"/>
            </div>
            :
            <>
              {success || firstLogin ?
                <Formik
                  initialValues={{
                    password: '',
                    passwordDuple: '',
                  }}
                  onSubmit={(values, { setStatus, setSubmitting, setErrors }) => {
                    setSubmitting(true);
                    setLoading(true)
                    props.ResetUserPassword(token, values.password, values.passwordDuple, firstLogin, (resp)=>{
                      console.log('callin')
                      setFormErrors(resp.message);
                      console.log(resp)
                      setSubmitting(false);
                      setLoading(false)
                    })
                  }}
                >
                  {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }) => (
                    <form
                      noValidate={true}
                      autoComplete="off"
                      className="kt-form"
                      onSubmit={handleSubmit}
                    >


                        <>
                          <div className="form-group">
                            <TextField
                              type="password"
                              label="Новый пароль"
                              margin="normal"
                              className="kt-width-full"
                              name="password"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.password}
                              helperText={touched.password && errors.password}
                              error={Boolean(touched.password && errors.password)}
                            />
                          </div>
                          <div className="form-group">
                          <TextField
                            type="password"
                            label="Повтор"
                            margin="normal"
                            className="kt-width-full"
                            name="passwordDuple"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.passwordDuple}
                            helperText={touched.passwordDuple && errors.passwordDuple}
                            error={Boolean(touched.passwordDuple && errors.passwordDuple)}
                          />
                        </div>
                          <div className="kt-login__actions">
                            <Link
                              to="/auth/login"
                              className="kt-link kt-login__link-forgot"
                            >
                              Назад
                            </Link>
                            <AuthButton title={firstLogin?'Сохранить' :'Сменить'} loading={loading} isSubmitting={isSubmitting}/>
                          </div>
                        </>
                    </form>
                  )}
                </Formik>
                :null}
            </>
          }
        </div>
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    null,
    {StartProcess, ResetUserPassword}
  )(SetPassword)
);
