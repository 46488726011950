import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";

const CustomDatePicker = ({
  register,
  value,
  name,
  onChange,
  placeholder,
  className,
  showTimeSelect
}) => {
  let [newValue, setNewValue] = useState();
  useEffect(() => {
    if (value) {
      if (typeof value === "string") {
        setNewValue(
          moment(value)
            .utc()
            .toDate()
        );
      } else {
        setNewValue(
          moment(
            new Date(
              Date.UTC(value.getFullYear(), value.getMonth(), value.getDate())
            )
          ).toDate()
        );
      }
    }
  }, [value]);
  const customOnChange = newVal => {
    const newDate = moment(
      new Date(
        Date.UTC(newVal.getFullYear(), newVal.getMonth(), newVal.getDate())
      )
    ).toDate();
    setNewValue(newDate);
    onChange(newDate);
  };
  return (
    <>
      <DatePicker
        onChange={customOnChange}
        calendarIcon={null}
        clearIcon={null}
        className={["form-control", className ? [...className] : ""]}
        selected={newValue}
        dateFormat={"dd MMM yyyy"}
      />
      <input type={"hidden"} {...register} defaultValue={newValue} />
    </>
  );
};

export default CustomDatePicker;
