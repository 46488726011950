import { combineReducers } from "redux";
import { createReduxHistoryContext } from "redux-first-history";
import { configureStore } from "@reduxjs/toolkit";
import { metronic } from "../../_metronic";
import authReducer from "./auth";
import miscReducer from "./misc";
import { baseReducer } from "./base";
import { createBrowserHistory } from "history";
import ticketsReducer from './tickets'
import buildReducer from './buildCheck'

export const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() });

export const rootReducer = combineReducers({
  router: routerReducer,
  builder: metronic.builder.reducer,
  auth: authReducer,
  base: baseReducer,
  misc: miscReducer,
  tickets: ticketsReducer,
  buildInfo: buildReducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(routerMiddleware)
});

export const history = createReduxHistory(store);
