import LoadingButton from "../../base/components/loadingButton";
import {useState} from "react";
import axios from "../../../helpers/axios";

const ButtonAvailableRooms = ({bookingComLink,setAvailableRoomsList})=>{
  const [loading, setLoading] = useState(false)
  const getAvailableRoomsList = () =>{
    setLoading(true)
    axios
      .post('/api/trips/parseOptionsRooms', {link:bookingComLink})
      .then(({data})=>{
        setAvailableRoomsList(data.availableRooms)
        console.log('avialable', data)
      })
      .catch(()=>alert('Что-то пошло не так'))
      .finally(()=>setLoading(false))
  }
  return <LoadingButton loading={loading} onClick={getAvailableRoomsList} type={'button'} title={'Booking.com'}/>
}
export default ButtonAvailableRooms