import React, { useEffect, useState } from "react";
import { Row, Col, Button, Table, Collapse } from "reactstrap";
import _ from "lodash";
import moment from "moment";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import { Tabs, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { SetCounterpartyIgnore } from "../../../../redux/actions/misc";

const DebtorCompare = () => {
  const [items, setItems] = useState([]);
  const [result, setResult] = useState();
  const [tabIndex, setTabIndex] = useState();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const counterparties = useSelector(state => state.misc.conterpartyIgnore);
  console.log("counterparties", counterparties);
  useEffect(() => {
    if (items.length === 2) {
      generate();
    }
    const handlePasteAnywhere = event => {
      const insertedText = event.clipboardData.getData("text");
      console.log(insertedText);
      const item = parseInsert(insertedText);
      if (item) {
        if (_.find(items, { date: item.date })) {
          window.alert("Дата уже добавлена");
          return;
        }
        setItems(
          _.orderBy([...items, item], item =>
            moment(item.date, "DD.MM.YYYY").toDate()
          )
        );
      } else {
        window.alert("Ошибка вставки");
      }
    };

    window.addEventListener("paste", handlePasteAnywhere);

    return () => {
      window.removeEventListener("paste", handlePasteAnywhere);
    };
  }, [items]);

  const generate = () => {
    const generated = {
      firstDate: "",
      lastDate: "",
      ignored: 0,
      okRows: [],
      diffRows: [],
      firstLost: [],
      secondLost: []
    };
    const first = items[0];
    const last = items[1];
    generated.firstDate = first.date;
    generated.lastDate = last.date;
    first.rows.forEach(row => {
      if (counterparties.indexOf(row.name) !== -1) {
        generated.ignored++;
        return;
      }
      const foundItem = _.find(last.rows, { name: row.name });
      if (foundItem) {
        if (foundItem.total === row.total) {
          generated.okRows.push(row);
        } else {
          generated.diffRows.push({
            old: row,
            new: foundItem,
            diff: foundItem.total - row.total
          });
        }
      } else {
        generated.secondLost.push(row);
      }
    });
    last.rows.forEach((item, index) => {
      const foundItem = _.find(first.rows, { name: item.name });
      if (!foundItem) {
        generated.firstLost.push(item);
      }
    });
    setResult(generated);
    console.log(generated);
  };

  const addCounterparty = name => {
    dispatch(SetCounterpartyIgnore([...counterparties, name]));
    // generate();
  };
  const removeCounterparty = index => {
    counterparties.splice(index, 1);
    dispatch(SetCounterpartyIgnore([...counterparties]));
    // generate();
  };
  return (
    <Row className={"block"}>
      <Col>
        <Portlet>
          <PortletHeader title={"Сравнение должников"} />
          <PortletBody>
            <Tabs
              id="controlled-tab-example"
              activeKey={tabIndex}
              onSelect={key => setTabIndex(key)}
            >
              <Tab eventKey={`desc1`} title={"Сравнение"}>
                <Row>
                  <Col md={12}>
                    <p>Вставте на страницу копию выгрузки с 1с</p>
                  </Col>
                </Row>
                <Row>
                  {items.map((item, index) => {
                    return (
                      <Col md={3} key={index}>
                        <div
                          style={{
                            borderRadius: "10px",
                            border: "1px solid #ebedf2",
                            padding: "10px"
                          }}
                        >
                          Выгрузка от <b>{item.date}</b> <br />
                          {item.rows.length} - cтрок <br />
                          <Button
                            size={"sm"}
                            color={"danger"}
                            onClick={() => {
                              items.splice(index, 1);
                              setItems([...items]);
                              setResult(null);
                            }}
                          >
                            удалить
                          </Button>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
                {result ? (
                  <Row className={"mt-3"}>
                    <Col md={12}>
                      {result.okRows.length ? (
                        <>
                          <h4>
                            Совпало ({result.okRows.length}){" "}
                            <Button onClick={() => setIsOpen(!isOpen)}>
                              Показать
                            </Button>
                          </h4>
                          <Collapse isOpen={isOpen}>
                            <Table bordered hover striped>
                              <thead>
                                <tr>
                                  <th>Контрагент</th>
                                  <th>Сумма</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {result.okRows.map((u, ii) => {
                                  return (
                                    <tr key={ii}>
                                      <td>{u.name}</td>
                                      <td>{u.total}</td>
                                      <td>
                                        {counterparties.indexOf(u.name) ===
                                        -1 ? (
                                          <Button
                                            onClick={() =>
                                              addCounterparty(u.name)
                                            }
                                          >
                                            Добавить в игнор
                                          </Button>
                                        ) : null}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </Collapse>
                        </>
                      ) : null}
                      {result.diffRows.length ? (
                        <>
                          <h4>Разная сумма ({result.diffRows.length})</h4>
                          <Table bordered hover striped>
                            <thead>
                              <tr>
                                <th>Контрагент</th>
                                <th>{result.firstDate}</th>
                                <th>{result.lastDate}</th>
                                <th>Разница</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {result.diffRows.map((u, ii) => {
                                return (
                                  <tr key={ii}>
                                    <td>{u.old.name}</td>
                                    <td>{u.old.total}</td>
                                    <td>{u.new.total}</td>
                                    <td>{u.diff}</td>
                                    <td>
                                      {counterparties.indexOf(u.old.name) ===
                                      -1 ? (
                                        <Button
                                          onClick={() =>
                                            addCounterparty(u.old.name)
                                          }
                                        >
                                          Добавить в игнор
                                        </Button>
                                      ) : null}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </>
                      ) : null}
                      {result.firstLost.length ? (
                        <>
                          <h4>
                            Нет в {result.firstDate} ({result.firstLost.length})
                          </h4>
                          <Table bordered hover striped>
                            <thead>
                              <tr>
                                <th>Контрагент</th>
                                <th>Сумма</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {result.firstLost.map((u, ii) => {
                                return (
                                  <tr key={ii}>
                                    <td>{u.name}</td>
                                    <td>{u.total}</td>
                                    <td>
                                      {counterparties.indexOf(u.name) === -1 ? (
                                        <Button
                                          onClick={() =>
                                            addCounterparty(u.name)
                                          }
                                        >
                                          Добавить в игнор
                                        </Button>
                                      ) : null}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </>
                      ) : null}
                      {result.secondLost.length ? (
                        <>
                          <h4>
                            Нет в {result.lastDate} ({result.secondLost.length})
                          </h4>
                          <Table bordered hover striped>
                            <thead>
                              <tr>
                                <th>Контрагент</th>
                                <th>Сумма</th>
                              </tr>
                            </thead>
                            <tbody>
                              {result.secondLost.map((u, ii) => {
                                return (
                                  <tr key={ii}>
                                    <td>{u.name}</td>
                                    <td>{u.total}</td>
                                    <td>
                                      {counterparties.indexOf(u.name) === -1 ? (
                                        <Button
                                          onClick={() =>
                                            addCounterparty(u.name)
                                          }
                                        >
                                          Добавить в игнор
                                        </Button>
                                      ) : null}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </>
                      ) : null}
                      {result.ignored ? (
                        <>
                          <h4>Проигнорированно {result.ignored}</h4>
                        </>
                      ) : null}
                    </Col>
                  </Row>
                ) : null}
              </Tab>
              <Tab
                eventKey={`desc2`}
                title={`Игнор контрагентов (${counterparties.length})`}
              >
                <Table bordered hover striped>
                  <thead>
                    <tr>
                      <th>Контрагент</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {counterparties.map((u, ii) => {
                      return (
                        <tr key={ii}>
                          <td>{u}</td>
                          <td>
                            <Button onClick={() => removeCounterparty(ii)}>
                              Убрать
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Tab>
            </Tabs>
          </PortletBody>
        </Portlet>
      </Col>
    </Row>
  );
};

export default DebtorCompare;

function getMatches(string, regex, index) {
  index || (index = 1); // default to the first capturing group
  var matches = [];
  var match;
  while ((match = regex.exec(string))) {
    matches.push(match[index]);
  }
  return matches;
}

const parseInsert = text => {
  try {
    const output = {
      date: null,
      total: 0,
      rows: []
    };
    const dateRegex = /Список\sдолжников\sна\s(?<date>[0-9]{1,2}\.[0-9]{2}\.[0-9]{4})/gm;

    const dateMatch = text.match(dateRegex);
    console.log("dateMatch", getMatches(text, dateRegex)[0]);
    output.date = getMatches(text, dateRegex)[0];
    if (!output.date) {
      return;
    }
    const rowRegexp = /([0-9]{1,3})\s+(.*?)(тел\.:\s.*?)?((\s[0-9]{1,3})+,[0-9]{2})\n/gm;
    // const rowsMatch = text.matchAll(rowRegexp);
    // console.log("rowsMatch", rowsMatch);
    let m;

    while ((m = rowRegexp.exec(text)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === rowRegexp.lastIndex) {
        rowRegexp.lastIndex++;
      }
      console.log("m", m);
      output.rows.push({
        id: m[1],
        name: m[2].replaceAll("\t", ""),
        total: parseInt(m[4].replaceAll("\t", "").replaceAll(/\s/gi, ""))
      });
    }
    return output;
  } catch (e) {
    return null;
  }
};
