import React, { useState, useEffect } from "react";
import {
  Portlet,
  PortletBody,
  PortletFooter,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import { Formik } from "formik";
import { Button, Col, DropdownButton, Form, Row } from "react-bootstrap";
import LoadingButton from "../../../base/components/loadingButton";
import * as yup from "yup";
import FormRow from "../../../base/components/forms/formRow";
import { connect } from "react-redux";
import { LoadList } from "../../../../redux/actions/base";
import parser from "../helpers/parser";
import testTimeTable from "../data/testTimeTable";
import BlockUi from "@availity/block-ui";
import Dropdown from "react-bootstrap/esm/Dropdown";
import VarInfoTable from "./varInfoTable";

const TemplateForm = ({ data, onSubmit, user, title, loader }) => {
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required()
      .default(""),
    order: yup.number().default(0),
    global: yup.boolean().default(false),
    mainTemplate: yup
      .string()
      .required()
      .default(""),
    routeTemplate: yup
      .string()
      .required()
      .default(""),
    segmentTemplate: yup
      .string()
      .required()
      .default(""),
    alignNumbers: yup.string().default("none")
  });
  const initialValues = data
    ? validationSchema.cast(data)
    : validationSchema.cast();
  useEffect(() => {}, [data]);
  const [showSettings, setShowSettings] = useState(false);
  const settingsClick = evt => {
    evt.preventDefault();
    setShowSettings(!showSettings);
  };

  const checkParser = vaslues => {
    console.log("parser", parser(vaslues, "ru", testTimeTable, {}));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSubmit(values, () => {
          setSubmitting(false);
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => {
        return (
          <Row>
            <Col xs={6}>
              <Portlet>
                <PortletHeader
                  title={title}
                  toolbar={
                    <PortletHeaderToolbar>
                      <BlockUi blocking={loader}>
                        {/*<DropdownButton variant="outline-danger" title="Удалить" className={'btn'}>*/}
                        {/*<Dropdown.Item as="button" onClick={deleteAction}>Да! Удалить</Dropdown.Item>*/}
                        {/*</DropdownButton>*/}
                      </BlockUi>
                    </PortletHeaderToolbar>
                  }
                />
                <BlockUi blocking={loader}>
                  <form noValidate={true} onSubmit={handleSubmit}>
                    <PortletBody>
                      <FormRow
                        value={values.name}
                        name={"name"}
                        title={"Название"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />
                      <FormRow
                        value={values.mainTemplate}
                        name={"mainTemplate"}
                        title={"Шаблон"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                        type={"textarea"}
                        rows={5}
                      />
                      <FormRow
                        value={values.routeTemplate}
                        name={"routeTemplate"}
                        title={"Шаблон маршрута"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                        type={"textarea"}
                        rows={5}
                      />
                      <FormRow
                        value={values.segmentTemplate}
                        name={"segmentTemplate"}
                        title={"Шаблон сегмента"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                        type={"textarea"}
                        rows={7}
                      />
                      {user.roles.indexOf("superAdmin") !== -1 ||
                      user.roles.indexOf("admin") !== -1 ? (
                        <FormRow
                          checked={values.global}
                          name={"global"}
                          title={"Глобальный шаблон"}
                          errors={errors}
                          handleChange={handleChange}
                          touched={touched}
                          type={"checkbox"}
                        />
                      ) : null}
                      <FormRow
                        value={values.order}
                        name={"order"}
                        title={"Порядок"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                        type={"number"}
                      />
                    </PortletBody>

                    <PortletFooter>
                      <Row>
                        <Col md={{ span: 10, offset: 2 }}>
                          <Button
                            variant="outline-primary"
                            onClick={() => checkParser(values)}
                          >
                            Проверить
                          </Button>

                          <LoadingButton
                            title={"Сохранить"}
                            disabled={isSubmitting}
                            loading={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </PortletFooter>
                  </form>
                </BlockUi>
              </Portlet>
            </Col>

            <div className="col-xs-6">
              <VarInfoTable
                template={values}
                setter={(newVal, field) => setFieldValue(field, newVal)}
              />
            </div>
          </Row>
        );
      }}
    </Formik>
  );
};
const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};

export default connect(mapStateToProps, { LoadList })(TemplateForm);
