import React, {useEffect, useState} from 'react';
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import Loader from "../../../components/loader";
import Button from "react-bootstrap/Button";
import SupSuplierModal from "./supSuplierModal";


const beautyType = {
  'avia':'Авиа',
  'railway': 'ЖД',
  'service': 'Услуги'
}
const SupSuppliersListByCp = ({counterpartyData}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const loadInfo = () =>{
    setLoading(true)
    axios.post('/api/counterparty/suppliers/byCp/'+ counterpartyData.counterparty)
      .then(({data})=>{
        setData(data.data)
        setLoading(false)
      })
      .catch(sayError)
  }

  useEffect(() => {
    if(counterpartyData.counterparty){
      loadInfo()
    }
  }, [counterpartyData]);

  if(loading ){
    return <Loader/>
  }
  return (
    <Portlet>
      <PortletHeader title={'Субпоставщики'} toolbar={<PortletHeaderToolbar>
        <SupSuplierModal counterpartyData={counterpartyData} reload={loadInfo}>
          <Button>Добавить</Button>
        </SupSuplierModal>
      </PortletHeaderToolbar>}/>
        {data.length >0 ? data.map((s,i)=>{
          return <div key={i} >
            <SupSuplierModal counterpartyData={counterpartyData}
                             reload={loadInfo}
                             id={s.id}>
              <div className={'d-flex justify-content-between align-items-center serviceCard'}  style={{flex:2}}>
                <div className={'d-flex gap-2 '} style={{flex:100}}>{s.name}
                  <div style={{color: 'rgb(171,171,183)'}}>{s.types.map((t, i) => {
                    return <React.Fragment key={i}>{beautyType[t]}{i !== s.types.length - 1 && ', '}</React.Fragment>
                  })}
                  </div>
                </div>
                <i className="fa fa-pencil" />
              </div>

            </SupSuplierModal>
          </div>
        }) : <PortletBody>У контрагента нет субпоставщиков</PortletBody>}
    </Portlet>
  );
};

export default SupSuppliersListByCp;