import React, {useEffect, useState} from "react";
import moment from "moment";
import {connect, useSelector} from "react-redux";
import {
  GetItem,
  UpdateItem,
  CreateItem
} from "../../../../redux/actions/base";
import {AirBookingListRoute} from "../../../routesConstants";
import AirBookingForm from "../components/airBookingForm";
import {useNavigate} from "react-router";
import BlockUi from "@availity/block-ui";

const AirBookingCreatePage = props => {
  const [airBooking, setAirBooking] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()



  const airBookingTemplate = {
    paxes: [],
    segments: [],
    seller: process.env.REACT_APP_KAYAR_ID,
    issueDate: moment().startOf('day').format('DD.MM.YYYY'),
    issuedWithoutDocs: false,
    serviceComment:'',
    issuedBy: ''
  };

  useEffect(() => {
    // const dataFromStorage = localStorage.getItem('ticket')
    if (airBooking === null) {
      // if(dataFromStorage){
      //   setAirBooking(JSON.parse(dataFromStorage))
      //   localStorage.removeItem('ticket')
      // }else{
      //   setAirBooking(airBookingTemplate);
      // }
      setAirBooking(airBookingTemplate);
    }
  }, [airBooking, props]);
  const callback = (values, cb) => {
    setLoading(true)
    // axios.put("/api/air/airBooking/", {airBooking: values})
    //   .then(() => navigate(AirBookingListRoute))
    //   .catch(e => cb()).finally(u => setLoading(false))
    props.CreateItem(
      "/api/air/airBooking/",
      {airBooking: values, importOneS:values.importOneS},
      () => {
        cb();
        setLoading(false)
      },
      AirBookingListRoute
    );
  };
  return (
    <>
      {airBooking ? (
        <BlockUi blocking={loading} >
          <AirBookingForm
            loading={loading}
            newForm={true}
            setter={airBooking => setAirBooking(airBooking.booking)}
            onSubmit={callback}
            data={airBooking}
          />
        </BlockUi>
      ) : null}
    </>
  );
};

export default connect(null, {GetItem, CreateItem})(AirBookingCreatePage);
