import React, {useEffect, useState} from "react";
import {Col, Form, FormLabel, Nav, Row, Tab, Tabs} from "react-bootstrap";
import TripItemHotelRoom from "./tripHotelRoom";
import {useFieldArray} from "react-hook-form";
import RemoveLink from "../../air/airBooking/components/removeLink";
import AvailableRoomsBlock from "./AvailableRoomsBlock";
import _ from "lodash";
import FormHookInput from "../../air/airBooking/components/formHookInput";

const HotelRoomGroup = ({
                          availableRooms,
                          groupPrefix,
                          remove,
                          hotelPrefix,
                          group,
                          formController,
                          index,
                          activ
                        }) => {
  const {register, control, formState: {errors},} = formController;

  const [tabIndex, setTabIndex] = useState('room0');

  const getRoomName = (room, index) => {
    return room.name ? room.name : `Номер ${index + 1}`;
  };
  const roomsControl = useFieldArray({
    control,
    name: `${groupPrefix}.rooms`,
    rules: {required: true},
    shouldUnregister: true,
    keyName: 'customId'
  });
  const removeRoom = index => {
    roomsControl.remove(index);
    if (group.rooms.length - 1) {
      setTabIndex(`room${group.rooms.length - 2}`);
    }
  };
  const copyRoom = index => {
    const room = _.get(formController.getValues(), `${groupPrefix}.rooms[${index}]`);
    console.log('roomNeed', room)
    const newRoom = {...room};
    console.log("newRoom", newRoom);
    newRoom.descriptions?.forEach(u => (u._id = ""));
    newRoom.documents?.forEach(u => (u._id = ""));
    newRoom.photos.forEach(u => (u._id = ""));
    newRoom.rooms?.forEach(u => (u._id = ""));
    newRoom.id = "";
    newRoom._id = "";
    roomsControl.append(newRoom);
    //  /*if(item.rooms.length - 1){
    //   setTabIndex(`room${item.rooms.length - 2}`)
    // }*/
  };
  const tabClick = key => {
    if (key === "add") {
      const hotel = _.get(formController.getValues(), hotelPrefix);
      roomsControl.append({
        otherPaxes: [],
        descriptions: [],
        photos: [],
        startDate: hotel.startDate,
        startTime: hotel.startTime,
        endDate: hotel.endDate,
        endTime: hotel.endTime
      });
      setTabIndex(`room${roomsControl.fields.length}`);
    } else {
      setTabIndex(key);
    }
  };
  return (
    <>
      <input
        type={"hidden"}
        {...register(`${groupPrefix}._id`)}
        defaultValue={group._id}
      />

      <Row>
        <Col xs={3}>
          <FormLabel>Название группы</FormLabel>
          <FormHookInput
            defaultValue={group.name}
            register={register(`${groupPrefix}.name`, {required: true})}
            errors={errors}
          />
        </Col>
        <Col xs={3}>
          <RemoveLink onClick={remove} title={"группу номеров"}/>
        </Col>

      </Row>
      <br/>
      {_.get(errors, `${groupPrefix}.rooms`)?.root ? (
        <p className={"text-danger"}>Минимум 1 комната</p>
      ) : null}

      {availableRooms?.length ? (
        <AvailableRoomsBlock
          rooms={availableRooms}
          addRoom={room => {
            const hotel = _.get(formController.getValues(), hotelPrefix);
            roomsControl.append({
              otherPaxes: [],
              descriptions: [],
              documents: [],
              ...room,
              startDate: hotel.startDate,
              startTime: hotel.startTime,
              endDate: hotel.endDate,
              endTime: hotel.endTime
            });
          }}
        />
      ) : null}
      <br/>
      <Row>
        <Col xs={12}>
          <Tabs
            id={`sd${index}room`}
            activeKey={tabIndex}
            onSelect={key => tabClick(key)}
          >
            {roomsControl.fields.map((room, index) => {
              return (
                <Tab
                  eventKey={`room${index}`}
                  key={room.customId}
                  title={getRoomName(room, index)}
                  tabClassName={_.get(errors, `${groupPrefix}.rooms[${index}]`) ? 'tab-err' : null}
                >
                  <TripItemHotelRoom
                    room={room}
                    prefix={`${groupPrefix}.rooms[${index}]`}
                    hotelPrefix={hotelPrefix}
                    formController={formController}
                    removeItem={() => removeRoom(index)}
                    withoutPaxes={true}
                    copyRoom={() =>copyRoom(index)}
                    activ={activ && tabIndex === `room${index}`}
                  />
                </Tab>
              );
            })}
            <Tab eventKey={`add`} title={<i className={"fa fa-plus"}/>}/>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default HotelRoomGroup;
