import React, {useState} from "react";
import axios from "../../../helpers/axios";
import {useLocation, useParams} from "react-router";

const TokenButton = ({id, mainId, type, ticket, setToken, setCurrent, current}) => {
  const location = useLocation().pathname
  const[sending, setSending]=useState(false)
  const [err, setErr] = useState(false)

  let text = 'Сгенирировать токен'
  let classN = "btn btn-outline-primary btn-sm ml-5"
  if(sending && current === id){
    text = <i className="fas fa-spinner fa-spin"></i>
  }else if(current === id && err){
    text = 'Не удалось скопировать в буфер обмена'
    classN = "btn btn-outline-danger btn-sm ml-5"
  }else if(current === id){
    text = 'Ссылка скопирована в буфер'
    classN = "btn btn-outline-success btn-sm ml-5"
  }
  if(!/edit/.test(location)){
    return null
  }
  let finalId = mainId
  if(ticket){
    finalId = ticket
  }
  return <div style={{display: 'flex', flexDirection:'column'}}>
    <div className={classN}
              onClick={()=>{
                setCurrent(id)
                setSending(true)
                axios
                  .post('/api/trips/users/generateToursLoginToken', {id: id})
                  .then(({data})=>{
                  try {
                    window.navigator.clipboard.writeText(`${process.env.REACT_APP_TOURS_LINK}auth/${data.token}?returnUrl=${encodeURIComponent(type)}${finalId}`)
                      .then(() => {
                        setSending(false)
                        setTimeout(() => {
                          setCurrent(null)
                        }, 3000)
                      })
                  } catch (err){
                    setErr(true)
                    setToken(`${process.env.REACT_APP_TOURS_LINK}auth/${data.token}?returnUrl=${encodeURIComponent(type)}${finalId}`)
                    setSending(false)
                  }
                })
                  .catch(e=>window.alert('Что-то пошло не так'))
              }}>
      {text}
    </div>
  </div>
}
export default TokenButton