import React from 'react'
import ReactPaginate from "react-paginate";

function Pagination({page, totalPages, callback}) {
  return <div className={"float-right"}>
    <div className={"kt-pagination  kt-pagination--brand kt-align-right"}>
      <ReactPaginate pageCount={totalPages}
                     initialPage={page}
                     // forcePage={page}
                     containerClassName={"kt-pagination__links"}
                     pageClassName={"kt-pagination__link"}
                     activeClassName={"kt-pagination__link--active"}
                     previousClassName={"kt-pagination__link--prev"}
                     nextClassName={"kt-pagination__link--next"}
                     previousLabel={<i className={"fa fa-angle-left kt-font-brand"}/>}
                     nextLabel={<i className={"fa fa-angle-right  kt-font-brand"}/>}
                     onPageChange={({selected}) => {callback(selected)}}
                     disabledClassName={""}
                     // disableInitialCallback={true}
      />
    </div>
  </div>
}

export default Pagination;