import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../../components/Portlet";
import React, {useState} from "react";
import BlockUi from "@availity/block-ui";
import Button from 'react-bootstrap/Button'
import axios from "../../../../helpers/axios";
import {Table} from "reactstrap";
import {Link} from "react-router-dom";
import {ParsersCheckEditRoute} from "../../../routesConstants";


const ListActions = () =>{
  const [items, setItems] = useState(null)
  const [loading, setLoading] = useState(false)
  const [message, setMessage]= useState(null)
  const totalChecks = () =>{
    setLoading(true)
    axios
      .post('/api/parseCheck/allParseChecks')
      .then(({data})=>{
        setItems({totalChecks: data.totalChecks})
        setLoading(false)
      })
      .catch(e=>console.log('err', e))
  }
  const checkAll = () => {
    const send = () =>{
      axios
        .post('/api/parseCheck/checkSomeParsesForValid', {limit: 2})
        .then(({data})=>{
          setItems(items=>({...items, faildChecks: [...items.faildChecks, ...data.faildChecks] }))
          console.log('data', data)
          if(data.remainedUnchecked){
            send()
          }else{
            setLoading(false)
          }
        }).catch(e=>console.log('err', e))
    }
    setLoading(true)
    axios
      .post('/api/parseCheck/allParseChecks')
      .then(({data})=>{
        setItems({totalChecks: data.totalChecks, faildChecks: []})
      }).then(()=>send())
      .catch(e=>console.log('err', e))
  }
  console.log('itemsssss', items)
  return <Portlet>
    <BlockUi blocking={loading}>
      <PortletHeader title={items ? `Всего проверок: ${items.totalChecks}` : 'Проверка списка' } toolbar={
        <PortletHeaderToolbar>
          {/*<Button variant="primary"*/}
          {/*        onClick={totalChecks}*/}
          {/*        size="sm">totalChecks</Button>*/}
          <Button variant="primary"
                  size="sm"
                  onClick={checkAll}
                  className={'ml-2'}>Запустить проверку</Button>
        </PortletHeaderToolbar>
      }/>
      <PortletBody>
        {items && items.faildChecks.length>0 && <Table striped bordered hover>
          <thead>
          <tr>
            <td>faildChecks</td>
            <td >Всего ошибок: {items.faildChecks.length}</td>
          </tr>
          </thead>
          <tbody>
          {items.faildChecks.map((item, i)=>{
            return <tr key={i}>
              <th colSpan={2}>
                <Link to={ParsersCheckEditRoute + item}>
                  Ошибка № {i+1} — {item}
                </Link>
                </th>
            </tr>
          })}
          </tbody>
        </Table>}
        {items?.faildChecks.length<1 && !loading &&'Все ошибки проверены, несоответствий не найдено'}
        {/*{message && message}*/}
      </PortletBody>
    </BlockUi>

  </Portlet>
}
export default ListActions