import React, { useEffect, useState } from "react";
import {
  Portlet,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../components/Portlet";
import { connect } from "react-redux";
import { DeleteItem, GetItem, UpdateItem } from "../../../redux/actions/base";
import { useParams } from "react-router-dom";
import BlockUi from "@availity/block-ui";
import { Dropdown, Row, Col } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import { CurrencyListRoute } from "../../routesConstants";
import CurrencyForm from "../components/currencyForm";

const CurrencyEditPage = props => {
  const [currency, setCurrency] = useState(null);
  const [loader, setLoader] = useState(false);

  const { id } = useParams();
  useEffect(() => {
    if (currency === null) {
      setLoader(true);
      props.GetItem("/api/currencies", id, res => {
        setCurrency(res.data.data);
        setLoader(false);
      });
    }
  }, [currency, id, props]);
  const callback = (values, cb) => {
    console.log("update call");
    props.UpdateItem("/api/currencies/" + id, values, cb, CurrencyListRoute);
  };
  const deleteAction = () => {
    setLoader(true);
    props.DeleteItem("/api/currencies/" + id, () => {}, CurrencyListRoute);
  };
  return (
    <>
      <Row>
        <Col lg={6}>
          <Portlet>
            <PortletHeader
              title={"Редактирование валюты"}
              toolbar={
                <PortletHeaderToolbar>
                  <BlockUi blocking={loader}>
                    <DropdownButton variant="outline-danger" title="Удалить">
                      <Dropdown.Item as="button" onClick={deleteAction}>
                        Да! Удалить
                      </Dropdown.Item>
                    </DropdownButton>
                  </BlockUi>
                </PortletHeaderToolbar>
              }
            />
            <BlockUi blocking={loader}>
              <CurrencyForm onSubmit={callback} data={currency} edit/>
            </BlockUi>
          </Portlet>
        </Col>
      </Row>
    </>
  );
};

export default connect(null, { GetItem, UpdateItem, DeleteItem })(
  CurrencyEditPage
);
