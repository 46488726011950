import React, { useEffect } from "react";
import {
  Avatar,
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from "@material-ui/core";
import { Col, Row, ButtonGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import moment from "moment";
import CopyButton from "./buttons/copyButton";
import {
  etcTemplate,
  transferTemplate,
  hotelTemplate,
  charterTemplate
} from "./helpers/templates";
import _ from "lodash";
import BookingComButton from "../../../components/BookingComButton";
import LinkTo from "../linkTo";
import LoadingButton from "../../../../base/components/loadingButton";
import addDateToHotel from "../../../components/addDateToHotel";

const SideBar = ({
  groupArray,
  loading,
  selectedTab,
  values,
  setter,
  formController,
  fieldArray,
  type
}) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    getValues
  } = formController;
  const { fields, append, prepend, remove, swap, move, insert } = fieldArray;
  console.log('getValues', getValues())
  useEffect(() => {
    console.log("watch effect");
  }, [fields]);
  const etcAdd = () => {
    append({ ...etcTemplate });
    setter(`item${values.items.length}`);
  };
  const transferAdd = () => {
    append({ ...transferTemplate });
    setter(`item${values.items.length}`);
  };
  const charterAdd = () => {
    append({ ...charterTemplate });
    setter(`item${values.items.length}`);
  };
  const hotelAdd = () => {
    append({ ...hotelTemplate });
    setter(`item${values.items.length}`);
  };

  moment.locale("en");

  const groupTemplate = {
    name: "",
    hideGroup: "",
    type: "",
    items: []
  };
  const groupAdd = () => {
    groupArray.append({ ...groupTemplate });
    setter(`group${values.groups.length}`);
  };

  const generateTimeline = () => {
    const output = [];
    fields.forEach((u, i) => {
      const item = {
        type: u.type,
        startDate: u.startDate,
        startTime: u.startTime,
        endDate: u.endDate,
        endTime: u.endTime,
        index: i
      };
      if (u.type === "etc") {
        item.name = !u.name ? "Разное" : u.name;
        item.icon = "fa-ticket-alt";
      } else if (u.type === "hotel") {
        item.name = !u.name ? "Отель" : u.name;
        item.icon = "fa-hotel";
      } else if (u.type === "charter") {
        item.name = !u.name ? "Чартер" : u.name;
        item.icon = "fa-plane";
        item.startDate = u.segments[0]?.departureStartDate;
        item.startTime = u.segments[0]?.departureStartTime;
        item.endDate = u.segments[0]?.arrivalStartDate;
        item.endTime = u.segments[0]?.arrivalStartTime;
      } else if (u.type === "transfer") {
        item.name = !u.name ? "Трансфер" : u.name;
        item.icon = "fa-car";
        const end = moment(
          item.startDate + item.startTime,
          "DD MMM YYYYHH:mm"
        ).add(
          moment(u.driveTime, "HH:mm").diff(
            moment().startOf("day"),
            "milliseconds"
          ),
          "milliseconds"
        );
        item.endDate = end.format("DD MMM YYYY");
        item.endTime = end.format("HH:mm");
      }
      item.startDate = moment
        .utc(item.startDate + (item.startTime || "00:00"), "DD MMM YYYYHH:mm")
        .toDate();
      item.endDate = moment
        .utc(item.endDate + (item.endTime || "00:00"), "DD MMM YYYYHH:mm")
        .toDate();
      output.push(item);
    });
    return _.orderBy(output, "startDate", "asc");
  };

  return (
    <Card>
      <CardHeader
        title={
          <div className={"infoFlex"}>
            Инфо <LinkTo type={type} />
          </div>
        }
      />
      <List>
        <ListItem
          button
          selected={selectedTab === "home"}
          onClick={() => setter("home")}
        >
          <ListItemAvatar>
            <Avatar>
              <i className={"fa fa-suitcase"} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Общая информация" />
        </ListItem>
        <ListItem
          button
          selected={selectedTab === "tickets"}
          onClick={() => setter("tickets")}
        >
          <ListItemAvatar>
            <Avatar>
              <i className={"fa fa-plane"} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Билеты" />
        </ListItem>
        {generateTimeline().map((item, index) => {
          const tabName = `item${item.index}`;
          return (
            <ListItem
              button
              key={item.index}
              onClick={() => setter(tabName)}
              selected={selectedTab === tabName}
            >
              <ListItemAvatar>
                <Avatar>
                  <i className={`fa ${item.icon}`} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  className: _.get(errors, `items[${index}]`)
                    ? "text-danger"
                    : null
                }}
                primary={item.name}
                secondary={`${moment
                  .utc(item.startDate)
                  .format("DD MMM")} ${item.startTime}${
                  moment(item.endDate).isValid()
                    ? `${moment.utc(item.endDate).format(" - DD MMM")} ${item.endTime}`
                    : ""
                }`}
              />
            </ListItem>
          );
        })}
        {groupArray.fields.map((item, index) => {
          const tabName = `group${index}`;
          let name = item.name;

          if (!item.name) {
            name = "Группа №" + (index + 1);
          }
          return (
            <ListItem
              button
              key={item.id}
              onClick={() => setter(tabName)}
              selected={selectedTab === tabName}
            >
              <ListItemAvatar>
                <Avatar>
                  {/*<WorkIcon />*/}
                  <i className={"fa fa-object-group"} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={name} secondary={item.type} />
            </ListItem>
          );
        })}

        {/*<ListItem button>*/}
        {/*  <ListItemAvatar>*/}
        {/*    <Avatar>*/}
        {/*      /!*<WorkIcon />*!/*/}
        {/*    </Avatar>*/}
        {/*  </ListItemAvatar>*/}
        {/*  <ListItemText primary="Work" secondary="Jan 7, 2014" />*/}
        {/*</ListItem>*/}
        {/*<ListItem button>*/}
        {/*  <ListItemAvatar>*/}
        {/*    <Avatar>*/}
        {/*      /!*<BeachAccessIcon />*!/*/}
        {/*    </Avatar>*/}
        {/*  </ListItemAvatar>*/}
        {/*  <ListItemText primary="Vacation" secondary="July 20, 2014" />*/}
        {/*</ListItem>*/}
      </List>
      <Col xs={12} style={{ padding: "0 16px" }}>
        <ButtonGroup aria-label="Basic example">
          <Button size={"sm"} type={"button"} onClick={() => etcAdd()}>
            <i className={"fa fa-ticket-alt"} /> <i className={"fa fa-plus"} />
          </Button>
          <Button size={"sm"} type={"button"} onClick={() => hotelAdd()}>
            <i className={"fa fa-hotel"} /> <i className={"fa fa-plus"} />
          </Button>
          <Button size={"sm"} type={"button"} onClick={() => transferAdd()}>
            <i className={"fa fa-car"} /> <i className={"fa fa-plus"} />
          </Button>
          <Button size={"sm"} type={"button"} onClick={() => charterAdd()}>
            <i className={"fa fa-plane"} /> <i className={"fa fa-plus"} />
          </Button>
          {/*
            <Button size={'sm'} type={'button'} onClick={() => groupAdd()}><i className={'fa fa-object-group'}/> <i className={'fa fa-plus'}/></Button>
*/}
          <CopyButton
            fieldArray={fieldArray}
            tripId={""}
            formController={formController}
          />{" "}
        </ButtonGroup>
      </Col>
      <br />
      <Col xs={12} style={{ padding: "0 16px" }}>
        <BookingComButton
          setter={hotel => {
            console.log("hotel", hotel);
            append(hotel);
            setter(`item${values.items.length}`);
          }}
        />
      </Col>
      <CardHeader
        title={
          <Col xs={12}>
            <Button variant="primary" type={"submit"}>
              {loading ? (
                <i className={"fa fa-spinner fa-spin"} />
              ) : (
                "Сохранить"
              )}
            </Button>
            <br />
            <Button
              // size={"sm"}
              onClick={() => {
                console.log(getValues());
              }}
              variant={"secondary"}
            >
              Данные
            </Button>
            <Button
              // size={"sm"}
              onClick={() => {
                console.log(errors);
              }}
              variant={"secondary"}
            >
              Ошибки
            </Button>
          </Col>
        }
      />
    </Card>
  );
};

export default SideBar;
