import {Route, Routes} from "react-router";
import AirBookingList from "./pages/list";
import AirBookingCreatePage from "./pages/create";
import AirBookingEditPage from "./pages/edit";
import AirBookingReportPage from "./pages/report";
import React from "react";
import {
  AirBookingCreateRoute,
  AirBookingListRoute,
  AirBookingEditRoute,
  AirBookingReportRoute
} from "../../routesConstants";
import ErrorHandler from "../../base/components/errorHandler";

export default function () {
  return (

    <Routes>
      <Route path={"list"} exact={true} element={<AirBookingList/>}/>
      <Route path={"report"} exact={true} element={<AirBookingReportPage/>}/>
      <Route path={"create"} element={<AirBookingCreatePage/>}/>
      <Route path={"edit/:id"} exact={true} element={<AirBookingEditPage/>}/>
    </Routes>
  );
}
