import React, {useState} from "react";
import {TripCreateRoute, TripEditRoute} from "../../../routesConstants";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import {Link} from "react-router-dom";
import DataTable from "../../../../components/dataTable";
import PortletHeaderSearch from "../../../base/components/forms/portlerHeaderSearch";
import {Dropdown} from "react-bootstrap";
import {connect} from "react-redux";
import {
  DeleteItem,
  LoadList,
  StartProcess
} from "../../../../redux/actions/base";
import RemoveLink from "../../../air/airBooking/components/removeLink";
import moment from "moment";

const TripList = props => {
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const columns = [
    {
      Header: "Название",
      accessor: "name",
      Cell: ({value, row}) => (
        <Link to={TripEditRoute + row.original._id}>{value}</Link>
      )
    },
    {
      Header: "Даты",
      accessor: "startDate",
      Cell: ({value, row}) => (
        <>{moment(row.original.startDate).format('DD MMM YYYY')} - {moment(row.original.endDate).format('DD MMM YYYY')}</>
      )
    },
    {
      Header: "Участники",
      accessor: "paxes",
      Cell: ({value, row}) =>
        row.original.paxes.map(u => (
          <span>
            {u.lastName} {u.firstName} <br/>
          </span>
        ))
    },
    {
      Header: "Создано",
      accessor: "createdBy",
      Cell: ({value, row}) => (
        <>{value && <>
          {value} <br/>
          {moment(row.original.createdDate).format('DD MMM YYYY')}
        </>}</>
      )
    },
    {
      Header: "Действия",
      Cell: ({value, row}) => (
        <>
          <Link
            className={"btn btn-sm btn-clean btn-icon btn-icon-md"}
            to={TripEditRoute + row.original._id}
          >
            <i className={"la la-edit"}/>
          </Link>
          <RemoveLink
            onClick={() => remove(row.original._id)}
            noText
            title={"поездку"}
          />
        </>
      )
    }
  ];

  const remove = id => {
    setLoading(true);
    props.DeleteItem(`/api/trips/${id}`, () => {
      setLoading(false);
      console.log("setter", refetch);
      setRefetch(!refetch);
    });
  };
  const removeSelfFromAllTrips = () => {
    setLoading(true);
    const cb = res => {
      setLoading(false);
      if (res.data.success) {
        window.alert("Все прошло успешно");
      }
    };
    props.StartProcess("/api/trips/removeSelfFromAllTrips", cb);
  };

  const [query, setQuery] = useState("");
  return (
    <>
      <div className={"kt-grid__item"}>
        <Portlet>
          <PortletHeader
            title={"Список поездок"}
            toolbar={
              <PortletHeaderToolbar>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="default"
                    className="btn-sm"
                    id="dropdown-basic"
                  >
                    Действия
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={removeSelfFromAllTrips}>
                      Удалить себя из всех поездок
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {"\u00A0"}
                <PortletHeaderSearch
                  value={query}
                  cb={v => {
                    setQuery(v);
                  }}
                />
                {"\u00A0"}
                <Link
                  to={TripCreateRoute}
                  className="btn btn-sm btn-brand btn-elevate btn-icon-sm"
                >
                  <i className="la la-plus"/>
                  Добавить
                </Link>
              </PortletHeaderToolbar>
            }
          />
          <PortletBody>
            <DataTable
              columns={columns}
              search={query}
              url={`/api/trips`}
              refetch={refetch}
              itemsPerPage={25}
            />
          </PortletBody>
        </Portlet>
      </div>
    </>
  );
};

export default connect(() => ({}), {LoadList, StartProcess, DeleteItem})(
  TripList
);
