import React from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import { Col, Form, FormControl, FormLabel, Row } from "react-bootstrap";
import DescriptionsBlock from "../../components/descriptionsBlock";

import DocumentsBlock from "../../components/documentsBlock";
import UsersBlock from "../../trip/components/tripFormParts/usersBlock";
import ParseInput from "../../../air/airBooking/components/parseInput";
import RemoveLink from "../../../air/airBooking/components/removeLink";
import ImagesBlock from "../../components/imagesBlock";
import CountriesSelect from "../../../base/components/forms/countriesSelector";
import FlagSelect from "./flagSelect";
import FormHookInput from "../../../air/airBooking/components/formHookInput";

const OfferMainData = ({ values, className, formController, deleteAction, activ }) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue
  } = formController;

  return (
    <>
      <Portlet className={className}>
        <PortletHeader
          title={"Общая информация"}
          toolbar={
            <PortletHeaderToolbar>
              <RemoveLink
                onClick={() => deleteAction()}
                title={"предложение"}
              />
            </PortletHeaderToolbar>
          }
        />
        <PortletBody>
          <>
            <Row>
              <Col xs={6}>
                <FormLabel>Название</FormLabel>
                <FormHookInput
                  register={register("name", {
                    required: true
                  })}
                  placeholder={"Название"}
                  errors={errors}
                />
              </Col>
              <Col xs={6}>
                <FormLabel>Страны</FormLabel>
                <FlagSelect control={control} prefix={"countries"} defaultValue={values.countries}/>
              </Col>
              <Col xs={6}>
                <UsersBlock
                  type={'offers/'}
                  formController={formController}
                  prefix={"visibleToAccounts"}
                />
              </Col>
            </Row>
            <Form.Label className={'d-flex align-items-center gap-2'}>
              <input
                defaultChecked={values.public}
                {...register(`public`)}
                type={"checkbox"}
              />{" "}
              Публичный
            </Form.Label>
            <DescriptionsBlock
              title={"предложения"}
              descriptions={values.descriptions}
              formController={formController}
              prefix={"descriptions"}
            />
            <ImagesBlock
              formController={formController}
              prefix={`photos`}
              minLength={1}
              photos={values.photos}
              active={activ}
            />
            <DocumentsBlock
              formController={formController}
              documents={values.documents}
              prefix={"documents"}
            />
          </>
        </PortletBody>
      </Portlet>
    </>
  );
};

export default OfferMainData;
