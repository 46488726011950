import React from 'react'

const Duplicate = ({itemsArrayController, prefix, index, formController}) => {

  Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }
  const handleClick = (e) => {
    e.preventDefault();
    const formData = formController.getValues()
    const item = Object.byString(formData, prefix)[index];
    console.log('item', item)
    console.log('prefix', prefix)
    console.log('index', index)
    const newItem = JSON.parse(JSON.stringify(item, (k, v) => (k === '_id') ? undefined : v))
    itemsArrayController.append(newItem)
  }

  return <>
    <a href={'#'}
       style={{paddingRight: 15}}
       onClick={handleClick}><i className={'fa fa-copy'}/> Дублировать элемент</a>
  </>
}

export default Duplicate
