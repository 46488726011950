import React, { useEffect, useState } from "react";
import { Card, CardHeader } from "@material-ui/core";
import {
  Row,
  Col,
  FormControl,
  FormLabel,
  Form,
  Button
} from "react-bootstrap";
import SideBar from "./tripFormParts/sidebar";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";

import _ from "lodash";
import BlockUi from "@availity/block-ui";
import TripMainData from "./tripFormParts/tripMainData";
import TripEtcForm from "../../components/tripEtc";
import TripTransferForm from "../../components/tripTransfer";
import TripHotelForm from "../../components/tripHotel";
import TripTicketsForm from "./tripFormParts/tripTickets";
import { useFieldArray, useForm } from "react-hook-form";
import Duplicate from "./tripFormParts/buttons/duplicate";
import RemoveLink from "../../../air/airBooking/components/removeLink";
import TripCharterForm from "../../components/tripCharter";
import {useNavigatingAway} from "../../../../helpers/useNavigatingAway";
import ModalLivingPage from "../../../../components/layout/ModalLivingPage";

const TripForm = ({ data, onSubmit, tripSetter, tickets }) => {
  console.log("TripForm");
  const [currentTab, setCurrentTab] = useState("home");
  const [loading, setLoading] = useState(false);
  const formController = useForm({ defaultValues: data });
  const [canShowModalLivingPage, setCanShowModalLivingPage] = useState(false)
  const [
    showDialogLeavingPage,
    confirmNavigation,
    cancelNavigation
  ] = useNavigatingAway(canShowModalLivingPage)
  const {
    register,
    formState,
    handleSubmit,
    watch,
    formState: { errors , isDirty},
    control,
    getValues,
    reset
  } = formController;

  const fieldArray = useFieldArray({
    control,
    name: `items`
  });
  const { fields, append, prepend, remove, swap, move, insert } = fieldArray;
  const paxArray = useFieldArray({
    control,
    name: `paxes`
  });
  const groupArray = useFieldArray({
    control,
    name: `groups`
  });
  const submitAction = data => {
    setCanShowModalLivingPage(false)
    setLoading(true);
    _.filter(data.items, { type: "hotel" }).forEach(
      u => (u.availableRooms = [])
    );
    data.paxes.map((u, index) => (u.order = index));
    onSubmit(data, () => {
      setLoading(false);
    });
  };
  useEffect(()=>{
    if(isDirty){
      setCanShowModalLivingPage(true)
    }
  },[isDirty])
  return (
    <>
      <ModalLivingPage showDialog={showDialogLeavingPage}
                       setShowDialog={setCanShowModalLivingPage}
                       confirmNavigation={confirmNavigation}
                       cancelNavigation={cancelNavigation}/>
      <Row>
        <form
          onSubmit={handleSubmit(submitAction, e => {
            console.log("e", e);
            console.log("errors", errors);
            alert("Ошибка при заполнении формы");
          })}
          autoComplete="off"
          className={"col-12"}
        >
          <BlockUi blocking={false} className={"row"}>
            <Col xs={3}>
              <SideBar
                groupArray={groupArray}
                tripSetter={tripSetter}
                fieldArray={fieldArray}
                formController={formController}
                selectedTab={currentTab}
                values={data}
                loading={loading}
                setter={setCurrentTab}
                type={"trips"}
              />
            </Col>
            <Col xs={9}>
              <TripMainData
                paxArray={paxArray}
                formController={formController}
                values={data}
                active={currentTab === 'home'}
                className={currentTab === "home" ? "" : "hidden"}
              />
              <TripTicketsForm
                tickets={tickets}
                formController={formController}
                paxArray={paxArray}
                values={data}
                className={currentTab === "tickets" ? "" : "hidden"}
              />
              {fields.map((item, index) => {
                // if(currentTab !== `item${index}`){
                //   return null
                // }
                if (item.type === "etc") {
                  return (
                    <Portlet
                      key={index}
                      className={
                        `item${index}` === currentTab ? null : "hidden"
                      }
                    >
                      <PortletHeader
                        title={item.name ? item.name : "Разное"}
                        toolbar={
                          <PortletHeaderToolbar>
                            <Duplicate
                              formController={formController}
                              index={index}
                              prefix={`items`}
                              itemsArrayController={fieldArray}
                            />
                            <RemoveLink
                              onClick={() => remove(index)}
                              title={"элемент"}
                            />
                          </PortletHeaderToolbar>
                        }
                      />
                      <PortletBody>
                        <TripEtcForm
                          item={item}
                          key={item.id}
                          activ={currentTab === `item${index}`}
                          prefix={`items[${index}]`}
                          formController={formController}
                        />
                      </PortletBody>
                    </Portlet>
                  );
                } else if (item.type === "transfer") {
                  console.log("item", JSON.stringify(item));
                  return (
                    <Portlet
                      key={index}
                      className={
                        `item${index}` === currentTab ? null : "hidden"
                      }
                    >
                      <PortletHeader
                        title={item && item.name ? item.name : "Трансфер"}
                        toolbar={
                          <PortletHeaderToolbar>
                            <Duplicate
                              formController={formController}
                              index={index}
                              prefix={`items`}
                              itemsArrayController={fieldArray}
                            />
                            <RemoveLink
                              onClick={() => remove(index)}
                              title={"элемент"}
                            />
                          </PortletHeaderToolbar>
                        }
                      />
                      <PortletBody>
                        <TripTransferForm
                          item={item}
                          key={item.id}
                          prefix={`items[${index}]`}
                          formController={formController}
                          activ={currentTab === `item${index}`}
                        />
                      </PortletBody>
                    </Portlet>
                  );
                } else if (item.type === "charter") {
                  return (
                    <Portlet
                      key={index}
                      className={
                        `item${index}` === currentTab ? null : "hidden"
                      }
                    >
                      <PortletHeader
                        title={item && item.name ? item.name : "Чартер"}
                        toolbar={
                          <PortletHeaderToolbar>
                            <Duplicate
                              formController={formController}
                              index={index}
                              prefix={`items`}
                              itemsArrayController={fieldArray}
                            />
                            <RemoveLink
                              onClick={() => remove(index)}
                              title={"чартер"}
                            />
                          </PortletHeaderToolbar>
                        }
                      />
                      <PortletBody>
                        <TripCharterForm
                          item={item}
                          key={item.id}
                          prefix={`items[${index}]`}
                          formController={formController}
                        />
                      </PortletBody>
                    </Portlet>
                  );
                } else if (item.type === "hotel") {
                  return (
                    <Portlet
                      key={index}
                      className={
                        `item${index}` === currentTab ? null : "hidden"
                      }
                    >
                      <PortletHeader
                        title={item && item.name ? item.name : "Отель"}
                        toolbar={
                          <PortletHeaderToolbar>
                            <Duplicate
                              formController={formController}
                              index={index}
                              prefix={`items`}
                              itemsArrayController={fieldArray}
                            />
                            <RemoveLink
                              onClick={() => remove(index)}
                              title={"элемент"}
                            />
                          </PortletHeaderToolbar>
                        }
                      />
                      <PortletBody>
                        <TripHotelForm
                          item={item}
                          key={item.id}
                          index={index}
                          arrayPrefix={`items`}
                          formController={formController}
                          prefix={`items[${index}]`}
                          activ={currentTab === `item${index}`}
                        />
                      </PortletBody>
                    </Portlet>
                  );
                }
              })}
            </Col>
          </BlockUi>
        </form>
      </Row>
    </>
  );
};

export default TripForm;
