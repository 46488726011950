import React, {useEffect, useState} from "react";
import {
  RacoonListRoute,
  ReviseItemGetRoute,
  TripCreateRoute,
  TripEditRoute
} from "../../../routesConstants";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import { Link } from "react-router-dom";
import DataTable from "../../../../components/dataTable";
import PortletHeaderSearch from "../../../base/components/forms/portlerHeaderSearch";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import {
  DeleteItem,
  LoadList,
  StartProcess
} from "../../../../redux/actions/base";
import RemoveLink from "../../../air/airBooking/components/removeLink";
import reviseItemTypes from "../../helpers/reviseItemTypes";
import moment from "moment";
import _ from "lodash";
import Select from "react-select";
import WeekSelections from "../../components/WeekSelections";
import BlockUi from "@availity/block-ui";
import { Table } from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Form} from "reactstrap";
const dayWeek = (day)=>{
  if(day<8){
    return 1
  } else if(day>7 && day<16){
    return 2
  } else if (day>15 && day<24){
    return 3
  }else{
    return 4
  }
}
const ReviseItemListPage = props => {
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [data, setData] = useState()
  const {
    control,
    watch} = useForm({
    defaultValues: {
      activeYear: +moment().format("YYYY"),
      activeMonth: +moment().format("MM"),
      activeWeek: dayWeek(+moment().format("D")),
      activeType: 'all'
    }
  })

  const search = ()=>{
    setLoading(true)
    props.StartProcess(`/api/racoon/revise/item`, ({data})=>{
      setData(data.items)
      setLoading(false)
      },
      {
      year: watch('activeYear'),
      month: watch('activeMonth'),
      week: watch('activeWeek'),
      reviseType: watch('activeType')
    })
  }
  useEffect(()=>{search()},[refetch,])
  const remove = id => {
    setLoading(true);
    props.DeleteItem(`/api/racoon/revise/item/${id}`, () => {
      setLoading(false);
      console.log("setter", refetch);
      setRefetch(!refetch);
    });
  };
  const typeList = [
    {value: "all", label: "All"},
    {value: "aruana-kayar", label: "Aruana - Kayar"},
    {value: "silk-kayar",label: "Silk Tour - Kayar"},
    {value: "iata-aruana", label: "Iata - Aruana"}
  ]
  console.log('data', data)

  const reviceColor = (nums) =>{
    if(nums.length === 0){
      return 'rgba(0,0,0,0)'
    } else if (nums.reduce((a, b) => a + b, 0) === 0){
      return '#B7F46E'
    }
    return '#FFC373'
  }

  return (
    <>
      <div className={"kt-grid__item"}>
        <Portlet>
          <BlockUi blocking={loading}>
            <Form>
            <PortletHeader
              title={"Список сверок"}
              toolbar={
                <PortletHeaderToolbar>
                  <WeekSelections style={{ width: "150px", marginRight: "10px" }}
                                  control={control}
                                  watch={watch}
                                  cb={search}/>
                  <div style={{ width: "150px", marginRight: "10px" }}>
                    <Controller name={'activeType'}
                                control={control}
                                defaultValue={'all'}
                                render={({
                                           field: { onChange, onBlur, value, name, ref },
                                           fieldState: { invalid, isTouched, isDirty, error },
                                           formState,
                                         })=>(
                                  <Select
                                    options={typeList}
                                    onChange={val => {
                                      onChange(val.value);
                                      search()
                                    }}
                                    defaultValue={_.find(typeList, { value: 'all' })}
                                  />
                                )}/>
                  </div>
                  <Link
                    to={RacoonListRoute}
                    className="btn btn-brand btn-elevate btn-sm"
                  >
                    <i className="la la-plus" />
                    Добавить
                  </Link>
                </PortletHeaderToolbar>
              }
            />
          </Form>
            <PortletBody>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <td>Тип</td>
                    <td>Дата</td>
                    <td>Создал</td>
                    <td>Действие</td>
                  </tr>
                </thead>
                <tbody>
                  {data && data.map((item, i)=>{
                  return <tr key={i}>
                    <td>
                      <i className="fa fa-circle"
                         aria-hidden="true"
                         style={{color:`${reviceColor(item.numberResult.slice(1))}`, marginRight: '5px'}}></i>
                      {item.reviseType}
                    </td>
                    <td>{moment(item.startDate).format('DD.MM.YYYY')}-{moment(item.endDate).format('DD.MM.YYYY')}</td>
                    <td>{moment(item.createdDate).format('DD.MM.YYYY')}{' '}{item.createdBy}</td>
                    <td>
                      <div style={{display: 'flex'}}>
                        <Link
                          className={"btn btn-sm btn-clean btn-icon btn-icon-md"}
                          style={{height: '100%'}}
                          to={ReviseItemGetRoute + item.id}
                        >
                          <i className={"la la-edit"} />
                        </Link>
                        <RemoveLink
                          onClick={() => remove(item.id)}
                          noText
                          title={"поездку"}
                        />
                      </div>
                    </td>
                  </tr>
                })}
                  {(data?.length === 0) && <tr><th colSpan={4}>Нет сверок за период</th></tr>}
                </tbody>
              </Table>
            </PortletBody>
          </BlockUi>
        </Portlet>
      </div>
    </>
  );
};

export default connect(() => ({}), { LoadList, StartProcess, DeleteItem })(
  ReviseItemListPage
);
