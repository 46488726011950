import { SAVE_CURRENCIES_TYPE, SET_COUNTERPARTY_IGNORE, SAVE_AIRPLANES_TYPE } from "../actions/misc";

const initialState = {
  currencies: [],
  conterpartyIgnore: [],
  airplanes: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SAVE_CURRENCIES_TYPE:
      return { ...state, currencies: action.payload.currencies };
    case SET_COUNTERPARTY_IGNORE:
      return { ...state, conterpartyIgnore: action.payload };
    case SAVE_AIRPLANES_TYPE:
      return { ...state, airplanes: action.payload.airplanes };
    default:
      return state;
  }
}
