import React, {useEffect, useState} from 'react';
import {Table} from "react-bootstrap";
import _ from "lodash"
import MoreFunctionButton from "./moreFunctionButton";
import numberFormat from "../../../helpers/numberFormat";
import Button from "react-bootstrap/Button";
import OneConterpartyTable from "./oneConterpartyTable";

const baseObj = {
  "operation": "",
  "pax": "",
  "BSO": "",
  "route": "",
  "total": 0,
  "flightDate": ""
}
const BasicTable = ({editbleArray, title,setEditableArray, showCol,onlyOne, edit}) => {
  const style = {"display":"none"}
  const getTotal = (name) =>{
    let sum = 0
    editbleArray.forEach((t)=>{
      sum = sum+ _.sumBy(t?.tableData, name)
    })
    return sum
  }

  const [total, setTotal] = useState(getTotal('total'))
  useEffect(() => {
    setTotal(getTotal('total'))
  }, [editbleArray]);
  const editing = (index, value,name, tIndex)=>{
    const all = editbleArray.slice()
    if(name==='total'){
      all[tIndex].tableData[index][name] = +value.replace(/\s/g, '')
      setTotal(getTotal('total'))
    } else{
      all[tIndex].tableData[index][name] = value
    }
    setEditableArray(all)
  }
  const editName = (tIndex, value, name) =>{
    const newArr = editbleArray.slice()
    newArr[tIndex].header[name] = value
    setEditableArray(newArr)
  }
  const removeRow = (index, tIndex) =>{
    if(window.confirm(`Вы уверены что хотите удалить ${editbleArray[tIndex].tableData[index].operation}`)){
      const newArr = editbleArray.slice()
      newArr[tIndex].tableData.splice(index, 1)
      setEditableArray(newArr)
    }
  }
  const addRow = (index, direction, tIndex) =>{
    const newArr = editbleArray.slice()
    if(direction === 'up'){
      newArr[tIndex].tableData.splice(index, 0, baseObj)
    }else{
      newArr[tIndex].tableData.splice(index+1, 0, baseObj)
    }
    setEditableArray(newArr)
  }

  const getAllLenght = () =>{
    let sum = 0
    editbleArray.forEach(t=>{
      sum = sum + t.tableData.length
    })
    if(sum !== 0 ){
      return null
    }else{
      return 'notVisible'
    }
  }
  const colSpan = () =>{
    if(!showCol.name && !showCol.krs){
      return 6
    }else if(!showCol.name && showCol.krs){
      return 6
    }else if(showCol.name && !showCol.krs){
      return 5
    } else{
      return  5
    }
  }
  return <div className={'text-black mb-2'} id={getAllLenght()}>
      <h2 className={'text-center mt-4 mb-4'}>{title}</h2><br/>
      {(onlyOne) && <div>
        <div className={'d-flex'}>Покупатель: <div contentEditable suppressContentEditableWarning className={'ml-2'}
                                                   onBlur={e => editName(0, e.currentTarget.textContent, "counterparty")}>{editbleArray[0].header.counterparty}</div>
        </div>
        <div className={'d-flex'}>Период <div contentEditable suppressContentEditableWarning className={'ml-2'}
                                              onBlur={e => editName(0, e.currentTarget.textContent, "date")}>{editbleArray[0].header.date}
        </div></div>
        {editbleArray[0].tableData.length === 0 ? <div onClick={() => addRow(0, 'down', 0)} style={{cursor:'pointer'}}>
          Добавить строку <i className={"fa fa-plus ml-2 text-primary"}/>
        </div> : <br/>}
      </div>}
    {/*{(onlyOne && edit) && <div className={'mb-2'}>{editbleArray[0].header}</div>}*/}
    <Table striped bordered hover>
        <thead>
        <tr>
          <td>№</td>
          <td style={!showCol.name ? {} : style}>Документ/Операция</td>
          <td>Пассажир</td>
          <td>БСО</td>
          <td>Маршрут</td>
          <td>Дата вылета</td>
          <td style={showCol.krs ? {} : style}>КРС покупателя</td>
          <td style={showCol.krs ? {} : style}>КРС Kayar</td>
          <td>Итого</td>
        </tr>
        </thead>
      <tbody>
      {editbleArray.map((t, tIndex) => {
        const lenghtOld = () => {
          let index = 0
          let summLenght = 0
          while (index < tIndex) {
            summLenght = summLenght + editbleArray[index].tableData.length
            index++
          }
          return summLenght
        }
        return <React.Fragment key={tIndex}>
          <OneConterpartyTable table={t}
                               showCol={showCol}
                               editing={editing}
                               onlyOne={onlyOne}
                               editName={editName}
                               removeRow={removeRow}
                               addRow={addRow}
                               edit={edit}
                               firstNumber={tIndex === 0 ? 0 : lenghtOld()}
                               tableIndex={tIndex}/>
        </React.Fragment>
      })}

      <tr>
        <th colSpan={colSpan()}>Итого</th>
        <th style={showCol.krs ? {} : style} className={'text-end'}>{getTotal('krsBuyer')?numberFormat(getTotal('krsBuyer')):0}</th>
        <th style={showCol.krs ? {} : style} className={'text-end'}>{getTotal('krsKayar')?numberFormat(getTotal('krsKayar')):0}</th>

        <th className={'text-end'}>{numberFormat(total)}</th>
      </tr>
      </tbody>
    </Table>
  </div>
};

export default BasicTable;