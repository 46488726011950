import React from 'react';

const LogoFlyArystan = () => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="35" height="35" viewBox="0 0 1080.000000 1080.000000"
         preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
         fill="#ba1f2d" stroke="none">
        <path d="M0 5400 l0 -5400 5400 0 5400 0 0 5400 0 5400 -5400 0 -5400 0 0
-5400z m6129 4185 c554 -80 984 -359 1256 -817 68 -115 146 -294 179 -413 39
-138 38 -135 48 -90 5 22 7 99 5 170 -4 100 -11 150 -32 220 -42 146 -116 293
-184 368 -33 36 -13 35 68 -3 366 -174 529 -461 466 -822 -25 -144 -108 -351
-187 -466 -17 -24 -28 -45 -26 -47 2 -2 38 -27 81 -55 139 -90 247 -212 434
-490 76 -113 102 -142 154 -179 67 -48 287 -222 354 -281 140 -125 373 -359
444 -448 56 -70 56 -83 4 -120 -47 -32 -103 -46 -218 -51 l-110 -6 46 -27 c95
-56 224 -233 222 -304 -3 -107 -224 -353 -419 -467 -85 -51 -197 -86 -295 -93
-253 -20 -566 96 -847 312 l-82 64 -53 -6 c-72 -8 -244 11 -353 40 -245 66
-484 213 -678 416 -95 100 -98 98 -40 -29 105 -229 318 -447 552 -565 131 -66
315 -120 455 -132 78 -7 79 -7 105 -48 71 -113 163 -349 211 -541 26 -104 62
-297 58 -302 -3 -2 -40 18 -84 46 -125 77 -210 116 -369 170 -156 53 -425 114
-476 109 -25 -2 -2 -16 133 -78 624 -284 1039 -633 1241 -1044 160 -323 180
-696 57 -1070 -119 -363 -390 -753 -699 -1002 -74 -60 -196 -144 -210 -144 -4
0 -10 60 -13 133 -12 271 -66 476 -193 732 -81 165 -160 282 -278 410 -67 74
-215 205 -230 205 -3 0 19 -41 50 -91 176 -290 274 -587 274 -827 0 -82 -14
-180 -28 -194 -5 -5 -36 27 -70 69 -129 164 -259 283 -444 406 -313 210 -625
332 -1413 557 -574 163 -773 233 -1027 360 -473 236 -731 556 -828 1025 -35
166 -46 370 -32 583 7 102 11 187 9 189 -6 5 -77 -91 -120 -162 -96 -161 -157
-393 -173 -661 -4 -63 -10 -114 -13 -114 -7 0 -74 77 -134 153 -66 83 -182
268 -234 372 -154 307 -219 614 -194 907 36 399 164 765 382 1090 33 48 59 90
59 92 0 12 -111 -60 -215 -139 -178 -134 -421 -385 -594 -612 -34 -46 -66 -85
-71 -88 -4 -2 -10 23 -14 58 -42 454 19 896 176 1267 227 536 678 967 1257
1200 293 118 573 180 928 203 l178 12 -69 18 c-38 10 -112 25 -165 33 -132 22
-554 30 -751 15 -277 -22 -350 -24 -350 -14 0 22 171 212 263 292 341 297 794
478 1326 531 152 15 500 12 669 -5 180 -19 399 -62 556 -110 114 -34 161 -52
291 -109 l60 -26 -72 69 c-143 136 -376 243 -702 322 -77 19 -141 37 -141 40
0 11 228 43 380 53 104 7 395 -4 499 -19z m1802 -4533 c201 -101 256 -117 414
-116 115 0 139 3 204 27 41 14 78 24 83 21 11 -7 10 -210 -2 -274 -16 -86 -53
-155 -101 -189 -36 -27 -51 -31 -105 -31 -79 0 -143 24 -249 95 -140 93 -268
243 -340 398 -30 65 -53 137 -43 137 3 0 65 -31 139 -68z m-6340 -432 l107 0
32 -57 c49 -86 226 -354 310 -468 457 -623 1017 -1071 1695 -1357 322 -136
544 -208 1160 -378 348 -95 620 -181 790 -247 368 -143 662 -329 886 -560 71
-73 211 -244 205 -250 -2 -2 -57 15 -122 37 -283 98 -533 144 -1319 245 -713
91 -1064 151 -1430 245 -837 214 -1418 550 -1851 1070 -354 425 -586 995 -688
1689 l-7 44 62 -7 c34 -3 110 -6 170 -6z"/>
        <path d="M4960 9024 c-87 -8 -345 -46 -359 -54 -7 -4 40 -10 105 -14 643 -38
1241 -160 1683 -343 226 -93 426 -217 581 -359 72 -66 56 -38 -37 67 -276 309
-665 539 -1078 639 -205 50 -320 62 -590 65 -143 1 -280 1 -305 -1z"/>
        <path d="M4370 8074 c-19 -2 -78 -9 -130 -14 -275 -30 -573 -133 -792 -273
-160 -103 -349 -279 -464 -432 -104 -137 -106 -146 -14 -65 366 325 872 520
1540 595 267 30 713 30 1005 -1 l50 -5 -35 16 c-107 49 -411 124 -628 155
-129 19 -455 33 -532 24z"/>
        <path d="M6315 8062 c-28 -10 -66 -28 -85 -42 -160 -113 -190 -277 -94 -503
34 -79 141 -253 151 -244 2 3 -6 42 -19 88 -18 68 -22 111 -23 224 0 126 2
144 22 182 47 86 117 118 246 110 134 -8 267 -58 457 -170 41 -25 77 -43 79
-41 9 8 -121 143 -213 220 -195 165 -376 226 -521 176z"/>
        <path d="M7580 7150 c8 -6 49 -28 90 -50 98 -52 133 -82 128 -112 -11 -78
-267 -54 -547 51 -63 24 -80 27 -70 15 38 -46 319 -267 459 -361 51 -35 250
-136 250 -128 0 3 -9 24 -21 47 -30 59 -22 109 30 188 22 35 41 70 41 76 0 7
23 25 52 41 28 15 53 33 55 39 2 6 -17 17 -42 24 -26 7 -74 31 -108 53 -79 52
-167 91 -237 107 -30 6 -64 14 -75 17 -17 3 -17 2 -5 -7z"/>
        <path d="M4360 7020 c-340 -75 -616 -217 -850 -437 -245 -231 -432 -554 -520
-898 -49 -196 -48 -204 15 -86 72 134 162 271 260 396 262 335 698 718 1113
979 50 32 92 60 92 62 0 7 -20 4 -110 -16z"/>
        <path d="M6341 6994 c-2 -39 -42 -157 -78 -229 -47 -94 -108 -177 -184 -253
-97 -94 -261 -212 -298 -212 -12 0 -41 127 -41 183 0 71 35 166 80 221 22 26
40 50 40 53 0 9 -81 -42 -127 -79 -58 -49 -88 -87 -121 -158 -21 -45 -25 -68
-25 -140 0 -47 8 -123 17 -170 14 -78 64 -243 82 -271 4 -6 20 14 40 48 58
104 214 255 387 376 202 142 296 323 268 512 -12 79 -39 159 -40 119z"/>
        <path d="M5264 6873 c-250 -206 -448 -474 -547 -738 -152 -406 -25 -704 345
-805 79 -21 211 -42 383 -60 438 -45 713 -113 958 -236 l52 -26 -25 27 c-42
45 -181 132 -311 194 -138 66 -305 128 -514 191 -219 66 -392 128 -492 176
-114 54 -209 139 -250 222 -25 52 -28 67 -27 162 0 88 5 118 28 185 61 173
185 381 380 638 53 70 95 127 93 127 -2 0 -34 -26 -73 -57z"/>
        <path d="M4174 5951 c-191 -139 -391 -513 -440 -821 -22 -141 -15 -362 15
-475 113 -414 448 -733 1005 -955 169 -67 504 -170 555 -170 9 0 -47 29 -124
65 -453 211 -804 488 -1013 797 -103 153 -177 332 -207 502 -28 166 -15 390
34 564 39 137 57 181 142 350 48 94 83 172 79 172 -4 0 -25 -13 -46 -29z"/>
        <path d="M5211 4406 c2 -2 76 -29 164 -59 231 -80 255 -89 410 -150 480 -191
926 -426 1210 -640 331 -249 531 -499 611 -762 24 -79 27 -107 28 -232 l1
-142 18 36 c10 19 30 79 44 132 34 124 38 346 10 451 -91 331 -298 574 -687
805 -378 225 -935 408 -1620 535 -161 29 -198 34 -189 26z"/>
      </g>
    </svg>
  );
};

export default LogoFlyArystan;