import React, { useEffect } from "react";
import {
  Avatar,
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from "@material-ui/core";
import { Col, Row, Button, ButtonGroup } from "react-bootstrap";
import moment from "moment";
import { useFieldArray } from "react-hook-form";
import _ from "lodash";
import LinkTo from "../../trip/components/linkTo";

const SideBar = ({
  groupArray,
  tripSetter,
  loading,
  selectedTab,
  values,
  setter,
  formController,
  fieldArray,
  type
}) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    getValues
  } = formController;

  moment.locale("ru");

  const groupTemplate = {
    name: "",
    hideGroup: "",
    type: "",
    items: []
  };
  const groupAdd = () => {
    groupArray.append({ ...groupTemplate });
    setter(`group${values.groups.length}`);
  };
  const createIcon = (type) =>{
    let icon;
    switch (type) {
      case 'hotel':
        icon = 'fa fa-hotel'
        break;
      case 'etc' :
        icon = 'fa fa-ticket-alt'
        break;
      case 'transfer':
        icon = 'fa fa-car'
        break;
      case 'ticket':
        icon = "fa fa-plane"
        break;
      default:
        icon = 'fa fa-object-group'
    }
    return icon
  }

  return (
    <Card>
      <CardHeader
        title={
          <div className={"infoFlex"}>
            Инфо <LinkTo type={type} />
          </div>
        }
      />
      <List>
        <ListItem
          button
          selected={selectedTab === "home"}
          onClick={() => setter("home")}
        >
          <ListItemAvatar>
            <Avatar>
              <i className={"fa fa-suitcase"} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Общая информация" primaryTypographyProps={{
            className: _.get(errors, `name` || 'photosss')
              ? "text-danger"
              : null
          }}/>
        </ListItem>
        {groupArray.fields.map((item, index) => {
          const tabName = `group${index}`;
          let name = item.name;

          if (!item.name) {
            name = "Группа №" + (index + 1);
          }
          return (
            <ListItem
              button
              key={item.id}
              onClick={() => setter(tabName)}
              selected={selectedTab === tabName}
            >
              <ListItemAvatar>
                <Avatar>
                  {/*<WorkIcon />*/}
                  <i className={createIcon(item.type)} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primaryTypographyProps={{
                className: _.get(errors, `groups[${index}]`)
                  ? "text-danger"
                  : null
              }}
                            primary={name}
                            secondary={item.type} />
            </ListItem>
          );
        })}
      </List>
      <Col xs={12} style={{ padding: "0 16px" }}>
        <ButtonGroup aria-label="Basic example">
          <Button size={"sm"} type={"button"} onClick={() => groupAdd()}>
            <i className={"fa fa-object-group"} />{" "}
            <i className={"fa fa-plus"} />
          </Button>
        </ButtonGroup>
      </Col>
      <br />
      {values && values.selections && values.selections.length ? (
        <ListItem
          button
          onClick={() => setter("selections")}
          selected={selectedTab === "selections"}
        >
          <ListItemText primary={`Заявки (${values.selections.length})`} />
        </ListItem>
      ) : null}
      <CardHeader
        title={
          <Col xs={12}>
            <Button type={"submit"}>
              {loading ? (
                <i className={"fa fa-spinner fa-spin"} />
              ) : (
                "Сохранить"
              )}
            </Button>
            <br />
            <Button
              onClick={() => {
                console.log(getValues());
              }}
              variant={"secondary"}
            >
              Данные
            </Button>
            <Button
              onClick={() => {
                console.log(errors);
              }}
              variant={"secondary"}
            >
              Ошибки
            </Button>
          </Col>
        }
      />
    </Card>
  );
};

export default SideBar;
