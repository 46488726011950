import moment from "moment/moment";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import _ from "lodash";
import {Controller, useForm} from "react-hook-form";
const dayWeek = (day)=>{
  if(day<8){
    return 1
  } else if(day>7 && day<16){
    return 2
  } else if (day>15 && day<24){
    return 3
  }else{
    return 4
  }
}
const WeekSelections = ({style, control, watch, cb}) =>{
  const activeYear = +moment().format("YYYY")
  const activeMonth = +moment().format("MM")
  const activeWeek = dayWeek(+moment().format("D"))
  const createArrayYers = () => {
    const start = 2022;
    const end = +moment().format("YYYY");
    const arr = [];
    for (let i = start; i <= end; ++i) {
      arr.push({ value: i, label: i });
    }
    return arr;
  };
  const yearsList = createArrayYers();
  const monthList = Array.from({length:12}, (mon, i)=>({
    value: i+1, label:moment(i+1, 'M', 'ru').format('MMMM')}))
  const weekList= [
    {value:1, label: '01-07'},
    {value:2, label: '08-15'},
    {value:3, label: '16-23'},
    {value:4, label: '24+'},
  ]

  useEffect(()=>{
    console.log('done', watch())
    cb();
  },[watch('activeMonth'), watch('activeYear'), watch('activeWeek')])
  return<>
    <div style={style}>
      <Controller name={'activeYear'}
                  control={control}
                  defaultValue={activeYear}
                  render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      })=>(
        <Select
          options={yearsList}
          onChange={val => {
            onChange(val.value);
          }}
          defaultValue={_.find(yearsList, { value: activeYear })}
        />
      )}/>
    </div>
    <div style={style}>
      <Controller name={'activeMonth'}
                  control={control}
                  defaultValue={activeMonth}
                  render={({
                             field: { onChange, onBlur, value, name, ref },
                             fieldState: { invalid, isTouched, isDirty, error },
                             formState,
                           })=>(
                    <Select
                      options={monthList}
                      onChange={val => {
                        onChange(val.value);
                      }}
                      defaultValue={_.find(monthList, { value: activeMonth })}
                    />
                  )}/>
    </div>
    <div style={style}>
      <Controller name={'activeWeek'}
                  control={control}
                  defaultValue={activeWeek}
                  render={({
                             field: { onChange, onBlur, value, name, ref },
                             fieldState: { invalid, isTouched, isDirty, error },
                             formState,
                           })=>(
                    <Select
                      options={weekList}
                      onChange={val => {
                        onChange(val.value);
                      }}
                      defaultValue={_.find(weekList, { value: activeWeek })}
                    />
                  )}/>
    </div>
  </>
}
export default WeekSelections