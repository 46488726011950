import React, { useState, useEffect } from "react";
import { Button, Col, Form, FormLabel, Modal, Row } from "react-bootstrap";
import CustomDatePicker from "../../../../../../components/customDatePicker";
import _ from "lodash";

const ParsePaxesButton = ({ formController, addPax }) => {
  const [text, setText] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const parseText = () => {
    const formData = formController.getValues();

    const regex = /[0-9]\.([A-Z]+)\/((?!EXST)[A-Z ]+?)(MRS|MR|MS|MISS|\(CHD(\))?|\s{2,})(\(IDDOB[A-Z0-9]+\))?(\s|\n|$)/gm;
    let m;
    console.log("text", text);

    function x(data) {
      return new Promise(function(resolve, reject) {
        setTimeout(function() {
          resolve(data);
        }, 100);
      });
    }

    while ((m = regex.exec(text)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      const data = {
        firstName: m[2].trim(),
        lastName: m[1].trim(),
        title: m[3]
      };
      const oldPax = _.find(formData.paxes, {
        firstName: data.firstName,
        lastName: data.lastName
      });

      if (!oldPax) {
        x(data).then(function(data) {
          addPax(data.title, data.lastName, data.firstName);
        });
      }
    }
    handleClose();
  };

  return (
    <>
      <Button size={"sm"} onClick={handleShow}>
        Парс пассажиров из брони
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Пассажиры текстом</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <Form.Control
                as="textarea"
                rows="20"
                value={text}
                onChange={val => setText(val.target.value)}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Закрыть
          </Button>
          <Button variant="primary" onClick={parseText}>
            Разпарсить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ParsePaxesButton;
