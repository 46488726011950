import React, {useState} from 'react';
import {useController, useForm} from "react-hook-form";
import {Button, Col, Form, Modal} from "react-bootstrap";
import BlockUi from "@availity/block-ui";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import ApiSelect from "../../base/components/forms/apiSelect";
import _ from "lodash";
import axios from "../../../helpers/axios";

const ModalCityForm = ({changeValue}) => {
  const [loading, setLoading] = useState(false)
  const [show, setShow]=useState(false)
  const {register,
    control,
    handleSubmit,
    formState:{errors}} = useForm()
  const {
    field: { onChange: countryOnChange, value: countryValue }
  } = useController({
    name: `country`,
    control,
    rules: { required: true },
    defaultValue: ''
  });
  const onSubmit = (data) =>{
    setLoading(true)
    axios.put('/api/locations/cities',data)
      .then(({data})=>{
        changeValue(data.id)
        setShow(false)
      })
      .catch((e)=>{
        window.alert(e.response.data.message[0])
        console.log('err',e)
      })
      .finally(()=>{setLoading(false)})

  }
  return (
    <>
      <div onClick={()=>setShow(true)} style={{cursor:"pointer"}}><i className={"la la-plus"}/></div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Добавить новый город</Modal.Title>
        </Modal.Header>
        <BlockUi blocking={loading}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
              <FormHookInput
                register={register(`name`, {required: true,})}
                label={'Имя'}
                style={{marginBottom: 10}}
                errors={errors}
              />
              <FormHookInput
                register={register(`nameEn`, {required: true,})}
                label={'Имя на английском'}
                style={{marginBottom: 10}}
                errors={errors}
              />
              <Col xs={5}>
                <ApiSelect
                  value={countryValue}
                  label={'Страна'}
                  hasError={_.get(errors, `country`)}
                  url={"/api/locations/countries/searchInput"}
                  cb={newVal => countryOnChange(newVal)}
                />
              </Col>
              <p>
                После сохранения при повторном вводе ваш город отобразиться в списке
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShow(false)}>
                Закрыть
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit(onSubmit)}
              >
                Сохранить
              </Button>
            </Modal.Footer>
          </form>
        </BlockUi>
      </Modal>
    </>

  );
};

export default ModalCityForm;