import LoadingButton from "../../../base/components/loadingButton";
import React, {useState} from "react";
import axios from "../../../../helpers/axios";
import {ParsersCheckListRoute} from "../../../routesConstants";

const CheckButton = ({isValid, item, setResultCheck}) =>{
  const [loader, setLoader] = useState()
  const checkParse = () => {
    setLoader(true)
    console.log('item', item)

    const send = item.dateCheck ?{
      parseType: item.parseType,
      parseText: item.parseText,
      checks: item.checks,
      createdDate: item.createdDate
    }:{
      parseType: item.parseType,
      parseText: item.parseText,
      checks: item.checks,
    };
    axios
      .post(`/api/parseCheck/checkWithoutSave`, send)
      .then(({data})=>{
        setResultCheck(data)
        console.log('response',data)
        setLoader(false)
      }).catch(e => {
        setLoader(false)
      console.log("err", e)
      alert('Что-то пошло не так...')
    });
  }
  return <div>
    <LoadingButton disabled={!isValid}
                   title={"Проверить"}
                   type={'button'}
                   onClick={checkParse}
                   loading={loader} />
  </div>
}
export default CheckButton