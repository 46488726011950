import { Route, Routes } from "react-router";
import AirlineList from "./pages/list";
import AirlineCreatePage from "./pages/create";
import AirlineEditPage from "./pages/edit";
import React from "react";
import {
  AirlineCreateRoute,
  AirlineEditRoute,
  AirlineListRoute
} from "../../routesConstants";
import ErrorHandler from "../../base/components/errorHandler";

export default function() {
  return (
    <Routes>
      <Route path={"/"} exact={true} element={<AirlineList />} />
      <Route path={"create"} element={<AirlineCreatePage />} />
      <Route path={"edit/:id"} exact={true} element={<AirlineEditPage />} />
    </Routes>
  );
}
