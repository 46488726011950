import React from "react";
import { InputGroup, Row, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Controller, useController } from "react-hook-form";
import moment from "moment";
import _ from "lodash";
import InputMask from "react-input-mask";
import { TextField } from "@material-ui/core";

const FormHookDatePicker = props => {
  const {
    date,
    dateRules,
    dateName,
    time,
    timeRules,
    timeName,
    formController,
    errors,
    changer
  } = props;
  moment.locale("en");
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields }
  } = useController({
    name: dateName,
    control: formController.control,
    rules: dateRules,
    defaultValue: date
  });
  const {
    field: { onChange: timeOnChange, value: timeValue }
  } = useController({
    name: timeName,
    control: formController.control,
    rules: timeRules,
    defaultValue: time
  });
  const { control } = formController;
  let dateClassNames = "form-control datepickerBorder";
  if (_.get(errors, dateName)) {
    dateClassNames += " is-invalid";
  }
  let timeClassNames = "form-control";
  if (timeName && _.get(errors, timeName)) {
    timeClassNames += " is-invalid";
  }

  const TIME_MASK = [/^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/];
  return (
    <div>
      <Row>
        <Col xs={timeName ? 7 : 12} style={{ paddingRight: 0 }}>
          <DatePicker
            selected={value ? moment.utc(value, "DD.MM.YYYY").toDate() : null}
            onChange={d => {
              onChange(moment(d).format("DD.MM.YYYY"));
              changer && changer();
            }}
            inline={false}
            className={dateClassNames}
            //utcOffset={0}
            dateFormat={"dd.MM.yyyy"}
            onError={true}
            error
          />
        </Col>
        {timeName ? (
          <Col xs={5} style={{ paddingLeft: 0 }}>
            <InputMask
              mask={TIME_MASK}
              value={timeValue}
              className={timeClassNames}
              onChange={timeOnChange}
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            />
          </Col>
        ) : null}
      </Row>
    </div>
  );
};
export default FormHookDatePicker;
