import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useForm, Controller, useController } from "react-hook-form";
import _ from "lodash";
import PropTypes from "prop-types";

const HookDatePicker = props => {
  moment.locale("en");
  const {
    defaultValue,
    showTimeSelect,
    formController,
    placeholder,
    name,
    className,
    label,
    rules
  } = props;
  console.log("name", name);
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control: formController.control,
    rules: rules,
    defaultValue: defaultValue
  });
  const convertDate = datea => {
    console.log(datea);
    if (!datea) {
      return null;
    }
    const date = new Date(datea);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const newVal = new Date(date.getTime() + userTimezoneOffset);
    return moment(newVal)
      .utc()
      .add(moment(newVal).utcOffset(), "m")
      .toDate();
  };
  let [newValue, setNewValue] = useState(null);
  useEffect(() => {
    if (defaultValue) {
      setNewValue(defaultValue);
    }
  }, []);
  const {
    formState: { errors }
  } = formController;
  let classNames = className ? className : "form-control";
  if (_.get(errors, name)) {
    classNames += " is-invalid";
  }
  return (
    <div style={{position:'relative'}}>
      <label className={'absolutLabel'}>{label}</label>
      <DatePicker
        id={name}
        selected={value ? convertDate(value) : null}
        onChange={d => {
          onChange(convertDate(d));
        }}
        placeholderText={placeholder}
        inline={false}
        timeFormat={showTimeSelect ? "HH:mm" : undefined}
        className={classNames}
        //utcOffset={0}
        showTimeSelect={showTimeSelect ? true : false}
        dateFormat={showTimeSelect ? "dd MMM yyyy HH:mm" : "dd MMM yyyy"}
      />
    </div>
  );
};
HookDatePicker.propTypes = {
  defaultValue: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  formController: PropTypes.object,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  rules: PropTypes.object
};

export default HookDatePicker;
