import React, { useState } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader
} from "../../../../components/Portlet";
import { Col, ListGroup, Row, Button } from "react-bootstrap";
import { ListItem, ListItemText } from "@material-ui/core";
import { connect } from "react-redux";
import { StartProcess } from "../../../../redux/actions/base";
import axios from "../../../../helpers/axios";

const Selection = connect(null, { StartProcess })(props => {
  const { item, StartProcess } = props;
  const [loading, setLoading] = useState(false);
  const [generated, setGenerated] = useState(false);
  const createTrip = () => {
    setLoading(true);
    const cb = ({ data }) => {
      if (data.success) {
        setLoading(false);
        setGenerated(true);
        window.open(`/trips/trip/edit/${data.tripId}`, "_blank");
      }
    };
    StartProcess(`/api/trips/offer/createTripFromOffer/${item._id}`, cb);
  };
  return (
    <Row className={item.isValid ? "" : "text-danger"}>
      <Col xs={4}>
        <b>
          {item.user.lastName} {item.user.firstName}
        </b>{" "}
        <br />
        {item.createdDate} {item.createdTime} <br />
        {generated ? (
          <Button disabled={true} variant={"outline-success"}>
            Готово
          </Button>
        ) : (
          <Button disabled={loading} onClick={createTrip}>
            {loading ? "Генерируем" : "Сгенерировать поездку"}
          </Button>
        )}
      </Col>
      <Col xs={4}>
        <ListGroup>
          {item.groups.map(group => {
            return (
              <ListItem>
                <ListItemText
                  primary={group.groupName}
                  secondary={group.selected}
                />
              </ListItem>
            );
          })}
        </ListGroup>
      </Col>
      <Col xs={4} className={'d-flex justify-content-end align-items-start'}>
        <Button variant="outline-danger" onClick={()=>{
          if(window.confirm('Точно удалить заявку?')){
            axios.delete(`/api/trips/offer/removeSelection/${item._id}`)
              .then(()=>{
                window.alert('Заявка удалена')
                window.location.reload()
              })
          }
        }}>Удалить</Button>
      </Col>
    </Row>
  );
});

const SelectionList = ({ data, className }) => {
  return (
    <>
      <Portlet className={className}>
        <PortletHeader title={"Выбранные поездки"} />
        <PortletBody>
          {data.selections?.map(item => {
            return <Selection item={item} />;
          })}
        </PortletBody>
      </Portlet>
    </>
  );
};

export default SelectionList;
