import React from 'react';
import {Col, Row, Tab, Table, Tabs} from "react-bootstrap";
import numberFormat from "../../../helpers/numberFormat";
import {showCount} from "../../../helpers/showCount";

const AruanaIataRender = ({data}) => {
  return (
    <>
      <Row>
        <Col>
          <Tabs defaultActiveKey="correct">
            <Tab
              eventKey="correct"
              title={`Правильные (${data.okTickets?.length})`}
            >
              <Table striped hover>
                <thead>
                <tr>
                  <td>Номер</td>
                  <td>Тип</td>
                  <td>Итого</td>
                </tr>
                </thead>
                <tbody>
                {data.okTickets.map((u, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {u.airline} {u.ticketNumber}
                      </td>
                      <td>{u.refund ? "Возврат" : "Инвойс"}</td>
                      <td>{numberFormat(u.total)}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={2}>
                    <b>Итого</b>
                  </td>
                  <td>
                    <b>
                      {numberFormat(
                        data.okTickets
                          .map(u => u.total)
                          .reduce((a, b) => a + b, 0)
                      )}
                    </b>
                  </td>
                </tr>
                </tbody>
              </Table>
            </Tab>
            <Tab
              eventKey="noIata"
              title={`Есть в Iata но нет в Aruana (${showCount(data.aruanaMissing)})`}
            >
              <Table striped hover>
                <thead>
                <tr>
                  <td>Номер</td>
                  <td>Тип</td>
                  <td>Итого</td>
                </tr>
                </thead>
                <tbody>
                {data.aruanaMissing.map((u, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {u.airline} {u.ticketNumber}
                      </td>
                      <td>{u.refund ? "Возврат" : "Инвойс"}</td>
                      <td>{numberFormat(u.total)}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={2}>
                    <b>Итого</b>
                  </td>
                  <td>
                    <b>
                      {numberFormat(
                        data.aruanaMissing
                          .map(u => u.total)
                          .reduce((a, b) => a + b, 0)
                      )}
                    </b>
                  </td>
                </tr>
                </tbody>
              </Table>
            </Tab>
            <Tab
              eventKey="noAruana"
              title={`Есть в Aruana но нет в Iata (${showCount(data.iataMissing)})`}
            >
              <Table striped hover>
                <thead>
                <tr>
                  <td>Номер</td>
                  <td>Тип</td>
                  <td>Итого</td>
                </tr>
                </thead>
                <tbody>
                {data.iataMissing.map((u, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {u.airline} {u.ticketNumber}
                      </td>
                      <td>{u.refund ? "Возврат" : "Инвойс"}</td>
                      <td>{numberFormat(u.total)}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={2}>
                    <b>Итого</b>
                  </td>
                  <td>
                    <b>
                      {numberFormat(
                        data.iataMissing
                          .map(u => u.total)
                          .reduce((a, b) => a + b, 0)
                      )}
                    </b>
                  </td>
                </tr>
                </tbody>
              </Table>
            </Tab>
            <Tab
              eventKey="diff"
              title={`Не соответствуют (${showCount(data.diffPrice)})`}
            >
              <Table striped hover>
                <thead>
                <tr>
                  <td>Номер</td>
                  <td>Итого Iata</td>
                  <td>Итого Aruana</td>
                </tr>
                </thead>
                <tbody>
                {data.diffPrice.map((u, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {u.airline} {u.ticketNumber}
                      </td>
                      <td>{numberFormat(u.total.iata)}</td>
                      <td>{numberFormat(u.total.aruana)}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td>
                    <b>Итого</b>
                  </td>
                  <td>
                    <b>
                      {numberFormat(
                        data.diffPrice
                          .map(u => u.total.iata)
                          .reduce((a, b) => a + b, 0)
                      )}
                    </b>
                  </td>
                  <td>
                    <b>
                      {numberFormat(
                        data.diffPrice
                          .map(u => u.total.aruana)
                          .reduce((a, b) => a + b, 0)
                      )}
                    </b>
                  </td>
                </tr>
                </tbody>
              </Table>
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <Row>
        <Col>
          Сумма Iata: <b>{numberFormat(data.iataTotal)}</b>(
          {numberFormat(data.iataTotal - data.aruanaTotal)})
          <br />
          Cумма Aruana: <b>{numberFormat(data.aruanaTotal)}</b>(
          {numberFormat(data.aruanaTotal - data.iataTotal)})
          <br />
        </Col>
      </Row>
    </>
  );
};

export default AruanaIataRender;