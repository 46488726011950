import React, { useEffect, useState } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../components/Portlet";
import {
  Row,
  Col,
  FormLabel,
  FormControl,
  Form,
  Button
} from "react-bootstrap";
import CustomDatePicker from "../../../components/customDatePicker";
import Select from "react-select";
import DescriptionsBlock from "./descriptionsBlock";
import PaxesBlock from "./paxesBlock";
import ImagesBlock from "./imagesBlock";
import ApiSelect from "../../base/components/forms/apiSelect";
import RemoveLink from "../../air/airBooking/components/removeLink";
import _ from "lodash";
import { FieldArray } from "formik";
import { ages, titles, transferPointType } from "../../../helpers/enums";
import FastInput from "../../../components/FastInput";
import { Controller, useController, useFieldArray } from "react-hook-form";
import HookDatePicker from "../../../components/hookDatePicker";
import PaxesSelector from "./paxesSelector";
import CurrencyPicker from "../../base/components/forms/currencySelector";
import Duplicate from "../trip/components/tripFormParts/buttons/duplicate";
import PhotoUploadButton from "../../base/components/photoUploadButton";
import PhotoInput from "../trip/components/tripFormParts/photoInput";
import DocumentsBlock from "./documentsBlock";
import LocationPicker from "../../../components/locationPicker";
import InputMask from "react-input-mask";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import FormHookSelect from "../../air/airBooking/components/formHookSelect";
import FormLocation from "../../base/components/forms/formLocation";
import FormHookDatePicker from "../../base/components/forms/formHookDatePicker";
import TransferTransports from "./transferTransports";
import TransferPoints from "./transferPoints";
import TripCharterSegments from "./tripCharterSegments";
import TimeInput from "./TimeInput";
import AirplaneSelector from "../../base/components/forms/airplaneSelector";

const TripCharterForm = ({ withoutPaxes, item, prefix, formController }) => {
  const {
    register,
    formState: { errors },
    formState,
    control,
    setValue,
    getValues
  } = formController;
  const {
    field: { onChange: airplaneOnChange, value: airplaneValue }
  } = useController({
    name: `${prefix}.airplane`,
    control: formController.control,
    defaultValue: item.airplane
  });
  return (
    <>
      <input
        type={"hidden"}
        {...register(`${prefix}.type`)}
        defaultValue={"charter"}
      />
      <input
        type={"hidden"}
        {...register(`${prefix}._id`)}
        defaultValue={item._id}
      />

      <Row>
        <Col xs={6}>
          <FormLabel>Название</FormLabel>
          <FormHookInput
            defaultValue={item.name}
            register={register(`${prefix}.name`, { required: true })}
            errors={errors}
          />
        </Col>
        <Col xs={3}>
          <FormLabel>Самолет</FormLabel>
          <AirplaneSelector
            placeholder={"Самолет"}
            value={item.airplane}
            width = {'100%'}
            name={`${prefix}.airplane`}
            formController={formController}
            errors={errors}/>
        </Col>
        <Col xs={3}>
          <FormLabel>Время в пути</FormLabel>
          <TimeInput
            control={control}
            name={`${prefix}.flightTime`}
            value={item.flightTime}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        {!withoutPaxes ? (
          <Col xs={6}>
            <FormLabel>Пассажиры</FormLabel>
            <PaxesSelector
              formController={formController}
              value={item.paxes}
              name={`${prefix}.paxes`}
            />
          </Col>
        ) : null}
        <Col xs={3}>
          <FormLabel>Цена</FormLabel>
          <FormHookInput
            defaultValue={item.price}
            type={"number"}
            register={register(`${prefix}.price`)}
            errors={errors}
          />
        </Col>
        <Col xs={3}>
          <FormLabel>Валюта</FormLabel>
          <CurrencyPicker
            value={item.currency}
            formController={formController}
            name={`${prefix}.currency`}
            errors={errors}
          />
          {/*<ApiSelect*/}
          {/*  value={item.currency}*/}
          {/*  url={"/api/currencies/searchInput"}*/}
          {/*  cb={(newVal)=>{console.log(`${prefix}.currency`);setValue(`${prefix}.currency`,newVal)}}*/}
          {/*/>*/}
        </Col>
      </Row>
      <DocumentsBlock
        formController={formController}
        prefix={`${prefix}.documents`}
        documents={item.documents}
      />
      <TripCharterSegments
        prefix={`${prefix}.segments`}
        transports={item.segments}
        formController={formController}
      />
    </>
  );
};

export default TripCharterForm;
