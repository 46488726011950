import React, { useState, useEffect, useRef } from "react";
import ApiSelect from "../../../../base/components/forms/apiSelect";
import { FormLabel, Row, Col } from "react-bootstrap";
import _ from "lodash";
import RemoveLink from "../../../../air/airBooking/components/removeLink";
import { useFieldArray } from "react-hook-form";
import { connect } from "react-redux";
import { SearchInput, StartProcess } from "../../../../../redux/actions/base";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useParams } from "react-router";
import TokenButton from "../../../components/tokenButton";

const UsersBlock = ({ formController, prefix, SearchInput, type, ticket }) => {
  const [users, setUsers] = useState([]);
  const [token, setToken] = useState(null)
  const [current, setCurrent]= useState(null)
  const params = useParams();
  console.log("this offer params", params);
  // return <>
  //   <ApiSelect
  //     value={val}
  //     url={"/api/locations/countries/searchInput"}
  //     cb={(newVal)=>{setVal(newVal)}}
  //     isMulti={true}
  //   />
  //   {val.map((cc, i) => <input type={'hidden'} name={`${name}[${i}]`} ref={formController.register()} value={cc}/>)}
  // </>
  const { control, register } = formController;
  const usersControl = useFieldArray({
    control,
    name: `${prefix}`
  });
  const { fields, append, remove } = usersControl;

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (fields.length) {
      setLoading(true);
      const cb = data => {
        if (data.data.success) {
          setUsers(data.data.data.map(u => u.value));
        }
        setLoading(false);
      };
      SearchInput("/api/trips/users/searchInput", cb, {
        ids: fields.map(u => u.userId)
      });
    }
  }, []);
  const cb = data => {
    console.log(users);
    console.log(_.find(users, { _id: data._id }));
    if (_.find(users, { _id: data._id })) {
      window.alert("Этот пользователь уже добавлен");
    } else {
      setUsers([...users, data]);
      append({ userId: data._id });
    }
  };
  return (
    <>
      <FormLabel>Аккаунты</FormLabel>
      <ApiSelect value={null} url={"/api/trips/users/searchInput"} cb={cb} />
      <br />
      {loading ? (
        <i className="fas fa-spinner fa-spin"></i>
      ) : (
        fields.map((user, index) => {
          const userData = _.find(users, { _id: user.userId });
          if (!userData) {
            return (
              <React.Fragment key={index}>
                loading...
                <br />
              </React.Fragment>
            );
          }
          return (
            <Row key={index}>
              <Col>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <div>
                    <a href={`/users/edit/${userData._id}`} target={"_blank"}>
                      {userData.lastName} {userData.firstName}
                    </a>{" "}
                    {userData.email}
                    <br />
                    <RemoveLink
                      title={"пользователя"}
                      onClick={() => remove(index)}
                    />
                  </div>
                  {type && (
                    <TokenButton
                      id={userData._id}
                      mainId={params.id}
                      type={type}
                      ticket={ticket}
                      setToken={setToken}
                      current={current}
                      setCurrent={setCurrent}
                    />
                  )}
                </div>
                <input
                  type={"hidden"}
                  {...register(`${prefix}[${index}].userId`)}
                  defaultValue={user.userId}
                />
                {token && current === userData._id && <div>{token}</div>}
                <hr />
              </Col>
            </Row>
          );
        })
      )}
    </>
  );
};

export default connect(() => ({}), { SearchInput, StartProcess })(UsersBlock);
