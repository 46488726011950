export const LOGIN_ACTION = 'login_action';
export const LOGIN_PUT = 'login_put';
export const LOGOUT = 'logout';
export const RESET_USER_PASSWORD = 'reset_user_password';

export const ResetUserPassword = (token, password, passwordDuple, firstLogin, cb) => {
  return {
    type: RESET_USER_PASSWORD,
    payload: {token, password, passwordDuple, firstLogin, cb}
  }
}
export const Login = (email, password, cb) => {
  return {
    type: LOGIN_ACTION,
    payload: {email, password, cb}
  }
}
export const Logout = () => {
  return {
    type: LOGOUT,
  }
}

export const LoginPut = (user) => {
  return {
    type: LOGIN_PUT,
    payload: user
  }
}