import { Route, Routes } from "react-router";
import UserList from "./pages/list";
import UserCreatePage from "./pages/create";
import UserEditPage from "./pages/edit";
import React from "react";
import {
  UserListRoute,
  UserCreateRoute,
  UserEditRoute
} from "../routesConstants";
import ErrorHandler from "../base/components/errorHandler";

export default function() {
  return (
    <ErrorHandler>
      <Routes>
        <Route path={"list"} element={<UserList />} />
        <Route path={"create"} element={<UserCreatePage />} />
        <Route path={"edit/:id"} exact={true} element={<UserEditPage />} />
      </Routes>
    </ErrorHandler>
  );
}
