import React, { useState } from "react";
import { Button, FormLabel, Modal, Col, Row } from "react-bootstrap";
import CustomDatePicker from "../../../components/customDatePicker";
import _ from "lodash";

const HotelDatesModal = ({ prefix, formController, index }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [checkIn, setCheckIn] = useState();
  const [checkOut, setCheckOut] = useState();

  const acceptChanges = () => {
    const { setValue, getValues } = formController;
    const formData = getValues();
    console.log("formData", formData);
    console.log("prefix", prefix);

    const item = _.get(formData, prefix);
    console.log("item", item);
    if (item.rooms) {
      setValue(`${prefix}.startDate`, checkIn);
      setValue(`${prefix}.endDate`, checkOut);
      item.rooms.forEach((room, i) => {
        setValue(`${prefix}.rooms[${i}].startDate`, checkIn);
        setValue(`${prefix}.rooms[${i}].endDate`, checkOut);
      });
    }
    handleClose();
  };

  return (
    <>
      <Button onClick={handleShow}>Даты отеля</Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Даты номеров</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={6}>
              <FormLabel>Дата заезда</FormLabel>
              <CustomDatePicker
                value={checkIn}
                onChange={val => setCheckIn(val)}
                showTimeSelect={true}
              />
            </Col>
            <Col xs={6}>
              <FormLabel>Дата выезда</FormLabel>
              <CustomDatePicker
                value={checkOut}
                onChange={val => setCheckOut(val)}
                showTimeSelect={true}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={acceptChanges}>
            Применить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HotelDatesModal;
