import React, {useEffect} from 'react';
import {useFieldArray, useForm} from "react-hook-form";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import CustomDatePicker from "../../base/components/forms/customDatePicker";
import LoadingButton from "../../base/components/loadingButton";
import moment from "moment";

const paxTempData = {
  "firstName": "",
  "lastName": ""
}
const FinderCountTableForm = ({cb, loading}) => {
  const formController = useForm()
  const {control,
    register,
    formState:{errors},
    handleSubmit} = formController
  const paxesControll = useFieldArray({
    control,
    name: "paxes",
    rules: {required: true},
    shouldUnregister: true,
    keyName: 'customId',
  });
  const addPax = () =>{
    paxesControll.append(paxTempData)
  }
  const deletePax = (index) =>{
    paxesControll.remove(index)
  }
  useEffect(() => {
    addPax()
  }, []);

  const onSubmit = (data) =>{
    console.log('submit',data)
    cb(data)
  }
  const defaultStartDate = moment().subtract(1, 'year').format('DD.MM.YYYY');
  const defaultEndDate = moment().format('DD.MM.YYYY');

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'d-flex justify-content-between align-items-end '}>
      <div className={'d-flex align-items-end gap-2'}>
        <CustomDatePicker
          formController={formController}
          label={"Начало периода"}
          name={"start"}
          value={defaultStartDate}
          rules={{required: true}}
        />
        <CustomDatePicker
          formController={formController}
          label={"Конец периода"}
          name={"end"}
          value={defaultEndDate}
          rules={{required: true}}
        />
        <div>
          {paxesControll.fields.map((pax,i)=>{
            let classN = i=== 0 ? '' : 'mt-3'
            return <div className={'d-flex align-items-center gap-2 ' +classN} key={i}>
              <FormHookInput
                defaultValue={pax.lastName}
                register={register(`paxes[${i}].lastName`, {
                  required: true,
                })}
                style={{minWidth:'200px'}}
                label={'Фамилия'}
                errors={errors}
              />
              <FormHookInput
                defaultValue={pax.firstName}
                register={register(`paxes[${i}].firstName`)}
                style={{minWidth:'200px'}}
                label={'Имя'}
                errors={errors}
              />
              <i className={"fa fa-plus"} onClick={addPax}/>
              {paxesControll.fields.length > 1 && <i className={"fa fa-trash text-danger"} onClick={()=>deletePax(i)}/>}
            </div>
          })}
        </div>
      </div>



      <LoadingButton
        title={"Загрузить"}
        disabled={loading}
        loading={loading}
      />
    </form>
  );
};

export default FinderCountTableForm;