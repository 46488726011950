import { useForm } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import {
  Portlet,
  PortletBody,
  PortletFooter,
  PortletHeader
} from "../../../../components/Portlet";
import { Button } from "react-bootstrap";
import FormHookInput from "../../../air/airBooking/components/formHookInput";
import { connect } from "react-redux";
import { StartProcess } from "../../../../redux/actions/base";

const ChangePassword = ({ userId, StartProcess, url}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setError
  } = useForm();
  console.log("errors", errors);
  const onSubmit = data => {
    StartProcess(url, ({data})=> {
      if(data.success){
        alert('Ваш пароль изменен!');
        reset();
      }
      console.log('data', data)
    }, {id: userId, ...data});
    console.log(data);
  };
  return (
    <Portlet>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <PortletHeader title={"Смена пароля"} />
        <PortletBody>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Новый пароль
            </Form.Label>
            <Col sm={10}>
              <FormHookInput
                register={register(`password`, {
                  required: true,
                  minLength: 3
                })}
                errors={errors}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Повторите пароль
            </Form.Label>
            <Col sm={10}>
              <FormHookInput
                register={register(`passwordDuple`, {
                  required: true,
                  minLength: 3,
                  validate: (value, formValue) => {
                    if (formValue.password !== value) {
                      setError("passwordDuple", {
                        type: "required",
                        message: "Don't match"
                      });
                      return false;
                    }
                    return true;
                  }
                })}
                errors={errors}
              />
              {errors.passwordDuple?.type === "validate" && (
                <span className="kt-font-danger">This field is required</span>
              )}
            </Col>
          </Form.Group>
        </PortletBody>
        <PortletFooter>
          <Row>
            <Col md={{ span: 10, offset: 2 }}>
              <Button type="submit">Изменить пароль</Button>
            </Col>
          </Row>
        </PortletFooter>
      </Form>
    </Portlet>
  );
};

export default connect(null, { StartProcess })(ChangePassword);
