import React, { useState, useEffect } from "react";
import Pagination from "./paginaton";
import { usePagination, useTable } from "react-table";
import { connect } from "react-redux";
import { LoadList } from "../redux/actions/base";
import { Table } from "react-bootstrap";
import BlockUi from "@availity/block-ui";

function DataTable(props) {
  const [items, setItems] = useState(props.items ? props.items : []);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0)
  const [loading, setLoading] = useState(false);
  const [selectedPage, setSelectedPage] = useState(props.startPage ? props.startPage :0);
  const [firstLoad, setFirstLoad] = useState(true)
  const [itemsPerPage, setItemsPerPage] = useState(props.itemsPerPage ? props.itemsPerPage : 10)
  const lockedColumns = React.useMemo(() => [...props.columns], [
    props.columns
  ]);

  const { search, refetch } = props;
  useEffect(() => {
    if (search) {
      callback(props.startPage ?props.startPage : 0);
    }
  }, [search]);

  useEffect(() => {
    let perPage = props.all ? totalItems : itemsPerPage
    console.log('selectedPage',selectedPage)
    props.LoadList(props.url, selectedPage, perPage, search && search, res => {
      console.log("res", res);
      if (res.data.items) {
        setItems(res.data.items);
        setTotalPages(res.data.pages);
        res.data.totalItems && setTotalItems(res.data.totalItems)
      } else {
        setItems([]);
      }
      setFirstLoad(false)
      setLoading(false);
    });
  }, [refetch, search, props.all]);
  const data = React.useMemo(() => [...items], [items]);
  const instance = useTable(
    {
      columns: lockedColumns,
      data,
      initialState: { pageIndex: props.startPage ? props.startPage : selectedPage },
      pageCount: totalPages,
      manualPagination: true
    },
    usePagination
  );

  const {
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex }
  } = instance;
  const callback = selected => {
    if (!props.items) {
      setLoading(true);
      setSelectedPage(selected);
      localStorage.setItem("page",  selected)
      props.LoadList(props.url, selected, itemsPerPage, search, res => {
        console.log("res", res);
        if (res.data?.items) {
          setItems(res.data.items);
          setTotalPages(res.data.pages);
        } else {
          setItems([]);
        }

        setLoading(false);
      });
    }
  };
  console.log('items', items)
  return (
    <div>
      <BlockUi blocking={loading}>
        <Table striped hover>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, index) => (
                  <th {...column.getHeaderProps()} key={index}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td {...cell.getCellProps()} className={"align-middle"} key={index}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {totalPages > 1 ? (
          <div className={'d-flex gap-2 align-items-center'}>
            <Pagination
              page={pageIndex}
              totalPages={totalPages}
              callback={callback}
            />
          </div>
        ) : null}
      </BlockUi>
      <div className={'text-center'}>{!firstLoad && items.length<1 && 'Список пуст'}</div>
    </div>
  );
}

export default connect(null, { LoadList })(DataTable);
