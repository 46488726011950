import React from "react";
import PaxesBlock from "../../components/paxesBlock";
import {Col, Form, Row} from "react-bootstrap";
import { useFieldArray } from "react-hook-form";
import TripTransferForm from "../../components/tripTransfer";
import TripEtcForm from "../../components/tripEtc";
import UsersBlock from "../../trip/components/tripFormParts/usersBlock";

const TransferBlock = props => {
  const { formController, transfer } = props;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    getValues,
    reset
  } = formController;
  const paxArray = useFieldArray({
    control,
    name: `paxes`
  });
  return (
    <>
      <Row>
        <Col xs={12}>
          <UsersBlock
            prefix={"item.visibleToAccounts"}
            formController={formController}
            type={`trips/${transfer.item.type}/`}
          />
          <PaxesBlock
            prefix={"paxes"}
            formController={formController}
            paxes={transfer.paxes}
            paxArray={paxArray}
            hideButtons={true}
          />
          <Form.Label className={'d-flex align-items-center gap-2'}>
            <input
              defaultChecked={transfer.item.public}
              {...register(`item.public`)}
              type={"checkbox"}
            />{" "}
            Публичный
          </Form.Label>
          <br />
          <TripTransferForm
            formController={formController}
            item={transfer.item}
            prefix={"item"}
            withoutPaxes={true}
            activ
          />
        </Col>
      </Row>
    </>
  );
};

export default TransferBlock;
