/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import { Logout } from "../../../redux/actions/auth";
import { Button } from "reactstrap";
import {UserEditRoute} from "../../routesConstants";

const HeaderDropdownToggle = React.forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      className="kt-header__topbar-wrapper"
      onClick={e => {
        e.preventDefault();
        props.onClick(e);
      }}
    >
      {props.children}
    </div>
  );
});

HeaderDropdownToggle.displayName = "HeaderDropdownToggle";

class UserProfile extends React.Component {
  render() {
    const { user } = this.props;

    return (
      <Dropdown
        className="kt-header__topbar-item kt-header__topbar-item--user"
        drop="down"
        alignright="true"
      >
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-profile"
        >
          <div className="kt-header__topbar-user">
            <span className="kt-header__topbar-username kt-hidden-mobile">
              {user.email}
            </span>

            <img alt="Pic" src={user.avatar} />

          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          <Link to={UserEditRoute+user.id}>
            <div
              className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`
              }}
            >
              <div className="kt-user-card__avatar" style={{display:'flex', alignItems:'center'}}>
                <img alt="Pic" className="kt-hidden" src={user.avatar} />
                <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold "style={{color: 'white'}}>
                  {user.firstName.slice(0,1)}
                </span>
                <span style={{color: 'white', marginLeft: '1rem'}}>
                  {user.email} <i className="fa fa-cog ml-2" aria-hidden="true"></i>

                </span>
              </div>
              <div className="kt-user-card__name">{user.fullname}</div>
            </div>
          </Link>
          <div className="kt-notification">
            {/*<a className="kt-notification__item">*/}
            {/*  <div className="kt-notification__item-icon">*/}
            {/*    <i className="flaticon2-calendar-3 kt-font-success" />*/}
            {/*  </div>*/}
            {/*  <div className="kt-notification__item-details">*/}
            {/*    <div className="kt-notification__item-title kt-font-bold">*/}
            {/*      Профиль*/}
            {/*    </div>*/}
            {/*    <div className="kt-notification__item-time">*/}
            {/*      Настройки аккаунта и пароля*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</a>*/}
            <div className="kt-notification__custom">
              <Button
                className="btn btn-label-brand btn-sm btn-bold"
                onClick={() => this.props.Logout()}
              >
                Выйти
              </Button>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps, { Logout })(UserProfile);
