import ErrorHandler from "../base/components/errorHandler";
import {Route, Routes} from "react-router";
import React from "react";
import RailwaysList from "./pages/list";
import RailwaisCreate from "./pages/create";
import RailOrderPage from "./pages/edit";

export default function() {
  return (
    <ErrorHandler>
      <Routes>
        <Route path={'/list'} element={<RailwaysList/>} />
        <Route path={'/create'} element={<RailwaisCreate/>} />
        <Route path={"/edit/:id"} exact={true} element={<RailOrderPage/>} />

      </Routes>
    </ErrorHandler>
  );
}