import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React from "react";

const ModalLivingPage = (
  {showDialog,
    setShowDialog,
    cancelNavigation,
    confirmNavigation
  })=>{
  return <Modal isOpen={showDialog}
                      toggle={() => {
                        setShowDialog(!showDialog);
                      }}>
    <ModalHeader>
      Вы уверены что хотите покинуть страницу?
    </ModalHeader>
    <ModalBody>
      Текущие изменения будет не сохранены
    </ModalBody>
    <ModalFooter>
      <Button variant="secondary" onClick={cancelNavigation}>
        Остаться
      </Button>
      <Button variant="primary" onClick={confirmNavigation}>
        Покинуть
      </Button>
    </ModalFooter>
  </Modal>
}
export default ModalLivingPage