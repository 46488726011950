import React, { useEffect, useState } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../components/Portlet";
import {
  Row,
  Col,
  FormLabel,
  FormControl,
  Form,
  Button
} from "react-bootstrap";
import CustomDatePicker from "../../../components/customDatePicker";
import Select from "react-select";
import DescriptionsBlock from "./descriptionsBlock";
import PaxesBlock from "./paxesBlock";
import ImagesBlock from "./imagesBlock";
import ApiSelect from "../../base/components/forms/apiSelect";
import RemoveLink from "../../air/airBooking/components/removeLink";
import _ from "lodash";
import { FieldArray } from "formik";
import { ages, titles, transferPointType } from "../../../helpers/enums";
import FastInput from "../../../components/FastInput";
import { Controller, useController, useFieldArray } from "react-hook-form";
import HookDatePicker from "../../../components/hookDatePicker";
import PaxesSelector from "./paxesSelector";
import CurrencyPicker from "../../base/components/forms/currencySelector";
import Duplicate from "../trip/components/tripFormParts/buttons/duplicate";
import PhotoUploadButton from "../../base/components/photoUploadButton";
import PhotoInput from "../trip/components/tripFormParts/photoInput";
import DocumentsBlock from "./documentsBlock";
import LocationPicker from "../../../components/locationPicker";
import InputMask from "react-input-mask";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import FormHookSelect from "../../air/airBooking/components/formHookSelect";
import FormLocation from "../../base/components/forms/formLocation";
import FormHookDatePicker from "../../base/components/forms/formHookDatePicker";
import TransferTransports from "./transferTransports";
import TransferPoints from "./transferPoints";
import IconSelect from "./IconSelect";

const TripTransferForm = ({ withoutPaxes, item, prefix, formController, activ, offer }) => {
  const {
    register,
    formState: { errors },
    formState,
    control,
    setValue,
    getValues
  } = formController;

  const TIME_MASK = [/^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/];
  let classNames = "form-control";
  if (_.get(errors, `${prefix}.driveTime`)) {
    classNames += " is-invalid";
  }
  const {
    field: { onChange: timeOnChange, value: timeValue }
  } = useController({
    name: `${prefix}.driveTime`,
    control: formController.control,
    rules: { required: true, minLength: 5 },
    defaultValue: item.driveTime
  });

  return (
    <>
      <input
        type={"hidden"}
        {...register(`${prefix}.type`)}
        defaultValue={"transfer"}
      />
      <input
        type={"hidden"}
        {...register(`${prefix}._id`)}
        defaultValue={item._id}
      />

      <Row>
        <Col xs={6}>
          <FormLabel>Название</FormLabel>
          <FormHookInput
            defaultValue={item.name}
            register={register(`${prefix}.name`, { required: true })}
            errors={errors}
          />
        </Col>
        <Col xs={3}>
          <FormLabel>Дата начала</FormLabel>
          <FormHookDatePicker
            formController={formController}
            dateRules={{ required: true }}
            date={item.startDate}
            dateName={`${prefix}.startDate`}
            timeRules={{ required: true }}
            time={item.startTime}
            timeName={`${prefix}.startTime`}
            errors={errors}
          />
        </Col>
        <Col xs={3}>
          <FormLabel>Время в пути</FormLabel>
          <InputMask
            mask={TIME_MASK}
            value={timeValue}
            className={classNames}
            onChange={timeOnChange}
            placeholder={"Время в пути"}
          />
        </Col>

        {/*<Col xs={3}>*/}
        {/*  <FormLabel>Дата окончания</FormLabel>*/}
        {/*  <CustomDatePicker value={item.endDate}*/}
        {/*                    name={`${prefix}.endDate`}*/}
        {/*                    showTimeSelect={true}*/}
        {/*                    onChange={(newVal) => setFieldValue(`${prefix}.endDate`,newVal)}*/}
        {/*  />*/}
        {/*</Col>*/}
      </Row>
      <Row>
        {!withoutPaxes ? (
          <Col xs={6}>
            <FormLabel>Пассажиры</FormLabel>
            <PaxesSelector
              formController={formController}
              value={item.paxes}
              name={`${prefix}.paxes`}
            />
          </Col>
        ) : null}
        <Col xs={2}>
          <FormLabel>Цена</FormLabel>
          <FormHookInput
            defaultValue={item.price}
            type={"number"}
            register={register(`${prefix}.price`)}
            errors={errors}
          />
        </Col>
        <Col xs={2}>
          <FormLabel>Валюта</FormLabel>
          <CurrencyPicker
            value={item.currency}
            formController={formController}
            name={`${prefix}.currency`}
            className={classNames}
            errors={errors}
          />
          {/*<ApiSelect*/}
          {/*  value={item.currency}*/}
          {/*  url={"/api/currencies/searchInput"}*/}
          {/*  cb={(newVal)=>{console.log(`${prefix}.currency`);setValue(`${prefix}.currency`,newVal)}}*/}
          {/*/>*/}
        </Col>
        <Col xs={2}>
          <FormLabel>Иконка</FormLabel>
          <IconSelect defaultValue={item.icon} prefix={prefix} control={control}/>
        </Col>
        {offer && <Col xs={1}>
          <FormLabel>Порядок</FormLabel>
          <FormHookInput
            defaultValue={item.order}
            type={"number"}
            register={register(`${prefix}.order`)}
          />
        </Col>}
        <Col xs={3}>
          <FormLabel>Разное</FormLabel>
          <Form.Check
            type={"checkbox"}
            {...register(`${prefix}.hideElement`)}
            label={"Спрятать элемент"}
            defaultChecked={item.hideElement}
          />

          <Form.Check
            type={"checkbox"}
            label={"Прятать цену"}
            {...register(`${prefix}.hidePrice`)}
            defaultChecked={item.hidePrice}
          />
        </Col>
      </Row>
      <DescriptionsBlock
        title={"трансфера"}
        descriptions={item.descriptions}
        formController={formController}
        prefix={`${prefix}.descriptions`}
      />
      <DocumentsBlock
        formController={formController}
        prefix={`${prefix}.documents`}
        documents={item.documents}
      />
      {/*<ImagesBlock*/}
      {/*  formController={formController}*/}
      {/*  prefix={`${prefix}.photos`}*/}
      {/*  minLength={1}*/}
      {/*  photos={item.photos}*/}
      {/*/>*/}
      {/*<ImagesBlock*/}
      {/*  images={item.photos}*/}
      {/*  formik={formik}*/}
      {/*  prefix={`${prefix}.photos`}/>*/}
      <br />
      {activ && <TransferTransports
        prefix={`${prefix}.transports`}
        transports={item.transports}
        formController={formController}
      />}

      <br />
      <TransferPoints
        prefix={`${prefix}.points`}
        points={item.points}
        formController={formController}
      />
    </>
  );
};

export default TripTransferForm;
