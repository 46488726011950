import React from 'react';
import {Col, Form, Row} from "react-bootstrap";

const FormRow = ({title, name, value, handleChange, touched, errors,type, required,disabled,placeholder, rows, checked} ) =>{
  let inputType = "text";
  const renderInput = () => {
    if(type === 'textarea'){
      return <Form.Control
        name={name}
        value={value}
        onChange={handleChange}
        className={touched && errors ? (touched[name] && errors[name] ? "is-invalid" : null):null}
        as={'textarea'}
        rows={rows}
        disabled={disabled}
        placeholder={placeholder}
      />
    }
    else{
      return <Form.Control
        name={name}
        value={value}
        onChange={handleChange}
        className={touched && errors ? (touched[name] && errors[name] ? "is-invalid" : null):null}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
      />
    }
  }

  const renderRow = () => {
    if(type === 'checkbox'){
      return <Col md={{span:10, offset: 2}}>
        <Form.Check
          type={'checkbox'}
          name={name}
          onChange={handleChange}
          label={title}
          checked={checked}
          className={touched && errors ? (touched[name] && errors[name] ? "is-invalid" : null):null}
          disabled={disabled}
        />
      </Col>
    }
    return <>
      <Form.Label column sm="2">{title}</Form.Label>
      <Col sm={10}>
        {renderInput()}
      </Col>
    </>
  }
  return <Form.Group as={Row}>
    {renderRow()}
  </Form.Group>
}

export default FormRow
