import React, {useState, useEffect} from "react";
import {Tab, Tabs} from "react-bootstrap";
import AirBookingTicketsForm from "./airBookingTicketsForm";
import {AirBookingPaxItemForm} from "./airBookingPaxesForm";
import {FieldArray} from "formik";
import {titles} from "../../../../helpers/enums";
import Form from "react-bootstrap/esm/Form";
import {useFieldArray, useWatch} from "react-hook-form";
import _ from "lodash";

const AirBookingPaxDataForm = ({data, formController}) => {
  const defaultTabName = "pax0";
  const {
    control,
    getValues,
    formState: {errors}
  } = formController;
  const paxesControl = useFieldArray({
    control,
    name: `paxes`,
    rules: {required: true},
    shouldUnregister: true,
    keyName: 'customId'
  });
  const paxTemplate = {
    lastName: "",
    firstName: "",
    title: titles[0],
    tickets: []
  };
  const [tabIndex, setTabIndex] = useState(null);
  useEffect(() => {
    if (!tabIndex && data.paxes.length !== 0) {
      setTabIndex(defaultTabName);
    }
  }, [data]);

  const deletePax = (index, helpers) => {
    paxesControl.remove(index);
    if (getValues().paxes.length - 1) {
      setTabIndex(defaultTabName);
    }
  };
  const paxesLength = useWatch({name: `paxes`, control: control});
  const addPax = arrayHelpers => {
    const currenctPaxesLength = data.paxes.length;
    paxesControl.append({...paxTemplate});
    console.log("paxesLength new", paxesLength.length);
    setTabIndex(`pax${paxesLength.length}`);
  };
  const tabClick = (key, arrayHelpers) => {
    if (key === "add") {
      addPax(arrayHelpers);
    } else {
      setTabIndex(key);
    }
  };
  const getPaxTabName = ({lastName, firstName}, index) => {
    if (lastName && firstName) {
      return lastName + "/" + firstName;
    }
    return `Пассажир ${index + 1}`;
  };
  return (
    <>
      <h6>Данные пассажиров ({getValues().paxes.length})</h6>
      <Tabs
        variant="pills"
        className={"paxTabs"}
        id="controlled-tab-example"
        activeKey={tabIndex}
        onSelect={key => tabClick(key)}
      >
        {paxesControl.fields.map((pax, index) => {
          return (
            <Tab
              eventKey={`pax${index}`}
              key={pax.customId}
              title={getPaxTabName(pax, index)}
              tabClassName={
                _.get(errors, `paxes[${index}]`) ? "is-invalid" : ""
              }
            >
              <AirBookingPaxItemForm
                pax={pax}
                index={index}
                prefix={`paxes[${index}]`}
                formController={formController}
                deletePax={() => deletePax(index)}
              />
              <AirBookingTicketsForm
                pax={pax}
                index={index}
                prefix={`paxes[${index}]`}
                formController={formController}
              />
            </Tab>
          );
        })}
        <Tab eventKey={`add`} title={<i className={"fa fa-plus"}/>}/>
      </Tabs>
      {(_.get(errors, `paxes`)?.root) ? (
        <p className={"text-danger"}>Минимум 1 пассажир</p>
      ) : null}
    </>
  );
};

export default AirBookingPaxDataForm;
