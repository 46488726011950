import React, { useRef } from "react";
import { Col, Form, FormLabel, Row } from "react-bootstrap";
import { ages, titles } from "../../../helpers/enums";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import FormHookSelect from "../../air/airBooking/components/formHookSelect";
import { useDrag, useDrop } from "react-dnd";
import RemoveLink from "../../air/airBooking/components/removeLink";

const PaxForm = ({
  removePax,
  prefix,
  formController,
  pax,
  index,
  moveCard
}) => {
  const {
    register,
    formState: { errors }
  } = formController;
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "pax",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });
  const [{ isDragging }, drag] = useDrag({
    type: "pax",
    item: () => {
      return { id: pax.tempId, index };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Row
      ref={ref}
      style={{ opacity: opacity, height: 50 }}
      data-handler-id={handlerId}
    >
      <input
        type={"hidden"}
        {...register(`${prefix}.tempId`)}
        defaultValue={pax.tempId}
      />
      <input
        type={"hidden"}
        {...register(`${prefix}._id`)}
        defaultValue={pax._id}
      />
      <input
        type={"hidden"}
        {...register(`${prefix}.order`, { value: index })}
        defaultValue={index}
      />
      <Col xs={2}>
        <FormHookSelect
          register={register(`${prefix}.title`)}
          defaultValue={pax.title}
          errors={errors}
        >
          {titles.map((item, i) => (
            <option value={item} key={i}>
              {item}
            </option>
          ))}
        </FormHookSelect>
      </Col>
      <Col xs={3}>
        <FormHookInput
          register={register(`${prefix}.lastName`, {
            required: true,
            minLength: 2,
            onChange: e =>
              (e.target.value = ("" + e.target.value).toUpperCase())
          })}
          className={"form-control"}
          defaultValue={pax.lastName}
          errors={errors}
        />
      </Col>
      <Col xs={3}>
        <FormHookInput
          register={register(`${prefix}.firstName`, {
            required: true,
            minLength: 2,
            onChange: e =>
              (e.target.value = ("" + e.target.value).toUpperCase())
          })}
          className={"form-control"}
          defaultValue={pax.firstName}
          errors={errors}
        />
      </Col>
      <Col xs={2}>
        <FormHookSelect
          register={register(`${prefix}.age`)}
          errors={errors}
          defaultValue={pax.age}
        >
          {ages.map((item, i) => (
            <option value={item} key={i}>
              {item}
            </option>
          ))}
        </FormHookSelect>
      </Col>
      <Col xs={2}>
        <RemoveLink onClick={() => removePax(index)} title={"пассажира"} />
      </Col>
    </Row>
  );
};

export default PaxForm;
