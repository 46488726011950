import React from 'react';
import {Badge, Col, Form, Row} from "react-bootstrap";
import {airClasses} from "../../../../helpers/enums";
import RemoveLink from "../../airBooking/components/removeLink";

const AirlineClassForm = ({prefix, values, formik, deleteClass}) =>{
  const {handleChange, errors, touched} = formik;
  console.log(errors)

  return <Row  className="align-items-center">
    <Col xs={4}>
      <Form.Control
        name={`${prefix}.classLetter`}
        value={values.classLetter}
        onChange={handleChange}
        className={touched.classLetter && errors.classLetter ? "is-invalid" : null}
        required
        placeholder={'Символ'}
        type="text"
      />
    </Col>
    <Col xs={4}>
      <Form.Control
        as="select"
        name={`${prefix}.classType`}
        value={values.classType}
        onChange={handleChange}
        className={touched.title && errors.title ? "is-invalid" : null}
        required
      >
        {airClasses.map((item, i)=><option value={item} key={i}>{item}</option>)}

      </Form.Control>
    </Col>
    <Col xs={4}>
      <RemoveLink onClick={deleteClass} title={"класс"}/>
    </Col>
  </Row>
}

export default AirlineClassForm;
