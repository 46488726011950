import {
  LOAD_CURRENCIES_TYPE,
  LOAD_AIRPLANES_TYPE,
  SaveCurrenciesAction,
  SaveAirplanesAction
} from "../actions/misc";
import { call, put, takeLeading, takeLatest, fork } from "redux-saga/effects";
import ApiService from "../../services/apiService";
import axios from "axios";

function* loadCurrenciesWorker(action) {
  try {
    const res = yield call(ApiService.post, "/api/currencies/getAll");
    console.log("result", res);

    yield put(SaveCurrenciesAction(res.data.data));
  } catch (e) {
    console.log(e);
    console.log(JSON.stringify(e.response.data));
    console.log(action);
    action.payload.cb(e.response.data);
  }
}

function* watchLoadCurrenciesRequested() {
  yield takeLeading(LOAD_CURRENCIES_TYPE, loadCurrenciesWorker);
}

function* loadAirplanesWorker(action) {
  const res = yield call(ApiService.post, "/api/air/airplanes/getAll");
  console.log("listAirplanes", res.data);

  yield put(SaveAirplanesAction(res.data.data));
}

function* watchLoadAirplanesRequested() {
  yield takeLeading(LOAD_AIRPLANES_TYPE, loadAirplanesWorker);
}

export default [watchLoadCurrenciesRequested, watchLoadAirplanesRequested];
