import { Route, Routes } from "react-router";
import TripUsersList from "./pages/TripUsersList";
import React from "react";
import {
  TripUserCreateRoute,
  TripUserEditRoute,
  TripUsersRoute
} from "../../routesConstants";
import ErrorHandler from "../../base/components/errorHandler";
import TripUserCreatePage from "./pages/TripUserCreatePage";
import TripUserEditPage from "./pages/TripUserEditPage";

export default function() {
  return (
    <ErrorHandler>
      <Routes>
        <Route path={"/"} exact={true} element={<TripUsersList />} />
        <Route path={"create"} exact={true} element={<TripUserCreatePage />} />
        <Route path={"edit/:id"} exact={true} element={<TripUserEditPage />} />
      </Routes>
    </ErrorHandler>
  );
}
