import { connect } from "react-redux";
import { LoadAirplanesAction } from "../../../../redux/actions/misc";
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { useController } from "react-hook-form";
import Select from "react-select";
import _ from "lodash";

const AirplaneSelector = ({
  airplanes,
  LoadAirplanesAction,
  value,
  formController,
  width,
  name,
  label,
  placeholder,
  errors
}) => {
  useEffect(() => {
    if (!airplanes.length) {
      LoadAirplanesAction();
    }
  }, []);
  const [val, setVal] = useState(value);
  const {
    field: { onChange, value: currValue },
    fieldState
  } = useController({
    name,
    control: formController.control,
    // rules: { required: true },
    defaultValue: value
  });
  console.log('airplainsError',_.get(errors, name))
  return (
    <div style={{position: 'relative'}}>
      <label className={'absolutLabel'}>{label}</label>
      <Select
        options={airplanes}
        // className={className}
        value={airplanes.filter(plain => plain.value === currValue)}
        error={!!fieldState.error}
        placeholder={placeholder}
        onChange={newVal => {
          console.log(newVal);
          onChange(newVal.value);
        }}
        getOptionValue={obj => obj.value === val}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            width: width || '16.9rem',
            borderColor: _.get(errors, name) ? "#FD397A" : "#e2e5ec"
          }),
          menu:provided => {
            return {...provided, zIndex: '10000'};
          },
        }}
      />
    </div>
  );
};
const mapStateToProps = ({misc}) => {
  const { airplanes } = misc;
  return { airplanes };
};
export default connect(mapStateToProps, { LoadAirplanesAction })(
  AirplaneSelector
);
