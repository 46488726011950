import React from 'react';
import {Row, Tab, Table, Tabs} from "react-bootstrap";
import moment from "moment";
import numberFormat from "../../../helpers/numberFormat";
import {showCount} from "../../../helpers/showCount";

const GoogleKayarRender = ({data}) => {

  return (
    <>
      <Row>
        <Tabs defaultActiveKey="correct">
          <Tab
            eventKey="correct"
            title={`Правильные (${data.correct.length})`}
          >
            <Table striped hover>
              {renderHeader()}
              {renderStandartBody(data.correct)}
            </Table>
          </Tab>
          <Tab
            eventKey="noGoogle"
            title={`Есть в Kayar но нет в Google (${showCount(data.googleMissing)})`}
          >
            <Table striped hover>
              {renderHeader()}
              {renderStandartBody(data.googleMissing)}
            </Table>
          </Tab>
          <Tab
            eventKey="noKayar"
            title={`Есть в Google но нет в Kayar (${showCount(data.kayarMissing)})`}
          >
            <Table striped hover>
              {renderHeader()}
              {renderStandartBody(data.kayarMissing)}
            </Table>
          </Tab>
          <Tab
            eventKey="diff"
            title={`Не соответствуют (${showCount(data.diff)})`}
          >
            <Table striped hover>
              <thead>
              <tr>
                <td>Номер</td>
                <td>Выписан</td>
                <td>Итого Kayar</td>
                <td>Итого Google</td>
              </tr>
              </thead>
              <tbody>
              {data.diff.map((u, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {u.airline} {u.ticketNumber}
                    </td>
                    <td>{u.issueDate}</td>
                    <td>{numberFormat(u.oneCTotal)}</td>
                    <td>{numberFormat(u.total)}</td>
                  </tr>
                );
              })}
              <tr>
                <td>
                  <b>Итого</b>
                </td>
                <td></td>
                <td>
                  <b>
                    {numberFormat(
                      data.diff
                        .map(u => u.oneCTotal)
                        .reduce((a, b) => a + b, 0)
                    )}
                  </b>
                </td>
                <td>
                  <b>
                    {numberFormat(
                      data.diff.map(u => u.total).reduce((a, b) => a + b, 0)
                    )}
                  </b>
                </td>
              </tr>
              </tbody>
            </Table>
          </Tab>
          <Tab
            eventKey="wrongMonth"
            title={`Неправильная дата (${showCount(data.wrongMonth)})`}
          >
            <Table striped hover>
              {renderHeader()}
              {renderStandartBody(data.wrongMonth)}
            </Table>
          </Tab>
        </Tabs>
      </Row>
      
    </>
  );
};

export default GoogleKayarRender;

const renderHeader = () => {
  return <thead>
  <tr>
    <td>Номер</td>
    <td>Выписан</td>
    <td>Итого</td>
  </tr>
  </thead>
}

const renderStandartBody = (array) =>{
  return <tbody>
  {array.map((u, index) => {
    return (
      <tr key={index}>
        <td>
          {u.airline} {u.ticketNumber} {u.refund && ' B'}
        </td>
        <td>{u.issueDate}</td>
        <td>{numberFormat(u.total)}</td>
      </tr>
    );
  })
  }
  <tr>
    <td colSpan={2}>
      <b>Итого</b>
    </td>
    <td>
      <b>
        {numberFormat(
          array
            .map(u => u.total)
            .reduce((a, b) => a + b, 0)
        )}
      </b>
    </td>
  </tr>
  </tbody>

}
