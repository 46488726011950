import ErrorHandler from "../base/components/errorHandler";
import {Route, Routes} from "react-router";
import React from "react";
import CreateSalesRegister from "./page/create";
import EditSalesRegister from "./page/edit";
import ListSalesRegister from "./page/list";


export default function() {
  return (
    <ErrorHandler>
      <Routes>
        <Route path={"list"} element={<ListSalesRegister/>} />
        <Route path={"create"} element={<CreateSalesRegister/>} />
        <Route path={"edit/:id"} element={<EditSalesRegister/>} />

      </Routes>
    </ErrorHandler>
  );
}