import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";

const FormHookInput = props => {
  const {
    defaultValue,
    className,
    style,
    errors,
    placeholder,
    inputstyle,
    type,
    label,
    step,
    disabled,
    register
  } = props;
  let classNames = className ? className : "form-control";
  if (_.get(errors, register.name)) {

    classNames += " is-invalid";
  }
  return (
    <div style={{position:'relative',...style}}>
      <label className={'absolutLabel'}>{label}</label>
      <input
        type={type ? type : "text"}
        style={inputstyle}
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={defaultValue}
        className={classNames}
        step={(type === 'number' && step) && step}
        {...register}
      />
    </div>

  );
};
FormHookInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  //defaultValue: PropTypes.string,
  errors: PropTypes.object,
  className: PropTypes.string,
  ref: PropTypes.any,
  type: PropTypes.string
};

export default FormHookInput;
