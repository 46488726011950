import React, {useEffect, useState} from "react";
import {
  Portlet,
  PortletBody,
  PortletFooter,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import {Col, Form, Row, Button, Alert} from "react-bootstrap";
import LoadingButton from "../../../base/components/loadingButton";
import AirBookingSegmentsForm from "./airBookingSegmentsForm";
import AirBookingPaxDataForm from "./аirBookingPaxDataForm";
import moment from "moment";

import ParseInput from "./parseInput";
import {useFieldArray, useForm} from "react-hook-form";
import AirBookingMainForm from "./airBookingMainForm";
import {useNavigatingAway} from "../../../../helpers/useNavigatingAway";
import ModalLivingPage from "../../../../components/layout/ModalLivingPage";
import FlyArystanParse from "./flyArystanParse";
import axios from "../../../../helpers/axios";
import AnimateLoading from "../../../base/components/animateLoading";
import ReactJson from "react-json-view";
import {ErrorMessage} from "@hookform/error-message";
import PotentialBuyers from "../../../touristServices/components/potentialBuyers";

const AirBookingForm = ({data, onSubmit, className, setter, newForm, loading, edit}) => {
  const renderBookingInfo = data => {
    if (!data) {
      return null;
    } else {
      const renderSegment = (segment, index) => {
        const airports =
          segment.departure.airport + " - " + segment.arrival.airport;
        const arrival = moment.utc(segment.arrival.date, 'DD.MM.YYYY');
        const departure = moment.utc(segment.departure.date, 'DD.MM.YYYY');
        const times = segment.departure.time + " - " + segment.arrival.time;
        let dates = departure.format("DDMMM") + " - " + arrival.format("DDMMM");
        if (departure.format("DDMMM") === arrival.format("DDMMM")) {
          dates = departure.format("DDMMM");
        }
        return (
          <small key={index}>
            {airports}, {times}, {dates}
            <br/>
          </small>
        );
      };

      const renderTickets = ({tickets}) => {
        if (tickets === undefined || !tickets.length) {
          return <>(Нет Билетов)</>;
        }
        return tickets.map((ticket, index) => {
          return (
            <div key={index}>
              {ticket.airlineCode}-{ticket.ticketNumber}
              <br/>
            </div>
          );
        });
      };
      return (
        <>
          Бронь {data.bookingNumber} от{" "}
          {moment(data.issueDate, "DD.MM.YYYY HH:mm").format("DD MMM YYYY")}
          <br/>
          {data.segments.map(renderSegment)}
          {data.paxes.map((pax, index) => {
            return (
              <div key={index}>
                <b>
                  {pax.lastName} {pax.firstName}
                </b>
                <br/>
                {renderTickets(pax)}
              </div>
            );
          })}
        </>
      );
    }
  };

  const [suppliers, setSuppliers] = useState();
  const [mainLoading, setMainLoading ] = useState(true);
  const [showErrors, setShowErrors] = useState(false)
  const [potentialBuyers, setPotentialBuyers] = useState()
  const formController = useForm({defaultValues: data});
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: {errors, isDirty},
    control,
    setError,
    getValues,
    reset
  } = formController;
  const [canShowModalLivingPage, setCanShowModalLivingPage] = useState(false)
  const [
    showDialogLeavingPage,
    confirmNavigation,
    cancelNavigation
  ] = useNavigatingAway(canShowModalLivingPage)
  useEffect(() => {
    if (isDirty) {
      setCanShowModalLivingPage(true)
    }
  }, [isDirty])
  useEffect(() => {
    reset(data);
  }, [data]);
  useEffect(() => {
    axios.post('/api/counterparty/suppliers/getAll',{type: 'avia'}).then(({data}) => {
      console.log('supl',data)
      setSuppliers(data.data)
      setMainLoading(false)
    });
  }, []);
  const submitWith1c = (data, e) =>{
    submitAction({...data, importOneS:true},e)
  }
  const submitAction = (data, e) => {
    setCanShowModalLivingPage(false)
    e.preventDefault();
    console.log("refund", data)
    const errors = []
    const checkInput = (value,path) =>{
      if(value<0){
        setError(`${path}`, { type: 'custom', message: 'custom message' })
        errors.push(path)
      }
    }
    data.paxes.forEach((p,pi)=>{
      p.tickets.forEach((t,ti)=>{
        if(!t.refund){
          checkInput(t.fare,`paxes[${pi}].tickets[${ti}].fare`)
          checkInput(t.taxes,`paxes[${pi}].tickets[${ti}].taxes`)
          checkInput(t.penalties,`paxes[${pi}].tickets[${ti}].penalties`)
          checkInput(t.krsBuyer,`paxes[${pi}].tickets[${ti}].krsBuyer`)
          checkInput(t.krsKayar,`paxes[${pi}].tickets[${ti}].krsKayar`)
          checkInput(t.krsSeller,`paxes[${pi}].tickets[${ti}].krsSeller`)
        }
      })
    })
    if(errors.length===0){
      onSubmit(data, () => {});
    }
  };

  const segmentsControl = useFieldArray({
    control,
    name: "segments",
    rules: {required: true},
    shouldUnregister: true,
    keyName: 'customId'
  });
  if(mainLoading){
    return <div
      className={`kt-header kt-grid__item kt-header--fixed`}
      id="kt_header"
    >
      <AnimateLoading />

    </div>
  }
  const errorsToString = (errors) =>{
    let newObject = []
    const objectVay = (errors,pref)=>{
      Object.keys(errors).forEach(key=>{
        if(Array.isArray(errors[key])){
          const array = errors[key]
          if(array['root']){
            const str = key + ": required;"
            newObject.push(pref ? pref+str: str)
          } else{
            array.forEach((obj,i)=>{
              objectVay(obj,`${key}[${i}]`)
            })
          }
        }else {
          const obj = errors[key]
          const str = key + ': ' +obj.type+ ';'
          newObject.push(pref ? pref+str: str)
        }
      })
    }
    objectVay(errors)
    return newObject
  }

  return (<>
      <ModalLivingPage showDialog={showDialogLeavingPage}
                       setShowDialog={setCanShowModalLivingPage}
                       confirmNavigation={confirmNavigation}
                       cancelNavigation={cancelNavigation}/>
      <div className={"col-12"}>
        <Col xs={12}>
          {data.info && data.info.length ? (
            <Alert variant="info">
              <div className={"alert-text"}>
                {data.info.map((text, i) => (
                  <p key={i}>{text}</p>
                ))}
              </div>
            </Alert>
          ) : null}
        </Col>
        <Row>
          <Col xs={9}>
            <Portlet>
              <PortletHeader
                title={"Редактирование авиа бронирования"}
                toolbar={
                  newForm ?
                    <PortletHeaderToolbar>
                      <FlyArystanParse setter={setter} potentialBuyers={buyers=>setPotentialBuyers(buyers)}/>
                      <ParseInput setter={setter} url={"/api/air/parse/airBooking"} potentialBuyers={buyers=>setPotentialBuyers(buyers)} keyValue={'airBooking'} placeholder={"Парс авиа"}/>
                    </PortletHeaderToolbar> : null
                }
              />
              <div className={className}>
                <form onSubmit={handleSubmit(submitAction)}>
                  <PortletBody>
                    <AirBookingMainForm
                      values={data}
                      formController={formController}
                      setter={setter}
                      suppliers={suppliers}
                    />
                    <AirBookingSegmentsForm
                      segmentsController={segmentsControl}
                      data={data}
                      prefix={"segments"}
                      formController={formController}
                    />
                    {/*<AirBookingPaxesForm paxes={values.paxes} formik={formik}/>*/}
                    <AirBookingPaxDataForm
                      data={data}
                      formController={formController}
                      control={control}
                    />
                    {showErrors && <><p className={'text-danger'}>Список ошибок:</p>
                      {errorsToString(errors).map((str,i)=>{
                      return <div key={i}>
                        {str}<br/>
                      </div>
                    })}</>}
                  </PortletBody>
                  <PortletFooter>
                    <Row>
                      <Col md={{span: 9, offset: 3}}>
                        <LoadingButton title={"Сохранить"} loading={loading}/>{" "}
                        <Button
                          onClick={() => {
                            console.log("errors", errors);
                            console.log(getValues());
                            setShowErrors(true)
                          }}
                        >
                          Показать ошибки
                        </Button>{" "}
                        <LoadingButton title={"Сохранить и в 1с"} loading={loading} onClick={handleSubmit(submitWith1c)}/>

                      </Col>
                    </Row>
                  </PortletFooter>
                </form>
              </div>
            </Portlet>
          </Col>
          <Col xs={3}>
            {potentialBuyers && <PotentialBuyers potentialBuyers={potentialBuyers} setBuyer={id=>setValue('buyer', id)}/> }
            <Portlet>
              <PortletHeader title={"Информация по бронированию"}/>
              <PortletBody>
                {renderBookingInfo(data)}
                {edit && <><hr/>Создано: <br/> Кем: {data.createdBy.email} <br/>Когда: {data.createdAt} </>}
              </PortletBody>
            </Portlet>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default  AirBookingForm;
