import React from 'react';
import {useFieldArray} from "react-hook-form";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import {Form} from "react-bootstrap";

const phoneTempData = {
  "phone": "",
  "comment": ""
}
const PhonesFields = ({formController, data,prefix}) => {
  const {control,register,formState:{errors}} = formController
  const contactsControl = useFieldArray({
    control: control,
    name: `${prefix}.phones`,
    // rules: {required: true},
    shouldUnregister: true,
    keyName: 'customId',
    defaultValue: data
  });
  const addCountr = ()=>{
    contactsControl.append(phoneTempData)
  }
  const removeCountr = (index)=>{
    contactsControl.remove(index)
  }
  return (
    <div>
      <div className={'d-flex align-items-center gap-2 mb-3 p-2 pointer'}
           onClick={addCountr}
           style={{backgroundColor:'rgba(121,129,134,0.15)'}}>
        <h5 className={'mb-0'}>Телефоны:</h5>
        <i className={'fa fa-plus'}/>
      </div>
      {contactsControl.fields.map((contact, index) => {
        return <div className={'d-flex flex-column gap-3'} key={index}>
          <div className={'d-flex justify-content-between align-items-center'}>
            <div>Телефон №{index + 1} </div>
            <i className={'fa fa-trash pointer ml-2'} onClick={() => removeCountr(index)}/>
          </div>
          <FormHookInput
            register={register(`${prefix}.phones[${index}].phone`, {required: true})}
            label={'Телефон'}
            type={'phone'}
            defaultValue={contact.phone}
            errors={errors}
          />
          <FormHookInput
            register={register(`${prefix}.phones[${index}].comment`)}
            label={'Комментарий'}
            defaultValue={contact.comment}
            errors={errors}
          />
          {contactsControl.fields.length >1 && index+1 !==contactsControl.fields.length && <hr/>}

        </div>
      })}

    </div>
  );
};

export default PhonesFields;