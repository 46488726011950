import React, {useEffect, useState} from "react";
import {CloudinaryContext, Image} from "cloudinary-react";
import {Row, Col, Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FieldArray} from "formik";
import AddImageButton from "../trip/components/tripFormParts/buttons/addImageButton";
import {useFieldArray} from "react-hook-form";
import PhotoUploadButton from "../../base/components/photoUploadButton";
import PhotosSelector from "./PhotosSelector";
import _ from 'lodash'

const ImagesBlock = ({photos, prefix, formController, minLength, active, etcTimeLine, onlyLink,linkCb}) => {
  const {control, register, formState: {errors}} = formController;
  const photosControl = useFieldArray({
    control,
    name: `${prefix}`,
    rules: etcTimeLine ? {} : {required: true},
    shouldUnregister: true,
    keyName: 'customId'
  });

  useEffect(() => {
    if (!photosControl.fields.length && photos && photos.length) {
      console.log('photosappend',photos)
      photosControl.append(photos);
    }
  }, [photos]);
  console.log(`${prefix}`, photosControl.fields)
  return (
    <>
    {/*<CloudinaryContext cloudName="unoegohh">*/}
      <br/>
      <>
        <Row>
          <Col xs={12}>
            <h5>
              Фотографии{" "}
              <PhotosSelector
                cb={url => {
                  onlyLink ? linkCb(url) : photosControl.append({url, _id: null})
                }}
                folder={'photos'}
              />
            </h5>
          </Col>
        </Row>
        <Row>
          {photosControl.fields.map((img, index) => {
            console.log(img)
            if( onlyLink && img.url.includes('.pdf')){
              return
            }
            // const link = onlyLink ? img : img.url
            return (
              <Col xs={2} key={img.customId} className={"photo-select-item"}>
                {/*<OverlayTrigger*/}
                {/*  placement="bottom"*/}
                {/*  delay={{ show: 250, hide: 1000 }}*/}
                {/*  overlay={*/}
                {/*    <Tooltip*/}
                {/*      id={`tooltip-bottom`}*/}
                {/*      onClick={() => {*/}
                {/*        photosControl.remove(index);*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      <i className={"text-danger fa fa-times"} /> Удалить*/}
                {/*    </Tooltip>*/}
                {/*  }*/}
                {/*>*/}
                <a
                  className={"btn btn-outline-secondary view btn-sm"}
                  href={img.url}
                  target={"_blank"}
                >
                  <i className={"fa fa-eye"}/>
                </a>
                <Button
                  className={"delete"}
                  variant={"outline-secondary"}
                  size={"sm"}
                  onClick={() => {
                    photosControl.remove(index);
                  }}
                >
                  <i className={"fa fa-trash"}/>
                </Button>
                {active && <img
                  style={{height: 70, width: "100%", objectFit: "contain"}}
                  src={img.url || "https://placehold.co/600x400/EEE/31343C"}
                />}

                {/*</OverlayTrigger>*/}
                <input
                  type={"hidden"}
                  defaultValue={img.url}
                  {...register(`${prefix}[${index}].url`)}
                />
              </Col>
            )
          })}
        </Row>
        {_.get(errors, `${prefix}`)?.root ? (
          <p className={"text-danger"}>Минимум 1 фото</p>
        ) : null}
        {photosControl.fields.length < minLength ? (
          <>
            <input
              type={"text"}
              style={{display: "none"}}
              defaultValue={""}
              {...register(`${prefix}ss`, {required: true})}
            />
          </>
        ) : null}
      </>
    {/*</CloudinaryContext>*/}
    </>
  );
};

export default ImagesBlock;
