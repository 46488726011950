import { Route, Routes } from "react-router";
import TripList from "./pages/list";
import TripCreatePage from "./pages/create";
import TripEditPage from "./pages/edit";
import React from "react";
import {
  TripListRoute,
  TripCreateRoute,
  TripEditRoute,
  TripItemListRoute
} from "../../routesConstants";
import ErrorHandler from "../../base/components/errorHandler";

export default function() {
  return (
    <ErrorHandler>
      <Routes>
        <Route path={"/"} exact={true} element={<TripList />} />
        <Route path={"create"} exact={true} element={<TripCreatePage />} />
        <Route path={"edit/:id"} exact={true} element={<TripEditPage />} />
      </Routes>
    </ErrorHandler>
  );
}
