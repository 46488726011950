import { LOGIN_ACTION, LoginPut, RESET_USER_PASSWORD } from "../actions/auth";
import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { push } from "redux-first-history";
import jwt_decode from "jwt-decode";
import axiosM from "../../helpers/axios";

function* resetUserPasswordWorker(action) {
  try {
    const res = yield call(axios.post, "/api/auth/setUserPassword", {
      token: action.payload.token,
      password: action.payload.password,
      passwordDuple: action.payload.passwordDuple,
      firstLogin: action.payload.firstLogin
    });

    const { token } = res.data;
    localStorage.setItem("jwtToken", token);
    const decoded = jwt_decode(token);
    yield put(LoginPut(decoded));
    console.log(123);
    yield put(push("/"));
    console.log(1234);

    // yield call(action.payload.cb);
  } catch (e) {
    console.log(JSON.stringify(e.response.data));
    console.log(action);
    action.payload.cb(e.response.data);
  }
}

function* watchResetUserPasswordRequested() {
  yield takeEvery(RESET_USER_PASSWORD, resetUserPasswordWorker);
}

function* loginRequestWorker(action) {
  try {
    const res = yield call(axios.post, "/api/auth/login", {
      email: action.payload.email,
      password: action.payload.password
    });

    const { token } = res.data;
    localStorage.setItem("jwtToken", token);
    const decoded = jwt_decode(token);
    yield put(LoginPut(decoded));
    yield put(push("/"));
    // yield call(action.payload.cb);
  } catch (e) {
    console.log(JSON.stringify(e.response.data));
    yield call(action.payload.cb, e.response.data.errors);
  }
}

function* watchLoadCountriesRequested() {
  yield takeEvery(LOGIN_ACTION, loginRequestWorker);
}

export default [watchLoadCountriesRequested, watchResetUserPasswordRequested];
