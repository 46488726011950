import React, {useState} from "react";
import {connect} from "react-redux";
import {
  CounterpartyCreateRoute,
  CounterpartyEditRoute
} from "../../routesConstants";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import {Link} from "react-router-dom";
import DataTable from "../../../components/dataTable";
import { Tab, Tabs, Col, Row } from "react-bootstrap";
import Process from "../../../components/process";
import PortletHeaderSearch from "../../base/components/forms/portlerHeaderSearch";

const CounterPartyList = (props)=>{
  const columns = [
    {
      Header: 'Название',
      accessor: 'name',
      Cell: ({value, row}) => <Link to={CounterpartyEditRoute + row.original._id } >{value}</Link>
    },
    {
      Header: 'ClientName',
      accessor: 'clientName',
    },
    {
      Header: 'Код',
      accessor: 'code',
    },
    {
      Header: 'Спрятан',
      accessor: 'isHidden',
      Cell: ({value, row}) => <>{value && <i className="fa fa-ban text-danger" style={{marginLeft:20}} />}</>
    },
    {
      Header: "Действия",
      Cell: ({value, row}) => (<Link className={"btn btn-sm btn-clean btn-icon btn-icon-md"} to={CounterpartyEditRoute + row.original._id}><i className={"la la-edit"}/></Link>)
    }
  ]
  const [query, setQuery] = useState("");
  const [tabIndex, setTabIndex] = useState("home");
  return <>
    <Tabs
      variant="pills"
      id="controlled-tab-example"
      activeKey={tabIndex}
      onSelect={key => setTabIndex(key)}
    >
      <Tab eventKey="home" title="Список">
        <div className={"kt-grid__item"}>
          <Portlet>
            <PortletHeader
              title={"Список контрагентов"}
              toolbar={
                <PortletHeaderToolbar>
                  <PortletHeaderSearch value={query} cb={(v)=>{setQuery(v)}}/>
                  {/*<Link to={CounterpartyCreateRoute} className="btn btn-sm btn-brand btn-elevate btn-icon-sm">*/}
                  {/*  <i className="la la-plus"/>*/}
                  {/*  Добавить*/}
                  {/*</Link>*/}
                </PortletHeaderToolbar>}
            />
            <PortletBody>

              <DataTable columns={columns} search={query} url={`/api/counterparty`}/>
            </PortletBody>
          </Portlet>
        </div>
      </Tab>
      <Tab eventKey="profile" title="Действия"  className="tab-no-background">
        <Row>
          <Col md={6}>
            <Process desc={"c базой 1с"}
                     title={"Синхронизация контрагентов"}
                     link={"/api/counterparty/syncOneS"}
            />
          </Col>
        </Row>

      </Tab>
    </Tabs>

  </>
}


export default connect(null)(CounterPartyList)