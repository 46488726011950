import {useState} from "react";
import {Tab, Tabs} from "react-bootstrap";
import PaxToCounterparty from "../components/paxToCounterparty";
import CounterpartyToPax from "../components/counterpartyToPax";


const CounterpartiesFinderPage = () => {
  const [tabIndex, setTabIndex] = useState("paxToCon");

  return (
    <>
      <Tabs
        // variant="underline"
        variant={'pills'}
        id="controlled-tab-example"
        activeKey={tabIndex}
        onSelect={key => setTabIndex(key)}
      >
        <Tab eventKey="paxToCon" title="Пасажир — контрагенты" >
          <PaxToCounterparty/>
        </Tab>
        <Tab eventKey="сonToPax" title="Контрагент — пасажиры" >
          <CounterpartyToPax/>
        </Tab>
      </Tabs>
    </>
  );
};

export default CounterpartiesFinderPage;