import React, {useEffect} from 'react';
import FormHookInput from "../../air/airBooking/components/formHookInput";
import {Col, Form, Row} from "react-bootstrap";
import CustomDatePicker from "../../base/components/forms/customDatePicker";
import FormHookSelect from "../../air/airBooking/components/formHookSelect";
import _ from 'lodash'

const RailwayTicketForm = ({formController,prefix,deleteTicket, ticket}) => {
  const {control, register, formState: {errors}, setValue, watch, getValues} = formController
  useEffect(() => {
    const ticketPrice = +_.get(getValues(),`${prefix}.ticketPrice` )
    const agencyFee = +_.get(getValues(),`${prefix}.agencyFee` )
    setValue(`${prefix}.totalPrice`, ticketPrice+agencyFee)
  }, [watch(`${prefix}.ticketPrice`),watch(`${prefix}.agencyFee`)]);
  return (
    <div>
      <div className={'d-flex align-items-end justify-content-between gap-2 mb-3'}>
        <div>
          <FormHookInput
            label={'Номер билета'}
            register={register(`${prefix}.ticketNumber`, {required: true,})}
            errors={errors}
          />
        </div>
        <div>
          <FormHookInput
            label={'Имя'}
            register={register(`${prefix}.firstName`, {required: true,})}
            errors={errors}
          />
        </div>
        <div>
          <FormHookInput
            label={'Фамилия'}
            register={register(`${prefix}.lastName`, {required: true,})}
            errors={errors}
          />
        </div>
        <div>
          <FormHookInput
            label={'Отчество'}
            register={register(`${prefix}.middleName`)}
            errors={errors}
          />
        </div>
        <div>
          <FormHookInput
            label={'Номер документа'}
            register={register(`${prefix}.documentNumber`, {required: true,})}
            errors={errors}
          />
        </div>
      </div>
      <div className={'d-flex align-items-end  mb-3'}>
        <div style={{width: '7%'}}>
          <FormHookInput
            label={'Класс'}
            register={register(`${prefix}.serviceClass`, {required: true,})}
            errors={errors}
          />
        </div>
        <div style={{width: '20%'}}>
          <FormHookInput
            label={'№ поезда'}
            register={register(`${prefix}.trainNumber`, {required: true,})}
            errors={errors}
          />
        </div>
        <div style={{width: '10%'}}>
          <FormHookInput
            label={'№ вагона'}
            register={register(`${prefix}.carriageNumber`, {required: true,})}
            errors={errors}
          />
        </div>
        <div>
          <FormHookInput
            label={'Место'}
            register={register(`${prefix}.place`, {required: true,})}
            errors={errors}
          />
        </div>
        <div>
          <FormHookInput
            label={'Стоимость билета'}
            register={register(`${prefix}.ticketPrice`, {required: true,})}
            errors={errors}
          />
        </div>
        <div>
          <FormHookInput
            label={'КРС поставщика'}
            register={register(`${prefix}.fee`, {required: true,})}
            errors={errors}
          />
        </div>
        <div>
          <FormHookInput
            label={'КРС покупателя'}
            register={register(`${prefix}.agencyFee`, {required: true,})}
            errors={errors}
          />
        </div>
      </div>
      <Row>
        <Col xs={8}>
          <div className={'d-flex align-items-end '}>
            <div>
              <FormHookInput
                label={'Откуда'}
                register={register(`${prefix}.departure`, {required: true,})}
                errors={errors}
              />
            </div>
            <div>
              <CustomDatePicker formController={formController}
                                label={'Время выезда'}
                // placeholder={'Время выезда'}
                                value={ticket.departureDate}
                                name={`${prefix}.departureDate`}
                                time
                                rules={{required: true}}/>
            </div>
            <div>
              <FormHookInput
                label={'Куда'}
                register={register(`${prefix}.arrival`, {required: true,})}
                errors={errors}
              />
            </div>
            <div>
              <CustomDatePicker formController={formController}
                                label={'Время прибытия'}
                                value={ticket.arrivalDate}
                                name={`${prefix}.arrivalDate`}
                                time
                                rules={{required: true}}/>
            </div>
          </div>
        </Col>
        <Col xs={4}>
          <div className={'d-flex align-items-end '}>
            <div>
              <FormHookSelect
                label={'Статус'}
                register={register(`${prefix}.status`, {required: true})}
                errors={errors}
              >
                <option value="Подтвержден">Подтвержден</option>
                <option value="В ожидании оплаты">В ожидании оплаты</option>
                <option value="Возвращен">Возвращен</option>
                <option value="Аннулирован">Аннулирован</option>
              </FormHookSelect>
            </div>
            <div>
              <FormHookInput
                label={'Итог. стоимость'}
                disabled={true}
                register={register(`${prefix}.totalPrice`, {required: true,})}
                errors={errors}
              />
            </div>
          </div>
        </Col>

      </Row>
      <div className={'d-flex justify-content-between align-items-center mt-2'}>
        <Form.Label>
          <input
            {...register(`${prefix}.refund`)}
            type={"checkbox"}
          />{" "}
          Возврат
        </Form.Label>
        <div className={'text-danger'}
             onClick={deleteTicket}
             style={{cursor: 'pointer', textAlign: 'end', width: 'max-content'}}>
          Удалить билет <i className={"fa fa-trash"}/>
        </div>
      </div>
    </div>

  )
    ;
};

export default RailwayTicketForm;