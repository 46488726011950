import React, { useRef, useEffect } from "react";
import { HandleError } from "../../../redux/actions/base";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";
import Fade from "@material-ui/core/Fade";
import { useLocation } from "react-router-dom";

const ErrorHandler = props => {
  const { error } = props;
  const container = useRef();
  const location = useLocation();
  useEffect(() => {
    if (error && container) {
      window.scrollTo(0, container.current.offsetTop);
    }
  }, [error, container]);
  useEffect(() => {
    if (error) {
      props.HandleError();
    }
  }, [location]);
  console.log("error", error);
  return (
    <>
      <Fade in={!!error}>
        {error ? (
          <Alert variant="danger">
            <div className={"alert-text"} ref={container}>
              <div
                className={"alert-heading h4"}
                onClick={() => console.log("error", error)}
              >
                Произошла ошибка <small>{error.status}</small>
              </div>
              {error.errors?.map((text, i) => (
                <p key={i}>{text}</p>
              ))}
            </div>
          </Alert>
        ) : (
          <div></div>
        )}
      </Fade>
      {props.children}
    </>
  );
};

const mapStateToProps = ({ base }) => {
  const { error } = base;
  return { error };
};

export default connect(mapStateToProps, { HandleError })(ErrorHandler);
