import React, {useEffect, useState} from "react";
import { Col, Form, Row } from "react-bootstrap";
import ApiSelect from "../../../base/components/forms/apiSelect";
import HookDatePicker from "../../../../components/hookDatePicker";
import FormHookInput from "./formHookInput";
import CustomDatePicker from "../../../base/components/forms/customDatePicker";
import FormHookSelect from "./formHookSelect";
import _ from "lodash";
import { Controller, useController } from "react-hook-form";
import {useSelector} from "react-redux";
import IssuedBySelect from "./issuedBySelect";
import SearchSelect from "../../../../components/searchSelect";
import axios from "../../../../helpers/axios";

const AirBookingMainForm = ({ values, formController, setter, suppliers }) => {
  const [emplArray, setEmplArray] = useState()
  useEffect(() => {
    axios.post('/api/users/emplSearch', {operatorOneS: true})
      .then(({data})=>{
        const newArr = data.data.map(user=>{
          return {label: user.label, value: user.value._id}
        })
        setEmplArray(newArr)
      })
      .catch(e=>{
        console.log('err',e)
      })
  }, []);
  const {
    register,
    watch,
    control,
    formState: { errors },
    setValue,
    getValues
  } = formController;
  const {
    field: { onChange: sellerOnChange, value: sellerValue }
  } = useController({
    name: "seller",
    control,
    rules: { required: true },
    defaultValue: values.seller
  });
  const {
    field: { onChange: buyerOnChange, value: buyerValue }
  } = useController({
    name: "buyer",
    control,
    rules: { required: true },
    defaultValue: ""
  });
  const {
    field: { onChange: issuedByOnChange, value: issuedByValue }
  } = useController({
    name: "issuedBy",
    control,
    rules: { required: true },
    defaultValue: ''
  });
  console.log('valueApi', values)



  return (
    <>
      <Row className="align-items-center mb-3">
        <Col xs="3">
          <FormHookInput
            defaultValue={values.bookingNumber}
            register={register("bookingNumber", {
              required: true
            })}
            label={'Код бронирования'}
            // placeholder={"Номер бронирования"}
            errors={errors}
          />
        </Col>
        <Col xs="3">
          <CustomDatePicker 
            formController={formController}
            label={"Дата выписки"}
            value={values.issueDate}
            name={"issueDate"}
            rules={{required: true}}
          />
        </Col>

        <Col xs="3">
          <FormHookSelect
            defaultValue={values.supplier}
            register={register(`supplier`, { required: true })}
            errors={errors}
            label={'Поставщик'}
          >
            <option value="">Выбрать поставщика</option>
            {suppliers?.map(u=>{
              return <option value={u.value}>{u.label}</option>
            })}
          </FormHookSelect>
        </Col>
      </Row>
      <Row className={'mb-3'}>
        <Col xs="3">
          <ApiSelect
            value={sellerValue}
            label={'Продавец'}
            hasError={_.get(errors, "seller")}
            url={"/api/counterparty/searchInput"}
            queryBase={process.env.REACT_APP_COUNTERPARTIES_FROM_NEWBASE === "true" ? {fromNewBase: true} : {}}
            cb={newVal => sellerOnChange(newVal)}
          />
        </Col>
        <Col xs="3">
          <ApiSelect
            value={buyerValue}
            label={'Покупатель'}
            hasError={_.get(errors, "buyer")}
            url={"/api/counterparty/searchInput"}
            queryBase={process.env.REACT_APP_COUNTERPARTIES_FROM_NEWBASE === "true" ? {fromNewBase: true} : {}}
            cb={newVal => {
              buyerOnChange(newVal);
            }}
          />
        </Col>
        <Col xs="3">
          {emplArray && <SearchSelect
            onChange={(v) => {
              issuedByOnChange(v)
            }}
            defaultValue={values.issuedBy}
            hasError={_.get(errors, "issuedBy")}
            label={'Выписал'}
            array={emplArray}/>}

        </Col>
      </Row>
      <Row className={'d-flex align-items-center'}>
        <Col xs={9}>
          <FormHookInput
            defaultValue={values.serviceComment}
            label={'Коментарий для 1с'}
            register={register(`serviceComment`)}
          />
        </Col>
        <Col>
          <Form.Label className={'d-flex align-items-center gap-2'}>
            <input
              defaultChecked={values.issuedWithoutDocs}
              {...register(`issuedWithoutDocs`)}
              type={"checkbox"}
            />{" "}
            Без документов
          </Form.Label>
        </Col>
      </Row>
    </>
  );
};

export default AirBookingMainForm;
