import { HANDLE_ERROR } from "../actions/base";

const initialState = {
  user: null,
  error: null
};
export const baseReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_ERROR:
      return { ...state, error: action.payload };
    case "@@router/LOCATION_CHANGE":
      return { ...state, error: null };
    default:
      return state;
  }
};
