import { Route, Routes } from "react-router";
import OfferList from "./pages/list";
import OfferCreatePage from "./pages/create";
import OfferEditPage from "./pages/edit";
import React from "react";
import {
  OfferListRoute,
  OfferCreateRoute,
  OfferEditRoute
} from "../../routesConstants";
import ErrorHandler from "../../base/components/errorHandler";

export default function() {
  return (
    <ErrorHandler>
      <Routes>
        <Route path={""} exact={true} element={<OfferList />} />
        <Route path={"create"} element={<OfferCreatePage />} />
        <Route path={"edit/:id"} element={<OfferEditPage />} />
      </Routes>
    </ErrorHandler>
  );
}
