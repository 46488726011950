import React from "react";
import { toAbsoluteUrl } from "../../_metronic";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/login";
import ForgotPassword from "./pages/forgotPawword";
import SetPassword from "./pages/setPassword";
import "../../_metronic/_assets/sass/pages/login/login-1.scss";

class AuthRouter extends React.Component {
  render() {
    return (
      <>
        <div className="kt-grid kt-grid--ver kt-grid--root">
          <div
            id="kt_login"
            className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
          >
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
              <div
                className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`
                }}
              >
                <div className="kt-grid__item">
                  <Link to="/" className="kt-login__logo">
                    <img
                      alt="Logo"
                      style={{ width: "50px" }}
                      src={toAbsoluteUrl("/media/logo.png")}
                    />
                  </Link>
                </div>
              </div>

              <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
                <Routes>
                  <Route path="/login" element={<LoginPage />} />
                  <Route
                    path="/forgot-password"
                    exact={true}
                    element={<ForgotPassword />}
                  />
                  <Route
                    path="/forgot-password/:token"
                    element={<SetPassword />}
                  />
                  <Route
                    path="/setPassword/:token"
                    element={<SetPassword />}
                    setPassword={true}
                  />
                  <Route
                    path="/*"
                    element={<Navigate replace to="/auth/login" />}
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AuthRouter;
