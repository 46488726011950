import {call, takeEvery} from "redux-saga/effects";
import {COUNTRY_LIST_LOAD} from "./actions";
import ApiService from "../../../services/apiService";

function* watchCountryListRequestWorker(action){
  try {
    const res = yield call(ApiService,"/api/locations/countries",action.payload.data )
    action.payload.cb({ data: res.data })
  }catch(e){
    console.log('error', e)
    yield call(action.payload.cb, { errors: e.response.data.errors});
  }
}
function* watchCountryListLoad(){
  yield takeEvery(COUNTRY_LIST_LOAD, watchCountryListRequestWorker)
}

export default [
  watchCountryListLoad
]