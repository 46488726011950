import _ from "lodash";
import { useController } from "react-hook-form";
import InputMask from "react-input-mask";
import React from "react";

const TimeInput = ({ name, control, value, errors, placeholder }) => {
  const TIME_MASK = [/^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/];
  let classNames = "form-control";
  if (_.get(errors, name)) {
    classNames += " is-invalid";
  }
  const {
    field: { onChange: timeOnChange, value: timeValue }
  } = useController({
    name: name,
    control: control,
    rules: { required: true, minLength: 5 },
    defaultValue: value
  });

  return (
    <InputMask
      mask={TIME_MASK}
      value={timeValue}
      className={classNames}
      onChange={timeOnChange}
      placeholder={placeholder}
    />
  );
};

export default TimeInput;
