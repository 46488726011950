import React, { useState, useEffect, useCallback, memo } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../../components/Portlet";
import RemoveLink from "../../../../air/airBooking/components/removeLink";
import { Row, Col, Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import ApiSelect from "../../../../base/components/forms/apiSelect";
import _ from "lodash";
import moment from "moment";
import { useForm, useFieldArray } from "react-hook-form";
import Duplicate from "./buttons/duplicate";
import axios from "axios";
import { connect } from "react-redux";
import { StartProcess } from "../../../../../redux/actions/base";
import DocumentsBlock from "../../../components/documentsBlock";
import TicketDocumentsPopUp from "../../../components/TicketDocumentsPopUp";

const TripTicketsForm = ({
  tickets,
  className,
  values,
  formController,
  paxArray,
  StartProcess
}) => {
  console.log("TripTicketsForm");

  const [routes, setRoute] = useState([]);
  const [loading, setLoading] = useState(false);
  const { fields } = paxArray;
  console.log('AAAA', values)
  // useEffect(() => {
  //   if (routes.length) {
  //     generate();
  //   }
  // }, [routes]);
  useEffect(() => {
    generate();
  }, []);

  const generate = useCallback((ids = []) => {
    const formData = formController.getValues();
    if (!formData.paxes) {
      return;
    }
    setLoading(true);
    const ticketIds = [];
    const paxesTickets = _.flatten(formData.paxes.map(pax => pax.tickets));

    paxesTickets.forEach(ti => {
      if (ti) {
        ticketIds.push(ti.ticketId);
      }
    });
    const callback = res => {
      setRoute(res.data.result);
      setLoading(false);
    };
    StartProcess("/api/trips/generateTripRoute", callback, { ticketIds });
  });
  const formValues = formController.getValues();
  const autoTickets = () => {
    const newValues = formController.getValues();
    newValues.paxes.forEach(pax => {
      pax.tickets = [];
    });
    //
    const cb = ({ data }) => {
      console.log("res", data);
      newValues.paxes.forEach(pax => {
        pax.tickets = _.filter(data.result, {
          pax: {
            lastName: pax.lastName,
            firstName: pax.firstName
          }
        }).map(tt => ({
          documents: [],
          descriptions: [],
          ticketId: tt._id
        }));
        console.log(" pax.tickets length", pax.tickets);
      });
      tickets[1](data.result);
      paxArray.replace(newValues.paxes);
    };
    StartProcess("/api/trips/autoTickets", cb, {
      startDate: newValues.startDate,
      endDate: newValues.endDate,
      paxes: newValues.paxes.map(pax => ({
        lastName: pax.lastName,
        firstName: pax.firstName
      }))
    });
  };
  return (
    <Portlet className={className}>
      <PortletHeader
        title={"Билеты"}
        toolbar={
          <PortletHeaderToolbar>
            <Button
              // disabled={
              //   !formValues.paxes?.length ||
              //   !formValues.startDate ||
              //   !formValues.endDate
              // }
              style={{ marginRight: 5 }}
              onClick={autoTickets}
            >
              Авто билеты
            </Button>
            <Button disabled={tickets[0].length === 0} onClick={generate}>
              Сгенерировать маршрут
            </Button>
          </PortletHeaderToolbar>
        }
      />
      <PortletBody>
        <Row>
          <Col xs={9}>
            <h3>Пассажиры</h3>
            {fields.map((pax, paxIndex) => {
              return (
                <TripTicketsPaxForm
                  tickets={tickets}
                  generate={generate}
                  key={pax.id}
                  pax={pax}
                  paxIndex={paxIndex}
                  formController={formController}
                  values={values}
                />
              );
            })}
          </Col>
          <Col xs={3}>
            <h3>Маршрут</h3>
            {loading ? (
              <i className={"fa fa-spinner fa-spin"} />
            ) : (
              <>
                {routes &&
                  routes.map((route, i) => {
                    return (
                      <div key={i}>
                        <b>
                          {i + 1}. {route.departure.airport}-
                          {route.arrival.airport} (
                          {moment.utc(route.departure.date).format("DD.MMM")})
                        </b>
                        <br />
                        {route.segments.map((segment, ii) => {
                          return (
                            <span key={ii}>
                              - {segment.departure.airport}-
                              {segment.arrival.airport} (
                              {moment
                                .utc(segment.departure.date)
                                .format("DD.MMM")}
                              )<br />
                            </span>
                          );
                        })}
                        <br />
                      </div>
                    );
                  })}
              </>
            )}
          </Col>
        </Row>
      </PortletBody>
    </Portlet>
  );
};
const TripTicketsPaxForm = ({
  pax,
  values,
  paxIndex,
  tickets,
  formController,
  generate
}) => {
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState();
  const [ticketsData, setTicketsData] = useState(tickets[0]);
  const { register, control, getValues } = formController;

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: `paxes[${paxIndex}].tickets`
    }
  );
  const paxValues = _.find(getValues().paxes, { tempId: pax.tempId });
  if (!paxValues) {
    return null;
  }
  const selectTicket = value => {
    if (value && typeof value === "object") {
      //setTicketsData([...ticketsData, value]);
      console.log("value", value);
      tickets[1]({ ...tickets[0], value });
      setInputValue(null);
      append({
        documents: [],
        descriptions: [],
        ticketId: value._id
      });
    }
  };

  return (
    <div key={paxIndex}>
      <h5>
        {paxValues.title} {paxValues.lastName} {paxValues.firstName}{" "}
        <Button size={"sm"} onClick={() => setShowInput(!showInput)}>
          <i className={showInput ? "fa fa-minus" : "fa fa-plus"}></i>
        </Button>
      </h5>
      {showInput ? (
        <Row>
          <Col xs={6}>
            <ApiSelect
              value={inputValue}
              url={"/api/air/airBooking/tickets/tripSearch"}
              cb={selectTicket}
            />
          </Col>
        </Row>
      ) : null}
      {fields.map((paxTicket, index) => {
        const ticket = _.find(tickets[0], { _id: paxTicket.ticketId });
        if (!ticket) {
          return;
        }
        return (
          <TripTicketRow
            paxIndex={paxIndex}
            ticket={ticket}
            remove={remove}
            index={index}
            key={index}
            paxTicket={paxTicket}
            formController={formController}
          />
        );
      })}
      <br />
    </div>
  );
};

const TripTicketRow = ({
  ticket,
  index,
  paxTicket,
  formController,
  paxIndex,
  remove
}) => {
  const { register } = formController;
  const [showDocuments, setShowDocuments] = useState(false);
  return (
    <>
      <Row key={paxTicket.id}>
        <input
          type={"hidden"}
          {...register(`paxes[${paxIndex}.tickets[${index}]._id`)}
          defaultValue={paxTicket._id}
        />
        <input
          type={"hidden"}
          {...register(`paxes[${paxIndex}.tickets[${index}].ticketId`)}
          defaultValue={paxTicket.ticketId}
        />
        <Col xs={3}>
          <b>
            <a
              href={`/air/airBooking/edit/${ticket.airBookingId}`}
              target={"blank"}
            >
              {ticket.airlineNumber}-{ticket.ticketNumber}
            </a>
          </b>{" "}
          <br />
          Выписан {moment.utc(ticket.dateOfIssue, "DD.MM.YYYY HH:mm").format("DD MMM YYYY")} <br />
          Вылет {moment.utc(ticket.dateOfFlight, "DD.MM.YYYY HH:mm").format("DD MMM YYYY")}
        </Col>

        <Col xs={3}>
          {ticket.route} <br />
          Цена {ticket.price} {ticket.currencyCode}
          <br />
        </Col>
        <Col xs={3}>
          Документы ({paxTicket.documents?.length}){" "}
          <span onClick={() => setShowDocuments(!showDocuments)}>
            <i className={"fa fa-pencil-alt"} />
          </span><br/>
          <Form.Check
            type={"checkbox"}
            label={"Прятать цену"}
            {...register(`paxes[${paxIndex}].tickets[${index}].hidePrice`)}
            defaultChecked={paxTicket.hidePrice}
          />
          <Form.Check
            type={"checkbox"}
            {...register(`paxes[${paxIndex}].tickets[${index}].hideElement`)}
            label={"Спрятать элемент"}
            defaultChecked={paxTicket.hideElement}
          />
        </Col>
        <Col>
          <RemoveLink
            onClick={() => {
              remove(index);
            }}
            title={"билет"}
          />
        </Col>
      </Row>
      <TicketDocumentsPopUp
        small
        prefix={`paxes[${paxIndex}].tickets[${index}].documents`}
        paxTicket={paxTicket}
        formController={formController}
      />
    </>
  );
};
export default connect(
  () => {
    return {};
  },
  { StartProcess }
)(TripTicketsForm);
