import React, {useState} from 'react';
import moment from "moment/moment";
import {renderRegisterName, targetWeek} from "./aruanaKayarReviseRender";
import ReviseDocumentWrapper from "./reviseDocumentWrapper";
import UpdateRevise from "./updateRevise";
import YanaKayarRender from "./YanaKayarRender";

const YanaKayarReviseRender = ({ revise }) => {
  const [data, setData] = useState(JSON.parse(revise.dataJson))
  const [canSend, setCanSend] = useState(false)

  const [sendFiles, setSendFiles] = useState(revise.documents.map((u, index) => {
    u.registerName = renderRegisterName(u.name)
    return u}))
  const options = {
    targetWeek: targetWeek(+moment(revise.startDate).format('D')),
    targetMonth: +moment(revise.startDate).format('M'),
    targetYear:+moment(revise.startDate).format('YYYY'),
    url: "/api/racoon/pdfParse/checkKayarYana",
    reviseId:revise.id
  }
  return (
    <>
      <ReviseDocumentWrapper revise={revise} withoutFils>
        <UpdateRevise cb={(data, file) => {
          setData(data)
          file ? setSendFiles([file]) : setSendFiles([])
          setCanSend(true)
        }}
                      title={'Обновить информацию Yana(Акт)-Каяр'}
                      revComment={revise.comment}
                      data={data}
                      options={options}/>

      </ReviseDocumentWrapper>
      <YanaKayarRender data={data}/>

    </>
  );
};

export default YanaKayarReviseRender;