import {Col, Form, Row} from "react-bootstrap";
import CheckTypeSelect from "./checkTypeSelect";
import FormHookInput from "../../../air/airBooking/components/formHookInput";
import React from "react";
import {useFieldArray} from "react-hook-form";

const StandartInputsChecks = ({formController, fildParam}) =>{
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = formController;
  const { fields, append, remove } = fildParam
  const addCheck = () =>{
    append({checkType: 'value', path:null, value: null})
  }
  const values = [
    {value: 'value', label:'Value'},
    {value: 'length', label:'Length'}
  ]
  return <>
    {fields.map((field, i)=>{
      return <Row className={'mb-2'} key={field.id}>
        <Col xs="3">
          <Form.Label>CheckType</Form.Label>
          <CheckTypeSelect control={control} defaultValue={field.checkType} values={values} name={`checks[${i}].checkType`}/>
        </Col>
        <Col xs="5">
          <Form.Label>Path </Form.Label>
          <FormHookInput
            register={register(`checks[${i}].path`, {
              required: true,
              minLength: 3
            })}
            placeholder={"Path"}
            errors={errors}
          />
        </Col>
        <Col xs="4">
          <Form.Label>Value</Form.Label>
          <div style={{display:"flex", alignItems:'center'}}>
            <FormHookInput
              register={register(`checks[${i}].value`, {
                required: true,
              })}
              placeholder={"Value"}
              errors={errors}
            />
            <i className={"fa fa-times-circle text-danger ml-2"} onClick={()=> {
              remove(i)
            }}/>
          </div>
        </Col>

      </Row>
    })}
    <div className={"btn btn-sm btn-brand btn-elevate btn-icon-sm mt-2"} onClick={addCheck}>Добавить проверку</div>
  </>
}
export default StandartInputsChecks