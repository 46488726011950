import React from "react";
import {
  Portlet,
  PortletBody,
  PortletFooter,
  PortletHeader
} from "../../../components/Portlet";
import { connect } from "react-redux";
import { CreateItem } from "../../../redux/actions/base";
import {
  AirportListRoute,
  CityListRoute,
  UserListRoute
} from "../../routesConstants";
import * as yup from "yup";
import FormRow from "../../base/components/forms/formRow";
import { Col, Form, Row } from "react-bootstrap";
import CitySelect from "../../base/components/forms/citySelect";
import LoadingButton from "../../base/components/loadingButton";
import { Formik } from "formik";

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .default(""),
  lastName: yup
    .string()
    .required()
    .default(""),
  operatorCode: yup
    .string().default(""),
  phone: yup.string().default(""),
  telegramUsername: yup.string().default(""),
  railwaysLogin: yup.string().default(""),
  ratehawkLogin: yup.string().default(""),
  email: yup
    .string()
    .email()
    .required()
    .default(""),
  active: yup.boolean().default(true),
  dontSendEmail: yup.boolean().default(false),
  role: yup
    .string()
    .required()
    .default("employee"),
  sendTgNotifications: yup
    .array()
    .of(
      yup.lazy(value =>
        typeof value === "number" ? yup.number() : yup.string()
      )
    ).default([])
});
const UserCreatePage = ({ CreateItem }) => {
  return (
    <>
      <div className={"row"}>
        <div className="col-xl-6">
          <Portlet>
            <PortletHeader title={"Создание пользователя"} />
            <Formik
              initialValues={validationSchema.cast()}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => {
                // console.log("form sent", values);
                setSubmitting(true);

                CreateItem(
                  "/api/users",
                  values,
                  () => {
                    setSubmitting(false);
                  },
                  UserListRoute
                );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue
              }) => {
                console.log(values);
                return (
                  <form noValidate={true} onSubmit={handleSubmit}>
                    <PortletBody>
                      <FormRow
                        value={values.firstName}
                        name={"firstName"}
                        title={"Имя"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />
                      <FormRow
                        value={values.lastName}
                        name={"lastName"}
                        title={"Фамилия"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />
                      <FormRow
                        value={values.phone}
                        name={"phone"}
                        title={"Телефон (+11111111111)"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />
                      <FormRow
                        value={values.telegramUsername}
                        name={"telegramUsername"}
                        title={"Имя пользователя в телеграмме"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />
                      <FormRow
                        value={values.railwaysLogin}
                        name={"railwaysLogin"}
                        title={"Имя ЖД"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />
                      <FormRow
                        value={values.ratehawkLogin}
                        name={"ratehawkLogin"}
                        title={"Имя Rate Hawk"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />
                      <FormRow
                        value={values.operatorCode}
                        name={"operatorCode"}
                        title={"Код оператора 1С"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />

                      <FormRow
                        value={values.email}
                        name={"email"}
                        title={"Почта"}
                        errors={errors}
                        handleChange={(e)=> {
                          e.target.value = ("" + e.target.value).toLowerCase()
                          handleChange(e)
                        }}
                        touched={touched}
                      />
                      <Col md={{ span: 10, offset: 2 }}>
                        <div
                          className="alert alert-secondary offset-sm-3"
                          role="alert"
                        >
                          <div className="alert-text">
                            На указанную почту будет отправленно письмо с
                            инструкцией о сайте и как задать пароль
                          </div>
                        </div>
                      </Col>
                      <Form.Group as={Row}>
                        <Form.Label column sm="2">
                          Роли
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Check
                            value={"account"}
                            name={"role"}
                            type={"radio"}
                            label={`Пользователь`}
                            id={`User`}
                            checked={values.role === "account"}
                            onChange={handleChange}
                            touched={touched}
                          />
                          <Form.Check
                            value={"accountant"}
                            name={"role"}
                            type={"radio"}
                            label={`Бухгалтер`}
                            id={`User`}
                            checked={values.role === "accountant"}
                            onChange={handleChange}
                            touched={touched}
                          />
                          <Form.Check
                            value={`employee`}
                            name={"role"}
                            type={"radio"}
                            label={`Сотрудник`}
                            id={`Employee`}
                            checked={values.role === "employee"}
                            onChange={handleChange}
                            touched={touched}
                          />
                          <Form.Check
                            value={`admin`}
                            name={"role"}
                            type={"radio"}
                            label={`Администратор`}
                            id={`Admin`}
                            checked={values.role === "admin"}
                            onChange={handleChange}
                            touched={touched}
                          />
                          <Form.Check
                            value={`tours_user`}
                            name={"role"}
                            type={"radio"}
                            label={`Создание туров`}
                            id={`tours_user`}
                            checked={values.role === "tours_user"}
                            onChange={handleChange}
                            touched={touched}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Col sm={{ span: 10, offset: 2 }}>
                          <Form.Check
                            name={"active"}
                            type={"checkbox"}
                            id={`active`}
                            label={"Активирован"}
                            checked={values.active}
                            onChange={handleChange}
                            touched={touched}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Col sm={{ span: 10, offset: 2 }}>
                          <Form.Check
                            name={"dontSendEmail"}
                            type={"checkbox"}
                            id={`active`}
                            label={"Не отправлять письмо"}
                            checked={values.dontSendEmail}
                            onChange={handleChange}
                            touched={touched}
                          />
                        </Col>
                      </Form.Group>
                    </PortletBody>
                    <PortletFooter>
                      <Row>
                        <Col md={{ span: 10, offset: 2 }}>
                          <LoadingButton
                            title={"Сохранить"}
                            disabled={isSubmitting}
                            loading={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </PortletFooter>
                  </form>
                );
              }}
            </Formik>
          </Portlet>
        </div>
      </div>
    </>
  );
};

export default connect(null, { CreateItem })(UserCreatePage);
