import React, { useEffect, useState } from "react";
import { Button, FormLabel, Modal, Col, Row } from "react-bootstrap";
import CustomDatePicker from "../../../components/customDatePicker";
import DocumentsBlock from "./documentsBlock";
import { useFieldArray } from "react-hook-form";
import { documentTypes } from "../../../helpers/enums";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import FormHookSelect from "../../air/airBooking/components/formHookSelect";
import PhotoInput from "../trip/components/tripFormParts/photoInput";
import RemoveLink from "../../air/airBooking/components/removeLink";

const TicketDocumentsPopUp = ({ prefix, paxTicket, formController, small }) => {
  const {
    control,
    register,
    formState: { errors }
  } = formController;
  const documentsControl = useFieldArray({
    control,
    name: `${prefix}`
  });
  const { fields, remove, append } = documentsControl;

  useEffect(
    () =>
      !documentsControl.fields.length &&
      paxTicket.documents &&
      paxTicket.documents.length
        ? append(paxTicket.documents)
        : () => {},
    []
  );
  const addDocument = () => {
    append({
      type: documentTypes[0],
      _id: null
    });
  };
  const removeDocument = index => {
    remove(index);
  };
  return (
    <>
      {small ? (
        <h6>
          Документы{" "}
          <span size={"sm"} onClick={() => addDocument()}>
            <i className={"fa fa-plus"}></i>
          </span>{" "}
        </h6>
      ) : (
        <h5>
          Документы{" "}
          <Button size={"sm"} onClick={() => addDocument()}>
            <i className={"fa fa-plus"}></i>
          </Button>{" "}
        </h5>
      )}
      {fields.map((document, index) => {
        return (
          <Row key={document.id}>
            <Col xs={3}>
              <FormLabel>Название</FormLabel>
              <FormHookInput
                register={register(`${prefix}[${index}].name`, {
                  required: true,
                  minLength: 2
                })}
                defaultValue={document.name}
                errors={errors}
              />
            </Col>
            <Col xs={3}>
              <FormLabel>Тип</FormLabel>
              <FormHookSelect
                register={register(`${prefix}[${index}].type`, {
                  required: true
                })}
                defaultValue={document.type}
              >
                {documentTypes.map((item, i) => (
                  <option value={item} key={i}>
                    {item}
                  </option>
                ))}
              </FormHookSelect>
            </Col>
            <Col xs={3}>
              <PhotoInput
                formController={formController}
                title={"Файл"}
                name={`${prefix}[${index}].url`}
                url={document.url}
              />
            </Col>
            <Col xs={3}>
              <RemoveLink
                onClick={() => removeDocument(index)}
                title={"документ"}
              />
            </Col>
          </Row>
        );
      })}
      <br />
    </>
  );
};

export default TicketDocumentsPopUp;
