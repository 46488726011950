import React, {useState} from 'react';
import {useSelector} from "react-redux";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";

const PaidButton = ({paid, id,cb}) => {
  const [loading, setLoading]=useState(false)
  const { email, roles } = useSelector(state => state.auth.user);
  const paiding = () =>{
    if(window.confirm('Вы уверены что эта услуга оплачена?')){
      setLoading(true)
      axios.post('/api/touristServices/accountant/markPaid/'+ id)
        .then(()=>{
          cb ? cb() : window.location.reload()
        })
        .catch(sayError)
        .finally(()=>setLoading(false))
    }

  }
  if(paid){
    return <>
      Оплачено <i className={"fa fa-check-circle text-success ml-2"}/>
    </>
  } else if(roles.indexOf('admin') === -1 && roles.indexOf('accountant') === -1){
    return <></>
  }
  return (
    <button className={"btn btn-outline-primary btn-sm ml-5"}
            onClick={() => paiding()}
    >
      {loading ? 'Загрузка...' : 'Оплатить'}
    </button>
  );
};

export default PaidButton;