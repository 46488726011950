import React, {useState} from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import Duplicate from "../../trip/components/tripFormParts/buttons/duplicate";
import RemoveLink from "../../../air/airBooking/components/removeLink";
import {Col, Form, FormLabel, Row, Button} from "react-bootstrap";
import {tripItemTypes} from "../../../../helpers/enums";
import {useFieldArray} from "react-hook-form";
import {
  etcTemplate,
  transferTemplate,
  hotelTemplate,
  flightTemplate
} from "../../trip/components/tripFormParts/helpers/templates";
import TripEtcForm from "../../components/tripEtc";
import TripTransferForm from "../../components/tripTransfer";
import TripHotelForm from "../../components/tripHotel";
import OfferFlightForm from "./offerFlightForm";
import ParseInput from "../../../air/airBooking/components/parseInput";
import BookingComButton from "../../components/BookingComButton";
import FormHookInput from "../../../air/airBooking/components/formHookInput";
import _ from "lodash";
import DescriptionsBlock from "../../components/descriptionsBlock";
import addDateToHotel from "../../components/addDateToHotel";
import CopyButton from "../../trip/components/tripFormParts/buttons/copyButton";

const TripGroup = ({
                     group,
                     key,
                     formController,
                     prefix,
                     groupArray,
                     className,
                     removeGroup,
                     activ,
                     index
                   }) => {
  const {register, setValue, getValues, formState: {errors}} = formController;
  const [type, setType] = useState(group.type);
  const itemArray = useFieldArray({
    control: formController.control,
    name: `${prefix}.items`,
    rules: {required: true},
    shouldUnregister: true,
    keyName: 'customId'
  });
  const groupNames = {
    etc: "Разное",
    hotel: "Отель",
    transfer: "Трансфер",
    ticket: "Перелет"
  };
  console.log('getValue', getValues())
  const addItem = (addedItem, prefix) => {
    console.log("type", type);
    if (type === "hotel") {
      console.log('addedItem', prefix)
      itemArray.append(addedItem ? addDateToHotel(getValues(), addedItem, prefix) : addDateToHotel(getValues(), {...hotelTemplate}, prefix));
    } else if (type === "etc") {
      itemArray.append({...etcTemplate});
    } else if (type === "transfer") {
      itemArray.append({...transferTemplate});
    } else if (type === "ticket") {
      itemArray.append({...flightTemplate});
    }
    setCurrentTab(`item${itemArray.fields.length}`);
  };
  const remove = index => {
    itemArray.remove(index);
    console.log(itemArray.fields.length);
    setCurrentTab(`item${itemArray.fields.length - 2}`);
  };
  const [currentTab, setCurrentTab] = useState("item0");
  return (
    <div className={className}>
      <Row>
        <Col xs={12}>
          <input
            type={"hidden"}
            {...register(`${prefix}._id`)}
            defaultValue={group._id}
          />
          <input
            type={"hidden"}
            {...register(`${prefix}.type`)}
            defaultValue={type}
          />

          <Portlet className={className}>
            <PortletHeader
              title={"Группа элементов"}
              toolbar={
                <PortletHeaderToolbar>
                  <Duplicate
                    formController={formController}
                    index={index}
                    prefix={'groups'}
                    itemsArrayController={groupArray}
                  />
                  <RemoveLink onClick={removeGroup} title={"элемент "}/>
                  <div className={'ml-2'}>
                    <CopyButton
                      fieldArray={itemArray}
                      tripId={""}
                      type={type}
                      formController={formController}
                    /></div>
                  {type ? (
                    <Button size={'sm'} style={{marginLeft: 5}} onClick={() => addItem(null, `groups[${index}]`)}>
                      <i className={"fa fa-plus"}/>
                    </Button>
                  ) : null}
                  {type === "hotel" ? (
                    <div style={{marginLeft: 5}}>
                      <BookingComButton setter={hotel => addItem(hotel, `groups[${index}]`)}/>
                    </div>
                  ) : null}
                </PortletHeaderToolbar>
              }
            />
            <PortletBody>
              <Row className={!type ? "hidden" : 'mb-2'}>
                <Col xs={3}>
                  <FormLabel>Название</FormLabel>
                  <FormHookInput
                    register={register(`${prefix}.name`)}
                    placeholder={"Название"}
                    errors={errors}
                    defaultValue={group.name}
                  />
                </Col>
                <Col xs={3}>
                  <Form.Check
                    type={"checkbox"}
                    label={"Прятать группу"}
                    {...register(`${prefix}.hideGroup`)}
                    defaultChecked={group.hideGroup}
                  />
                </Col>
              </Row>
              <Row className={type ? "hidden" : null}>
                <Col xs={3}>
                  <FormLabel>Тип группы</FormLabel>
                  <Form.Control
                    as="select"
                    defaultValue={group.type}
                    {...register(`${prefix}.type`, {
                      required: true,
                      onChange: e => setType(e.target.value)
                    })}
                    className={_.get(errors, `groups[${index}]`) ? "is-invalid" : null}
                  >
                    <option></option>
                    {tripItemTypes.map((item, i) => (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
              <DescriptionsBlock
                title={"группы"}
                descriptions={group.descriptions}
                formController={formController}
                prefix={`${prefix}.descriptions`}
              />
              {_.get(errors, `${prefix}.items`)?.root &&
                <p className={"text-danger"}>Минимум 1 элемент</p>}
            </PortletBody>
          </Portlet>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {itemArray.fields.map((item, i) => {
            let buttonType = "default"
            if (`item${i}` === currentTab && !_.get(errors, `groups[${index}].items[${i}]`)) {
              buttonType = "primary"
            } else if (_.get(errors, `groups[${index}].items[${i}]`) && `item${i}` === currentTab) {
              buttonType = 'danger'
            } else if (_.get(errors, `groups[${index}].items[${i}]`) && `item${i}` !== currentTab) {
              buttonType = 'outline-danger'
            }
            console.log('type', errors)
            return (
              <Button
                key={item.customId}
                onClick={() => setCurrentTab(`item${i}`)}
                variant={buttonType}
              >
                {item.name ? item.name : `Элемент ${i + 1}`}
              </Button>
            );
          })}
        </Col>
        <br/>
        <br/>
      </Row>
      {itemArray.fields.map((item, index) => {
        if (item.type === "etc") {
          return (
            <Portlet
              key={item.customId}
              className={`item${index}` === currentTab ? null : "hidden"}
            >
              <PortletHeader
                title={item && item.name ? item.name : "Разное"}
                toolbar={
                  <PortletHeaderToolbar>
                    <Duplicate
                      formController={formController}
                      index={index}
                      prefix={`${prefix}.items`}
                      itemsArrayController={itemArray}
                    />
                    <RemoveLink
                      onClick={() => remove(index)}
                      title={"элемент"}
                    />
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody>
                <TripEtcForm
                  item={item}
                  withoutPaxes={true}
                  offer
                  index={index}
                  prefix={`${prefix}.items[${index}]`}
                  formController={formController}
                  activ={activ && `item${index}` === currentTab}
                />
              </PortletBody>
            </Portlet>
          );
        } else if (item.type === "transfer") {
          return (
            <Portlet
              key={item.customId}
              className={`item${index}` === currentTab ? null : "hidden"}
            >
              <PortletHeader
                title={item && item.name ? item.name : "Трансфер"}
                toolbar={
                  <PortletHeaderToolbar>
                    <Duplicate
                      formController={formController}
                      index={index}
                      prefix={`${prefix}.items`}
                      itemsArrayController={itemArray}
                    />
                    <RemoveLink
                      onClick={() => remove(index)}
                      title={"элемент"}
                    />
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody>
                <TripTransferForm
                  item={item}
                  withoutPaxes={true}
                  offer
                  prefix={`${prefix}.items[${index}]`}
                  formController={formController}
                  activ={activ && `item${index}` === currentTab}
                />
              </PortletBody>
            </Portlet>
          );
        } else if (item.type === "hotel") {
          return (
            <Portlet
              key={item.customId}
              className={`item${index}` === currentTab ? null : "hidden"}
            >
              <PortletHeader
                title={item && item.name ? item.name : "Отель"}
                toolbar={
                  <PortletHeaderToolbar>
                    <Duplicate
                      formController={formController}
                      index={index}
                      prefix={`${prefix}.items`}
                      itemsArrayController={itemArray}
                    />
                    <RemoveLink onClick={() => remove(index)} title={"отель"}/>
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody>
                <TripHotelForm
                  item={item}
                  offer
                  index={index}
                  prefix={`${prefix}.items[${index}]`}
                  formController={formController}
                  activ={activ && `item${index}` === currentTab}
                />
              </PortletBody>
            </Portlet>
          );
        } else if (item.type === "ticket") {
          return (
            <div
              key={item.customId}
              className={`item${index}` === currentTab ? null : "hidden"}
            >
              <OfferFlightForm
                item={item}
                itemArray={itemArray}
                index={index}
                offer
                removeItem={() => remove(index)}
                prefix={`${prefix}.items[${index}]`}
                totalPrefix={`${prefix}.items`}
                formController={formController}
              />
            </div>
          );
        }
      })}
    </div>
  );
};

export default TripGroup;
