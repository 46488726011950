import { useNavigate } from "react-router";
import { Breadcrumb } from "react-bootstrap";
import React from "react";

const Breadcrumbs = ({ data }) => {
  const navigate = useNavigate();
  return (
    <Breadcrumb>
      {data.map((link, i) => {
        if (data.length - 1 === i) {
          return (
            <Breadcrumb.Item active key={i}>
              {link.title}
            </Breadcrumb.Item>
          );
        }
        return (
          <Breadcrumb.Item key={i} onClick={() => navigate(link.path)}>
            {link.title}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};
export default Breadcrumbs;
