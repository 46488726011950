import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  Label
} from "reactstrap";
import { FormControl } from "react-bootstrap";
import reviseItemTypes from "../helpers/reviseItemTypes";
import axios from "axios";
import { connect, useSelector } from "react-redux";
import ApiService from "../../../services/apiService";
import HookDatePicker from "../../../components/hookDatePicker";
import { useForm } from "react-hook-form";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import _ from "lodash";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  ReviseGetRoute,
  ReviseItemListRoute,
  ReviseItemRoute
} from "../../routesConstants";
import ApiSelect from "../../base/components/forms/apiSelect";
import { CreateItem, StartProcess } from "../../../redux/actions/base";
import Select from "react-select";
import WeekSelections from "./WeekSelections";
import BlockUi from "@availity/block-ui";
import ReviceSelector from "./reviceSelector";

const dayWeek = day => {
  if (day < 8) {
    return 1;
  } else if (day > 7 && day < 16) {
    return 2;
  } else if (day > 15 && day < 24) {
    return 3;
  } else {
    return 4;
  }
};
const CreateReviseButton = ({
  files,
  reviseType,
  data,
  StartProcess,
  CreateItem
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [items, setItems] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch
  } = useForm({
    defaultValues: {
      activeYear: +moment().format("YYYY"),
      activeMonth: +moment().format("MM"),
      activeWeek: dayWeek(+moment().format("D"))
    }
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const submit = values => {
    const { iataCheckId, aruanaCheckId, silkCheckId, comment } = values;
    if (iataCheckId === "" && aruanaCheckId === "" && silkCheckId === "") {
      setError(true);
    } else {
      setError(false);
      //проверка наличия
      console.log("values", values);
      CreateItem(
        "/api/racoon/revise/",
        {
          iataCheckId: iataCheckId || null,
          aruanaCheckId: aruanaCheckId || null,
          silkCheckId: silkCheckId || null,
          comment
        },
        ({ data }) => {
          navigate(ReviseGetRoute + data.reviseId);
        }
      );
    }
  };
  const search = () => {
    setLoading(true);
    StartProcess(
      "/api/racoon/revise/getItems",
      ({ data }) => {
        setValue(
          "iataCheckId",
          _.find(data.items, { type: "iata-aruana" })?.id || ""
        );
        setValue(
          "aruanaCheckId",
          _.find(data.items, { type: "aruana-kayar" })?.id || ""
        );
        setValue(
          "silkCheckId",
          _.find(data.items, { type: "silk-kayar" })?.id || ""
        );
        console.log("res", data.items);
        setItems(data.items);
        setLoading(false);
      },
      {
        year: watch("activeYear"),
        month: watch("activeMonth"),
        week: watch("activeWeek")
      }
    );
  };
  useEffect(() => {}, [items]);
  return (
    <>
      <Button
        color={"primary"}
        onClick={() => {
          setIsVisible(true);
        }}
      >
        Создать
      </Button>
      <Modal
        isOpen={isVisible}
        toggle={() => {
          setIsVisible(!isVisible);
        }}
      >
        <ModalHeader>Создание отчета</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit(submit)}>
            <BlockUi blocking={loading}>
              <FormGroup>
                <Label>Выберите период</Label>
                <WeekSelections
                  style={{ marginBottom: "10px" }}
                  control={control}
                  watch={watch}
                  cb={search}
                />
              </FormGroup>
              <FormGroup>
                <Label>Сверка Iata</Label>
                <ReviceSelector
                  name={"iataCheckId"}
                  options={_.filter(items, { type: "iata-aruana" })}
                  control={control}
                />
              </FormGroup>
              <FormGroup>
                <Label>Сверка Aruana</Label>
                <ReviceSelector
                  name={"aruanaCheckId"}
                  options={_.filter(items, { type: "aruana-kayar" })}
                  control={control}
                />
              </FormGroup>
              <FormGroup>
                <Label>Сверка Silk</Label>
                <ReviceSelector
                  name={"silkCheckId"}
                  options={_.filter(items, { type: "silk-kayar" })}
                  control={control}
                />
              </FormGroup>
              <FormGroup>
                <Label>Коментарий</Label>
                <FormControl {...register("comment")} />
              </FormGroup>
              <Button type={"submit"}>Создать</Button>
              {error && <div>Выберите хотя бы 1 отчет</div>}
            </BlockUi>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default connect(null, { StartProcess, CreateItem })(CreateReviseButton);
