import axios from "axios";

const contentType = "application/x-www-form-urlencoded";
const getToken = () => localStorage.getItem("jwtToken");
const putCancelToken = axios.CancelToken.source();
const patchCancelToken = axios.CancelToken.source();
const deleteCancelToken = axios.CancelToken.source();
const postCancelToken = axios.CancelToken.source();

const ApiService = {
  put: (link, params) => {
    return axios.put(link, params, {
      headers: { Authorization: getToken() },
      cancelToken: putCancelToken.token,
      "Content-Type": contentType
    });
  },
  post: (link, params, contentTypeClient, controller) => {
    return axios.post(link, params, {
      headers: { Authorization: getToken() },
      signal: controller?.signal,
      "Content-Type": contentTypeClient ? contentTypeClient : contentType
    });
  },
  patch: (link, params) => {
    console.log("patch");
    return axios.patch(link, params, {
      headers: { Authorization: getToken() },
      cancelToken: patchCancelToken.token,
      "Content-Type": contentType
    });
  },
  delete: link =>
    axios.delete(link, {
      headers: { Authorization: getToken() },
      cancelToken: deleteCancelToken.token,
      "Content-Type": contentType
    }),
  get: link =>
    axios.get(link, {
      headers: { Authorization: getToken() }
    })
};

export default ApiService;
