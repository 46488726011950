import { Route, Routes } from "react-router";
import {
  ParsersCheckCreateRoute,
  ParsersCheckEditRoute,
  ParsersCheckListRoute
} from "../../routesConstants";
import ParsersList from "./pages/list";
import ParserCheckEdit from "./pages/edit";
import ParserCheckCreate from "./pages/create";

export default function() {
  return (
    <Routes>
      <Route path={"list"} exact={true} element={<ParsersList />} />
      <Route path={"edit/:id"} element={<ParserCheckEdit />} />
      <Route path={"create"} element={<ParserCheckCreate />} />
    </Routes>
  );
}
