import React, {useState} from 'react'
import { connect } from "react-redux";
import {StartProcess} from "../redux/actions/base";
import LoadingButton from "../modules/base/components/loadingButton";

const Process = (props) => {
  const {desc, title, link, cb} = props;
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const load = () =>{
    setLoading(true);
    props.StartProcess(link, ({data})=>{
      console.log(data)
      if(!data.errors){
        if(data.messages){
          setMessages(data.messages)
        }
        if(cb){
          console.log(typeof cb)
          cb(data)
        }
      }
      setLoading(false);
    })
  }



  return <div className="kt-portlet kt-callout kt-callout--diagonal-bg">
    <div className="kt-portlet__body">
      <div className="kt-callout__body">
        <div className="kt-callout__content">
          <h3 className="kt-callout__title">{title}</h3>
          <p className="kt-callout__desc">
            {desc} <br/>
            {messages.map((mess, i )=><small key={i}>{mess} <br/></small>)}
          </p>
        </div>
        <div className="kt-callout__action">
          <LoadingButton title={"Старт"} loading={loading} onClick={load}/>
        </div>
      </div>
    </div>
  </div>
}

export default connect(null, {StartProcess})(Process)