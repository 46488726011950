import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TripItemEditRoute } from "../../../../routesConstants";
import DataTable from "../../../../../components/dataTable";
import { PortletBody } from "../../../../../components/Portlet";
import moment from "moment";
import RemoveLink from "../../../../air/airBooking/components/removeLink";
import { connect } from "react-redux";
import { DeleteItem } from "../../../../../redux/actions/base";
import PortletHeaderSearch from "../../../../base/components/forms/portlerHeaderSearch";
import FindOptions from "../../../../air/airBooking/components/findOptions";

const HotelsTab = props => {
  const [refetch, setRefetch] = useState(false);
  const [query, setQuery] = useState({
    name:'',
    execDate:[],
    tourUser: ''
  });


  const remove = id => {
    props.DeleteItem(`/api/trips/item/${id}`, () => {
      setRefetch(!refetch);
    });
  };
  const columns = [
    {
      Header: "Название",
      accessor: "name",
      Cell: ({ value, row }) => (
        <Link to={TripItemEditRoute + row.original._id}>{value}</Link>
      )
    },
    {
      Header: "Пассажиры",
      accessor: "paxes",
      Cell: ({ value, row }) =>
        row.original.paxes.map((p, i) => (
          <React.Fragment key={i}>
            {p.lastName} {p.firstName}
            <br />
          </React.Fragment>
        ))
    },
    {
      Header: "Даты",
      accessor: "dates",
      Cell: ({ value, row }) =>
        moment(row.original.startDate).format("DD.MM.YYYY") +
        "-" +
        moment(row.original.endDate).format("DD.MM.YYYY")
    },
    {
      Header: "Действия",
      Cell: ({ value, row }) => (
        <>
          <Link
            className={"btn btn-sm btn-clean btn-icon btn-icon-md"}
            to={TripItemEditRoute + row.original._id}
          >
            <i className={"la la-edit"} />
          </Link>
          <RemoveLink
            onClick={() => remove(row.original._id)}
            noText
            title={"элемент отеля"}
          />
        </>
      )
    }
  ];
  return (
    <>
      <FindOptions query={query} setQuery={setQuery}/>
      <DataTable
        columns={columns}
        url={`/api/trips/item/hotelList`}
        refetch={refetch}
        search={query}
      />
    </>
  );
};
export default connect(() => ({}), { DeleteItem })(HotelsTab);
