import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {ApiServiseLogos} from "./ApiServiseLogos";



const ApiItem = ({r}) => {
  const [show, setShow] = useState(false)
  return (
    <>
      <div className={`d-flex justify-content-between align-items-center  ${r.error.length > 0 ? 'serviceCardError' : 'serviceCard'}`}
           onClick={() => setShow(!show)}>
        <div>{ApiServiseLogos[r.serviceName]}{r.serviceName}</div>
        {r.isWorking ? <i className={"fa fa-check-circle text-success"}/> :
          <i className={"fa fa-times-circle text-danger"}/>}
      </div>
      <Modal show={show} onHide={() => setShow(!show)}>
        <Modal.Header closeButton>
          <Modal.Title>Ошибки</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {r.error.length === 0 ? 'Ошибок нет. Сервис работает исправно!' : r.error.map((e, i) => {
            return <p key={i}>{e}</p>
          })}
        </Modal.Body>
      </Modal>
    </>

  );
};

export default ApiItem;