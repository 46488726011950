import React, {useState} from 'react';
import {Portlet, PortletBody, PortletHeader} from "../../../components/Portlet";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import ItemsTable from "./itemsTable";
import FinderPaxTableForm from "./finderPaxTableForm";

const CounterpartyToPax = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState()
  const getInfo = (data) =>{
    setLoading(true)
    axios.post('/api/counerpartiesFinder/byCounterparty', data)
      .then(({data})=>{
        console.log('axios',data)
        setData(data.table)
      })
      .catch(sayError)
      .finally(()=>setLoading(false))
  }
  return (
    <Portlet>
      <PortletHeader title={'Поиск пассажиров по контрагенту'}/>
      <PortletBody>
        <FinderPaxTableForm cb={getInfo} loading={loading}/>
        {data ? <ItemsTable data={data}/> :
          'Заполните форму выше, чтобы получить информацию'}
      </PortletBody>
    </Portlet>
  );
};

export default CounterpartyToPax;