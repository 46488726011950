import React from "react";
import { I18nProvider, LayoutContextProvider } from "./index";
import ThemeProvider from "./materialUIThemeProvider/ThemeProvider";
import { connect } from "react-redux";
import { history } from "../redux/reducers/index";

class ThemeWrapper extends React.Component {
  render() {
    return (
      <ThemeProvider>
        <I18nProvider>
          <LayoutContextProvider history={history}>
            {this.props.children}
          </LayoutContextProvider>
        </I18nProvider>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = ({ history }) => {
  return { history };
};

export default connect(mapStateToProps)(ThemeWrapper);
