import { Route, Routes } from "react-router";
import React from "react";
import { DebtorRoutes } from "../routesConstants";
import DebtorRouter from "./debtor/router";
import ErrorHandler from "../base/components/errorHandler";

export default function() {
  return (
    <ErrorHandler>
      <Routes>
        <Route path={"debtor/*"} element={<DebtorRouter />} />
      </Routes>
    </ErrorHandler>
  );
}
