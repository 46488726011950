import React, {useState} from 'react';
import LogoFlyArystan from "./logoFlyArystan";
import {Button, Form, Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import axios from "../../../../helpers/axios";
import BlockUi from "@availity/block-ui";

const FlyArystanParse = ({setter, potentialBuyers}) => {
  const [show, setShow]=useState(false)
  const [loading, setLoading]=useState(false)
  const formController = useForm()
  const { register,
    reset,
    handleSubmit,
    formState:{errors}} = formController
  const onSubmit = (data) =>{
    setLoading(true)
    const sendData = new FormData()
    sendData.append('arystanPdf', data.file[0])
    axios.post('/api/air/parse/flyArystan', sendData)
      .then(({data})=>{
        console.log('datafile', data)
        setter(data.converted)
        potentialBuyers(data.potentialBuyers)
        setShow(false)
      })
      .catch((e)=>{
        window.alert(e.response.data.message[0])
        console.log('err',e)
        setLoading(false)
      }).finally(()=>setLoading(false))
    console.log('data',data)

  }
  return (
    <div>
      <div onClick={()=>setShow(true)} style={{cursor:'pointer'}}>
        <LogoFlyArystan />
      </div>
      <Modal show={show} onHide={()=>setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Парс FlyArystan</Modal.Title>
        </Modal.Header>
        <BlockUi blocking={loading}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
              <Form.Control
                type={"file"}
                {...register('file', { required: true })}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setShow(false)}>
                Закрыть
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit(onSubmit)}
              >
                Распарсить
              </Button>
            </Modal.Footer>
          </form>
        </BlockUi>
      </Modal>
    </div>
  );
};

export default FlyArystanParse;