import LoadingButton from "../../base/components/loadingButton";
import {useState} from "react";
import axios from "../../../helpers/axios";

const CheckedReviseButton = ({id, checked, url}) => {
  const [loading, setLoading] = useState(false)
  const [stateChecked, setStateChecked]= useState(checked)
  const sendFunc = () =>{
    setLoading(true)
    axios.post(url+id)
      .then(()=>{
        setStateChecked(!stateChecked)
      })
      .catch(e=>{
        console.log('err', e)
        alert('Something went wrong')
      })
      .finally(()=>setLoading(false))
  }
  return (
    <LoadingButton title={stateChecked ? 'Вернуть' : 'Подтвердить'} loading={loading} size={'sm'} onClick={sendFunc}/>
  );
};

export default CheckedReviseButton;