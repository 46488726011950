import React, { useEffect, useState } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../components/Portlet";
import { Row, Col, FormLabel, FormControl, Form } from "react-bootstrap";
import CustomDatePicker from "../../../components/customDatePicker";
import Select from "react-select";
import DescriptionsBlock from "./descriptionsBlock";
import PaxesBlock from "./paxesBlock";
import ImagesBlock from "./imagesBlock";
import ApiSelect from "../../base/components/forms/apiSelect";
import RemoveLink from "../../air/airBooking/components/removeLink";
import _ from "lodash";
import FastInput from "../../../components/FastInput";
import PaxesSelector from "./paxesSelector";
import HookDatePicker from "../../../components/hookDatePicker";
import CurrencyPicker from "../../base/components/forms/currencySelector";
import Duplicate from "../trip/components/tripFormParts/buttons/duplicate";
import DocumentsBlock from "./documentsBlock";
import LocationPicker from "../../../components/locationPicker";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import FormLocation from "../../base/components/forms/formLocation";
import FormHookDatePicker from "../../base/components/forms/formHookDatePicker";
import { Button } from "@material-ui/core";
import moment from "moment";
import TripEtcTimeLine from "./tripEtcTimeline";
import IconSelect from "./IconSelect";

const TripEtcForm = ({ withoutPaxes, item, prefix, formController, activ, offer}) => {
  const {
    register,
    setValue,
    getValues,
    control,
    formState: { errors }
  } = formController;

  console.log("etc", item);
  const insertTempData = () => {
    setValue(`${prefix}.name`, "Тестовое название");
    setValue(
      `${prefix}.startDate`,
      moment()
        .add(15, "d")
        .format("DD MMM YYYY")
    );
    setValue(`${prefix}.startTime`, "21:15");
    setValue(
      `${prefix}.endDate`,
      moment()
        .add(15, "d")
        .format("DD MMM YYYY")
    );
    setValue(`${prefix}.endTime`, "23:15");
    setValue(`${prefix}.price`, "100");
    setValue(`${prefix}.pointAddress.latitude`, 55);
    setValue(`${prefix}.pointAddress.longitude`, 55);
    setValue(`${prefix}.pointAddress.latitude`, "55");
    setValue(`${prefix}.pointAddress`, "some address");
    setValue(`${prefix}.pointComment`, "some comment");
    setValue(`${prefix}.pointPhone`, "+7 999 111 11 11");
  };
  return (
    <>
      <input
        type={"hidden"}
        {...register(`${prefix}.type`)}
        defaultValue={"etc"}
      />
      <input
        type={"hidden"}
        {...register(`${prefix}._id`)}
        defaultValue={item._id}
      />

      <Row>
        <Col xs={6}>
          <FormLabel>Название </FormLabel>
          <FormHookInput
            defaultValue={item.name}
            register={register(`${prefix}.name`, {
              required: true,
              minLength: 2
            })}
            errors={errors}
          />
        </Col>
        <Col xs={3}>
          <FormLabel>Дата начала</FormLabel>
          <FormHookDatePicker
            formController={formController}
            dateRules={{ required: true }}
            date={item.startDate}
            dateName={`${prefix}.startDate`}
            timeRules={{ required: true }}
            time={item.startTime}
            timeName={`${prefix}.startTime`}
            errors={errors}
          />
        </Col>
        <Col xs={3}>
          <FormLabel>Дата окончания</FormLabel>
          <FormHookDatePicker
            formController={formController}
            dateRules={{ required: true }}
            date={item.endDate}
            dateName={`${prefix}.endDate`}
            timeRules={{ required: true }}
            time={item.endTime}
            timeName={`${prefix}.endTime`}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        {!withoutPaxes ? (
          <Col xs={6}>
            <FormLabel>Пассажиры</FormLabel>
            <PaxesSelector
              name={`${prefix}.paxes`}
              value={item.paxes}
              formController={formController}
            />
          </Col>
        ) : null}
        {/*<Col xs={2}>*/}
        {/*  <FormLabel>Иконка</FormLabel>*/}
        {/*  <FormHookInput*/}
        {/*    defaultValue={item.icon}*/}
        {/*    register={register(`${prefix}.icon`)}*/}
        {/*  />*/}
        {/*</Col>*/}
        <Col xs={2}>
          <FormLabel>Иконка</FormLabel>
          <IconSelect defaultValue={item.icon} prefix={prefix} control={control}/>
        </Col>
        <Col xs={2}>
          <FormLabel>Цена</FormLabel>
          <FormHookInput
            defaultValue={item.price}
            type={"number"}
            register={register(`${prefix}.price`)}
          />
        </Col>
        <Col xs={2}>
          <FormLabel>Валюта</FormLabel>
          <CurrencyPicker
            value={item.currency}
            formController={formController}
            name={`${prefix}.currency`}
            errors={errors}
          />
        </Col>
        <Col xs={6}>
          <FormLabel>Место</FormLabel>
          <FormLocation
            formController={formController}
            prefix={prefix}
            point={item}
            addressName={`${prefix}.pointAddress`}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <FormLabel>Адрес</FormLabel>
          <FormHookInput
            defaultValue={item.pointAddress}
            register={register(`${prefix}.pointAddress`)}
          />
        </Col>
        <Col xs={3}>
          <FormLabel>Коментарий к адресу</FormLabel>
          <FormHookInput
            defaultValue={item.pointComment}
            register={register(`${prefix}.pointComment`)}
          />
        </Col>
        <Col xs={2}>
          <FormLabel>Номер телефона</FormLabel>
          <FormHookInput
            defaultValue={item.pointPhone}
            register={register(`${prefix}.pointPhone`)}
          />
        </Col>
        {offer && <Col xs={1}>
          <FormLabel>Порядок</FormLabel>
          <FormHookInput
            defaultValue={item.order}
            type={"number"}
            register={register(`${prefix}.order`)}
          />
        </Col>}
        <Col xs={3}>
          <FormLabel>Разное</FormLabel>
          <Form.Check
            type={"checkbox"}
            {...register(`${prefix}.hideElement`)}
            label={"Спрятать элемент"}
            defaultChecked={item.hideElement}
          />

          <Form.Check
            type={"checkbox"}
            {...register(`${prefix}.hidePrice`)}
            label={"Прятать цену"}
            defaultChecked={item.hidePrice}
          />
        </Col>
      </Row>

      <DescriptionsBlock
        title={"разного"}
        descriptions={item.descriptions}
        formController={formController}
        prefix={`${prefix}.descriptions`}
      />

      <DocumentsBlock
        formController={formController}
        prefix={`${prefix}.documents`}
        documents={item.documents}
      />
      <ImagesBlock
        formController={formController}
        prefix={`${prefix}.photos`}
        photos={item.photos}
        active={activ}
      />
      <TripEtcTimeLine
        formController={formController}
        prefix={`${prefix}.timeline`}
        active={activ}
        // photos={item.photos}
      />
      <Button onClick={() => insertTempData()}>Вставить тестовые данные</Button>
    </>
  );
};

export default TripEtcForm;
