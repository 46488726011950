import React, { useEffect, useState } from "react";
import Select from "react-select";
import _ from "lodash";
import { Col } from "react-bootstrap";
import { useWatch, Controller, useController } from "react-hook-form";

const PaxesSelector = ({
  formController,
  value,
  name,
  single,
  rules,
  required,
  errors
}) => {
  const { register, setValue, watch, getValues, control } = formController;

  const paxesMain = watch("paxes", []);
  const defaultValue = single
    ? _.find(paxesMain, p => value === p.tempId)
    : _.filter(paxesMain, p => value && value.indexOf(p.tempId) !== -1);
  const [result, setResult] = useState(defaultValue);
  const {
    field: { onChange, onBlur, value: paxValue, ref },
    fieldState,
    formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control: formController.control,
    rules: rules,
    defaultValue: value
  });

  return (
    <Select
      isMulti={!single}
      getValue={p => p.tempId}
      error={!!fieldState.error}
      getOptionLabel={p => {
        return `${p.title} ${p.lastName} ${p.firstName}`;
      }}
      getOptionValue={p => p.tempId}
      value={
        single
          ? _.find(paxesMain, { tempId: paxValue })
          : _.filter(paxesMain, p => paxValue.indexOf(p.tempId) !== -1)
      }
      options={
        single
          ? paxesMain
          : [
              ...paxesMain,
              {
                title: "Добавить всех",
                lastName: "",
                firstName: "",
                addAll: true
              }
            ]
      }
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor:
            (single && _.get(errors, name))
              ? "#FD397A"
              : "#e2e5ec"
        })
      }}
      onChange={(newVal, something) => {
        if (something.option?.addAll) {
          onChange(paxesMain.map(p => p.tempId));
        } else {
          const newValue = single ? newVal.tempId : newVal.map(p => p.tempId);
          console.log("newVal", newValue);
          onChange(newValue);
        }
      }}
    />
  );
};

export default PaxesSelector;
