import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GetItem } from "../../../../redux/actions/base";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import { Tab, Tabs } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";
import ReviseItemRender from "../../components/reviseItemRender";
import reviseItemTypes from "../../helpers/reviseItemTypes";
import EditReviseButton from "../../components/editReviseButton";
import { useParams } from "react-router-dom";
import CheckedReviseButton from "../../components/CheckedReviseButton";

const RevisePage = props => {
  const [item, setItem] = useState();
  const reviceColor = nums => {
    if (nums.reduce((a, b) => a + b, 0) === 0) {
      return "#B7F46E";
    }
    return "#FFC373";
  };

  const { id } = useParams();
  console.log("id", id);
  useEffect(() => {
    refreshRevise();
  }, []);

  const refreshRevise = () => {
    props.GetItem("/api/racoon/revise/", id, ({ data }) => {
      setItem(data.revise);
      console.log("item", data);
    });
  };
  return (
    <>
      <Portlet>
        <PortletHeader
          title={"Отчет"}
          toolbar={
            <PortletHeaderToolbar>
              {item ? (
                <>
                  <EditReviseButton revise={item} refreshRevise={refreshRevise} />
                  <div style={{marginLeft: '0.5rem'}}>
                    <CheckedReviseButton id={id} checked={!!!_.find(item.items, {checked: false})} url={'/api/racoon/revise/toggle/'}/>
                  </div>
                </>
              ) : null}
            </PortletHeaderToolbar>
          }
        />
        <PortletBody>
          {item ? (
            <>
              <p>
                Создал - {item.createdBy} <br />
                Создано - {moment(item.createdDate).format("DD.MM.YYYY")} <br />
                Даты - {moment(item.startDate).format("DD.MM.YYYY")} -{" "}
                {moment(item.endDate).format("DD.MM.YYYY")}
                <br />
                {item.comment}
                <br />
                <br />
                <Tabs defaultActiveKey="item0">
                  {item.items.map((u, index) => {
                    return (
                      <Tab
                        key={index}
                        eventKey={`item${index}`}
                        title={
                          <>
                            <i
                              className="fa fa-circle"
                              aria-hidden="true"
                              style={{
                                color: `${reviceColor(
                                  u.numberResult.slice(1)
                                )}`,
                                marginRight: "5px"
                              }}
                            ></i>
                            {
                              _.find(reviseItemTypes, { value: u.reviseType })
                                .name
                            }
                          </>
                        }
                      >
                        <ReviseItemRender revise={u} />
                      </Tab>
                    );
                  })}
                </Tabs>
              </p>
            </>
          ) : (
            "Загрузка"
          )}
        </PortletBody>
      </Portlet>
    </>
  );
};

export default connect(null, { GetItem })(RevisePage);
