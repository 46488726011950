import React, {useEffect, useState} from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import {connect} from "react-redux";
import {
  DeleteItem,
  GetItem,
  UpdateItem
} from "../../../../redux/actions/base";
import {AirBookingListRoute} from "../../../routesConstants";
import {useParams} from "react-router-dom";
import BlockUi from "@availity/block-ui";
import {Row, Col} from "react-bootstrap";
import moment from "moment";
import AirBookingForm from "../components/airBookingForm";

const AirBookingEditPage = props => {
  const [airBooking, setAirBooking] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const {id} = useParams();
  useEffect(() => {
    if (airBooking === null) {
      setLoader(true);
      props.GetItem("/api/air/airBooking", id, res => {
        console.log("res", res);
        setAirBooking(res.data.airBooking);
        setLoader(false);
      });
    }
  }, [airBooking, id, props]);
  const callback = (values, cb) => {
    setLoading(true)
    props.UpdateItem(
      "/api/air/airBooking/" + id,
      {airBooking: values,importOneS:values.importOneS},
      () => {
        cb();
        setLoading(false)
      },
      AirBookingListRoute
    );
  };
  return (
    <>
      <BlockUi blocking={loader}>
        {airBooking ? (
          <AirBookingForm onSubmit={callback} data={airBooking} loading={loading} edit/>
        ) : null}
      </BlockUi>
    </>
  );
};

export default connect(null, {GetItem, UpdateItem})(AirBookingEditPage);
