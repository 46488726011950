import React from 'react';
import {useController, useForm} from "react-hook-form";
import moment from "moment/moment";
import CustomDatePicker from "../../base/components/forms/customDatePicker";
import ApiSelect from "../../base/components/forms/apiSelect";
import _ from "lodash";
import LoadingButton from "../../base/components/loadingButton";

const FinderPaxTableForm = ({cb, loading}) => {
  const formController = useForm()
  const {control,
    register,
    formState:{errors},
    handleSubmit} = formController
  const onSubmit = (data) =>{
    console.log('submit',data)
    cb(data)
  }
  const defaultStartDate = moment().subtract(1, 'year').format('DD.MM.YYYY');
  const defaultEndDate = moment().format('DD.MM.YYYY');
  const {
    field: { onChange: buyerOnChange, value: buyerValue }
  } = useController({
    name: `counterparty`,
    control,
    rules: { required: true },
    defaultValue: ''
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'d-flex justify-content-between align-items-end '}>
      <div className={'d-flex align-items-center gap-2'}>
        <CustomDatePicker
          formController={formController}
          label={"Начало периода"}
          name={"start"}
          value={defaultStartDate}
          rules={{required: true}}
        />
        <CustomDatePicker
          formController={formController}
          label={"Конец периода"}
          name={"end"}
          value={defaultEndDate}
          rules={{required: true}}
        />
        <ApiSelect
          style={{width:'200px'}}
          value={buyerValue}
          label={'Покупатель'}
          hasError={_.get(errors, `counterparty`)}
          url={"/api/counterparty/searchInput"}
          cb={newVal => {
            buyerOnChange(newVal);
          }}
        />
      </div>
      <LoadingButton
        title={"Загрузить"}
        disabled={loading}
        loading={loading}
      />
    </form>
    );
};

export default FinderPaxTableForm;