import React, {useEffect, useState} from 'react';
import {Controller, useFieldArray, useForm} from "react-hook-form";
import Counterpartiesfilds from "./counterpartiesfilds";
import {Row,Col} from "react-bootstrap";
import ContactsFields from "./contactsFields";
import LoadingButton from "../../base/components/loadingButton";



const ContactsForm = ({onSubmit, data}) => {

  const formController = useForm()
  const {
    register,
    control,
    getValues,
    watch,
    handleSubmit} = formController
  const beforeSubmit = (data) =>{
    let arrayWithString = []
    data.counterparties.forEach((c,i)=>arrayWithString.push(c.id))
    console.log(data)
    onSubmit({...data, counterparties:arrayWithString})
  }
  useEffect(() => {
  }, [watch('contacts')]);
  return (
    <>
      <form onSubmit={handleSubmit(beforeSubmit)}>
        <Row>
          <Col xs={6}>
            <Counterpartiesfilds
              formController={formController}
              data={data}/>
            <ContactsFields formController={formController}
                            data={data}/>
          </Col>
          <Col xs={6}>
            Общая инфо(текстом):
            {getValues().contacts && getValues().contacts.map((c,i)=>{
              return <div key={i}>
                <div className={'d-flex justify-content-between h5 p-2'} style={{backgroundColor:'rgba(121,129,134,0.15)'}}>
                  <div>{c.name}</div>
                  <div>{c.job}</div>
                </div>
                <div>{c.description}</div>
                {c.phones.length>0 && <div >
                  <b>Телефоны:</b><br/>
                  {c.phones.map((p,index)=>{
                    return <div  key={index}><a style={{fontSize:16}} href={`tel:${p.phone}`}>{p.phone}</a> — {p.comment}
                    </div>
                  })}
                </div>}
                {c.emails.length>0 && <div>
                  <b>Emails:</b><br/>
                  {c.emails.map((p,index)=>{
                    return <div key={index} ><a style={{fontSize:16}} href={`mailto:${p.email}`}>{p.email}</a> — {p.comment}</div>
                  })}
                </div>}
              </div>
            })}

          </Col>
        </Row>
        <LoadingButton loading={false} title={'Сохранить'}/>
      </form>
      
    </>
  );
};

export default ContactsForm;