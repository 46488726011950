import React, { useState, useEffect } from "react";
import { Button, Col, FormLabel, Modal, Row } from "react-bootstrap";
import CustomDatePicker from "../../../../../../components/customDatePicker";

const TextPaxes = ({ type, formController }) => {
  const [formatedText, setFormatedText] = useState(false);
  const [title, setTitle] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    let text = "";
    const formData = formController.getValues();
    console.log("formData", formData);
    if (type === "text" && formData.paxes) {
      formData.paxes.forEach(pax => {
        text += pax.title + " " + pax.lastName + " " + pax.firstName + "\n";
      });
    } else if (type === "avia" && formData.paxes) {
      formData.paxes.forEach((pax, i) => {
        text += `${i + 1} ${pax.lastName}/${pax.firstName} ${pax.title} \n`;
      });
    }
    setShow(true);
    setFormatedText(text);
  };

  useEffect(() => {
    if (type === "text") {
      setTitle("Текстом");
    } else if (type === "avia") {
      setTitle("Текстом для авиа");
    }
  }, [type]);

  return (
    <>
      <Button size={"sm"} onClick={handleShow}>
        {title}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Пассажиры текстом</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={6} style={{ whiteSpace: "pre-wrap" }}>
              {formatedText}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TextPaxes;
