import BlockUi from "@availity/block-ui";
import {Modal, Row, Form, Col, Button} from "react-bootstrap";
import FormHookSelect from "../../air/airBooking/components/formHookSelect";
import React, {useState} from "react";
import LoadingButton from "../../base/components/loadingButton";
import {useForm} from "react-hook-form";
import FormHookInput from "../../air/airBooking/components/formHookInput";

import {connect} from "react-redux";
import {CreateItem, StartProcess} from "../../../redux/actions/base";

const BonusModal = props=>{
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setError
  } = useForm();
  console.log('id', props.id)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSubmit = ({amount, method, description}) =>{
    setLoader(true)
    const newAmount = method === 'cancel' ? -1 * amount : amount
    const newData = {
      amount: +newAmount,
      description
    }
    console.log('am', typeof newAmount)
    props.StartProcess(
      `/api/trips/users/createBonusTransaction/${props.id}`,
      (res) => {
        if(!res){
          console.log('error')
        }
        console.log()
        reset()
        setLoader(false)
        setShow(false)
        console.log('Form bonus', newData)
        props.bonusRequest()

      },
      newData,
    );
    // reset()
    // setShow(false)

  }

  return<>
    <LoadingButton
      title={"Добавить / Списать"}
      onClick={handleShow}
    />
    <Modal show={show} onHide={handleClose}>
      <BlockUi blocking={loader}>
        <Modal.Header closeButton>
          <Modal.Title>Редактирование баллов</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={Row}>
            <Modal.Body>
              <Col md={{span: 10}}>
                <Form.Label>
                  Что хотите сделать?
                </Form.Label>
              </Col>
              <FormHookSelect
                register={register(`method`, { required: true })}
                errors={errors}>
                <option value={'add'}>Начислить баллы</option>
                <option value={'cancel'}>Списать баллы</option>
              </FormHookSelect>
              <Col md={{span: 10}} className={'mt-3'}>
                <Form.Label >
                  Введите нужное количество
                </Form.Label>
              </Col>
              <FormHookInput
                register={register(`amount`, {
                  required: true,
                  min: 1
                })}
                type={'number'}
                errors={errors}
              />
              <Col md={{span: 10}} className={'mt-3'}>
                <Form.Label >
                  Описание(не обязательно)
                </Form.Label>
              </Col>
              <FormHookInput
                register={register(`description`, {
                  minLength: 5,
                })}
                errors={errors}
              />
            </Modal.Body>
          </Form.Group>
          <Modal.Footer>
            <Row>
              <Col >
                <Button type="submit">Сохранить изменения</Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Form>
      </BlockUi>
    </Modal>
  </>
}
export default connect(null, {StartProcess})(BonusModal);