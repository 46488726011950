import {useLocation, useParams} from "react-router";

const LinkTo = ({type})=>{
  const {id} = useParams()
  const location = useLocation().pathname

  if(!/edit/.test(location)){
    return null
  }

  return <a href={`${process.env.REACT_APP_TOURS_LINK}${type}/${id}`}
            target="_blank"
            style={{color:'#bdbdbd'}}>
    <i className="fa fa-share-square" style={{fontSize:"16px"}}/>
  </a>
}
export default LinkTo