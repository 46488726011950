import React, {useEffect, useState} from "react";
import axios from "../../../helpers/axios";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import {Table} from "reactstrap";
import 'chart.js/auto';
import TicketDiagramm from "./ticketDiagramm";
import moment from "moment";
import numberFormat from "../../../helpers/numberFormat";
import ChangeDate from "./changeDate";
import BlockUi from "@availity/block-ui";

const IssuedTicketsForDay = () =>{
  const [loading, setLoading] = useState(true)
  const [data, setData] =useState(null)
  const [values, setValues] = useState({
    dateFrom: moment().subtract(1, 'days').toDate(),
    dateTo:moment().toDate()})

  useEffect(()=>{
    setLoading(true)
    axios
      .post('/api/dashboard/issuedTicketsForDay', {dateFrom: moment(values.dateFrom).format('DD.MM.YYYY'), dateTo:moment(values.dateTo).format('DD.MM.YYYY')})
      .then((data)=>{
        console.log(data)
        setData(data.data)
      })
      .catch(e=>console.log('err',e))
      .finally(()=>setLoading(false))
  },[values])

  const color = [
    '#FF6384FF',
    '#36A2EBFF',
    '#FFCD56FF',
    '#80FF56FF'
  ]
  return <Portlet>
    <BlockUi blocking={loading} >
      <PortletHeader title={'Выписанные билеты'} toolbar={
        <PortletHeaderToolbar>
          <ChangeDate values={values} set={setValues}/>
        </PortletHeaderToolbar>
      }/>
      <PortletBody>
        {/*{loading && <MiniLoader/>}*/}
        {data && <Table size="sm" responsive >
          <thead>
          <tr>
            <td align={'center'} rowSpan={4} >
              <TicketDiagramm data={data} color={color}/>
            </td>
            <th>Всего</th>
            <th>{numberFormat(data.allSum)}</th>
          </tr>
          <tr>
            <th>Всего Net</th>
            <th>{numberFormat(data.allNetSum)}</th>
          </tr>
          <tr>
            <th>Всего Krs</th>
            <th>{numberFormat(data.allKrsSum)}</th>
          </tr>
          <tr>
            <th>Билетов</th>
            <th>{numberFormat(data.allQuantity)}</th>
          </tr>
          </thead>
          <tbody>
          {data.suppliers.map((sup, i)=>{
            return <React.Fragment key={i} >
              <tr>
                <th rowSpan={4} width={200}>
                  <i className="fa fa-circle" aria-hidden="true" style={{color:`${color[i]}`}}></i> {sup.supplierName}
                </th>
                <td>Всего</td>
                <td>{numberFormat(sup.sum)}</td>
              </tr>
              <tr>
                <td>Всего Net</td>
                <td>{numberFormat(sup.netSum)}</td>
              </tr>
              <tr>
                <td>Всего Krs</td>
                <td>{numberFormat(sup.krsSum)}</td>
              </tr>
              <tr>
                <td>Билетов</td>
                <td>{numberFormat(sup.quantity)}</td>
              </tr>
            </React.Fragment>
          })}
          </tbody>
        </Table>}
      </PortletBody>
    </BlockUi>
    </Portlet>

}
export default IssuedTicketsForDay
