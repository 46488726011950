import React, {useEffect, useState} from "react";
import {
  OfferCreateRoute,
  OfferEditRoute,
  TripCreateRoute,
  TripEditRoute
} from "../../../routesConstants";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import { Link } from "react-router-dom";
import DataTable from "../../../../components/dataTable";
import PortletHeaderSearch from "../../../base/components/forms/portlerHeaderSearch";
import RemoveLink from "../../../air/airBooking/components/removeLink";
import { connect } from "react-redux";
import { DeleteItem } from "../../../../redux/actions/base";
import axios from "../../../../helpers/axios";
import moment from "moment/moment";

const OfferList = props => {
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const remove = id => {
    setLoading(true);
    props.DeleteItem(`/api/trips/offer/${id}`, () => {
      setLoading(false);
      setRefetch(!refetch);
    });
  };
  const columns = [
    {
      Header: "Название",
      accessor: "name",
      Cell: ({ value, row }) => (
        <Link to={OfferEditRoute + row.original._id}>{value}</Link>
      )
    },
    {
      Header: "Даты",
      accessor: "dates"
    },
    {
      Header: "Создано",
      accessor: "createdBy",
      Cell: ({value, row}) => (
        <>{value && <>
          {value} <br/>
          {moment(row.original.createdDate).format('DD MMM YYYY')}
        </>}</>
      )
    },
    {
      Header: "Действия",
      Cell: ({ value, row }) => (
        <>
          <Link
            className={"btn btn-sm btn-clean btn-icon btn-icon-md"}
            to={OfferEditRoute + row.original._id}
          >
            <i className={"la la-edit"} />
          </Link>

          <RemoveLink
            onClick={() => remove(row.original._id)}
            noText
            title={"поездку"}
          />
        </>
      )
    }
  ];
  const [query, setQuery] = useState("");
  console.log('qwery', query)

  return (
    <>
      <div className={"kt-grid__item"}>
        <Portlet>
          <PortletHeader
            title={"Список предложений"}
            toolbar={
              <PortletHeaderToolbar>
                <PortletHeaderSearch
                  value={query}
                  cb={v => {
                    setQuery(v);
                  }}
                />
                <Link
                  to={OfferCreateRoute}
                  className="btn btn-sm btn-brand btn-elevate btn-icon-sm"
                >
                  <i className="la la-plus" />
                  Добавить
                </Link>
              </PortletHeaderToolbar>
            }
          />
          <PortletBody>
            <DataTable
              columns={columns}
              search={query}
              url={`/api/trips/offer/`}
              refetch={refetch}
              itemsPerPage={25}
            />
          </PortletBody>
        </Portlet>
      </div>
    </>
  );
};

export default connect(null, { DeleteItem })(OfferList);
