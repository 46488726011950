import React, {useEffect, useState} from 'react';
import {Button, Modal, Tab, Table, Tabs} from "react-bootstrap";
import BlockUi from "@availity/block-ui";
import ParseInput from "./parseInput";
import {Link} from "react-router-dom";
import {AirBookingEditRoute} from "../../../routesConstants";


const tabsName = {
  missed:'Нет в базе',
  incorrect:'Отличаются',
  correct:'Правильные'
}
const AmadeusButton = () => {
  const [show, setShow]=useState(false)
  const [loading, setLoading]=useState(false)
  const [data, setData] = useState(null)
  useEffect(() => {
    !show && setData(null)
  }, [show]);
  const getList = (v) =>{
    console.log("list",v)
    setData(v)
  }
  const renderInfo = (array,key)=>{
    return array.map((diff,i)=>{
      return <React.Fragment key={i}>{diff[key]}<br/></React.Fragment>
    })
  }
  return (
    <div>
      <Button onClick={() => setShow(true)} variant={'outline-primary'} size={'sm'}>
        Сверка с Амадеус
      </Button>
      <Modal show={show} dialogClassName="modal-90w" onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Сверка с Амадеус</Modal.Title>
        </Modal.Header>
        <BlockUi blocking={loading}>
          <Modal.Body>
            <ParseInput setter={getList} url={"/api/air/airBooking/amadeusRevise"} keyValue={'amadeusText'} placeholder={"Парс"}/>
            {data && <div className={'mt-2'}><Tabs defaultActiveKey="missed">
              {Object.keys(data).map((key,index)=>{
                return <Tab
                  eventKey={key}
                  key={index}
                  title={`${tabsName[key]} (${data[key].length})`}>
                  <Table striped bordered hover>
                    <thead>
                    <tr>
                      <th>Бронь</th>
                      <th>Билет</th>
                      <th>Пассажир</th>
                      <th>Тариф</th>
                      <th>Такса</th>
                      {key === 'incorrect' && <>
                        <th>Отличается</th>
                        <th>Амадеус</th>
                        <th>База</th>
                      </>}
                    </tr>
                    </thead>
                    <tbody>
                    {data[key].map((t,indexR)=>{
                      return <tr key={indexR}>
                        <td>{key !== "missed" ?
                          <Link to={AirBookingEditRoute+t.id} target={'_blank'}>{t.bookingNumber}</Link> :
                          t.bookingNumber
                        }</td>
                        <td>{t.airlineCode}-{t.ticketNumber}{t.refund && '-B'}</td>
                        <td>{t.paxName}</td>
                        <td>{t.fare}</td>
                        <td>{t.tax}</td>
                        {key === 'incorrect' && <React.Fragment>
                          <td>{renderInfo(t.info,'field')}</td>
                          <td>{renderInfo(t.info, 'amadeus')}</td>
                          <td>{renderInfo(t.info, 'base')}</td>
                        </React.Fragment>}
                      </tr>})}
                    </tbody>

                  </Table>
                </Tab>
              })}

            </Tabs></div>}
              </Modal.Body>
              </BlockUi>
      </Modal>

    </div>
  );
};

export default AmadeusButton;