import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  FormLabel,
  FormControl,
  Form,
  Tabs,
  Tab,
  Button
} from "react-bootstrap";
import CustomDatePicker from "../../../components/customDatePicker";
import { mealTypes } from "../../../helpers/enums";
import ApiSelect from "../../base/components/forms/apiSelect";
import _ from "lodash";
import Select from "react-select";
import DescriptionsBlock from "./descriptionsBlock";
import ImagesBlock from "./imagesBlock";
import { PortletBody } from "../../../components/Portlet";
import RemoveLink from "../../air/airBooking/components/removeLink";
import FastInput from "../../../components/FastInput";
import HookDatePicker from "../../../components/hookDatePicker";
import PaxesSelector from "./paxesSelector";
import CurrencyPicker from "../../base/components/forms/currencySelector";
import { useFieldArray } from "react-hook-form";
import DocumentsBlock from "./documentsBlock";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import FormHookSelect from "../../air/airBooking/components/formHookSelect";
import FormHookDatePicker from "../../base/components/forms/formHookDatePicker";



const TripItemHotelRoom = ({
  room,
  prefix,
  removeItem,
  formController,
  withoutPaxes,
  hotelPrefix,
  copyRoom,
  activ
}) => {
  const {
    register,
    formState: { errors },
    watch,
    control,
    setValue,
    getValues
  } = formController;
  const [paxes, setPaxes] = useState([]);
  useEffect(() => {
    setPaxes(getValues().paxes);
  }, []);
  const roomsControl = useFieldArray({
    control,
    name: `${prefix}.rooms`,
  });
  useEffect(() => {
    console.log('time change')
    setValue(`${prefix}.startDate`, _.get(getValues(), hotelPrefix).startDate)
  }, [watch(`${hotelPrefix}.startDate`), ]);
  useEffect(() => {
    setValue(`${prefix}.startTime`, _.get(getValues(), hotelPrefix).startTime)
  }, [watch(`${hotelPrefix}.startTime`)]);
  useEffect(() => {
    setValue(`${prefix}.endTime`, _.get(getValues(), hotelPrefix).endTime)
  }, [watch(`${hotelPrefix}.endTime`)]);
  useEffect(() => {
    setValue(`${prefix}.endDate`, _.get(getValues(), hotelPrefix).endDate)
  }, [watch(`${hotelPrefix}.endDate`)]);

  return (
    <>
      <input
        type={"hidden"}
        {...register(`${prefix}._id`)}
        defaultValue={room._id}
      />

      <Row>
        <Col xs={3}>
          <FormLabel>Название номера</FormLabel>
          <FormHookInput
            defaultValue={room.name}
            register={register(`${prefix}.name`, { required: true })}
            errors={errors}
          />
        </Col>
        {!withoutPaxes ? (
          <Col xs={2}>
            <FormLabel>Код бронирования</FormLabel>
            <FormHookInput
              defaultValue={room.confirmationCode}
              errors={errors}
              register={register(`${prefix}.confirmationCode`, { required: true })}
            />
          </Col>
        ) : null}
        <Col xs={1}>
          <FormLabel>Метраж</FormLabel>
          <FormHookInput
            defaultValue={room.squareMeters}
            errors={errors}
            register={register(`${prefix}.squareMeters`)}
          />
        </Col>
        <Col xs={2}>
          <FormLabel>Питание</FormLabel>
          <FormHookSelect
            defaultValue={room.meal}
            register={register(`${prefix}.meal`)}
            errors={errors}
          >
            {mealTypes.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
          </FormHookSelect>
        </Col>
        <Col xs={2}>
          <FormLabel>Вид из номера</FormLabel>
          <FormHookInput
            defaultValue={room.roomView}
            register={register(`${prefix}.roomView`)}
            errors={errors}
          />
        </Col>
        <Col xs={2}>
          <FormLabel>Цена</FormLabel>
          <FormHookInput
            defaultValue={room.price}
            register={register(`${prefix}.price`, {required: true})}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <FormLabel>Заезд</FormLabel>
          <FormHookDatePicker
            formController={formController}
            dateRules={{ required: true }}
            date={room.startDate}
            dateName={`${prefix}.startDate`}
            timeRules={{ required: true }}
            time={room.startTime}
            timeName={`${prefix}.startTime`}
            errors={errors}
          />
          <RemoveLink onClick={removeItem} title={"комнату"} />
        </Col>
        <Col xs={3}>
          <FormLabel>Выезд</FormLabel>

          <FormHookDatePicker
            formController={formController}
            dateRules={{ required: true }}
            date={room.endDate}
            dateName={`${prefix}.endDate`}
            timeRules={{ required: true }}
            time={room.endTime}
            timeName={`${prefix}.endTime`}
            errors={errors}
          />
          <a
            href={"#"}
            onClick={e => {
              e.preventDefault();
              copyRoom();
            }}
          >
            Скпировать комнату
          </a>
        </Col>
        {!withoutPaxes ? (
          <Col xs={3}>
            <FormLabel>Основной гость </FormLabel>
            <PaxesSelector
              name={`${prefix}.mainPax`}
              single={true}
              formController={formController}
              rules={{ required: true }}
              value={room.mainPax}
              errors={errors}
            />
          </Col>
        ) : null}
        {!withoutPaxes ? (
          <Col xs={3}>
            <FormLabel>Остальные гости </FormLabel>

            <PaxesSelector
              name={`${prefix}.otherPaxes`}
              formController={formController}
              value={room.otherPaxes}
            />
          </Col>
        ) : null}
      </Row>
      <h5>
        Комнаты{" "}
        <Button size={"sm"} onClick={() => roomsControl.append({})}>
          <i className={"fa fa-plus"}></i>
        </Button>
      </h5>
      {roomsControl.fields.map((hotelRoom, index) => {
        return (
          <Row key={hotelRoom.id}>
            <Col xs={4}>
              <FormLabel>Название</FormLabel>
              <FormHookInput
                defaultValue={hotelRoom.name}
                register={register(`${prefix}.rooms[${index}].name`, {
                  required: true
                })}
              />
            </Col>
            <Col xs={4}>
              <FormLabel>Кровати</FormLabel>
              <FormHookInput
                defaultValue={hotelRoom.beds}
                register={register(`${prefix}.rooms[${index}].beds`, {
                  required: true
                })}
              />
            </Col>
            <Col xs={4}>
              <RemoveLink
                onClick={() => roomsControl.remove(index)}
                title={"комнату"}
              />
            </Col>
          </Row>
        );
      })}
      <br />
      <DescriptionsBlock
        title={"комнаты"}
        descriptions={room.descriptions}
        formController={formController}
        prefix={`${prefix}.descriptions`}
      />
      <DocumentsBlock
        formController={formController}
        documents={room.documents}
        prefix={`${prefix}.documents`}
      />
      <ImagesBlock
        formController={formController}
        photos={room.photos}
        prefix={`${prefix}.photos`}
        minLength={1}
        active={activ}
      />
      {/*<ImagesBlock*/}
      {/*  images={room.photos}*/}
      {/*  formik={formik}*/}
      {/*  prefix={`${prefix}.photos`}/>*/}
    </>
  );
};

export default TripItemHotelRoom;
