import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import Paper from "@material-ui/core/Paper";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {RacoonListRoute} from "../../routesConstants";
import Typography from "@material-ui/core/Typography";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import clsx from "clsx";
import SaveReportButton from "./saveReportButton";
import SilkKayarReviseRender from "./silkKayarReviseRender";
import axios from "../../../helpers/axios";
import BlockUi from "@availity/block-ui";
import AruanaKayarReviseRender from "./aruanaKayarReviseRender";
import AruanaIataReviseRender from "./aruanaIataReviseRender";
import SilkKayarRender from "./SilkKayarRender";
import AruanaKayarRender from "./AruanaKayarRender";
import AruanaIataRender from "./AruanaIataRender";
import {typeList, weeks} from "../pages/revise/reviseSortPage";
import moment from "moment";
import SaveReviseItemsBut from "./saveReviseItemsBut";
import SiteKayarRender from "./SiteKayarRender";
import YanaKayarRender from "./YanaKayarRender";

const ReviseItemsCheck = ({options, close, reload}) => {
  const [loading, setLoading] = useState();
  const [data, setData] = useState();
  const [show, setShow] = useState(false)
  const [error, setError] = useState()
  const [files, setFiles] = useState()
  const date = `${weeks[options.targetWeek-1][0]}-${options.targetWeek ===4 ?moment(`${options.targetMonth}.${options.targetYear}`, 'MM.YYYY').daysInMonth():weeks[options.targetWeek-1][1]} ${moment(options.targetMonth, 'M', 'ru').format('MMMM')} ${options.targetYear}`
  const dataRender = type =>{
    if(type === "silk-kayar"){
      return <SilkKayarRender data={data}/>
    } else if(type === "aruana-kayar"){
      return <AruanaKayarRender data={data} />
    } else if(type === "kayar-site"){
      return <SiteKayarRender data={data} />
    }else if(type === "iata-aruana"){
      return <AruanaIataRender data={data}/>
    }else {
      return <YanaKayarRender data={data}/>
    }
  }
  const startDate = moment(`${weeks[options.targetWeek-1][0]}.${options.targetMonth}.${options.targetYear}`, 'DD.M.YYYY').format('DD.MM.YYYY')
  const endDate = options.targetWeek===4 ? moment(`${moment(`${options.targetMonth}.${options.targetYear}`, 'MM.YYYY').daysInMonth()}.${options.targetMonth}.${options.targetYear}`, 'DD.M.YYYY').format('DD.MM.YYYY'):
    moment(`${weeks[options.targetWeek-1][1]}.${options.targetMonth}.${options.targetYear}`, 'DD.M.YYYY').format('DD.MM.YYYY')
  const {control,
    register,
    handleSubmit,
    getValues} = useForm();
  const onSubmit = values => {
    console.log("values", values);
    setLoading(true);
    setData(null);
    const data = new FormData();
    options.inputs.forEach(input =>{
      if(values[input.registerName][0]){
        data.append(`${input.registerName}`, values[input.registerName][0])
      }
    });
    data.append('targetWeek', options.targetWeek);
    data.append('targetYear', options.targetYear);
    data.append('targetMonth', options.targetMonth);
    setFiles(options.inputs.map(input=>{
      return {
        name: input.title,
        file: values[input.registerName][0]
      }
    }))
    axios.post(options.url, data)
      .then((res)=>{
        console.log("res", res);
        setData(res.data);
      })
      .catch(e=>{
        console.log('err', e)
        setError(e.response.data.message)
      })
      .finally(()=>{
        setLoading(false);
    })
  };
  return (
    <>{!data ? <BlockUi blocking={loading}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title className={'d-flex justify-content-between gap-5'}>{typeList[options.type]} {date} <a
            href="https://convertio.co/ru/xls-xlsx/"
            target={"_blank"}
            referrerPolicy={"no-referrer"}
          >
            Конвертер xls в xlsx
          </a> <a
            className={"mr-3"}
            href="https://www.pdf2go.com/ru/pdf-to-excel"
            target={"_blank"}
            referrerPolicy={"no-referrer"}
          >
            Конвертер pdf в xlsx
          </a></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {options.inputs.length>0 ? <Col>
                <Form.Label>{options.inputs[0].title}</Form.Label>
                <Form.Control
                  type={"file"}
                  {...register(options.inputs[0].registerName, {required: true})}
                />
                <p className={'mt-2'}>Достаточно загрузить только файл "{options.inputs[0].title}". Остальные данные загрузятся напрямую из 1с</p>
              </Col>
              :
              'После нажатия "Долучить данные", данные автоматически загрузятся из 1с'
            }
          </Row>
          {error && <p className={'text-danger'}>{error[0]}</p>}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Закрыть
          </Button>
          <Button
            type={"submit"}
            className={` ${clsx({
              "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
            })}`}
          >
            Получить Данные
          </Button>
        </Modal.Footer>
      </form>
    </BlockUi>
      :
    <>
      <Modal.Header closeButton>
        <Modal.Title className={'d-flex justify-content-between gap-5'}>Результаты сверки {date}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={'d-flex justify-content-between align-items-center mb-4'}>
          <h3>Cохранить результаты данной сверки</h3>
          <SaveReviseItemsBut
            reviseType={options.type}
            data={data}
            startDate={startDate}
            endDate={endDate}
            cb={reload}
            files={files}
          />
        </div>
        {dataRender(options.type)}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Закрыть
        </Button>
        <SaveReviseItemsBut
          reviseType={options.type}
          data={data}
          startDate={startDate}
          endDate={endDate}
          cb={reload}
          files={files}
        />
      </Modal.Footer>

    </>}
    </>
  );
};

export default ReviseItemsCheck;