import React from 'react';
import {Portlet, PortletHeader} from "../../../../components/Portlet";
import CountryForm from "../components/countryForm";
import {connect} from "react-redux";
import {CreateItem} from "../../../../redux/actions/base";
import {CountryListRoute} from "../../../routesConstants";


const CountryCreatePage = ({CreateItem}) =>{

  const callback = (values, cb)=>{
    CreateItem('/api/locations/countries', values, cb, CountryListRoute )
  }
  return <>
    <div className={"row"}>
      <div className="col-xl-6">
        <Portlet>
          <PortletHeader title={"Создание страны"}/>
          <CountryForm onSubmit={callback}/>
        </Portlet>
      </div>
    </div>
  </>
}

export default connect(null, {CreateItem})(CountryCreatePage)