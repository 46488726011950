import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import _ from 'lodash'
import {Controller, useForm} from "react-hook-form";
import {connect} from "react-redux";
import {GetItem, StartProcess} from "../../../../redux/actions/base";

const FlagSelect = (props) =>{
  const [data, setData] = useState([])
  const [val, setVal] = useState(null)
  useEffect(()=>{
    props.StartProcess('/api/locations/countries', ({data})=>{
      setData(data.items)
      setVal(props.defaultValue.map(c=>{
        console.log('1233', _.find(data.items,{_id: c}))
        const find = _.find(data.items,{_id: c})
        return {...find, value: find._id, label: find.name, iso2: find.iso2.toLowerCase()}
      }))
    },{page:'0', itemsPerPage: '1250'})
  },[])
  const newArr = data.map(country=>{
    return {...country, label: country.name, value: country._id, iso2:country.iso2.toLowerCase()}
  })


  useEffect(()=>{},[val])

  const formatOptionLabel = ({ value, label, customAbbreviation ,iso2}) => (
    <div style={{ display: "flex" }}>
      <div style={{ marginRight: "10px", color: "red" }}>
        <img
          src={`https://flagcdn.com/16x12/${iso2}.png`}
          srcSet={`https://flagcdn.com/32x24/${iso2}.png 2x,https://flagcdn.com/48x36/${iso2}.png 3x`}
          width="16"
          height="12"
          alt={label}/>
      </div>
      <div>{label}</div>
    </div>
  );
  return <Controller
    control={props.control}
    name={props.prefix}
    render={({
               field: { onChange, onBlur, value, name, ref },
               fieldState: { invalid, isTouched, isDirty, error },
               formState,
             }) => (
      <Select
              isMulti
              value={val}
              options={newArr}
              onChange={(val)=> {
                setVal(val)
                onChange(val)
              }}
              formatOptionLabel={formatOptionLabel}/>

    )}
  />
}
export default connect(null,{StartProcess})(FlagSelect)