import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import {DocumentsListRoute} from "../../routesConstants";
import DocumentsForm from "./documentsForm";
import Loader from "../../../components/loader";

const EditModal = ({id,children}) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    if(id && show){
      axios.get('/api/documents/'+id)
        .then(({data})=>{
          console.log(data)
          setData({...data.document, photos: data.document.photos.map(l=>({url:l}))})
          setLoading(false)
        })
        .catch(sayError)
    }
  }, [id,show]);
  const onSubmit = (dataInfo) =>{
    setLoading(true)
    console.log('submit', dataInfo)
    let sendingInfo = {...dataInfo}
    sendingInfo.photos = dataInfo.photos.map(l=>(l.url))
    axios.patch('/api/documents/'+id, sendingInfo)
      .then(()=>{
        // navigate(DocumentsListRoute)
        handleClose()
        setData(null)
      })
      .catch(sayError)
      .finally(()=>setLoading(true))
  }

  return (
    <>
      <div onClick={handleShow} className={'link-info'} style={{cursor:'pointer'}}>{children}</div>
      <Modal show={show} dialogClassName="modal-90w" onHide={handleClose}>
        <Modal.Header closeButton>
          {/*<Modal.Title>Галерея</Modal.Title>*/}
        </Modal.Header>
        <Modal.Body >
          {data ?
            <DocumentsForm data={data}
                           onSubmit={onSubmit}
                           title={'Редактировать информацию по документу'}/>: <Loader/>
          }
        </Modal.Body>
      </Modal>

    </>
  );
};

export default EditModal;