import React from "react";

const RemoveLink = ({ onClick, title, noText, style }) => {
  const clickEvent = e => {
    e.preventDefault();
    let isOk = window.confirm(`Удалить этот ${title}?`);
    if (isOk) {
      onClick();
    }
  };
  return (
    <a onClick={clickEvent} href="#">
      <span className={"text-danger"} style={style ? style : null}>
        <i className={"fa fa-times"} />
      </span>
      {noText ? "" : ` Удалить ${title}`}
    </a>
  );
};
export default RemoveLink;
