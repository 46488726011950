import React, {useState} from 'react';
import {ApiServiseLogos} from "../../landing/components/ApiServiseLogos";
import {Modal} from "react-bootstrap";
import {Table} from "reactstrap";
import numberFormat from "../../../helpers/numberFormat";

const DiffTicketsPopUp = ({data, type}) => {
  const [show, setShow] = useState(false)
  const keys = Object.keys(data.data)
  const krsColor = () =>{
    if(type === 'gk'){
       return (data.data.google.krsBuyer - data.data.google.krsSeller) === data.data.kayar.krs
    } else if(type === 'ga'){
      return data.data.google.krsSeller === data.data.aruana.krs
    } else{
      return false
    }
  }
  // "table-danger"
  return (
    <>
      <div onClick={() => setShow(!show)} style={{cursor:'pointer', display:'flex', justifyContent:'center', alignItems:'center', fontSize:18}}>
        <i className="fa fa-ellipsis-v"/>
      </div>
      <Modal show={show} onHide={() => setShow(!show)}>
        <Modal.Header closeButton>
          <Modal.Title>Отличия</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped hover bordered>
            <thead>
              <tr>
                <td>Свойство</td>
                <td>{keys[0]}</td>
                <td>{keys[1]}</td>
              </tr>
            </thead>
            <tbody>
            <tr>
              <td>Номер билета</td>
              <td colSpan={2}>{data.ticketNumber}</td>
            </tr>
            <tr>
              <td>Дата</td>
              <td colSpan={2}>{data.date}</td>

            </tr>
            <tr>
              <td>Пассажир</td>
              <td colSpan={2}>{data.pax}</td>
            </tr>
            <tr>
              <td>Направление</td>
              <td colSpan={2}>{data.route}</td>
            </tr>
            <tr className={krsColor() ? '' : "table-danger"}>
              <td>Крс</td>
              <td>{keys[0] === 'google' ? numberFormat(data.data[keys[0]].krsBuyer) + '/' + numberFormat(data.data[keys[0]].krsSeller) : numberFormat(data.data[keys[0]].krs)}</td>
              <td>{keys[1] === 'google' ? numberFormat(data.data[keys[1]].krsBuyer) + '/' + numberFormat(data.data[keys[1]].krsSeller) : numberFormat(data.data[keys[1]].krs)}</td>
            </tr>
            <tr className={data.data[keys[0]].netto === data.data[keys[1]].netto ? '' : "table-danger"}>
              <td>Нетто</td>
              <td>{numberFormat(data.data[keys[0]].netto)}</td>
              <td>{numberFormat(data.data[keys[1]].netto)}</td>
            </tr>
            <tr className={data.data[keys[0]].fare === data.data[keys[1]].fare ? '' : "table-danger"}>
              <td>Тариф</td>
              <td>{numberFormat(data.data[keys[0]].fare)}</td>
              <td>{numberFormat(data.data[keys[1]].fare)}</td>
            </tr>
            <tr className={data.data[keys[0]].taxes === data.data[keys[1]].taxes ? '' : "table-danger"}>
              <td>Таксы</td>
              <td>{numberFormat(data.data[keys[0]].taxes)}</td>
              <td>{numberFormat(data.data[keys[1]].taxes)}</td>
            </tr>
            <tr className={data.data[keys[0]].penalties === data.data[keys[1]].penalties ? '' : "table-danger"}>
              <td>Штраф</td>
              <td>{numberFormat(data.data[keys[0]].penalties)}</td>
              <td>{numberFormat(data.data[keys[1]].penalties)}</td>
            </tr>
            <tr className={data.data[keys[0]].refund === data.data[keys[1]].refund ? '' : "table-danger"}>
              <td>Возврат</td>
              <td>{data.data[keys[0]].refund && 'B'}</td>
              <td>{data.data[keys[1]].refund && 'B'}</td>
            </tr>
            <tr className={data.data[keys[0]].total === data.data[keys[1]].total ? '' : "table-danger"}>
              <td>Итого</td>
              <td>{numberFormat(data.data[keys[0]].total)}</td>
              <td>{numberFormat(data.data[keys[1]].total)}</td>
            </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>

  );
};

export default DiffTicketsPopUp;