import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import _ from 'lodash'
import {Controller, useForm} from "react-hook-form";
import {banksLogo} from "./banksLogo";

const CardSelect = (props) =>{
  const [val, setVal] = useState({label: 'Все', value: undefined})
  const newArr = props.cards.map(card=>{
    return {...card, label: '....'+card.cardNumber+' '+card.user, value: card.id, bank: card.bank}
  })
  newArr.unshift({label: 'Все', value: undefined})
  useEffect(()=>{},[val])

  const formatOptionLabel = ({ value, label, customAbbreviation ,bank}) => (
    <div style={{ display: "flex", alignItems:"center" }}>
      <div style={{ marginRight: "10px", color: "red" }}>
        {banksLogo[bank] || ''}
      </div>
      <div>{label}</div>
    </div>
  );
  return <div style={{width: 300}}>
    <Select
      value={val}
      options={newArr}
      onChange={(val)=> {
        setVal(val)
        props.cb(val.value)
      }}
      formatOptionLabel={formatOptionLabel}/>
  </div>
}
export default CardSelect