import {Chart} from "react-chartjs-2";
import React, {useEffect, useState} from "react";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import MiniLoader from "./miniLoader";
import axios from "../../../helpers/axios";
import {Table} from "reactstrap";
import numberFormat from "../../../helpers/numberFormat";
import {Link} from "react-router-dom";
import BlockUi from "@availity/block-ui";

const ParseCheckSector =()=>{
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  useEffect(()=>{
    axios
      .post('/api/dashboard/parseChecks')
      .then(({data})=>{
      console.log('dataParse', data)
        setData(data)
        setLoading(false)
    })
  },[])
  let pieChartData;
  const color = [
    '#FFCD56FF',
    '#80FF56FF',
    '#FF6384FF',
  ]
  if(data){
    pieChartData = {
      labels: ['Обработаные', 'Обработаные и правильные', 'Необработанные'] || [],
      datasets: [{
        data: [data.handledAndInvalid, data.handledAndValid, data.unhandled] || [],
        label: "Всего",
        backgroundColor: color,
        hoverBackgroundColor: ['#b28f3c','#5bb73c', '#af465c']
      }]}
  }
  return <Portlet>
    <BlockUi blocking={loading}>
      <PortletHeader title={'Парс ошибок'} toolbar={
        <PortletHeaderToolbar>
          <Link to={'/parsers/check/list'}>
            К списку ошибок
            <i className="fa fa-share-square" style={{fontSize:"14px", marginLeft:'5px'}}/>
          </Link>
        </PortletHeaderToolbar>
      }/>
      <PortletBody>
        {data && <Table size="sm" responsive >
          <thead>
          <tr>
            <td align={'center'} rowSpan={3} >
              <div style={{width: 150, height: 150,}}>
                <Chart type='doughnut' data={pieChartData} options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}/>
              </div>
            </td>
            <th><i className="fa fa-circle" aria-hidden="true" style={{color:`${color[0]}`}}></i></th>
            <th> Обработаные и невалидные</th>
            <th>{numberFormat(data.handledAndInvalid)}</th>
          </tr>
          <tr>
            <th><i className="fa fa-circle" aria-hidden="true" style={{color:`${color[1]}`}}></i></th>
            <th> Обработаные и валидные</th>
            <th>{numberFormat(data.handledAndValid)}</th>
          </tr>
          <tr>
            <th><i className="fa fa-circle" aria-hidden="true" style={{color:`${color[2]}`}}></i></th>
            <th>  Необработанные</th>
            <th>{numberFormat(data.unhandled)}</th>
          </tr>
          </thead>
        </Table>}
      </PortletBody>
    </BlockUi>
  </Portlet>

}
export default ParseCheckSector