import { all, call, put, spawn } from "redux-saga/effects";
import authSagas from "./auth";
import baseSagas from "./base";
import miscSagas from "./misc";
import countrySaga from "../../modules/locations/countries/sagas";
import { HandleError } from "../actions/base";

export default function* rootSaga() {
  const sagas = [...authSagas, ...baseSagas, ...countrySaga, ...miscSagas];

  yield all(
    sagas.map(saga =>
      spawn(function*() {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log("final catch", e);
            if (e.response) {
              yield put(
                HandleError(
                  e.response.status,
                  e.message,
                  e.response.data.message
                )
              );
            }
          }
        }
      })
    )
  );
}
