import React from "react";
import { PortletBody, PortletFooter } from "../../../../components/Portlet";
import { Formik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import LoadingButton from "../../../base/components/loadingButton";
import * as yup from "yup";

const AirplaneForm = ({ data, onSubmit, className }) => {
  const validationSchema = yup.object().shape({
    name: yup.string().default("")
  });
  const initialValues = data ? data : validationSchema.cast();
  return (
    <div className={className}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          onSubmit(values, () => {
            setSubmitting(false);
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <form noValidate={true} onSubmit={handleSubmit}>
            <PortletBody>
              <Form.Group as={Row}>
                <Form.Label>
                  {data ? `Оригинальное название: ${data.originalName}` : null}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Название
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    className={
                      touched.name && errors.name ? "is-invalid" : null
                    }
                    required
                    type="text"
                  />
                </Col>
              </Form.Group>
            </PortletBody>
            <PortletFooter>
              <Row>
                <Col md={{ span: 9, offset: 3 }}>
                  <LoadingButton
                    title={"Сохранить"}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  />
                </Col>
              </Row>
            </PortletFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};
export default AirplaneForm;
