import React from 'react';
import {Table} from "react-bootstrap";
import _ from "lodash";
import {Link} from "react-router-dom";
import numberFormat from "../../../helpers/numberFormat";
import PaxTableItem from "./paxTableItem";

const ItemsTable = ({data, autoShow}) => {
  return (
    <div className={'mt-3'}>
      <Table striped bordered hover>
        <thead>
        <tr>
          <th width={350}>Номер</th>
          <th width={147}>Выписан</th>
          <th width={300}>Пассажир</th>
          <th>Сумма</th>
          <th>Покупатель</th>
        </tr>
        </thead>
        <tbody>
        {data.map((pax, index) => {
          const groupPaxes = _.groupBy(pax.tickets, (o) => (o.pax.toLowerCase()))

          return <React.Fragment key={index}>
            <tr>
              <th colSpan={5}>
                Имя в запросе — {pax.pax}
              </th>

            </tr>
            {Object.keys(groupPaxes).sort().map((key, index) => {
              return <React.Fragment key={index}>
                <PaxTableItem pax={groupPaxes[key]} autoShow={autoShow}/>
              </React.Fragment>
            })}
            {pax.tickets.length === 0 && <tr>
              <td colSpan={5}> В базе нет билетов по пассажиру</td>
            </tr>}
          </React.Fragment>
        })}
        </tbody>
      </Table>
    </div>
  );
};

export default ItemsTable;