const routePatternTestJson = {
  "airlineClassNames": {
    LH: "Lufthansa",
    AS: "Alaska Airlines"
  },
  "airplaneTranslations": {
    "333": "Airbus 333",
    "777": "Boeing 777"
  },
  "airportTranslations": {
    "ALA": {
      ru: {
        name: "Алматы",
        cityName: "Алматы"
      },
      eng: {
        name: "Almaty",
        cityName: "Almaty"
      },
    },
    "FRA": {
      ru: {
        name: "Международный",
        cityName: "Франкфурт"
      },
      eng: {
        name: "Frankfurt Intl",
        cityName: "Frankfurt"
      },
    },
    "TSE": {
      ru: {
        name: "Астана",
        cityName: "Астана"
      },
      eng: {
        name: "Astana",
        cityName: "Astana"
      },
    },
    "USJ": {
      ru: {
        name: "Лазурный Берег",
        cityName: "Ницца"
      },
      eng: {
        name: "Cote D Azur",
        cityName: "Nice"
      },
    }
  },
  convertedBooking: {
    "bookingNumber": "WURTT4",
    "issueDate": "2020-07-15T06:09:00.000Z",
    "supplier": "ALAKZ25W4",
    "info": ["Flight DV-745 06 Aug 2020 12:00 was not found (FlightStats)", "Flight DV-746 12 Aug 2020 14:00 was not found (FlightStats)"],
    "paxes": [
      {
        "firstName": "IRINA",
        "lastName": "MOTUZ",
        "title": "MRS",
        "id": 1
      }
    ],
    "routes": [
      [{
        "tempId": 2,
        "flightNumber": "745",
        "airline": "AS",
        "flightClass": "Y",
        "airplane": 333,
        "classType": "Economy",
        'connectionTime': 1000,
        flightTime: 10000,
        "arrival": {"date": "2020-08-06T13:00:00.000Z", "airport": "USJ", terminal: 3},
        "departure": {"date": "2020-08-06T12:00:00.000Z", "airport": "ALA"}
      },
        {
          "tempId": 3,
          "flightNumber": "746",
          "airline": "AS",
          "flightClass": "Y",
          "airplane": 333,
          "classType": "Economy",
          "arrival": {"date": "2020-08-06T17:00:00.000Z", "airport": "USJ", terminal: 3},
          "departure": {"date": "2020-08-06T16:00:00.000Z", "airport": "ALA"}
        }],
      [{
        "tempId": 3,
        "flightNumber": "746",
        "airline": "LH",
        "flightClass": "Y",
        "airplane": 777,
        "classType": "Economy",
        "arrival": {"date": "2020-08-12T15:00:00.000Z", "airport": "ALA", terminal: 2},
        "departure": {"date": "2020-08-12T14:00:00.000Z", "airport": "USJ", terminal: 'D'}
      }],
    ],
  },
  "pricing": {
    "currency": {
      "name": "Евро",
      "threeLetterCode": "EUR",
      "symbol": "€"
    },
    "adultEcomon": 100,
    "adultPremium": 170,
    "adultBusiness": 111,
    "adultFirst": 140,
    "youthEcomon": 100,
    "youthPremium": 160,
    "youthBusiness": 150,
    "youthFirst": 140,
    "infantEcomon": 50,
    "infantPremium": 100,
    "infantBusiness": 75,
    "infantFirst": 65
  },
};
export default {
  routePatternTestJson
}
