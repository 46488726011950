import React, {useState} from 'react';
import {Portlet, PortletHeader, PortletBody} from "../../../../components/Portlet";
import TemplateForm from "./templateForm";
import {Tab, Tabs} from "react-bootstrap";
import _ from 'lodash'

const VarInfoTable = ({template, setter}) => {


  const [tabIndex, setTabIndex] = useState("global");

  const titleClick= (data, field) => {
    setter(template[field]+ ' {' + data+ '}',field );
  }
  const renderTable = (data, field) => {
    return <table className={'table'}>
      <tbody>
      {_.chunk(Object.keys(data), 2).map((items, index) => {
        return <tr key={index}>
          {items.map((item)=> {
            return <>
              <td>
                {item.split(',').map((name, index) => <b key={index} onClick={() => titleClick(name,field)}>{'{'}{name}{'}'} <br/></b>)}
              </td>

              <td>{data[item]}</td>
            </>
          })}
        </tr>
      })}
      </tbody>
    </table>
  }
  const renderDatesTable = () => {
    return <table className={'table'}>
      <tbody>
      {_.chunk(Object.keys(datesData), 3).map((items, index) => {
        return <tr key={index}>
          {items.map((item)=> {
            return <>
              <td>
                <b key={index}>{item} <br/></b>
              </td>

              <td>{datesData[item]}</td>
            </>
          })}
        </tr>
      })}
      </tbody>
    </table>
  }
  return  <Portlet>
    <PortletHeader title={"Название и описание переменных"}/>
    <PortletBody>
      <Tabs
        variant="pills"
        id="controlled-tab-example"
        activeKey={tabIndex}
        onSelect={key => setTabIndex(key)}
      >
        <Tab eventKey="global" title="Глобальные">
          {renderTable(globalData, 'mainTemplate')}
          <p>
            <b onClick={() => titleClick('Trans RU="русский" EN="english"','mainTemplate' )}>{'{'}Trans RU="русский" EN="english"{'}'}</b>
          </p>
        </Tab>
        <Tab eventKey="segments" title="Сегменты">
          {renderTable(segmentData, 'segmentTemplate')}
        </Tab>
        <Tab eventKey="paxes" title="Пассажиры">
          {renderTable(paxData, 'paxTemplate')}
        </Tab>
        <Tab eventKey="prices" title="Цены">
          {renderTable(priceData, 'priceTemplate')}
          <p>
            <b>RAW</b> - Будет отображаться как указана ( по умолчанию такой формат - 10 000) <br/>
            <b>DS</b> - Разделитель десятых<br/>
            <b>TS</b> - Разделитель тысяч<br/>
            <b>D</b> - Кол-во десятых<br/>
            <b>FL</b> - округление вниз (кол-во символов)<br/>
            <b>RU</b> - округление вверх (кол-во символов)<br/>
            Пример<br/>
            {'{'}APB{'}'} - 81 123<br/>
            {'{'}APB DS="," TS=“-" D=“3” Fl=“3”{'}'} - 81-000,000
          </p>
        </Tab>
        <Tab eventKey="dates" title="Даты">
          <p>Пример: <b>DD MMM YYYY</b> - 14 Jul 2015</p>
          {renderDatesTable(datesData)}
        </Tab>
      </Tabs>
    </PortletBody>
  </Portlet>
}

const globalData = {
  'NUM': 'Номер бронирования',
  'PAX': 'Пассажиры',
  'SGM': 'Сегменты',
  'PNG': 'Цены',
  'CMT': 'Комментарий',
  'if=""}{/if': 'Условие',
  'NL': 'Новая строка',
  'EL': 'Пустая строка',
}

const segmentData = {
   FN: 'Номер рейса',
   AL: 'Перевозчик',
   'DD,DD="формат"': 'Дата вылета',
   DC: 'Код вылета',
   'AD,AD="формат"': 'Дата прилета',
   AC: 'Код прилета',
   DA: 'Город вылета',
   FT: 'Время в пути',
   AA: 'Город прилета',
   FC: 'Класс перелета',
   CT: 'Время пересадки',
   CTD: 'Время стыковки',
   I: 'Итерация',
   ALN: 'Название авиакомпании',
   DAA: 'Название аэропорта вылета',
   AAA: 'Название аэропорта прилета',
   DTR: 'Терминал вылета',
   ATR: 'Терминал прилета',
   APL: 'Самолет',
   ANC: 'Класс перелета (Текст)',
}

const paxData = {
  PFN: 'Имя',
  APR: 'Обращение',
  PLN: 'Фамилия',
  I: 'Итерация',
}

const priceData = {
  APE: 'Эконом класс взросый',
  IPE: 'Эконом класс младенец',
  APP: 'Премиум класс взрослый',
  IPP: 'Премиум класс младенец',
  APB: 'Бизнесс класс взрослый',
  IPB: 'Бизнесс младенец',
  APF: 'Первый класс взрослый',
  IPF: 'Первый младенец',
  YPE: 'Эконом класс подросток',
  CUR: 'Название валюты',
  YPP: 'Премиум класс подросток',
  CURS: 'Символ валюты',
  YPB: 'Бизнесс класс подросток',
  CURC: 'Код валюты',
  YPF: 'Первый класс подросток',
}

const datesData = {
  D: 'число(1)',
  M: 'месяц (7)',
  'h/hh': 'часы (12)',
  DD: 'число(01)',
  MM: 'месяц (07)',
  'H/HH': 'часы(24)',
  dd: 'число(Fr)',
  MMM: 'месяц(Jul)',
  'm/mm': 'минуты(15)',
  dddd: 'число(Friday)',
  MMMM: 'месяц(July)',
  'a/A': '(am/pm)',
  YY: 'год(15)',
  YYYY: 'год(2015)',
}
export default VarInfoTable;
