import React, {useState} from 'react';
import ContactsForm from "./contactsForm";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import BlockUi from "@availity/block-ui";

const tempData={
  "counterparties": [
    {id:''}
  ],
  "contacts": [
    {
      "name": "",
      "job": "",
      "description": "",
      "emails": [
      ],
      "phones": [
        {
          "phone": "",
          "comment": ""
        }
      ]
    }
  ]
}
const CreateContacts = ({closed}) => {
  const [loading, setLoading] = useState(false)
  const onSubmit = (data) =>{
    setLoading(true)
    axios.put('/api/counterparty/contacts',data)
      .then(()=>{
        closed()
      })
      .catch(sayError)
      .finally(()=>setLoading(false))
  }
  return (
    <BlockUi blocking={loading}>
      <ContactsForm data={tempData} onSubmit={onSubmit}/>
    </BlockUi>
  );
};

export default CreateContacts;