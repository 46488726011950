import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Table} from "reactstrap";
import {removeIgnore} from "../../../redux/reducers/tickets";

const TableBlackListName = ({cb}) => {
  const store = useSelector(state => state.tickets)
  const dispatch = useDispatch()
  const deleteName = (name) => {
    if(window.confirm(`Вы уверены что хотите удалить ${name}`)){
      dispatch(removeIgnore(name))
      cb()
    }
  }
  return (
    <Table striped hover bordered>
      <thead>
      <tr>
        <td>Фамилия/№ Билета</td>
        <td style={{width:50}}>Удалить</td>
      </tr>
      </thead>
      <tbody>
      {store.ignore.map((name, i)=>{
        return <tr key={i}>
          <td>{name}</td>
          <td style={{cursor:'pointer', color:'red'}} className={'d-flex justify-content-center'} onClick={()=>deleteName(name)}>
            <i class="fa fa-ban" aria-hidden="true"></i>
          </td>
        </tr>
      })}
      </tbody>

    </Table>
  );
};

export default TableBlackListName;