import React, {useEffect, useState} from "react";
import { Col, Row, Tab, Table, Tabs } from "react-bootstrap";
import numberFormat from "../../../helpers/numberFormat";
import moment from "moment";
import {showCount} from "../../../helpers/showCount";
import ReloadReviseButton from "./reloadReviseButton";
import CopyDownloadLink from "./CopyDownloadLink";
import LoadingButton from "../../base/components/loadingButton";
import ReviseDocumentWrapper from "./reviseDocumentWrapper";
import ReSaveReportButton from "./reSaveReportButton";
import _ from 'lodash'
import AruanaKayarRender from "./AruanaKayarRender";
import UpdateRevise from "./updateRevise";

export const renderRegisterName = (str) =>{
  if(str.includes('Каяра') || str.includes('Kayar')){
    return 'kayarFile'
  }else if(str.includes('Аруаны') || str.includes('Aruana')){
    return 'aruanaFile'
  }else if(str.includes('Iata')){
    return "iataFile"
  }else {
    return 'silkFile'
  }
}
export const targetWeek = (startDate) =>{
  if(startDate<8){
    return 1
  } else if(startDate>7 && startDate<16){
    return 2
  } else if(startDate>15 && startDate<24){
    return 3
  } else {
    return 4
  }
}

const AruanaKayarReviseRender = ({ revise }) => {
  const [data, setData] = useState(JSON.parse(revise.dataJson))
  const [canSend, setCanSend] = useState(false)

  const [sendFiles, setSendFiles] = useState(revise.documents.map((u, index) => {
    u.registerName = renderRegisterName(u.name)
    return u}))
  const options = {
    targetWeek: targetWeek(+moment(revise.startDate).format('D')),
    targetMonth: +moment(revise.startDate).format('M'),
    targetYear:+moment(revise.startDate).format('YYYY'),
    url: "/api/racoon/pdfParse/checkAruanaKayar",
    reviseId:revise.id
  }

  console.log("revise", revise);
  return (
    <>
      <ReviseDocumentWrapper revise={revise} withoutFils>
        <UpdateRevise cb={(data, file) => {
          setData(data)
          file ? setSendFiles([file]) : setSendFiles([])
          setCanSend(true)
        }}
                      title={'Обновить информацию Аруана-Каяр из 1с'}
                      revComment={revise.comment}
                      data={data}
                      options={options}/>

      </ReviseDocumentWrapper>
      <AruanaKayarRender data={data}/>
    </>
  );
};
export default AruanaKayarReviseRender;
