import React, {useState} from 'react';
import {Button, Col, Row} from "reactstrap";
import {Form} from "react-bootstrap";
import {useForm} from "react-hook-form";

const AddToReduxForm = ({onSubmit, label}) => {
  const [title, setTitle] = useState('')
  const { control,
    register,
    handleSubmit, reset
  } = useForm();
  const onSubmiting = (data) =>{
    onSubmit(data)
    setTitle(' Добавлено! Очистка через 3с...')
    setTimeout(()=>{
      reset()
      setTitle('')
    }, 3000)
  }
  return (
    <form onSubmit={handleSubmit(onSubmiting)} className={'d-flex justify-content-between align-items-end'}>
      <div style={{width:'100%'}}>
        <Form.Label>{label}{title}</Form.Label>
        <Form.Control
          {...register('lastName', {required: true})} placeholder={'Фамилия'}
        />
      </div>
      <Button
        type={"submit"}>
        Добавить
      </Button>
    </form>
  );
};

export default AddToReduxForm;