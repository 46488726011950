import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  DeleteItem,
  GetItem,
  UpdateItem
} from "../../../../redux/actions/base";
import { OfferListRoute, TripListRoute } from "../../../routesConstants";
import OfferForm from "../components/offerForm";
import axios from "../../../../helpers/axios";
import Loader from "../../../../components/loader";
import { useParams } from "react-router-dom";
import {useNavigate} from "react-router";

const OfferEditPage = props => {
  console.log("offer edit");
  const [offer, setOffer] = useState(null);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate()

  const { id } = useParams();
  const setNewOffer = tripModel => {
    setOffer(tripModel);
  };
  useEffect(() => {
    if (offer === null) {
      setLoader(true);
      axios.get("/api/trips/offer/"+id)
        .then(({data})=>{
          setOffer(data.data);
          setLoader(false);
        })
        .catch(e=>{
        console.log('err',e)
        window.alert(e.response.data.message[0])
          navigate(TripListRoute)
      })
      // props.GetItem("/api/trips/offer", id, res => {
      //   setOffer(res.data.data);
      //   setLoader(false);
      // });
    }
  }, []);
  const callback = (values, cb) => {
    axios
      .patch(`/api/trips/offer/${id}`, values)
      .then(()=>{
      cb()
        // window.location.reload()

    })
    // props.UpdateItem("/api/trips/offer/" + id, values, cb, OfferListRoute);
  };
  const deleteAction = () => {
    setLoader(true);
    props.DeleteItem("/api/trips/offer/" + id, () => {}, OfferListRoute);
  };
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <OfferForm
          tripSetter={setNewOffer}
          deleteAction={deleteAction}
          onSubmit={callback}
          data={offer}
          title={"Редактирование предложения"}
        />
      )}
    </>
  );
};

export default connect(null, { GetItem, UpdateItem, DeleteItem })(
  OfferEditPage
);
