import React from 'react'
import {PortletBody, PortletFooter} from "../../../components/Portlet";
import {Formik} from "formik";
import {Col, Form, Row} from "react-bootstrap";
import LoadingButton from "../../base/components/loadingButton";
import * as yup from "yup";

const CurrencyForm  = ({data, onSubmit, className, edit}) =>{
  const validationSchema = yup.object().shape({
    name: yup.string().required().default(""),
    nameEn: yup.string().default(""),
    code: yup.string().min(3).max(3).required().default(""),
    exchangeRate: yup.number().min(1).required().default(1),
  });
  const initialValues = data?data: validationSchema.cast()
  return <div className={className}>
    <Formik initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              onSubmit(values, () => {
                setSubmitting(false);
              })
            }}
    >
      {( {values,
           errors,
           touched,
           handleChange,
           handleSubmit,
           isSubmitting }) => (

        <form noValidate={true} onSubmit={handleSubmit}>

          <PortletBody>
            <Form.Group as={Row} >
              <Form.Label column sm="3">Название</Form.Label>
              <Col sm={9}>
                <Form.Control
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  className={touched.name && errors.name ? "is-invalid" : null}
                  required
                  type="text"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">Название на анг</Form.Label>
              <Col sm={9}>
                <Form.Control
                  name="nameEn"
                  value={values.nameEn}
                  onChange={handleChange}
                  className={touched.nameEn && errors.nameEn ? "is-invalid" : null}
                  required
                  type="text"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">Трехзначный код</Form.Label>
              <Col sm={9}>
                <Form.Control
                  name="code"
                  value={values.code}
                  onChange={handleChange}
                  className={touched.code && errors.code ? "is-invalid" : null}
                  required
                  type="text"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">Символ</Form.Label>
              <Col sm={9}>
                <Form.Control
                  name="symbol"
                  value={values.symbol}
                  type="text"
                  disabled = {edit}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">Курс к KZT</Form.Label>
              <Col sm={9}>
                <Form.Control
                  name="exchangeRate"
                  value={values.exchangeRate}
                  onChange={handleChange}
                  className={touched.exchangeRate && errors.exchangeRate ? "is-invalid" : null}
                  required
                  type="number"
                />
              </Col>
            </Form.Group>
          </PortletBody>
          <PortletFooter>
            <Row>
              <Col md={{ span: 9, offset: 3 }}>
                <LoadingButton title={"Сохранить"} disabled={isSubmitting} loading={isSubmitting}/>
              </Col>
            </Row>
          </PortletFooter>
        </form>
      )}
    </Formik>
  </div>
}
export default CurrencyForm;