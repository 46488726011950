import {createSlice} from "@reduxjs/toolkit";
import _ from 'lodash'

const initialState = {
  ignore:[],
  needToCheck:[]
}

const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers:{
    addIgnore: (state, action) => {
      state.needToCheck.splice(state.needToCheck.indexOf(action.payload), 1)
      state.ignore.push(action.payload)
    },
    removeIgnore: (state, action) => {
      const res = []
      state.ignore.forEach(function(elem) {
        if(elem !== action.payload) {
          res.push(elem)
        }
      });
      state.ignore = res
    },
    addCheck: (state, action) => {state.needToCheck.push(action.payload)},
    clearIgnore: (state, action) => {state.ignore = []},
    clearCheck: (state, action) => {state.needToCheck=[]}
  }
})
const {actions, reducer}= ticketsSlice
export default reducer
export const {
  addIgnore,
  removeIgnore,
  addCheck,
  clearCheck,
  clearIgnore

} = actions
