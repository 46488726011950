import React from 'react';
import {useFieldArray} from "react-hook-form";
import FormHookInput from "../../air/airBooking/components/formHookInput";


const emailTempData = {
  "email": "",
  "comment": ""
}
const EmailsFilds = ({formController, prefix, data}) => {
  const {control,register,formState:{errors}} = formController
  const contactsControl = useFieldArray({
    control: control,
    name: `${prefix}.emails`,
    // rules: {required: true},
    shouldUnregister: true,
    keyName: 'customId',
    defaultValue: data
  });
  const addCountr = ()=>{
    contactsControl.append(emailTempData)
  }
  const removeCountr = (index)=>{
    contactsControl.remove(index)
  }
  return (
    <div>
      <div className={'d-flex align-items-center gap-2 mb-3 p-2 pointer'}
           onClick={addCountr}
           style={{backgroundColor:'rgba(121,129,134,0.15)'}}>
        <h5 className={'mb-0'}>Emails:</h5>
        <i className={'fa fa-plus '} />
      </div>
      {contactsControl.fields.map((contact, index)=>{
        return <div key={index} className={'d-flex flex-column gap-3'}>
          <div className={'d-flex justify-content-between align-items-center'}>
            <div>Email №{index + 1} </div>
            <i className={'fa fa-trash pointer ml-2'} onClick={()=>removeCountr(index)}/>
          </div>
          <FormHookInput
            register={register(`${prefix}.emails[${index}].email}`, {required: true})}
            label={'Email'}
            type={'email'}
            defaultValue={contact.email}
            errors={errors}
          />
          <FormHookInput
            register={register(`${prefix}.emails[${index}].comment`)}
            label={'Комментарий'}
            defaultValue={contact.comment}
            errors={errors}
          />
          {contactsControl.fields.length >1 && index+1 !==contactsControl.fields.length && <hr/>}
        </div>
      })}
    </div>
  );
};

export default EmailsFilds;