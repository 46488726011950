import React, {useState} from 'react';
import {Portlet, PortletBody, PortletHeader} from "../../../components/Portlet";

const PotentialBuyers = ({potentialBuyers, setBuyer}) => {
  const [show, setShow] = useState(false)

  const renderCounterpartyInfo = (array) => {
    return array.counterparties.map((c,i)=>{
      return <div key={i} className={'d-flex gap-2'}>
        <div className={'link-info text-decoration-underline mb-2'}
             style={{cursor: 'pointer'}}
             onClick={() => {
               setBuyer(c.id)
             }}>{c.name}</div>
        — {c.percent} ({c.count})
      </div>
    })
  }
  return (
    <Portlet>
      <PortletHeader title={'Потенциальные покупатели'}/>
      <PortletBody>
        {potentialBuyers.map((pax,index)=>{
          return <div key={index}>
            {pax.exactMatch.counterparties.length > 0 ?
              <>Полное соответствие имени<br/>{pax.exactMatch.paxes[0]}<br/>
                <hr/>
                {renderCounterpartyInfo(pax.exactMatch)}
              </> : pax.sameLastName.counterparties.length !== 0 &&
              <div>Нет абсолютного соответствия — {pax.paxFullName}</div>}
            {pax.sameLastName.counterparties.length > 0 ? <>
              <div className={'link-info text-decoration-underline'}
                   onClick={() => setShow(!show)}
                   style={{cursor: 'pointer', display: show ? 'none' : 'block'}}>Показать соответствие только по фамилии
              </div>
              {show && <>
                <hr/>
                <div>Только по фамилии:</div>
                {pax.sameLastName.paxes.map((name, i) => {
                  return <div key={i}>{name}</div>
                })}
                <hr/>
                {renderCounterpartyInfo(pax.sameLastName)}
              </>}
            </> : pax.exactMatch.counterparties.length === 0 && <div>Нет никаких совпадений — {pax.paxFullName}
            </div>}
            {index !== potentialBuyers.length -1 && <hr/>}
          </div>
        })}
      </PortletBody>
    </Portlet>
  );
};

export default PotentialBuyers;