import React from "react";
import {Button} from "react-bootstrap";
import { connect } from 'react-redux';
import {StartProcess} from "../../../../../../redux/actions/base";
import axios from 'axios';
import ApiService from "../../../../../../services/apiService";

const AddImageButton = ({StartProcess}) => {
  const inputId = 'fileInput'+ Math.random()

  const onChange = async (e) => {
    const formData = new FormData();
    const files = e.target.files;
    if(files.length){
      formData.append('file', files[0]);
      const nameArray = files[0].name.split(".");
      nameArray.splice(nameArray.length - 1, 1);
      let object = {};
      formData.forEach(function(value, key){
        object[key] = value;
      });
      const resp = await ApiService.post('/api/misc/fileUpload',formData, undefined)


      //StartProcess('/api/misc/fileUpload',()=>{}, {formData: json});
      //scope.name = nameArray.join(".")
      console.log(resp);
    }
  }

  return <>
    <label for={inputId} className="btn btn-primary btn-sm">
      <i className={'fa fa-plus'}/>
    </label>
    <input id={inputId} type="file" onChange={async (e) => await onChange(e)} style={{display: 'none'}}/>
    {/*<FileUpload options={options} chooseFile>*/}
    {/*  <button ref="chooseBtn">choose</button>*/}
    {/*  <button ref="uploadBtn">upload</button>*/}
    {/*</FileUpload>*/}
  </>
}

export default connect(()=>{}, {StartProcess})(AddImageButton);
