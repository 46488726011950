import React, {useEffect} from 'react';
import {useFieldArray} from "react-hook-form";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import EmailsFilds from "./emailsFilds";
import PhonesFields from "./phonesFields";
import {Col, Row} from "react-bootstrap";

const ContactsFields = ({formController,data}) => {
  const {control,register, formState:{errors}} =formController
  const contactsControl = useFieldArray({
    control: formController.control,
    name: `contacts`,
    rules: {required: true},
    shouldUnregister: true,
    keyName: 'customId',
    defaultValue: data.contacts
  });
  useEffect(() => {
    contactsControl.replace(data.contacts)
  }, []);

  const addCountr = ()=>{
    contactsControl.append({id:''})
  }
  const removeCountr = (index)=>{
    contactsControl.remove(index)
  }

  return (
    <>
      <div className={'d-flex align-items-center gap-2 mb-3 p-2 pointer justify-content-between'}
           onClick={addCountr}
           style={{backgroundColor: 'rgba(121,129,134,0.15)'}}>
        <h5 className={'mb-0'}>Контакты:</h5>
        <i className={'fa fa-plus'} />
      </div>
      {contactsControl.fields.map((p, i) => {
        return <React.Fragment key={i}>
          <div className={'d-flex flex-column gap-3 mb-3'}>
            <div className={'d-flex justify-content-between align-items-center p-2'} style={{backgroundColor: 'rgba(121,129,134,0.39)'}}>
              <div>Контакт №{i + 1} </div>
              <i className={'fa fa-trash pointer ml-2'} onClick={() => removeCountr(i)}/>
            </div>
            <Row>
              <Col xs={8}>
                <FormHookInput
                register={register(`contacts[${i}].name`, {required: true})}
                label={'Имя'}
                defaultValue={p.name}
                errors={errors}
                />
              </Col>
              <Col>
                <FormHookInput
                  register={register(`contacts[${i}].job`, {required: true})}
                  label={'Должность'}
                  defaultValue={p.job}
                  errors={errors}
                />
              </Col>
            </Row>
            <FormHookInput
              register={register(`contacts[${i}].description`)}
              label={'Комментарий'}
              defaultValue={p.description}
              errors={errors}
            />
          </div>
          <Row className={'mb-2'}>
            <Col>
              <PhonesFields formController={formController}
                            prefix={`contacts[${i}]`}
                            data={p.phones}/>
            </Col>
            <Col>
              <EmailsFilds data={p.emails}
                           prefix={`contacts[${i}]`}
                           formController={formController}/>
            </Col>
          </Row>


        </React.Fragment>
      })}
    </>
  );
};

export default ContactsFields;