import { Route, Routes } from "react-router";
import CityList from "./pages/list";
import CityCreatePage from "./pages/create";
import CityEditPage from "./pages/edit";
import React from "react";

export default function() {
  return (
    <Routes>
      <Route path={"/"} exact={true} element={<CityList />} />
      <Route path={"create"} element={<CityCreatePage />} />
      <Route path={"edit/:id"} exact={true} element={<CityEditPage />} />
    </Routes>
  );
}
