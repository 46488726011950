import React, { useEffect, useState } from "react";
import { Card, CardHeader } from "@material-ui/core";
import {
  Row,
  Col,
  FormControl,
  FormLabel,
  Form,
  Button
} from "react-bootstrap";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText
} from "@material-ui/core";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import * as yup from "yup";
import FormRow from "../../../base/components/forms/formRow";
import CustomDatePicker from "../../../../components/customDatePicker";
import ApiSelect from "../../../base/components/forms/apiSelect";
import DescriptionsBlock from "../../components/descriptionsBlock";
import PaxesBlock from "../../components/paxesBlock";
import ImagesBlock from "../../components/imagesBlock";
import BlockUi from "@availity/block-ui";
import TripEtcForm from "../../components/tripEtc";
import TripTransferForm from "../../components/tripTransfer";
import TripHotelForm from "../../components/tripHotel";
import { useFieldArray, useForm } from "react-hook-form";
import RemoveLink from "../../../air/airBooking/components/removeLink";
import { TripItemListRoute, TripListRoute } from "../../../routesConstants";
import OfferMainData from "./offerMainData";
import SideBar from "./sideBar";
import TripGroup from "./tripGroup";
import SelectionList from "./selectionsList";
import _ from "lodash";
import {useNavigatingAway} from "../../../../helpers/useNavigatingAway";
import ModalLivingPage from "../../../../components/layout/ModalLivingPage";

const TripForm = ({ data, onSubmit, tripSetter, deleteAction }) => {
  const [currentTab, setCurrentTab] = useState("home");
  const [loading, setLoading] = useState(false);
  const formController = useForm({ defaultValues: data });
  const { handleSubmit,
    control,
    formState: {isDirty},
    reset } = formController;
  const [canShowModalLivingPage, setCanShowModalLivingPage] = useState(false)
  const [
    showDialogLeavingPage,
    confirmNavigation,
    cancelNavigation
  ] = useNavigatingAway(canShowModalLivingPage)
  useEffect(() => {
    reset(JSON.parse(JSON.stringify(data)));
  }, [data]);
  useEffect(()=>{
    if(isDirty){
      setCanShowModalLivingPage(true)
    }
  },[isDirty])

  const groupArray = useFieldArray({
    control,
    name: `groups`,
  });
  console.log('current', currentTab)
  const submitAction = data => {
    setCanShowModalLivingPage(false)
    console.log('loading', loading)
    setLoading(true);
    data.groups.forEach(group => {
      _.filter(group.items, { type: "hotel" }).forEach(
        u => (u.availableRooms = [])
      );
    });
    onSubmit(data, () => {
      setLoading(false);
    });
  };
  console.log("data", data);
  return (
    <>
      <ModalLivingPage showDialog={showDialogLeavingPage}
                       setShowDialog={setCanShowModalLivingPage}
                       confirmNavigation={confirmNavigation}
                       cancelNavigation={cancelNavigation}/>
      <Row>
        <form
          onSubmit={handleSubmit(submitAction, () =>
            alert("Ошибка при заполнении формы")
          )}
          autoComplete="off"
          className={"col-12"}
        >
          <BlockUi blocking={false} className={"row"}>
            <Col xs={3}>
              <SideBar
                groupArray={groupArray}
                tripSetter={tripSetter}
                formController={formController}
                selectedTab={currentTab}
                values={data}
                loading={loading}
                setter={setCurrentTab}
                type={"offers"}
              />
            </Col>
            <Col xs={9}>
              <OfferMainData
                formController={formController}
                values={data}
                deleteAction={deleteAction}
                activ={currentTab === `home`}
                className={currentTab === "home" ? "" : "hidden"}
              />

              {groupArray.fields.map((group, index) => {
                return (
                  <TripGroup
                    prefix={`groups[${index}]`}
                    index={index}
                    group={group}
                    okey={group.id}
                    key={group.id}
                    removeGroup={() => {
                      groupArray.remove(index);
                    }}
                    groupArray={groupArray}
                    className={`group${index}` === currentTab ? null : "hidden"}
                    formController={formController}
                    activ={currentTab === `group${index}`}
                  />
                );
              })}
              <SelectionList
                data={data}
                className={`selections` === currentTab ? null : "hidden"}
              />
            </Col>
          </BlockUi>
        </form>
      </Row>
    </>
  );
};

export default TripForm;
