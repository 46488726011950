import React, { useState } from "react";
import PaxesBlock from "../../components/paxesBlock";
import { Col, Form, FormLabel, Row } from "react-bootstrap";
import { useFieldArray } from "react-hook-form";
import TripTransferForm from "../../components/tripTransfer";
import TripEtcForm from "../../components/tripEtc";
import ApiSelect from "../../../base/components/forms/apiSelect";
import { ages, titles } from "../../../../helpers/enums";
import RemoveLink from "../../../air/airBooking/components/removeLink";
import PaxForm from "../../components/paxForm";
import moment from "moment";
import UsersBlock from "../../trip/components/tripFormParts/usersBlock";
import TicketDocumentsPopUp from "../../components/TicketDocumentsPopUp";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";

const TicketBlock = props => {
  const { formController, ticket } = props;
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    getValues,
    reset
  } = formController;
  const [selectedTicket, setSelectedTicket] = useState(
    ticket.selectedTicket || null
  );
  const selectedTicketCb = value => {
    setValue("pax.lastName", value.pax.lastName);
    setValue("pax.firstName", value.pax.firstName);
    setValue("pax.title", value.pax.title);
    setValue("item.ticketId", value._id);
    console.log(value._id);
    setSelectedTicket(value);
    console.log(value.pax.lastName);
  };
  console.log("ticket", ticket);
  return (
    <>
      {!selectedTicket ? (
        <>
          <Row>
            <Col xs={12}>
              <h5>Посик билета</h5>
              <ApiSelect
                value={selectedTicket}
                url={"/api/air/airBooking/tickets/tripSearch"}
                cb={selectedTicketCb}
              />
            </Col>
          </Row>
        </>
      ) : null}
      <Row>
        <Col xs={12}>
          <UsersBlock
            prefix={"item.visibleToAccounts"}
            formController={formController}
            type={`trips/${ticket.type}/`}
            ticket={ticket.ticketId}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h5>Пассажир</h5>
        </Col>
      </Row>
      <Row>
        <DndProvider backend={HTML5Backend}>
          <PaxForm
            pax={ticket.pax}
            formController={formController}
            prefix={"pax"}
          />
        </DndProvider>
      </Row>
      <Form.Label className={'d-flex align-items-center gap-2'}>
        <input
          defaultChecked={ticket.public}
          {...register(`item.public`)}
          type={"checkbox"}
        />{" "}
        Публичный
      </Form.Label>
      <br />
      <Row>
        <input
          type={"text"}
          {...register(`item._id`)}
          style={{ display: "none" }}
          value={ticket._id}
        />
        <input type={"hidden"} {...register(`item.type`)} value={"ticket"} />
        <input
          type={"text"}
          {...register(`item.ticketId`)}
          style={{ display: "none" }}
          value={ticket.ticketId}
        />
        {selectedTicket ? (
          <>
            <Col xs={12}>
              <h5>Выбранный билет</h5>
            </Col>
            <Col xs={3}>
              <b>
                <a
                  href={`/air/airBooking/edit/${selectedTicket.airBookingId}`}
                  target={"blank"}
                >
                  {selectedTicket.airlineNumber}-{selectedTicket.ticketNumber}
                </a>
              </b>{" "}
              <br />
              Выписан{" "}
              {moment
                .utc(selectedTicket.dateOfIssue, "DD.MM.YYYY HH:mm")
                .format("DD MMM YYYY")}{" "}
              <br />
              Вылет{" "}
              {moment.utc(selectedTicket.dateOfFlight, "DD.MM.YYYY HH:mm").format("DD MMM YYYY")}
            </Col>

            <Col xs={3}>
              {selectedTicket.route} <br />
              Цена {selectedTicket.price} {selectedTicket.currencyCode}
              <br />
            </Col>
            <Col xs={8}>
              <TicketDocumentsPopUp
                small
                prefix={`item.documents`}
                paxTicket={ticket}
                formController={formController}
              />
            </Col>
          </>
        ) : null}
      </Row>
    </>
  );
};

export default TicketBlock;
