import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import CustomDatePicker from "../../base/components/forms/customDatePicker";
import FormHookSelect from "../../air/airBooking/components/formHookSelect";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import {Portlet, PortletBody, PortletFooter, PortletHeader} from "../../../components/Portlet";
import LoadingButton from "../../base/components/loadingButton";
import {Col, Row} from "react-bootstrap";
import UploadDocuments from "../../touristServices/components/uploadDocuments";
import ImagesBlock from "../../trips/components/imagesBlock";
import _ from 'lodash'
import {Link} from "react-router-dom";
import Galery from "./galery";

const DocumentsForm = ({data, onSubmit, title}) => {
  const formController = useForm()
  const {handleSubmit,
    register,
    formState:{errors},
    setValue,
    getValues,
    watch} = formController
  const [onlyPDF, setOnlyPDF] = useState(_.filter(getValues().photos, (l)=>(l.url.includes('.pdf'))))

  useEffect(() => {
    setOnlyPDF(_.filter(getValues().photos, (l)=>(l.url.includes('.pdf'))))
  }, [watch('photos')]);
  console.log('only',onlyPDF, getValues())
  const deletePDF = (url) =>{
    if(window.confirm('Вы уверены что хотите удалить PDF')){
      const array = _.filter(getValues().photos, (l)=>(l.url !== url))
      setValue('photos', array)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col sm={6}>
          <Portlet>
            <PortletHeader title={title}/>
            <PortletBody>
              <Row className={'mb-3'}>
                <Col sm={4}>
                  <FormHookSelect
                    defaultValue={data.documentType}
                    register={register(`documentType`, {required: true})}
                    errors={errors}
                    label={'Вид документа'}
                  >
                    <option value={'identification'}>{'Удостоверение'}</option>
                    <option value={'passport'}>{'Паспорт'}</option>
                    <option value={'visa'}>{'Виза'}</option>
                  </FormHookSelect>
                </Col>
                <Col>
                  <FormHookInput
                    register={register(`documentNumber`, {required: true})}
                    label={'№ Документа'}
                    defaultValue={data.documentNumber}
                    errors={errors}
                  />
                </Col>
                <Col sm={3}>
                  <CustomDatePicker formController={formController}
                                    label={"Истекает"}
                                    value={data.expirationDate}
                                    name={`expirationDate`}
                                    rules={{required: true}}/>
                </Col>
              </Row>
              <div className={'d-flex flex-column gap-3'}>
                <FormHookInput
                  register={register(`lastName`, {
                    required: true,
                    onChange: e => {
                      e.target.value = ("" + e.target.value).toUpperCase();
                    }})}
                  label={'Фамилия'}
                  defaultValue={data.lastName}
                  errors={errors}
                />
                <FormHookInput
                  register={register(`firstName`, {
                    required: true,
                    onChange: e => {
                      e.target.value = ("" + e.target.value).toUpperCase();
                    }})}
                  label={'Имя'}
                  defaultValue={data.firstName}
                  errors={errors}
                />
                <FormHookInput
                  register={register(`middleName`)}
                  label={'Отчество'}
                  defaultValue={data.middleName}
                  errors={errors}
                />
              </div>
              <hr/>
              <h4 className={'mb-0'}>Для загрузки PDF или фото без коррекции</h4>
              <div>Можно загрузить несколько за раз</div>
              <UploadDocuments cb={(v) => {
                let newArray = [...getValues().photos]
                v.forEach(l => {
                  newArray.push({url: l.url})
                })
                setValue(`photos`, newArray)
              }}
                               docs={data.photos}
                               onlylink
                               folder={'serviceDocs'}/>
              <hr/>
              <h4 className={'mb-0 mt-4'}>Для загрузки фото с возможностью редактирования</h4>
              <div>Редактировать можно только в момент загрузки</div>
              <ImagesBlock
                formController={formController}
                prefix={`photos`}
                minLength={1}
                onlyLink
                linkCb={(l) => {
                  let newArray = [...getValues().photos]
                  newArray.push({url: l})
                  setValue(`photos`, newArray)
                }}
                photos={data.photos}
                active={true}
              />
            </PortletBody>
            <PortletFooter>
              <LoadingButton title={"Сохранить"} type={'submit'} loading={false}/>
            </PortletFooter>
          </Portlet>
        </Col>
        <Col>
          <Portlet>
            <PortletBody>
              {onlyPDF.length !== 0 && <><div>Загруженные PDF:</div>
              {onlyPDF.map((l, i) => {
                return <div key={i}> {i + 1}) <Link to={l.url} target={'_blank'}>{l.url}</Link>
                  <i className={"fa fa-trash ml-2"}
                     style={{cursor:'pointer'}}
                     onClick={()=>deletePDF(l.url)}/></div>
              })}
              <hr/></>}
              <div>Галерея фото(нажми на фото чтобы увеличить)</div>
              <Galery photos={_.filter(getValues().photos, (l)=>(!l.url.includes('.pdf')))}/>
            </PortletBody>
          </Portlet>
        </Col>
      </Row>
    </form>
  );
};

export default DocumentsForm;