import React, { useEffect, useState } from "react";
import { TripItemCreateRoute, TripEditRoute } from "../../../routesConstants";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import { Link } from "react-router-dom";
import { Tabs, Tab, Dropdown } from "react-bootstrap";
import DataTable from "../../../../components/dataTable";
import PortletHeaderSearch from "../../../base/components/forms/portlerHeaderSearch";
import HotelsTab from "../components/tabs/hotels";
import {
  LoadList,
  StartProcess,
  DeleteItem
} from "../../../../redux/actions/base";
import { connect } from "react-redux";
import TransfersTab from "../components/tabs/transfers";
import EtcsTab from "../components/tabs/etcs";
import TicketsTab from "../components/tabs/tickets";

const TripItemListPage = props => {
  const [query, setQuery] = useState({
    name:''
  });
  const [key, setKey] = useState("hotel");
  const [loading, setLoading] = useState(false);
  // const [items, setItems] = useState();
  // useEffect(() => {
  //   setLoading(true);
  //   props.LoadList("/api/trips/item/", 0, 1000, null, res => {
  //     console.log("res", res);
  //     setItems(res.data);
  //     setLoading(false);
  //   });
  // }, [props]);
  // const remove = id => {
  //   setLoading(true);
  //   props.DeleteItem(`/api/trips/item/${id}`, () => {
  //     props.LoadList("/api/trips/item/", 0, 1000, null, res => {
  //       setItems(res.data);
  //       setLoading(false);
  //     });
  //   });
  // };
  const removeSelfFromAllItems = () => {
    setLoading(true);
    const cb = res => {
      setLoading(false);
      if (res.data.success) {
        window.alert("Все прошло успешно");
      }
    };
    props.StartProcess("/api/trips/removeSelfFromAllTripItems", cb);
  };

  return (
    <>
      <div className={"kt-grid__item"}>
        <Portlet>
          <PortletHeader
            title={"Список элементов"}
            toolbar={
              <PortletHeaderToolbar>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="default"
                    className="btn-sm"
                    id="dropdown-basic"
                  >
                    Действия
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={removeSelfFromAllItems}>
                      Удалить себя из всех элементов
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {"\u00A0"}
                {/*<PortletHeaderSearch value={query.name} cb={(v)=>{setQuery({name:v})}}/>*/}
                <Link
                  to={TripItemCreateRoute + `?type=${key}`}
                  className="btn btn-sm btn-brand btn-elevate btn-icon-sm"
                >
                  <i className="la la-plus" />
                  Добавить
                </Link>
              </PortletHeaderToolbar>
            }
          />
          <PortletBody>
            <Tabs
              activeKey={key}
              onSelect={k => setKey(k)}
              id="uncontrolled-tab-example"
            >
              <Tab eventKey="hotel" title="Отели">
                <HotelsTab />
              </Tab>
              <Tab eventKey="ticket" title="Перелеты">
                <TicketsTab />
              </Tab>
              <Tab eventKey="transfer" title="Трансферы">
                <TransfersTab />
              </Tab>
              <Tab eventKey="etc" title="Разное">
                <EtcsTab />
              </Tab>
            </Tabs>
          </PortletBody>
        </Portlet>
      </div>
    </>
  );
};

export default connect(() => ({}), { LoadList, StartProcess, DeleteItem })(
  TripItemListPage
);
