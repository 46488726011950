import moment from 'moment'
import 'moment/locale/ru';
import {
  convertDate,
  replaceIfs,
  takeParams,
  getMapNames,
  getNormalValue,
  convertTrans,
  convertBase
} from '../../baseParser';

const getLongestCityName = (booking, language) => {
  let longestCityName = 0;
  for (var iata in booking.airportTranslations) {
    const item = booking.airportTranslations[iata];
    const lengthd = item[language].cityName;
    if (lengthd && lengthd.length > longestCityName) {
      longestCityName = lengthd.length;
    }
  }
  return longestCityName;
}

const formatAirBooking = (template,language, booking, params) => {
  const  br = "\n";

  const patternMap = {
    "NUM": (booking) =>booking.bookingNumber,
    "PNG": (booking) =>convertPricing(booking),
    "CMT": () => params.comment,
    "PAX": (booking)  => {
      let paxes = " ";
      booking.paxes.forEach((pax, index) => {
        paxes += convertPaxes(pax, index);
        paxes += br;
      });
      return paxes;
    },
    "SGM": function(booking) {
      let segmentResult = "";
      console.log('booking.routes',booking )
      booking.routes.forEach((route) => {
        route.forEach((segment, segIndex) => {
          // if (moment().utc().isBefore(moment(segment.departure.date).utc()) && params.flown) {
            segmentResult += convertSegment(segment, segIndex);
            //segmentResult += br;
          //}
        })
      });
      return segmentResult;
    },
  }
  const paxesMap = {
    "PFN":  (pax) => pax.firstName,
    "PLN": (pax) => pax.lastName,
    "APR": (pax) => pax.title,
    "I": (pax, params, i) => i+1
  }
  const segmentMap = {
    "ANC": function (segment) {
      let result = '';
      if (language === "ru") {
        switch (segment.classType) {
          case('Economy'): result = 'Эконом'; break;
          case('Premium'): result = 'Премиум'; break;
          case('Business'): result = 'Бизнес'; break;
          case('First'): result = 'Первый'; break;
        }
      } else {
        return segment.classType;
      }
    },
    "DAA": (segment) => alignNames(booking.airportTranslations[segment.departure.airport][language].name),
    "AAA": (segment) => alignNames(booking.airportTranslations[segment.arrival.airport][language].name),
    "DD": (segment,params) => convertDate(segment, params, segment.departure.date + " " + segment.departure.time,language),
    "AD": (segment,params) => convertDate(segment, params, segment.arrival.date + " " + segment.arrival.time,language),
    "ALN": (segment) => booking.airlineClassNames[segment.airline],
    "AL": (segment) => segment.airline,
    "FC": (segment) => segment.flightClass,
    "I": (segment, params, i) => i + 1,
    "CTD": (segment) => segment.dockingTime,
    "APL": (segment) => booking.airplaneTranslations[segment.airplane],
    "CT": (segment) => segment.connectionTime,
    "DC": (segment) => segment.departure.airport,
    "DTR": (segment) => segment.departure.terminal,
    "ATR": (segment) => segment.arrival.terminal,
    "FN": (segment) => segment.flightNumber,
    "FT": (segment) => segment.flightTime,
    "AA": (segment) => booking.airportTranslations[segment.arrival.airport][language].cityName,
    "AC": (segment) => segment.arrival.airport,
    "DA": (segment) => booking.airportTranslations[segment.departure.airport][language].cityName,

  }
  const priceMap = {
    "APE": (pricing, params) => convertPprice(pricing.adultEcomon, params),
    "APP": (pricing, params) => convertPprice(pricing.adultPremium, params),
    "APB": (pricing, params) => convertPprice(pricing.adultBusiness, params),
    "APF": (pricing, params) => convertPprice(pricing.adultFirst, params),
    "YPE": (pricing, params) => convertPprice(pricing.youthEcomon, params),
    "YPP": (pricing, params) => convertPprice(pricing.youthPremium, params),
    "YPB": (pricing, params) => convertPprice(pricing.youthBusiness, params),
    "YPF": (pricing, params) => convertPprice(pricing.youthFirst, params),
    "IPE": (pricing, params) => convertPprice(pricing.infantEcomon, params),
    "IPP": (pricing, params) => convertPprice(pricing.infantPremium, params),
    "IPB": (pricing, params) => convertPprice(pricing.infantBusiness, params),
    "IPF": (pricing, params) => convertPprice(pricing.infantFirst, params),
    "CURC": (pricing) => pricing.currency.threeLetterCode,
    "CURS": (pricing) => pricing.currency.symbol,
    "CUR": (pricing) => pricing.currency.name,
  }

  let longestCityName = getLongestCityName(booking, language);
  const convertSegment = function (segment, iterationSgm) {
    var iti = template.segmentTemplate;
    if (iti != null) {
      iti = replaceIfs(iti, segment, segmentMap);

      var snaps = takeParams(iti, getMapNames(segmentMap));
      for (var j = 0; j < snaps.length; j++) {
        console.log('snaps[j]', snaps[j])
        iti = iti.replace(snaps[j].capture, getNormalValue(segmentMap[snaps[j].code](segment, snaps[j],iterationSgm)));
      }
    } else {
      iti = "";
    }

    iti = iti.replace(/^\s+|\s+$/g, '').replace(/\n\s*\n/g, '\n');
    return iti;
  }
  const convertPprice = function(price, params) {
    var returnPrice = parseFloat(price, 10);

    if ("FL" in params) {
      var num = "1";
      for (var i = 1; i < parseInt(params.FL); i++) {
        num += "0";
      }
      returnPrice = Math.floor(returnPrice / parseInt(num)) * parseInt(num);
    }
    if ("RU" in params) {
      var num = "1";
      for (var i = 1; i < parseInt(params.RU); i++) {
        num += "0";
      }
      returnPrice = Math.ceil(returnPrice / parseInt(num)) * parseInt(num);
    }
    var PriceParams = {
      "DS": "DS" in params ? params.DS : "",
      "TS": "TS" in params ? params.TS : " ",
      "D": "D" in params ? parseInt(params.D) : 0
    };
    if ("RAW" in params) {
    } else {
      returnPrice = returnPrice.toMoney(PriceParams.D, PriceParams.DS, PriceParams.TS);
    }
    // fl ru

    return returnPrice;
  };
  const convertPricing = function(pattern, booking) {
    var pricing = pattern.priceTemplate;
    var snaps = takeParams(pricing, getMapNames(priceMap));
    for (var j = 0; j < snaps.length; j++) {
      pricing = pricing.replace(snaps[j].capture, priceMap[snaps[j].code](booking.Pricing, snaps[j].settings));
    }
    return pricing;
  }
  const convertPaxes = function(paxes, iteration) {
    var pax = template.paxTemplate;
    if (pax != null) {
      pax = replaceIfs(pax, paxes, paxesMap, language);
      var snaps = takeParams(pax, getMapNames(paxesMap));
      for (var j = 0; j < snaps.length; j++) {
        pax = pax.replace(snaps[j].capture, getNormalValue(paxesMap[snaps[j].code](paxes, snaps[j], iteration)));
      }

    } else {
      pax = "";
    }

    pax = pax.replace(/^\s+|\s+$/g, '').replace(/\n\s*\n/g, '\n');
    return pax;
  };

  const alignNames = function (name) {
    if (template.alignCities) {
      for (var i = name.length; i < longestCityName; i++) {
        name += " ";
      }
    }
    return name;
  };
  const result = convertBase( template.mainTemplate, booking.convertedBooking,patternMap, language)
  return result;
}

export default formatAirBooking;
