import React, {useEffect, useState} from 'react';
import axios from "../../../helpers/axios";
import {Portlet, PortletBody} from "../../../components/Portlet";
import SearchSelect from "../../../components/searchSelect";
import ApiSelect from "../../base/components/forms/apiSelect";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import {Form} from "react-bootstrap";
import DateToFromPicker from "./dateToFromPicker";
import Loader from "../../../components/loader";
import BlockUi from "@availity/block-ui";
import {all} from "axios";

const ListOptions = ({query, setQuery, all, setAll}) => {
  useEffect(() => {

  }, [query]);
  const [options, setOptions]=useState({
    types:null,
    issuedBy:null,
    buyer:null,
    supplier:null
  })
  useEffect(() => {
    axios.post('/api/touristServices/typesSelect')
      .then(({data})=>{
        setOptions(o=>({...o,types: data.data}))
      })
      .catch(e=>{
        console.log('err',e)
        window.alert(e.response.data.message[0])
      })
    axios.post('/api/users/emplSearch',{operatorOneS:true})
      .then(({data})=>{
        setOptions(o=>({...o,issuedBy: data.data.map(e=>({label:e.label, value:e.value._id}))}))
      })
      .catch(e=>{
        console.log('err',e)
        window.alert(e.response.data.message[0])
      })
    axios.post('/api/counterparty/searchInput', process.env.REACT_APP_COUNTERPARTIES_FROM_NEWBASE === "true" ? {fromNewBase: true} : {})
      .then(({data})=>{
        console.log('bya', data)
        setOptions(o=>({...o,buyer: data.data, supplier: data.data}))
      })
      .catch(e=>{
        console.log('err',e)
        window.alert(e.response.data.message[0])
      })
  }, []);
  return (
    <BlockUi blocking={!options.types || !options.issuedBy || !options.buyer}><Portlet style={{minHeight : "139.78px"}}>
      <PortletBody>
        {(options.types && options.issuedBy && options.buyer) && <>
        <div className={'d-flex gap-2 mb-3'}>
          <div style={{position: 'relative', width: 200}}>
            <label className={'absolutLabel'}>Имя</label>
            <Form.Control
              name={'search'}
              defaultValue={query.pax}
              onChange={(e) => setQuery(q => ({...q, pax: e.target.value}))}
              className={'form-control'}
              type={'text'}
              placeholder="Поиск..."
            />
          </div>
          <div style={{width: 200}}>
            <SearchSelect
              onChange={(v) => {
                setQuery(q => ({...q, serviceType: v}))
              }}
              defaultValue={query.serviceType}
              label={'Тип услуг'}
              array={[{value: '', label: 'Все услуги'}, ...options.types]}/>
          </div>
          <div style={{width: 200}}>
            <SearchSelect
              onChange={v => {
                console.log('v', v)
                setQuery(q => ({...q, buyer: v}))
              }}
              defaultValue={query.buyer}
              label={'Покупатель'}
              array={[{value: '', label: 'Все'}, ...options.buyer]}/>
          </div>
          <DateToFromPicker label={'Дата выписки'} setOptions={(v)=>{
            setQuery(q=>({...q, issueDate: v}))
          }}/>
          <DateToFromPicker label={'Дата услуг'} setOptions={(v)=>{
            setQuery(q=>({...q, execDate: v}))
          }}/>
          <div style={{width: 100}}>
            <SearchSelect
              onChange={(v) => {
                setQuery(q => ({...q, paid: v}))
              }}
              defaultValue={query.paid}
              label={"Оплачено"}
              array={[{value: '', label: 'Все'}, {value: false, label: 'Нет'}, {
                value: true,
                label: 'Да'
              }]}/>
          </div>
        </div>
        <div className={'d-flex gap-2'}>
          <div style={{width: 200}}>
            <SearchSelect
              onChange={(v) => {
                setQuery(q => ({...q, issuedBy: v}))
              }}
              defaultValue={query.issuedBy}
              label={'Выписал'}
              array={[{value: '', label: 'Все'}, ...options.issuedBy]}/>
          </div>

          <div style={{width: 200}}>
            <SearchSelect
              onChange={(v) => {
                setQuery(q => ({...q, issuedWithoutDocs: v}))
              }}
              defaultValue={query.issuedWithoutDocs}
              label={"Документы"}
              array={[{value: '', label: 'Все'}, {value: false, label: 'C документами'}, {
                value: true,
                label: 'Без документов'
              }]}/>
          </div>
          <div style={{width: 200}}>
            <SearchSelect
              onChange={v => {
                console.log('v', v)
                setQuery(q => ({...q, supplier: v}))
              }}
              defaultValue={query.supplier}
              label={'Поставщик'}
              array={[{value: '', label: 'Все'}, ...options.supplier]}/>
          </div>
          <div style={{position: 'relative', width: 200}}>
            <label className={'absolutLabel'}>Индивидуальный код</label>
            <Form.Control
              name={'idInOneC'}
              defaultValue={query.idInOneC}
              //value={value}
              onChange={(e) => setQuery(q => ({...q, idInOneC: e.target.value}))}
              className={'form-control'}
              type={'text'}
              placeholder="Поиск..."
            />
          </div>
          <Form.Label className={'d-flex align-items-center gap-2 m-2'}>
            <input
              defaultChecked={query.deleted}
              onChange={(e) => {
                setQuery(q => ({...q, deleted: e.target.checked}))
              }}
              type={"checkbox"}
            />{" "}
            Удаленные
          </Form.Label>
          <Form.Label className={'d-flex align-items-center gap-2 m-2'}>
            <input
              defaultChecked={all}
              onChange={(e) => {
                setAll(!all)
              }}
              type={"checkbox"}
            />{" "}
            Все
          </Form.Label>
        </div></>}
      </PortletBody>
    </Portlet>
    </BlockUi>
  );
};

export default ListOptions;