import React, { useState, useEffect } from "react";
import PhotoUploadButton from "../../../../base/components/photoUploadButton";
import { FormLabel } from "react-bootstrap";
import RemoveLink from "../../../../air/airBooking/components/removeLink";
import { deep_value } from "../../../../../helpers/formHelpers";
import { useController } from "react-hook-form";
import PhotosSelector from "../../../components/PhotosSelector";

const PhotoInput = ({ title, url, name, formController, pdf }) => {
  const {
    register,
    control,
    formState: { errors }
  } = formController;
  const {
    field: { onChange, value }
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue: url
  });
  const isPhoto = value && value.match(/\.(jpeg|jpg|gif|png)$/) != null;

  return (
    <>
      <FormLabel>{title}</FormLabel> <br />
      {value ? (
        <>
          {isPhoto ? (
            <img
              src={value}
              style={{ width: 38, height: 38, borderRadius: 19 }}
            />
          ) : (
            <>
              <a href={value} target={"_blank"}>
                Файл выбран
              </a>{" "}
              <br />
            </>
          )}

          <RemoveLink title={title} onClick={() => onChange(null)} />
        </>
      ) : (
        <PhotosSelector cb={url => onChange(url)} />
      )}
      {/*<input*/}
      {/*  type={"hidden"}*/}
      {/*  {...register(name, { required: true, minLength: 2 })}*/}
      {/*  defaultValue={value}*/}
      {/*/>*/}
      {deep_value(errors, name) ? (
        <p className={"text-danger"}>Не выбран файл</p>
      ) : null}
    </>
  );
};

export default PhotoInput;
