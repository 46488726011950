import React, {useEffect, useState} from 'react';
import _ from 'lodash'
import {Col, Row, Table} from "reactstrap";
import {Tab, Tabs} from "react-bootstrap";
import numberFormat from "../../../helpers/numberFormat";
import DiffTicketsPopUp from "./diffTicketsPopUp";
import {useDispatch, useSelector} from "react-redux";
import AddLastNameButton from "./addLastNameButton";
import TableBlackListName from "./tableBlackListName";
import {addIgnore, addCheck, clearCheck, clearIgnore} from "../../../redux/reducers/tickets";
import { Button } from "react-bootstrap"
import moment from "moment";

const condition = (item, second, type) =>{
  if(item.refund){
    return item.total === second.total
  } else if(type === 'gk'){
    return (item.netto + (item.krsBuyer - item.krsSeller)) === second.total
  } else if(type === 'ga'){
    return (item.total - item.krsBuyer) === (second.total - second.krs)
  } else {
    return (item.total - item.krs) === (second.total - second.krs)
  }
}
const diffObject = (item) =>{
  const object = {
    fare: item.fare,
    taxes: item.taxes,
    penalties: item.penalties,
    netto: item.netto,
    total: item.total,
    refund: item.refund
  }
  if(item.type==='google'){
    object.krsBuyer = item.krsBuyer
    object.krsSeller = item.krsSeller
  }else {
    object.krs = item.krs
  }
  return object
}


const RenderRacoonTickets = ({data, type}) => {
  const store = useSelector(state => state.tickets)
  const dispatch = useDispatch()
  const [needData, setData] = useState(data)
  const keys = Object.keys(data)
  const [sorting, setSorting] = useState()
  const [sortBy, setSortBy] = useState({
    date: {acd:true,active: true},
    pax: {acd:false, active: false}
  })
  const [refetch, setRefetch]=useState(Math.random())
  console.log(store)

  const renderList = () =>{
    if(needData){
      setSorting(null)
      const keys = Object.keys(needData)
      const sortingData =  {
        correct:[],
        diff:[],
        needCheck:[],
        ignore:[],

      }
      const second = needData[keys[1]].result.slice()
      keys.map(key => {
        sortingData[`no${key}`] = []
      })
      needData[keys[0]].result.forEach(item=>{
        const ticket = _.find(second, {ticketNumber:item.ticketNumber, refund:item.refund})
        if(ticket){
          if(condition(item, ticket, type)){
            sortingData.correct.push(ticket)
          } else {
            sortingData.diff.push({
              ticketNumber:item.ticketNumber,
              pax:item.pax,
              date: item.issueDate,
              route:item.route,
              refund: item.refund,
              data:{
                [item.type] :diffObject(item),
                [ticket.type] : diffObject(ticket),
              }
            })
          }
          second.splice(second.indexOf(ticket), 1)
        } else {
          sortingData[`no${keys[1]}`].push(item)
        }
      })
      sortingData[`no${keys[0]}`] = second

      sortingData.diff.reduceRight(function (previousValue, t, index, array) {
        const neededInfo = {number: t.refund ?  t.ticketNumber+'-B' : t.ticketNumber, pax: t.pax}
        const ignoric = _.find(store.ignore, function (o){
          return neededInfo.number === o || neededInfo.pax.indexOf(o) !== -1
        })
        const needCheck =  _.find(store.needToCheck, function (o){
          return neededInfo.number === o || neededInfo.pax.indexOf(o) !== -1
        })
        if(ignoric){
          sortingData.ignore.push(t)
          array.splice(index, 1);
        } else if(needCheck){
          sortingData.needCheck.push(t)
          array.splice(index, 1);
        }
      });
      setSorting(sortingData)
    }
  }
  useEffect(() => {
    renderList()
  }, [refetch]);

  const sortingTitle = (title, type) => {
    return <div className={'d-flex justify-content-between'} onClick={() => {
      setSortBy(n=>{
        if(type === 'date'){
          return {
            date: {acd:!n.date.acd,active: true},
            pax: {acd:false, active: false}
          }
        } else {
          return {
            date: {acd:false, active: false},
            pax: {acd:!n.pax.acd,active: true}
          }
        }
      })
    }}>
      {title}
      <div>
        {
          sortBy[type].acd && sortBy[type].active ? <i className="fa fa-arrow-down" /> : sortBy[type].active ?
            <i className="fa fa-arrow-up" /> :
            <div style={{opacity:0.3}}>
              <i className="fa fa-arrow-down" />
              <i className="fa fa-arrow-up" />
            </div>
        }

      </div>
    </div>
  }
  useEffect(() => {
    if(sorting){
      const keys = Object.keys(sortBy)
      let array;
      keys.forEach((key)=>{
        if(sortBy[key].active){
          if(key === 'date'){
            console.log('date')
            array = sorting.diff.sort(function(a, b) {
              const c = new Date(moment(a.date,'DD.MM.YYYY'));
              const d = new Date(moment(b.date, 'DD.MM.YYYY'));
              console.log('c', c)

              return c-d;
            });
            console.log('array', array)
          } else {
            console.log('pax')
            array = sorting.diff.sort((a, b) => a.pax.localeCompare(b.pax))
          }
          if(!sortBy[key].acd){array.reverse()}
          setSorting(n => ({...n, diff:array}))
        }
      })
    }
  }, [sortBy]);

  console.log('sortingData', sorting)
  console.log('Data', data)



  return (
    <>
      {sorting && <Row>
        <div className={'d-flex justify-content-between align-items-center mb-4'}>
          <div>
            {Object.keys(needData).map((key, i) => {
              return <div>Всего билетов в {key}: {numberFormat(needData[key].result.length)}</div>
            })}
          </div>
          <div>
            <Button
              variant="outline-secondary"
              style={{marginRight:4}}
              className="text-center"
              onClick={() => {
                if(window.confirm('Вы уверены, что хотите очистить весь список проверок?')){
                  dispatch(clearCheck())
                  setRefetch(n=>Math.random()+n)
                }
              }}
            >Очистить "Нужно проверить"</Button>
            <Button
              variant="outline-secondary"
              className="text-center"
              onClick={() => {
                if(window.confirm('Вы уверены, что хотите очистить весь список игнорируемых?')){
                  dispatch(clearIgnore())
                  setRefetch(n=>Math.random()+n)
                }
              }}
            >Очистить "Игнорируемые"</Button>
          </div>

        </div>

        <Col>
          <Tabs defaultActiveKey="correct">
            {Object.keys(sorting).map((key, i) => {
              const tabsTitle = {
                correct: 'Правильные',
                diff: 'Отличаются',
                nogoogle: 'Нет в Google Doc',
                nokayar: 'Нет в 1C Kayar',
                noaruana: 'Нет в 1C Aruana',
                ignore: 'Игнорируемые',
                needCheck: 'Нужно проверить'
              }
              return <Tab key={i}
                          eventKey={key}
                          title={`${tabsTitle[key]} (${sorting[key].length})`}
              >
                <Table striped hover bordered>
                  <thead>
                  {tableHeader(key, type, sortingTitle)}
                  </thead>
                  <tbody>
                  {sorting[key].map((t,i)=>{
                    if(key !== 'diff' && key !=='ignore' && key !== 'needCheck'){
                      return <tr key={i}>
                        <td>{t.ticketNumber}{t.refund && '-B'}</td>
                        <td>{t.issueDate}</td>
                        <td>
                          {t.pax}<br/>
                          {t.route}
                        </td>
                        <td>{t.type === 'google' ? `${numberFormat(t.krsBuyer)}/${numberFormat(t.krsSeller)}` : numberFormat(t.krs)}</td>
                        <td>{t.total}</td>

                      </tr>
                    }else{
                      let keys =  Object.keys(t.data)
                      if(sorting[key].length>0){
                        const str = t.refund ? t.ticketNumber+'-B' : t.ticketNumber
                        return <tr key={i}>
                          {key === 'diff' ? <>
                              <td className={'d-flex justify-content-center'}
                                  style={{cursor: 'pointer'}}
                                  onClick={() => {
                                    console.log('check')
                                    dispatch(addCheck(str))
                                    setRefetch(n => (Math.random() + n))
                                  }}>
                                <i className="fa fa-external-link" aria-hidden="true"></i>
                              </td>
                            <td style={{textAlign:'center', cursor: 'pointer'}}
                                onClick={() => {
                              if (window.confirm('Добавить билет в игнорируемый?')) {
                                dispatch(addIgnore(str))
                                setRefetch(n => (Math.random() + n))
                              }
                            }}>
                              <i className="fa fa-low-vision" aria-hidden="true"></i>
                            </td>
                            </>
                            : key === 'needCheck' ?
                              <td className={'d-flex justify-content-center'}
                                  style={{cursor: 'pointer'}}
                                  onClick={() => {
                                    if (window.confirm('Добавить билет в игнорируемый?')) {
                                      dispatch(addIgnore(str))
                                      setRefetch(n => (Math.random() + n))
                                    }
                                  }}>
                                <i className="fa fa-low-vision" aria-hidden="true"></i>
                              </td> : null}

                          <td>{t.ticketNumber}{t.refund && '-B'}</td>
                          <td>{t.date}</td>
                          <td>{t.pax}
                            <br/>
                            {t.route}</td>
                          <td>{keys[0] === 'google' ? numberFormat(t.data[keys[0]].krsBuyer) + '/' + numberFormat(t.data[keys[0]].krsSeller) : numberFormat(t.data[keys[0]].krs)}</td>
                          <td>{keys[1] === 'google' ? numberFormat(t.data[keys[1]].krsBuyer) + '/' + numberFormat(t.data[keys[1]].krsSeller) : numberFormat(t.data[keys[1]].krs)}</td>
                          <td>{numberFormat(t.data[keys[0]].total)}</td>
                          <td>{numberFormat(t.data[keys[1]].total)}</td>
                          <td style={{width: '10px'}}><DiffTicketsPopUp data={t} type={type}/></td>
                        </tr>
                      }

                    }
                  })}

                  </tbody>
                </Table>

              </Tab>
            })}
            <Tab eventKey={'blackLastName'}
                 title={`BList`}>
              <TableBlackListName cb={()=>{
                setRefetch(n=>(Math.random()+n))
              }}/>
            </Tab>
            {keys.map((key, i)=>{
              return <Tab key={i}
                          eventKey={key}
                          title={`T${key[0]}`}
              >
                {data[key].trashRows.map((trash, i)=>{
                  return <p key={i}>
                    {i+1}. {trash}
                  </p>
                })}
              </Tab>
            })}
          </Tabs>
        </Col>
      </Row>}
      <AddLastNameButton cb={()=>{
        setRefetch(n=>(Math.random()+n))
      }}/>
    </>
  );
};

export default RenderRacoonTickets;

const tableHeader = (key, type, sortingTitle)=>{
  const arr = [1,2]
  if(key !== 'diff' && key !=='ignore' && key !== 'needCheck'){
    return <>
      <tr>
        <td>Номер билета</td>
        <td>Дата</td>
        <td>Пассажир</td>
        <td>Крс</td>
        <td>Итого</td>
      </tr>
    </>
  } else {
    return <>
      <tr>
        {key === 'diff' ? <>
            <td style={{width: 50}} rowSpan={2}>To<br/>Check</td>
            <td style={{width: 50}} rowSpan={2}>To<br/>ignore</td>
          </>
          :
          key === 'needCheck' ?
            <td style={{width: 50}} rowSpan={2}>To<br/>ignore</td> :
            null
        }
        <td rowSpan={2}>Номер билета</td>
        <td rowSpan={2} >
          {key === 'diff' ? sortingTitle('Дата', 'date') : 'Дата'}
        </td>
        <td rowSpan={2}>
          {key === 'diff' ? sortingTitle('Пассажир', 'pax') : 'Пассажир'}
        </td>
        <td colSpan={2}>Крс</td>
        <td colSpan={2}>Итого</td>
        <td rowSpan={2}>More</td>
      </tr>
      <tr>
        {arr.map((item, i) => {
          return <>
            <td>{type[0]}</td>
            <td>{type[1]}</td>
          </>
        })}
      </tr>
    </>
  }

}
