import {
  Portlet,
  PortletBody,
  PortletFooter,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import {Form, Col, Row, Button} from "react-bootstrap";
import {useForm} from "react-hook-form";
import FormHookInput from "../../../air/airBooking/components/formHookInput";
import React, {useEffect, useState} from "react";
import {
  GetItem,
  StartProcess,
  UpdateItem
} from "../../../../redux/actions/base";
import {connect} from "react-redux";
import {
  TripUserCreateRoute,
  TripUserEditRoute,
  TripUsersRoute,
  UserListRoute
} from "../../../routesConstants";
import {Formik} from "formik";
import FormRow from "../../../base/components/forms/formRow";
import LoadingButton from "../../../base/components/loadingButton";

import GenerateLoginToken from "../../../users/pages/components/GenerateLoginToken";
import * as yup from "yup";

import {Modal} from "react-bootstrap";
import BlockUi from "@availity/block-ui";
import FormHookSelect from "../../../air/airBooking/components/formHookSelect";
import BonusModal from "../../components/BonusModal";
import {Link} from "react-router-dom";
import DataTable from "../../../../components/dataTable";
import numberFormat from "../../../../helpers/numberFormat";
import moment from "moment";
import {useParams} from "react-router-dom";
import axios from "../../../../helpers/axios";

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .default(""),
  lastName: yup
    .string()
    .required()
    .default(""),
  password: yup.string().default(""),
  active: yup.bool().default(false),
  language: yup
    .string()
    .required()
    .default("en")
});
const TripUserEditPage = props => {
  const [user, setUser] = useState(null);
  const [loader, setLoader] = useState(false);
  const [query, setQuery] = useState("");
  const [bonus, setBonus] = useState(0);
  const [change, setChange] = useState(null);

  console.log("props", props);
  const {id} = useParams();
  useEffect(() => {
    if (user === null) {
      setLoader(true);
      props.GetItem("/api/trips/users", id, res => {
        setUser(res.data.user);
        // console.log(res);
        setLoader(false);
      });
    }
  }, [user, id, props]);

  const bonusRequest = () => {
    setLoader(true);
    setQuery(" ");
    props.StartProcess(
      `/api/trips/users/getCurrentBonusPoints/${user.id}`,
      res => {
        setBonus(res.data.bonusPoints);
        console.log("tec bon", res);
        setQuery("");
        setLoader(false);
      }
    );
  };
  useEffect(() => {
    if (user) {
      bonusRequest();
    }
  }, [user, change]);
  const callback = (values, cb) => {
    props.UpdateItem("/api/trips/users/" + id, values, cb, TripUsersRoute);
  };
  console.log("schema");
  console.log("user", user);
  if (user === null) {
    return "loading...";
  }

  const columns = [
    {
      Header: "Дата",
      accessor: "createdDate",
      Cell: ({value, row}) => {
        return moment(value).format("DD.MM.YY, HH.mm");
      }
    },
    {
      Header: "Изменил",
      accessor: "createdBy"
    },
    {
      Header: "Сумма",
      accessor: "amount",
      Cell: ({value, row}) => {
        return numberFormat(value);
      }
    },
    {
      Header: "Описание",
      accessor: "description"
    },
    {
      Header: "Статус",
      Cell: ({value, row}) => {
        console.log("row", row);
        return (
          <>
            {row.original.amount > 0 ? (
              <i className={"fa fa-plus-circle text-success"}/>
            ) : (
              <i className={"fa fa-minus-circle text-danger"}/>
            )}
          </>
        );
      }
    }
  ];

  return (
    <>
      <div className={"row"}>
        <div className="col-xl-6">
          <Portlet>
            <PortletHeader title={"Создание пользователя"}/>
            <Formik
              initialValues={
                user ? validationSchema.cast(user) : validationSchema.cast()
              }
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={(values, {setSubmitting}) => {
                console.log("form sent");
                setSubmitting(true);
                callback(values, () => {
                  setSubmitting(false);
                });
              }}
            >
              {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue
                }) => {
                console.log(values);
                return (
                  <form noValidate={true} onSubmit={handleSubmit}>
                    <PortletBody>
                      <FormRow
                        value={values.firstName}
                        name={"firstName"}
                        title={"Имя"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />
                      <FormRow
                        value={values.lastName}
                        name={"lastName"}
                        title={"Фамилия"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />
                      <FormRow
                        name={"password"}
                        title={"Пароль"}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      />

                      {/*<FormRow value={values.email} disabled={true} />*/}
                      <Form.Group as={Row}>
                        <Form.Label column sm="2">
                          Язык
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Check
                            value={"ru"}
                            name={"language"}
                            type={"radio"}
                            label={`Русский`}
                            id={`User`}
                            checked={values.language === "ru"}
                            onChange={handleChange}
                            touched={touched}
                          />
                          <Form.Check
                            value={`en`}
                            name={"language"}
                            type={"radio"}
                            label={`English`}
                            id={`Employee`}
                            checked={values.language === "en"}
                            onChange={handleChange}
                            touched={touched}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Col sm={{span: 10, offset: 2}}>
                          <Form.Check
                            name={"active"}
                            type={"checkbox"}
                            id={`active`}
                            label={"Активирован"}
                            checked={values.active}
                            touched={touched}
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>
                    </PortletBody>
                    <PortletFooter>
                      <Row>
                        <Col md={{span: 10, offset: 2}}>
                          <LoadingButton
                            title={"Сохранить"}
                            disabled={isSubmitting}
                            loading={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </PortletFooter>
                  </form>
                );
              }}
            </Formik>
          </Portlet>
        </div>
        <div className="col-xl-6">
          <GenerateLoginToken
            userId={id}
            tokens={user.toursAuthTokens}
            url={"/api/trips/users/generateToursLoginToken"}
          />
          <BlockUi blocking={loader}>
            <Portlet>
              <PortletHeader
                title={`Бонусные баллы - ${numberFormat(bonus)}`}
                toolbar={
                  <PortletHeaderToolbar>
                    <BonusModal id={id} bonusRequest={bonusRequest}/>
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody>
                <DataTable
                  search={query}
                  url={`/api/trips/users/bonusTransactionList/${id}`}
                  columns={columns}
                  itemsPerPage={"5"}
                />
              </PortletBody>
            </Portlet>
          </BlockUi>
        </div>
      </div>
    </>
  );
};
export default connect(null, {GetItem, UpdateItem, StartProcess})(
  TripUserEditPage
);
