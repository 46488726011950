import React, {useEffect, useState} from 'react';
import ApiService from "../../../services/apiService";
import {Button, Col, Form, FormLabel, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {deep_value} from "../../../helpers/formHelpers";
import RemoveLink from "../../air/airBooking/components/removeLink";

const UploadDocs = ({docs, title, name, formController,folder}) => {
  const {
    control,
    register,
    setValue,
    formState: { errors }
  } = formController;
  const [loading,setLoading]=useState(false)
  const [uploadStatus, setUploadStatus] = useState(docs ?[{url:docs}]: []);
  const uploadDocs = async (files) =>{
    const fileArray = []
    setLoading(true)
    const data = new FormData();
    data.append("file", files[0])
    folder && data.append("folder", folder)
    const resp = await ApiService.post("/api/misc/cloudStorageUpload", data);
    fileArray.push({
      // name: files[0].name,
      url: resp.data.fileUrl,
      // originalName: files[0].name,
      uploaded: true
    })
    setLoading(false)
    setUploadStatus(fileArray)
  }
  const removeDoc = () =>{
    setUploadStatus([])
    setValue(name, null)
  }
  useEffect(() => {
    if (
      uploadStatus &&
      uploadStatus.length &&
      uploadStatus.every(u => u.uploaded)
    ){
      setValue(name,uploadStatus[0].url)
    }
  }, [uploadStatus]);
  return (
    <>
      <FormLabel>{title}</FormLabel> <br />
      {uploadStatus.length<1 ?
        <div className={'mt-1 d-flex gap-2 align-items-center'}>
          <div>
            <label htmlFor="file-upload" >
              <i className={"fa fa-plus btn bg-primary text-white"} />
            </label>
            <input type="file"
                   disabled={loading}
                   {...register(name, {
                     required: true,
                     onChange: e => {
                       uploadDocs(e.target.files);
                     }
                   })}
                   // onChange={(e) => uploadDocs(e.target.files)}
                   id="file-upload" style={{display: "none"}}/>
          </div>
          {loading && <div><i className="fa fa-spinner fa-spin"></i></div>}
          {deep_value(errors, name) ? (
              <div className={"text-danger"}>Не выбран файл</div>
          ) : null}
        </div>:
        <div className={'d-flex gap-2 align-items-center'}>
          <a href={uploadStatus[0].url} target={"_blank"}><i className="fa fa-file-text mt-1" style={{fontSize: '30px'}}
                                               aria-hidden="true"></i></a>
            <RemoveLink title={title} onClick={() => removeDoc()} />
        </div>
      }


    </>
  );
};

export default UploadDocs;