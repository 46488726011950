import React from 'react';
import numberFormat from "../../../helpers/numberFormat";
import MoreFunctionButton from "./moreFunctionButton";
import {Link} from "react-router-dom";

const OneConterpartyTable = ({table, showCol,editing,addRow,removeRow,firstNumber, tableIndex,editName,onlyOne, edit}) => {
  const style = {"display":"none"}
  console.log('show',showCol)
  return <>
    {(!onlyOne) && <tr id={table.tableData.length === 0 ? 'notVisible' : null}>
      <th colSpan={!showCol ? 7 : 6} >
        <div className={'d-flex'}>
          <div contentEditable suppressContentEditableWarning
               onBlur={e => editName(tableIndex, e.currentTarget.textContent, "counterparty")}>{table.header.counterparty}</div>
          (<div contentEditable suppressContentEditableWarning
               onBlur={e => editName(tableIndex, e.currentTarget.textContent, "date")}>{table.header.date}
          </div>)
        </div>

      </th>
      <td id={'notVisible'}>
        <i className={"fa fa-plus ml-2 text-primary"}
           onClick={() => addRow(table.tableData.length - 1, 'down', tableIndex)}/>
      </td>
    </tr>}
    {/*{(!onlyOne && edit) && <tr id={table.tableData.length === 0 ? 'notVisible' : null}>*/}
    {/*  <th colSpan={!showCol ? 7 : 6}>*/}
    {/*    <div >{table.header}</div>*/}
    {/*  </th>*/}
    {/*  <td id={'notVisible'}>*/}
    {/*    <i className={"fa fa-plus ml-2 text-primary"}*/}
    {/*       onClick={() => addRow(table.tableData.length - 1, 'down', tableIndex)}/>*/}
    {/*  </td>*/}
    {/*</tr>}*/}
    {table.tableData.map((inv, i) => {
      return <tr style={inv.transferred ? {backgroundColor:'rgba(253, 57, 122,0.35)'}:{}}>
        <td>{firstNumber + i + 1}</td>
        <td  style={!showCol.name ? {} : style}
            // onBlur={e => editing(i, e.currentTarget.textContent, "operation", tableIndex)}
        >{inv.bookingId ?<Link to={inv.baseLink+inv.bookingId} target={'_blank'}>{inv.operation}</Link>:inv.operation}</td>
        <td contentEditable suppressContentEditableWarning
            onBlur={e => editing(i, e.currentTarget.textContent, "pax", tableIndex)}>{inv.pax}</td>
        <td contentEditable suppressContentEditableWarning style={{whiteSpace: "nowrap"}}
            onBlur={e => editing(i, e.currentTarget.textContent, "BSO", tableIndex)}>{inv.BSO}</td>
        <td contentEditable suppressContentEditableWarning
            onBlur={e => editing(i, e.currentTarget.textContent, "route", tableIndex)}>{inv.route}</td>
        <td contentEditable suppressContentEditableWarning
            onBlur={e => editing(i, e.currentTarget.textContent, "flightDate", tableIndex)}>{inv.flightDate}</td>
        <td contentEditable suppressContentEditableWarning className={'text-end'} style={showCol.krs ? {} : style}
            onBlur={e => editing(i, e.currentTarget.textContent, "krsBuyer", tableIndex)}>{inv.krsBuyer ? numberFormat(inv.krsBuyer) : ''}</td>
        <td contentEditable suppressContentEditableWarning className={'text-end'} style={showCol.krs ? {} : style}
            onBlur={e => editing(i, e.currentTarget.textContent, "krsKayar", tableIndex)}>{inv.krsKayar ? numberFormat(inv.krsKayar) : ''}</td>
        <td contentEditable suppressContentEditableWarning className={'text-end'}
            onBlur={e => editing(i, e.currentTarget.textContent, "total", tableIndex)}>{numberFormat(inv.total)}</td>
        <td style={{border: 'none'}} id={'notVisible'}>
          <MoreFunctionButton removeRow={() => removeRow(i, tableIndex)} addRow={(direction) => {
            addRow(i, direction, tableIndex)
          }}/>
        </td>
      </tr>

    })}</>
};

export default OneConterpartyTable;