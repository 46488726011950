import {
  Portlet,
  PortletBody,
  PortletFooter,
  PortletHeader
} from "../../../../components/Portlet";
import { Col, Row, Form, Breadcrumb } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import React, { useState } from "react";
import FormHookInput from "../../../air/airBooking/components/formHookInput";
import LoadingButton from "../../../base/components/loadingButton";
import StandartInputsChecks from "../components/standartInputsChecks";
import CheckTypeSelect from "../components/checkTypeSelect";
import axios from "../../../../helpers/axios";
import { useNavigate } from "react-router";
import { ParsersCheckListRoute } from "../../../routesConstants";
import Breadcrumbs from "../../../../components/breadcrumbs";
import CheckButton from "../components/checkButton";
import ResultTable from "../../../landing/components/resultTable";

const ParserCheckCreate = () => {
  const [loading, setLoading] = useState(false);
  const [parseText, setParseText] = useState("");
  const [resultCheck, setResultCheck] = useState(null)
  const navigate = useNavigate();
  const formController = useForm({
    defaultValues: {
      parseType: "airBooking",
      checks: []
    }
  });
  const {
    register,
    handleSubmit,
    formState: { errors , isValid},
    getValues,
    control
  } = formController;
  const fildParam = useFieldArray({
    control,
    name: "checks"
  });
  const values = [
    { value: "airBooking", label: "airBooking" },
    { value: "timeTable", label: "timeTable" }
  ];
  const onSubmit = data => {
    if (parseText.length < 3) {
      return;
    }
    const send = {
      parseText: parseText,
      parseType: data.parseType,
      message: data.message,
      isHandled: data.isHandled
    };
    if (data.checks.length > 0) {
      send.checks = data.checks;
    }
    setLoading(true);
    axios
      .put("/api/parseCheck", send)
      .then(() => {
        setLoading(false);
        navigate(ParsersCheckListRoute);
      })
      .catch(e => console.log(e));
    console.log("submit", send);
  };
  const crumbs = [
    { title: "Список ошибок", path: ParsersCheckListRoute },
    { title: "Создание ошибки" }
  ];
  console.log('getValues().check', getValues())
  return (
    <>
      <Breadcrumbs data={crumbs} />
      <Row>
        <Col xs={12}>
          <Portlet>
            <PortletHeader title={"Создать новую ошибку"} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <PortletBody>
                <Row>
                  <Col xs={6}>
                    <Row className={"mb-2"}>
                      <Form.Label column sm="3">
                        Тип парса
                      </Form.Label>
                      <Col sm={9}>
                        <CheckTypeSelect
                          control={control}
                          defaultValue={"airBooking"}
                          values={values}
                          name={"parseType"}
                        />
                      </Col>
                    </Row>
                    <Row className={"mb-2"}>
                      <Form.Label column sm="3">
                        Текст парса
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          as="textarea"
                          rows="10"
                          onChange={newVal => setParseText(newVal.target.value)}
                          errors={errors}
                        />
                        {parseText.length < 3 && (
                          <p>Парс текст должен быть длинее 3 символов</p>
                        )}
                      </Col>
                    </Row>
                    <Row className={"mb-2"}>
                      <Form.Label column sm="3">
                        Сообщение
                      </Form.Label>
                      <Col sm={9}>
                        <FormHookInput
                          register={register("message", {
                            required: true
                          })}
                          placeholder={"Сообщение"}
                          errors={errors}
                        />
                      </Col>
                    </Row>
                    <Form.Check
                      type={"checkbox"}
                      label={"Обработано"}
                      {...register(`isHandled`)}
                      defaultChecked={false}
                    />
                  </Col>
                  <Col xs={6}>
                    <StandartInputsChecks
                      formController={formController}
                      fildParam={fildParam}
                    />
                  </Col>
                </Row>
              </PortletBody>
              <PortletFooter>
                <div  style={{display:"flex", justifyContent:"space-between", alignItems:'center'}}>
                  <LoadingButton title={"Сохранить"} loading={loading} />
                  <CheckButton isValid={isValid && getValues().checks.length>0}
                               setResultCheck={setResultCheck}
                               item={{...getValues(), parseText: parseText}} />
                </div>
                {(getValues().checks.length<1|| !isValid) && <div style={{textAlign:'end'}}>Заполните форму(необходимы параметры проверки)</div>}
              </PortletFooter>
            </form>
          </Portlet>
        </Col>
      </Row>
      {resultCheck && <Row>
        <Col xs={6}>
          <ResultTable resultCheck={resultCheck}/>
        </Col>
      </Row>}
    </>
  );
};
export default ParserCheckCreate;
