import React, {useEffect} from "react";
import {Button, Col, FormLabel, Row} from "react-bootstrap";
import FormHookInput from "../../../air/airBooking/components/formHookInput";
import FormHookSelect from "../../../air/airBooking/components/formHookSelect";
import {transferPointType} from "../../../../helpers/enums";
import RemoveLink from "../../../air/airBooking/components/removeLink";
import {useFieldArray} from "react-hook-form";
import _ from 'lodash'

const OfferFlightPrices = ({formController, prefix, items}) => {
  const {
    register,
    formState: {errors},
    formState,
    control
  } = formController;

  const pricesControl = useFieldArray({
    control,
    name: `${prefix}`,
    rules: {required: true},
    shouldUnregister: true,
    keyName: 'customId'
  });
  useEffect(
    () =>
      !pricesControl.fields.length && items && items.length
        ? pricesControl.append(items)
        : () => {
        },
    []
  );

  const priceTemplate = {
    name: "",
    adultPrice: 0,
    childPrice: 0,
    infantPrice: 0,
    adultCount: 0,
    childCount: 0,
    infantCount: 0
  };
  const addPrice = () => {
    pricesControl.append({...priceTemplate});
  };
  return (
    <>
      <h5>
        Варианты{" "}
        <Button size={"sm"} onClick={() => addPrice()}>
          <i className={"fa fa-plus"}></i>
        </Button>
      </h5>
      {pricesControl.fields.map((price, index) => {
        return (
          <>
            <Row key={price.customId}>
              <Col xs={4}>
                <FormLabel>Название</FormLabel>
                <FormHookInput
                  defaultValue={price.name}
                  register={register(`${prefix}[${index}].name`, {
                    required: true
                  })}
                  errors={errors}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={2}>
                <FormLabel>Цена (взр)</FormLabel>
                <FormHookInput
                  defaultValue={price.adultPrice}
                  register={register(`${prefix}[${index}].adultPrice`)}
                  errors={errors}
                />
              </Col>
              <Col xs={2}>
                <FormLabel>Кол-во (взр)</FormLabel>
                <FormHookInput
                  defaultValue={price.adultCount}
                  register={register(`${prefix}[${index}].adultCount`)}
                  errors={errors}
                />
              </Col>
              <Col xs={2}>
                <FormLabel>Цена (дет)</FormLabel>
                <FormHookInput
                  defaultValue={price.childPrice}
                  register={register(`${prefix}[${index}].childPrice`)}
                  errors={errors}
                />
              </Col>
              <Col xs={2}>
                <FormLabel>Кол-во (дет)</FormLabel>
                <FormHookInput
                  defaultValue={price.childCount}
                  register={register(`${prefix}[${index}].childCount`)}
                  errors={errors}
                />
              </Col>
              <Col xs={2}>
                <FormLabel>Цена (млд)</FormLabel>
                <FormHookInput
                  defaultValue={price.infantPrice}
                  register={register(`${prefix}[${index}].infantPrice`)}
                  errors={errors}
                />
              </Col>
              <Col xs={2}>
                <FormLabel>Кол-во (млд)</FormLabel>
                <FormHookInput
                  defaultValue={price.infantCount}
                  register={register(`${prefix}[${index}].infantCount`)}
                  errors={errors}
                />
              </Col>
              <Col xs={5}>
                <RemoveLink
                  onClick={() => pricesControl.remove(index)}
                  title={"вариант"}
                />
              </Col>
            </Row>
          </>
        );
      })}
      {!pricesControl.fields.length ? (
        <>
          <input
            type={"text"}
            style={{display: "none"}}
            defaultValue={pricesControl.fields.length}
            {...register(`${prefix}ss`, {required: true, min: 1})}
          />
          {_.get(errors, prefix)?.root ? (
            <p className={"text-danger"}>Минимум 1 вариант</p>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default OfferFlightPrices;
