import React, { useState, useEffect } from "react";
import { Progress } from "reactstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const AnimateLoading = () => {
  const [width, setWidth] = useState(0);
  const [routeChanged, setRouteChanged] = useState();
  const location = useLocation();

  useEffect(() => {
    let animateTimeout;
    let stopAnimateTimeout;
    const animate = () => {
      animateTimeout = setTimeout(() => {
        if (width <= 100) {
          setWidth(width + 10);
          animate();
        } else {
          stopAnimate();
        }
      }, 30);
    };
    const stopAnimate = () => {
      clearTimeout(animateTimeout);
      stopAnimateTimeout = setTimeout(() => {
        setWidth(0);
      }, 300);
    };
    if (location.pathname !== location.pathname) {
      animate();
    }
    return () => {
      if (stopAnimateTimeout) {
        clearTimeout(stopAnimateTimeout);
      }
      if (animateTimeout) {
        clearTimeout(animateTimeout);
      }
    };
  }, []);

  return (
    <div
      className="header-progress-bar"
      style={{ height: "3px", width: "100%" }}
    >
      {width > 0 && <Progress value={width} style={{ height: "3px" }} />}
    </div>
  );
};

export default AnimateLoading;
