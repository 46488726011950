import React, {useEffect, useState} from "react";
import {Tab, Tabs} from "react-bootstrap";
import TripItemHotelRoom from "./tripHotelRoom";
import {useFieldArray} from "react-hook-form";
import HotelRoomGroup from "./hotelRoomGroup";
import _ from 'lodash'
import ButtonAvailableRooms from "./ButtonAvailableRooms";

const HotelRoomGroups = ({
                           availableRooms,
                           formController,
                           prefix,
                           hotelPrefix,
                           index,
                           groups,
                           bookingComLink,
                           activ
                         }) => {
  const {
    register,
    getValues,
    formState: {errors, isSubmitted},
    formState,
    control
  } = formController;

  const [tabIndex, setTabIndex] = useState('group0');
  const [availableRoomsList, setAvailableRoomsList] = useState(availableRooms)

  const groupRoomsControl = useFieldArray({
    control,
    name: `${prefix}`,
    rules: {required: true},
    shouldUnregister: true,
    keyName: 'customId'
  });
  useEffect(
    () =>
      !groupRoomsControl.fields.length && groups && groups.length
        ? groupRoomsControl.append(groups)
        : () => {
        },
    []
  );

  const tabClick = key => {
    if (key === "add") {
      groupRoomsControl.append({
        name: "",
        rooms: []
      });
      setTabIndex(`group${groupRoomsControl.fields.length}`);
    } else {
      setTabIndex(key);
    }
  };
  return (
    <>
      <div style={{display: 'flex', alignItems:'center', justifyContent:'space-between'}}>
        <h5>Группы номеров</h5>
        {(!availableRoomsList && bookingComLink) && <ButtonAvailableRooms bookingComLink={bookingComLink} setAvailableRoomsList={setAvailableRoomsList}/>}
      </div>
      {_.get(errors, prefix)?.root ? (
        <p className={"text-danger"}>Минимум 1 группа</p>
      ) : null}
      <Tabs
        id={`hotel${index}grouproom`}
        activeKey={tabIndex}
        onSelect={key => tabClick(key)}
      >
        {groupRoomsControl.fields.map((group, index) => {
          const groupPrefix = `${prefix}[${index}]`;
          return (
            <Tab
              eventKey={`group${index}`}
              key={group.customId}
              tabClassName={_.get(errors, `${groupPrefix}.rooms`) ? 'tab-err' : null}
              title={group.name ? group.name : `Группа №${index + 1}`}
            >
              <HotelRoomGroup
                formController={formController}
                index={index}
                hotelPrefix={hotelPrefix}
                // itemNo={itemNo}
                availableRooms={availableRoomsList}
                remove={() => {
                  console.log('remove roomGroup')
                  groupRoomsControl.remove(index);
                }}
                groupPrefix={groupPrefix}
                group={group}
                activ={activ && tabIndex === `group${index}`}
              />
            </Tab>
          );
        })}
        <Tab eventKey={`add`} title={<i className={"fa fa-plus"}/>}/>
      </Tabs>
    </>
  );
};

export default HotelRoomGroups;
