import React, { useEffect, useState } from "react";
import {
  Portlet,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import { connect } from "react-redux";
import {
  DeleteItem,
  GetItem,
  UpdateItem
} from "../../../../redux/actions/base";
import { useParams } from "react-router-dom";
import { Dropdown, Row, Col } from "react-bootstrap";
import { DropdownButton, Button } from "react-bootstrap";
import { AirBookingTemplateListRoute } from "../../../routesConstants";
import TemplateForm from "../components/templateForm";
import VarInfoTable from "../components/varInfoTable";

const AirBookingTemplateEditPage = props => {
  const [template, setTemplate] = useState(null);
  const [loader, setLoader] = useState(false);

  const { id } = useParams();
  useEffect(() => {
    if (template === null) {
      setLoader(true);
      props.GetItem("/api/parsers/airBooking", id, res => {
        setTemplate(res.data.data);
        setLoader(false);
      });
    }
  }, [template, id, props]);
  const callback = (values, cb) => {
    props.UpdateItem(
      "/api/parsers/airBooking/" + id,
      values,
      cb,
      AirBookingTemplateListRoute
    );
  };
  const deleteAction = () => {
    setLoader(true);
    props.DeleteItem(
      "/api/parsers/airBooking/" + id,
      () => {},
      AirBookingTemplateListRoute
    );
  };
  return (
    <>
      <TemplateForm
        onSubmit={callback}
        data={template}
        title={"Редактирование шаблона"}
      />
    </>
  );
};

export default connect(null, { GetItem, UpdateItem, DeleteItem })(
  AirBookingTemplateEditPage
);
