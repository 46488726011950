import React from "react";
import { openUploadWidget } from "../../../services/CloudinaryService";
import { Button } from "react-bootstrap";

const PhotoUploadButton = ({ cb }) => {
  const beginUpload = () => {
    const uploadOptions = {
      cloudName: "unoegohh",
      uploadPreset: "kym2volu",
      cropping: "true",
      croppingCoordinatesMode: "custom",
      multiple: false
    };
    console.log("cb", cb);

    openUploadWidget(uploadOptions, (error, photos) => {
      if (!error) {
        if (photos.event === "success") {
          cb(photos.info.url);
        }
      } else {
        console.log(error);
      }
    });
  };
  return (
    <>
      <Button size={"sm"} onClick={() => beginUpload()}>
        <i className={"fa fa-plus"}></i>
      </Button>
    </>
  );
};

export default PhotoUploadButton;
