import React, { useEffect } from "react";
import axios from "./axios";
import { useNavigate } from "react-router";
import {shallowEqual, useSelector} from "react-redux";

const AuthHelper = props => {
  const authToken = localStorage.getItem("jwtToken");
  const navigate = useNavigate();
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null
    }),
    shallowEqual
  );

  useEffect(() => {
    axios.interceptors.request.clear();
    axios.interceptors.request.use(
      function(config) {
        config.headers.setAuthorization(authToken);
        return config;
      },
      function(error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
  });
  if (!authToken || !isAuthorized) {
    navigate("/auth/login");
  }
  return <>{props.children}</>;
};
export default AuthHelper;
