import moment from "moment/moment";
import _ from 'lodash'


export const googleParse = (data) =>{
  const result = [];
  const trashRows = [];
  let company = '';
  data.forEach((cell) =>{
    if(cell[0]){
      company = cell[0];
      //return;
    }
    const matchTicketNumber = /(?<airCode>[0-9]{3})[ -]?(?<ticketNumber>[0-9]{10})(?<refund>-[BВ])?/gi.exec(cell[3])
    if(cell[3]==='465-9176650942-В'){
      console.log('sell', cell)
      console.log('matchTicketNumber', matchTicketNumber)
    }
    if(matchTicketNumber){
        const ticket = {
          type:'google',
          supplier: company,
          issueDate: moment(cell[1], 'DDMMM').format('DD.MM.YYYY'),
          flightDate: moment(cell[2], 'DDMMM').format('DD.MM.YYYY'),
          airCode: matchTicketNumber.groups.airCode,
          ticketNumber: matchTicketNumber.groups.ticketNumber,
          refund: !!matchTicketNumber.groups.refund || +cell[13]<0,
          pax: cell[4],
          route: cell[5],
          fare: +cell[6],
          taxes: +cell[7],
          penalties: +cell[8],
          netto: +cell[9],
          krsBuyer: +cell[10],
          krsSeller: +cell[11],
          counterparty: cell[12],
          total: cell[13].length && +cell[13],
        }
        if(typeof ticket.total !== 'number'){
          trashRows.push(cell.join(',') + '!NOT NUMBER'+'google')
        }else{
          result.push(ticket)
        }

    }else{
      trashRows.push(cell.join(',')+'google')
    }
  })
  // return _.filter(result, {supplier: 'ARUANA'})
  return {result, trashRows}

}

export const returnedParse = (data, oneType) =>{
  const result = [];
  const trashRows = [];
  data.forEach((cell) =>{
    const matchTicketNumber = /(?<ticketNumber>[0-9]{10})(?<refund>-В)?/gi.exec(cell[2])
    if(matchTicketNumber){
        const ticket = {
          type:oneType,
          issueDate: moment(cell[4], 'DD.MM.YYYY').format('DD.MM.YYYY'),
          // airCode: matchTicketNumber.groups.airCode,
          ticketNumber: matchTicketNumber.groups.ticketNumber,
          refund: true,
          pax: cell[6],
          route: cell[3],
          fare: +cell[7].replace(/\s+/g, '')*-1,
          taxes: +cell[8].replace(/\s+/g, '')*-1,
          penalties: +cell[18].replace(/\s+/g, ''),
          netto: +cell[15].replace(/\s+/g, '')>0 ?+cell[15].replace(/\s+/g, '')*-1 : +cell[15].replace(/\s+/g, ''),
          krs: +cell[9].replace(/\s+/g, '')>0 ?+cell[9].replace(/\s+/g, '')*-1 : +cell[9].replace(/\s+/g, ''),
          counterparty: cell[5],
          total: cell[22].length && +cell[22].replace(/\s+/g, '')*-1,
        }
        if(typeof ticket.total !== 'number'){
          trashRows.push(cell.join(',') + '!NOT NUMBER'+oneType)
        }else{
          result.push(ticket)
        }

    }else{
      trashRows.push(cell.join(',')+oneType)
    }
  })
  return {result, trashRows}

}
export const sellParse = (data, oneType) =>{
  const result = [];
  const trashRows = [];
  data.forEach((cell) =>{
    const matchTicketNumber = /(?<airCode>[0-9]{3})[ -](?<ticketNumber>[0-9]{10})(?<refund>-В)?/gi.exec(cell[2])
    if(matchTicketNumber){
      const ticket = {
        type: oneType,
        issueDate: moment(cell[4], 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY'),
        airCode: matchTicketNumber.groups.airCode,
        ticketNumber: matchTicketNumber.groups.ticketNumber,
        refund: false,
        pax: cell[6],
        route: cell[3],
        fare: +cell[7].replace(/\s+/g, ''),
        taxes: +cell[8].replace(/\s+/g, ''),
        penalties: +cell[10].replace(/\s+/g, ''),
        netto: +cell[16].replace(/\s+/g, ''),
        krs: +cell[11].replace(/\s+/g, ''),
        counterparty: cell[5],
        total: cell[12].length && +cell[12].replace(/\s+/g, ''),
      }
      if (ticket.ticketNumber === '2522039842') {
        console.log('tiket2', ticket)
      }
      if (typeof ticket.total !== 'number') {
        trashRows.push(cell.join(',') + '!NOT NUMBER'+oneType)
      } else {
        result.push(ticket)
      }
    }else{
      trashRows.push(cell.join(',')+oneType)
    }
  })
  return {result, trashRows}
}

export const oneParser = (texts, type) =>{
  if(type === 'gk' || type === 'ga'){
    const google = googleParse(texts.inputGoogleDoc)
    const oneC = {result:[], trashRows: []}
    const keys = Object.keys(texts)
    const secondType = _.find(keys.map(key=>{
      if(key.includes('Kayar')){
        return 'kayar'
      } else if (key.includes('Aruana')){
        return 'aruana'
      }
    }))
    keys.map(key=>{
      if(key.includes('Sell')){
        const array = sellParse(texts[key],secondType)
        oneC.result.push(...array.result)
        oneC.trashRows.push(...array.trashRows)
      } else if(key.includes('Refund')){
        const array = returnedParse(texts[key],secondType)
        oneC.result.push(...array.result)
        oneC.trashRows.push(...array.trashRows)
      }
    })
    console.log({google, [secondType]:oneC})

    return {google, [secondType]:oneC}
  } else {
    const aruana = {result:[], trashRows: []}
    const kayar = {result:[], trashRows: []}
    const pushFunc = (array, resultArray) =>{
      array.result.push(...resultArray.result)
      array.trashRows.push(...resultArray.trashRows)
    }
    Object.keys(texts).map(key=>{
      const oneType = key.includes('Kayar') ? 'kayar' : 'aruana'
      if(key.includes('Sell')){
        const objWithArrays = sellParse(texts[key], oneType)
        if(key.includes('Kayar')){
          pushFunc(kayar, objWithArrays)
        } else {
          pushFunc(aruana, objWithArrays)
        }
      } else {
        const array =returnedParse(texts[key], oneType)
        if(key.includes('Kayar')){
          pushFunc(kayar, array)
        } else {
          pushFunc(aruana, array)
        }
      }
    })
    return {aruana, kayar}
  }
}