import { Route, Routes } from "react-router";
import CountryList from "./pages/list";
import React from "react";
import {
  CountryCreateRoute,
  CountryEditRoute,
  CountryListRoute
} from "../../routesConstants";
import CountryCreatePage from "./pages/create";
import CountryEditPage from "./pages/edit";
import ErrorHandler from "../../base/components/errorHandler";

export default function() {
  return (
    <Routes>
      <Route path={"/"} exact={true} element={<CountryList />} />
      <Route path={"create"} element={<CountryCreatePage />} />
      <Route path={"edit/:id"} exact={true} element={<CountryEditPage />} />
    </Routes>
  );
}
