import React, {useEffect, useState} from "react";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import TripItemHotelRoom from "./tripHotelRoom";
import {useFieldArray} from "react-hook-form";
import AvailableRoomsBlock from "./AvailableRoomsBlock";
import _ from "lodash";
import LoadingButton from "../../base/components/loadingButton";
import axios from "../../../helpers/axios";
import ButtonAvailableRooms from "./ButtonAvailableRooms";

const HotelRooms = ({
                      formController,
                      prefix,
                      dataPrefix,
                      index,
                      rooms,
                      availableRooms,
                      bookingComLink,
                      activ,
                    }) => {
  const {
    register,
    formState: {errors},
    formState,
    control
  } = formController;

  const [tabIndex, setTabIndex] = useState('room0');

  const [availableRoomsList, setAvailableRoomsList] = useState(availableRooms)


  const getRoomName = (room, index) => {
    return room.name ? room.name : `Номер ${index + 1}`;
  };
  const roomsControl = useFieldArray({
    control,
    name: `${dataPrefix}`,
    rules: {required: true},
    shouldUnregister: true,
    keyName: 'customId'
  });
  const tabClick = key => {
    if (key === "add") {
      const hotel = _.get(formController.getValues(), prefix);
      roomsControl.append({
        otherPaxes: [],
        descriptions: [],
        documents: [],
        photos: [],
        startDate: hotel.startDate,
        startTime: hotel.startTime,
        endDate: hotel.endDate,
        endTime: hotel.endTime
      });
      setTabIndex(`room${roomsControl.fields.length}`);
    } else {
      setTabIndex(key);
    }
  };
  const removeRoom = index => {
    roomsControl.remove(index);
    /*if(item.rooms.length - 1){
      setTabIndex(`room${item.rooms.length - 2}`)
    }*/
  };
  const copyRoom = index => {
    const room = _.get(formController.getValues(), `${dataPrefix}[${index}]`);
    const newRoom = {...room};
    console.log("newRoom", newRoom);
    newRoom.descriptions.forEach(u => (u._id = ""));
    newRoom.documents.forEach(u => (u._id = ""));
    newRoom.photos.forEach(u => (u._id = ""));
    newRoom.rooms.forEach(u => (u._id = ""));
    newRoom.id = "";
    newRoom._id = "";
    newRoom.mainPax = "";
    newRoom.otherPaxes = [];
    roomsControl.append(newRoom);
    //  /*if(item.rooms.length - 1){
    //   setTabIndex(`room${item.rooms.length - 2}`)
    // }*/
  };
  useEffect(
    () =>
      !roomsControl.fields.length && rooms && rooms.length
        ? roomsControl.append(rooms)
        : () => {
        },
    []
  );

  return (
    <>
      {availableRoomsList?.length ? (
        <AvailableRoomsBlock
          rooms={availableRoomsList}
          addRoom={room => {
            const hotel = _.get(formController.getValues(), prefix);
            console.log("hotel insert", hotel);
            roomsControl.append({
              otherPaxes: [],
              descriptions: [],
              documents: [],
              ...room,
              startDate: hotel.startDate,
              startTime: hotel.startTime,
              endDate: hotel.endDate,
              endTime: hotel.endTime
            });
          }}
        />
      ) : null}
      <div style={{display: 'flex', alignItems:'center', justifyContent:'space-between'}}>
        <h5>Номера</h5>
        {(!availableRoomsList && bookingComLink) && <ButtonAvailableRooms bookingComLink={bookingComLink} setAvailableRoomsList={setAvailableRoomsList}/>}
      </div>

      <Tabs
        id={`hotel${index}room`}
        activeKey={tabIndex}
        onSelect={key => tabClick(key)}
      >
        {roomsControl.fields.map((room, index) => {
          return (
            <Tab
              eventKey={`room${index}`}
              key={room.customId}
              title={getRoomName(room, index)}
            >
              <TripItemHotelRoom
                room={room}
                hotelPrefix={prefix}
                prefix={`${dataPrefix}[${index}]`}
                formController={formController}
                removeItem={() => removeRoom(index)}
                activ={activ && tabIndex === `room${index}`}
                copyRoom={() => {
                  copyRoom(index);
                }}
              />
            </Tab>
          );
        })}
        <Tab eventKey={`add`} title={<i className={"fa fa-plus"}/>}/>
      </Tabs>

      {!roomsControl.fields.length ? (
        <>
          <input
            type={"text"}
            style={{display: "none"}}
            defaultValue={""}
            {...register(`${dataPrefix}ss`, {required: true})}
          />
          {formState.isSubmitted ? (
            <p className={"text-danger"}>Минимум 1 комната</p>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default HotelRooms;
