import { Route, Routes } from "react-router";
import AirportList from "./pages/list";
import AirportCreatePage from "./pages/create";
import AirportEditPage from "./pages/edit";
import React from "react";
import {
  AirportCreateRoute,
  AirportEditRoute,
  AirportListRoute
} from "../../routesConstants";
import ErrorHandler from "../../base/components/errorHandler";

export default function() {
  return (
    <ErrorHandler>
      <Routes>
        <Route path={"/"} exact={true} element={<AirportList />} />
        <Route path={"/create"} element={<AirportCreatePage />} />
        <Route path={"/edit/:id"} exact={true} element={<AirportEditPage />} />
      </Routes>
    </ErrorHandler>
  );
}
