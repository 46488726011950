import React from 'react';
import {Dropdown} from "react-bootstrap";

const MoreFunctionButton = ({removeRow,addRow}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle  id="dropdown-basic" variant={"outline-secondary"} style={{padding:0, paddingRight:'0.5rem'}}/>

      <Dropdown.Menu>
        <Dropdown.Item onClick={()=>{addRow('up')}}><i className="fa fa-plus" aria-hidden="true"/>Добавить строку выше</Dropdown.Item>
        <Dropdown.Item onClick={removeRow}><i className="fa fa-trash" aria-hidden="true"/>Удалить</Dropdown.Item>
        <Dropdown.Item onClick={()=>{addRow('down')}}><i className="fa fa-plus" aria-hidden="true"/>Добавить строку ниже</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MoreFunctionButton;