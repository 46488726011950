import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";
import { FormControl } from "react-bootstrap";
import { useForm } from "react-hook-form";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { StartProcess, UpdateItem } from "../../../redux/actions/base";
import BlockUi from "@availity/block-ui";
import ReviceSelector from "./reviceSelector";

const weeks = [
  [0, 7],
  [8, 15],
  [16, 23],
  [24, 32]
];
const EditReviseButton = ({
  revise,
  StartProcess,
  UpdateItem,
  refreshRevise
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [items, setItems] = useState();

  const {
    handleSubmit,
    register,
    control} = useForm({
    defaultValues: {
      aruanaCheckId: _.find(revise.items, { reviseType: "aruana-kayar" })?.id,
      iataCheckId: _.find(revise.items, { reviseType: "iata-aruana" })?.id,
      silkCheckId: _.find(revise.items, { reviseType: "silk-kayar" })?.id
    }
  });
  useEffect(() => {
    if (isVisible) {
      setLoading(true);
      StartProcess(
        "/api/racoon/revise/getItems",
        ({ data }) => {
          console.log("res", data.items);
          setItems(data.items);
          setLoading(false);
        },
        {
          year: moment.utc(revise.startDate).format("YYYY"),
          month: moment.utc(revise.startDate).format("M"),
          week: Math.ceil(moment.utc(revise.startDate).date() / 7)
        }
      );
    }
  }, [isVisible]);

  const save = values => {
    console.log("values", values);
    setEditLoading(true);
    UpdateItem(
      "/api/racoon/revise/" + revise.id,
      {
        aruanaCheckId: values.aruanaCheckId || null,
        iataCheckId: values.iataCheckId || null,
        silkCheckId: values.silkCheckId || null,
        comment: values.comment || null
      },
      () => {
        setEditLoading(false);
        setIsVisible(false);
        refreshRevise();
      }
    );
  };

  return (
    <>
      <Button color={"primary"} size={"sm"} onClick={() => setIsVisible(true)}>
        Редактировать
      </Button>
      <Modal
        isOpen={isVisible}
        toggle={() => {
          setIsVisible(!isVisible);
        }}
      >
        <ModalHeader>Редактирование отчета</ModalHeader>
        <ModalBody>
          <BlockUi blocking={editLoading}>
            <Form onSubmit={handleSubmit(save)}>
              {loading ? (
                "Loading"
              ) : (
                <>
                  <FormGroup>
                    <Label>Сверка Iata</Label>
                    <ReviceSelector name={'iataCheckId'}
                                    options={_.filter(items, { type: "iata-aruana" })}
                                    control={control}/>
                  </FormGroup>
                  <FormGroup>
                    <Label>Сверка Aruana</Label>
                    <ReviceSelector name={'aruanaCheckId'}
                                    options={_.filter(items, { type: "aruana-kayar" })}
                                    control={control}/>
                  </FormGroup>
                  <FormGroup>
                    <Label>Сверка Silk</Label>
                    <ReviceSelector name={'silkCheckId'}
                                    options={_.filter(items, { type: "silk-kayar" })}
                                    control={control}/>
                  </FormGroup>
                  <FormGroup>
                    <Label>Коментарий</Label>
                    <FormControl {...register("comment")} />
                  </FormGroup>
                  <Button type={"submit"}>Редактировать</Button>
                </>
              )}
            </Form>
          </BlockUi>
        </ModalBody>
      </Modal>
    </>
  );
};

export default connect(null, { StartProcess, UpdateItem })(EditReviseButton);
