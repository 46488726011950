import React from 'react';
import {Col, Row, Tab, Table, Tabs} from "react-bootstrap";
import moment from "moment/moment";
import numberFormat from "../../../helpers/numberFormat";
import {showCount} from "../../../helpers/showCount";

const SilkKayarRender = ({data}) => {
  return (
    <>
      <Row>
        <Col>
          <Tabs defaultActiveKey="correct">
            <Tab
              eventKey="correct"
              title={`Правильные (${data.correct.length})`}
            >
              <Table striped hover>
                <thead>
                <tr>
                  <td>Номер</td>
                  <td>Выписан</td>
                  <td>Тип</td>
                  <td>Итого</td>
                </tr>
                </thead>
                <tbody>
                {data.correct.map((u, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {u.airline} {u.ticketNumber}
                      </td>
                      <td>{moment.utc(u.issueDate).format("DD.MM.YYYY")}</td>
                      <td>{u.refund ? "Возврат" : "Инвойс"}</td>
                      <td>{numberFormat(u.total)}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={3}>
                    <b>Итого</b>
                  </td>
                  <td>
                    <b>
                      {numberFormat(
                        data.correct
                          .map(u => u.total)
                          .reduce((a, b) => a + b, 0)
                      )}
                    </b>
                  </td>
                </tr>
                </tbody>
              </Table>
            </Tab>
            <Tab
              eventKey="noIata"
              title={`Есть в Kayar но нет в Silk (${showCount(data.silkMissing)})`}
            >
              <Table striped hover>
                <thead>
                <tr>
                  <td>Номер</td>
                  <td>Выписан</td>
                  <td>Тип</td>
                  <td>Итого</td>
                </tr>
                </thead>
                <tbody>
                {data.silkMissing.map((u, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {u.airline} {u.ticketNumber}
                      </td>
                      <td>{moment.utc(u.issueDate).format("DD.MM.YYYY")}</td>
                      <td>{u.refund ? "Возврат" : "Инвойс"}</td>
                      <td>{numberFormat(u.total)}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={3}>
                    <b>Итого</b>
                  </td>
                  <td>
                    <b>
                      {numberFormat(
                        data.silkMissing
                          .map(u => u.total)
                          .reduce((a, b) => a + b, 0)
                      )}
                    </b>
                  </td>
                </tr>
                </tbody>
              </Table>
            </Tab>
            <Tab
              eventKey="noSilk"
              title={`Есть в Silk но нет в Kayar (${showCount(data.kayarMissing)})`}
            >
              <Table striped hover>
                <thead>
                <tr>
                  <td>Номер</td>
                  <td>Выписан</td>
                  <td>Тип</td>
                  <td>Итого</td>
                </tr>
                </thead>
                <tbody>
                {data.kayarMissing.map((u, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {u.airline} {u.ticketNumber}
                      </td>
                      <td>{moment.utc(u.issueDate).format("DD.MM.YYYY")}</td>
                      <td>{u.refund ? "Возврат" : "Инвойс"}</td>
                      <td>{numberFormat(u.total)}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={3}>
                    <b>Итого</b>
                  </td>
                  <td>
                    <b>
                      {numberFormat(
                        data.kayarMissing
                          .map(u => u.total)
                          .reduce((a, b) => a + b, 0)
                      )}
                    </b>
                  </td>
                </tr>
                </tbody>
              </Table>
            </Tab>
            <Tab
              eventKey="diff"
              title={`Не соответствуют (${showCount(data.diff)})`}
            >
              <Table striped hover>
                <thead>
                <tr>
                  <td>Номер</td>
                  <td>Выписан Kayar</td>
                  <td>Выписан Silk</td>
                  <td>Итого Kayar</td>
                  <td>Итого Silk</td>
                </tr>
                </thead>
                <tbody>
                {data.diff.map((u, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {u.airline} {u.ticketNumber}
                      </td>
                      <td>{moment.utc(u.kayarIssueDate).format("DD.MM.YYYY")}</td>
                      <td>{moment.utc(u.issueDate).format("DD.MM.YYYY")}</td>
                      <td>{numberFormat(u.total.kayar)}</td>
                      <td>{numberFormat(u.total.silk)}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td>
                    <b>Итого</b>
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                    <b>
                      {numberFormat(
                        data.diff
                          .map(u => u.total.kayar)
                          .reduce((a, b) => a + b, 0)
                      )}
                    </b>
                  </td>
                  <td>
                    <b>
                      {numberFormat(
                        data.diff.map(u => u.total.silk).reduce((a, b) => a + b, 0)
                      )}
                    </b>
                  </td>
                </tr>
                </tbody>
              </Table>
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <Row>
        <Col>
          Сумма Kayar:{" "}
          <b>
            {numberFormat(data.kayarTotal)} (
            {numberFormat(data.kayarTotal - data.silkTotal)})
          </b>
          <br />
          Cумма Silk:{" "}
          <b>
            {numberFormat(data.silkTotal)} (
            {numberFormat(data.silkTotal - data.kayarTotal)})
          </b>
          <br />
        </Col>
      </Row>

    </>
  );
};

export default SilkKayarRender;