import React, {useEffect, useState} from 'react';
import {
  Portlet, PortletBody, PortletFooter,
  PortletHeader, PortletHeaderToolbar,
} from "../../../components/Portlet";
import axios from "../../../helpers/axios";
import BasicTable from "./basicTable";
import {Form} from "react-bootstrap";
import OptionsForm from "./optionsForm";
import LoadingButton from "../../base/components/loadingButton";
import DeletedBtn from "./deletedBtn";
import _ from 'lodash'
import numberFormat from "../../../helpers/numberFormat";
import Button from "react-bootstrap/Button";
import {Link, useParams} from "react-router-dom";
import {SalesRegisterEditRoute} from "../../routesConstants";
import sayError from "../../../helpers/sayError";
import FormHookInput from "../../air/airBooking/components/formHookInput";

const SalesOneCounterparty = ({cb, data, saving,edit}) => {
  const {id} = useParams();
  const [loading, setLoading] = useState(false)
  const [arrayfromOneC, setArrayFromOneC]= useState(data.fromOneC)
  const [arrayfromBase, setArrayFromBase]= useState(data.fromBase)
  const [description, setDescription] = useState(data.description || '')
  const [totalSumm,setTotalSumm]= useState()
  const [prevRegister, setPrevRegister] = useState([])
  const [showCol,setShowCol] = useState({name:false, krs:false})
  const [options, setOptions] = useState(null)

  const saveRegister = () =>{
    const send = !edit ?{
      ...options,
      fromOneC:arrayfromOneC,
      fromBase:arrayfromBase,
      description
    }:{
      fromOneC:arrayfromOneC,
      fromBase:arrayfromBase,
      description
    }
    cb && cb(send)
    console.log('save', send)
  }
  console.log(arrayfromOneC, arrayfromBase)
  useEffect(() => {
    if(arrayfromOneC && arrayfromBase){
      const sumArray = []
      arrayfromOneC.forEach((b,i)=>{
        let name = typeof b.header === 'string' ? b.header : b.header.counterparty;
        let sumOneC = _.sumBy(b?.tableData, 'total')
        let sumBase = _.sumBy(
          _.find(arrayfromBase, {header:{counterparty: name}})?.tableData, 'total')
        let paxSum = {
          name: name,
          total: sumOneC+sumBase
        }
        sumArray.push(paxSum)
      })
      setTotalSumm(sumArray)
    }
  }, [arrayfromOneC,arrayfromBase]);

  const getValues = (data)=>{
    setLoading(true)
    setOptions(data)
    axios.post('/api/salesRegister/getRegister', data)
      .then(({data})=>{
        console.log('mydata',data)
        data.fromOneC.forEach(c=>{
          c.tableData.forEach(inv=>{
            inv.total = inv.krsKayar ? inv.total+inv.krsKayar : inv.total
          })
        })
        data.fromBase.forEach(c=>{
          c.tableData.forEach(inv=>{
            inv.total = inv.krsKayar ? inv.total+inv.krsKayar : inv.total
          })
        })
        setArrayFromOneC(data.fromOneC)
        setArrayFromBase(data.fromBase)
        setPrevRegister(data.prevRegisters)
        console.log("response",data)
      })
      .catch(e=>{
      console.log('err',e)
      window.alert(e.response.data.message[0])
    }).finally(()=>setLoading(false))

  }
  const isTransferred = (array) =>{
    let find = false
    array.forEach(c=>{
      if(_.find(c.tableData, {transferred:true})){
        find = true
      }
    })
    return find
  }

  const markPaid = () =>{
    setLoading(true)
    axios.post('/api/salesRegister/markPaid/'+id)
      .then(()=>{
        window.location.reload()
      })
      .catch(sayError)
      .finally(()=>setLoading(false))
  }

  return (
    <div id={'main'}>
      <Portlet >
        <PortletHeader title={'Краткий реестр продаж'} toolbar={<PortletHeaderToolbar>
          {edit && <div className={'d-flex gap-2'}>
            <LoadingButton title={data.isPaid ?'Оплачено':'Оплатить'}
                           variant={data.isPaid ?'success':'primary'}
                           loading={loading}
                           onClick={markPaid}/>
            <DeletedBtn isDeleted={data.isDeleted}/>
          </div>}
        </PortletHeaderToolbar>}/>
        <PortletBody>
          <OptionsForm cb={getValues}
                        loading={loading}
                        edit={edit}
                        info={(arrayfromOneC && arrayfromBase) ? {
                          fromOneC: arrayfromOneC,
                          fromBase: arrayfromBase
                        } : null}/>


          {(arrayfromOneC && arrayfromBase) ?
            <>
              <div className={'d-flex gap-2'}>
                <Form.Label className={'d-flex align-items-center gap-2 mt-2'}>
                  <input
                    defaultChecked={showCol.name}
                    onChange={()=>setShowCol(old=>({...old,name:!old.name}))}
                    type={"checkbox"}
                  />{" "}
                  Скрыть колонку "Документ/Операция"
                </Form.Label>
                <Form.Label className={'d-flex align-items-center gap-2 mt-2'}>
                  <input
                    defaultChecked={showCol.name}
                    onChange={()=>setShowCol(old=>({...old,krs:!old.krs}))}
                    type={"checkbox"}
                  />{" "}
                  Показать колонки "КРС"
                </Form.Label>
              </div>
              {(prevRegister.length > 0 &&!edit) && prevRegister.map((r,i)=>{
                return <div key={i}>
                  В базе найдет регистр для <Link to={SalesRegisterEditRoute + r.id}
                                                  target={'_blank'}>{r.counterparty}</Link><br/>
                  Период: {r.start} - {r.end}<br/>
                  Оплачен: {r.isPaid ? <i className="fa fa-check-circle text-success"/> :
                  <i className="fa fa-times-circle text-danger"/>}
                </div>
              })}
              {(edit && data.isPaid) && <div className={'text-end'}>
                Оплачен: {data.markedPaidDate} {data.markedPaidBy}
              </div>}
              {isTransferred(arrayfromOneC)&&
                <h4 className={'text-danger text-center'}>В реестре есть ПЕРЕСАЖЕННЫЕ билеты! Исправьте перед отправкой клиенту!<br/>Пересаженные билеты окрашены красным</h4>}

              <div id="printarea">
                <BasicTable editbleArray={arrayfromOneC}
                            showCol={showCol}
                            edit={edit}
                            onlyOne={arrayfromOneC.length===1}
                            setEditableArray={(v)=>setArrayFromOneC(v)}
                            title={"Краткий реестр продаж по покупателю"}/>
                <BasicTable editbleArray={arrayfromBase}
                            showCol={showCol}
                            edit={edit}
                            onlyOne={arrayfromBase.length===1}
                            setEditableArray={(v)=>setArrayFromBase(v)}
                            title={"Краткий реестр продаж по покупателю (Туруслуги)"}/>
                    <>
                      {totalSumm && totalSumm.map((pax,i)=>{
                        return <div key={i}>
                          Итого по {pax.name}: {numberFormat(pax.total)} KZT
                        </div>
                      })}
                    </>
              </div>
              <div className={'position-relative mt-3'}>
                <label className={'absolutLabel'}>Коментарий</label>
                <Form.Control
                  as="textarea"
                  rows="2"
                  value={description}
                  onChange={newVal => setDescription(newVal.target.value)}
                />
              </div>

            </> : <div>Задайте параметры и сформируйте таблицу</div>}
        </PortletBody>
        {/*}*/}

        {/*  </BlockUi>*/}

        {(arrayfromOneC && arrayfromBase) &&
          <PortletFooter>
            <LoadingButton
              title={"Сохранить таблицу"}
              type={'button'}
              onClick={saveRegister}
              disabled={saving || data.isDeleted === true}
              loading={saving}
            />
          </PortletFooter>}
      </Portlet>
    </div>

  );
};

export default SalesOneCounterparty;