import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const FormHookSelect = props => {
  const {
    defaultValue,
    style,
    name,
    children,
    className,
    errors,
    label,
    register
  } = props;
  let classNames = className ? className : "form-control";
  if (_.get(errors, register.name)) {
    classNames += " is-invalid";
  }
  return (
    <div style={{position: 'relative',...style}}>
      <label className={'absolutLabel'}>{label}</label>
      <select
        defaultValue={defaultValue}
        className={classNames}
        {...register}
      >
        <option value="" disabled></option>
        {children}
      </select>
    </div>

  );
};

FormHookSelect.propTypes = {
  //defaultValue: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  errors: PropTypes.object
};
export default FormHookSelect;
