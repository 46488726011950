import React, {useEffect, useState} from "react";
import {Row, Col, FormLabel, FormControl, Form} from "react-bootstrap";
import PaxesSelector from "../../components/paxesSelector";
import CurrencyPicker from "../../../base/components/forms/currencySelector";
import FormHookInput from "../../../air/airBooking/components/formHookInput";
import FormHookDatePicker from "../../../base/components/forms/formHookDatePicker";
import {Button} from "@material-ui/core";
import moment from "moment";
import AirBookingSegmentsForm from "../../../air/airBooking/components/airBookingSegmentsForm";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../components/Portlet";
import ParseInput from "../../../air/airBooking/components/parseInput";
import RemoveLink from "../../../air/airBooking/components/removeLink";
import {useFieldArray} from "react-hook-form";
import OfferFlightPrices from "./offerFlightPrices";
import DescriptionsBlock from "../../components/descriptionsBlock";
import _ from "lodash";

const OfferFlightForm = ({
                           removeItem,
                           item,
                           prefix,
                           index,
                           offer,
                           formController
                         }) => {
  const {
    register,
    setValue,
    getValues,
    formState: {errors},
    control
  } = formController;
  const segmentsControl = useFieldArray({
    control,
    name: `${prefix}.segments`,
    rules: {required: true},
    shouldUnregister: true,
    keyName: 'customId'
  });
  return (
    <Portlet>
      <PortletHeader
        title={item && item.name ? item.name : "Перелет"}
        toolbar={
          <PortletHeaderToolbar>
            <ParseInput url={"/api/air/parse/airBooking"} keyValue={'airBooking'} placeholder={"Парс авиа"}
              setter={data => {
                segmentsControl.remove();
                segmentsControl.prepend([...data.booking.segments]);
                //setValue(`${prefix}.items[${index}].segments`, data.booking.segments)
              }}
            />
            <RemoveLink onClick={() => removeItem()} title={"перелет"}/>
          </PortletHeaderToolbar>
        }
      />
      <PortletBody>
        <input
          type={"hidden"}
          {...register(`${prefix}.type`)}
          defaultValue={"ticket"}
        />
        <input
          type={"hidden"}
          {...register(`${prefix}._id`)}
          defaultValue={item._id}
        />

        <Row>
          <Col xs={6}>
            <FormLabel>Название </FormLabel>
            <FormHookInput
              defaultValue={item.name}
              register={register(`${prefix}.name`, {
                required: true,
                minLength: 2
              })}
              errors={errors}
            />
          </Col>
          <Col xs={3}>
            <FormLabel>Валюта</FormLabel>
            <CurrencyPicker
              value={item.currency}
              formController={formController}
              name={`${prefix}.currency`}
              errors={errors}
            />
          </Col>
          {offer && <Col xs={1}>
            <FormLabel>Порядок</FormLabel>
            <FormHookInput
              defaultValue={item.order}
              type={"number"}
              register={register(`${prefix}.order`)}
            />
          </Col>}
        </Row>
        <AirBookingSegmentsForm
          data={item}
          prefix={`${prefix}.segments`}
          segmentsController={segmentsControl}
          formController={formController}
        />
        {/*{_.get(errors, `${prefix}.segments`)?.root ? (*/}
        {/*  <p className={"text-danger"}>Минимум 1 сегмент</p>*/}
        {/*) : null}*/}
        <OfferFlightPrices
          prefix={`${prefix}.prices`}
          items={item.prices}
          formController={formController}
        />

        <DescriptionsBlock
          title={"перелета"}
          descriptions={item.descriptions}
          formController={formController}
          prefix={`${prefix}.descriptions`}
        />
      </PortletBody>
    </Portlet>
  );
};

export default OfferFlightForm;
