import React, {useState} from 'react';
import {Button} from "reactstrap";

const basic = {label:'Ссылка для скачивания', class:"btn btn-outline-primary btn-sm ml-5"}
const success = {label:'Ссылка скопирована! Сейчас откроется новое окно.', class:"btn btn-outline-success btn-sm ml-5"}
const CopyDownloadLink = ({url}) => {
  const [label, setLabel] = useState(basic)

  const [error, setError] = useState(false)
  let [classN, setClassN] = useState("btn btn-outline-primary btn-sm ml-5")

  return (
    <div>
      <button onClick={()=> {
        try {
          window.navigator.clipboard.writeText(`${url}`)
            .then(() => {
              setLabel(success)
              setTimeout(()=>{
                window.open()
              }, 1000)
            }).then(()=>{
            setTimeout(()=>{
              setLabel(basic)
            }, 2000)
          })
        } catch (err){
          console.log(err)
          setError(true)
        }
      }} className={label.class}>{label.label}</button>
      <div>{error && url}</div>
    </div>
  );
};

export default CopyDownloadLink;