import React, {useState} from "react";
import {connect} from "react-redux";
import {AirBookingCreateRoute, AirBookingEditRoute} from "../../../routesConstants";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../../components/Portlet";
import {Link} from "react-router-dom";
import DataTable from "../../../../components/dataTable";
import PortletHeaderSearch from "../../../base/components/forms/portlerHeaderSearch";
import Button from 'react-bootstrap/Button';
import {useNavigate} from "react-router";
import AmadeusButton from "../components/amadeusButton";

const AirlineList = (props)=>{
  const columns = [
    {
      Header: 'Код бронирования',
      accessor: 'bookingNumber',
      Cell: ({value, row}) => <Link to={AirBookingEditRoute + row.original._id } >{value}</Link>
    },
    {
      Header: 'Пассажиры',
      accessor: 'paxes',
      Cell: ({value, row}) => <span>{value.map((pax,index)=><span key={index}>{pax.lastName} {pax.firstName}<br/></span>)}</span>
    },
    {
      Header: 'Маршрут',
      accessor: 'route',
    },
    {
      Header: 'Дата выписки',
      accessor: 'issueDate',
      Cell: ({value, row}) => <span>{value}</span>
    },
    {
      Header: 'Дата создания',
      accessor: 'createdAt',
      Cell: ({value, row}) => <span>{value}</span>
    },
    {
      Header: "Действия",
      Cell: ({value, row}) => (<Link className={"btn btn-sm btn-clean btn-icon btn-icon-md"} to={AirBookingEditRoute + row.original._id}><i className={"la la-edit"}/></Link>)
    }
  ]

  const [query, setQuery] = useState("");
  const navigate = useNavigate()
  return <>
        <div className={"kt-grid__item"}>
          <Portlet>
            <PortletHeader
              title={"Список авиа бронирований"}
              toolbar={
                <PortletHeaderToolbar>
                  <div className={'d-flex gap-2 align-items-center'}>
                    <AmadeusButton/>
                    <PortletHeaderSearch value={query} cb={(v)=>{setQuery(v)}}/>
                    {/*<Button variant="primary" onClick={()=>navigate(AirBookingCreateRoute)}>*/}
                    {/*  <i className="la la-plus"/>*/}
                    {/*  Добавить*/}
                    {/*</Button>*/}
                    <Link to={AirBookingCreateRoute} className="btn btn-sm btn-primary">
                      <i className="la la-plus"/>
                      Добавить
                    </Link>
                  </div>


                </PortletHeaderToolbar>
              }
            />
            <PortletBody>
              <DataTable columns={columns} search={query} url={`/api/air/airBooking`}/>
            </PortletBody>
          </Portlet>
        </div>
  </>
}


export default connect(null)(AirlineList)
