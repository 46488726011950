import React, {useEffect, useState} from 'react';
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import {Modal} from "react-bootstrap";
import DocumentsForm from "../../documents/components/documentsForm";
import Loader from "../../../components/loader";
import EditContacts from "./editContacts";
import CreateContacts from "./createContacts";

const CreateEditModal = ({children,title,className, toggle,id,setRefetch}) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
    <>
      <div onClick={handleShow} className={className? className:'link-info pointer'}>{children}</div>
      <Modal show={show} dialogClassName="modal-90w" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{id ? 'Редактировать контакты' :'Создание контактной информации'}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          {id ? <EditContacts id={id}
                              closed={()=> {
                                setShow(false)
                                setRefetch(Math.random())
                              }}/> :
            <CreateContacts closed={()=> {
            setShow(false)
            setRefetch(Math.random())
          }
          }/>}
        </Modal.Body>
      </Modal>

    </>
  );
};

export default CreateEditModal;