import React, { useEffect, useState } from "react";
import {
  Portlet,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../components/Portlet";
import { connect } from "react-redux";
import { DeleteItem, GetItem, UpdateItem } from "../../../redux/actions/base";
import { useParams } from "react-router-dom";
import BlockUi from "@availity/block-ui";
import { Dropdown, Row, Col } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import { CounterpartyListRoute } from "../../routesConstants";
import CounterPartyForm from "../components/counterPartyForm";
import SupSuppliersListByCp from "../components/supSuppliersListByCp";

const CounterPartyEditPage = props => {
  const [counterparty, setCounterparty] = useState(null);
  const [loader, setLoader] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    if (counterparty === null) {
      setLoader(true);
      props.GetItem("/api/counterparty", id, res => {
        setCounterparty(res.data.data);
        setLoader(false);
      });
    }
  }, [counterparty, id, props]);
  const callback = (values, cb) => {
    props.UpdateItem(
      "/api/counterparty/" + id,
      values,
      cb,
      CounterpartyListRoute
    );
  };
  const deleteAction = () => {
    setLoader(true);
    props.DeleteItem(
      "/api/counterparty/" + id,
      () => {
        setLoader(false);
      },
      CounterpartyListRoute
    );
  };
  return (
    <>{ counterparty &&
      <Row>
        <Col lg={6}>
          <Portlet>
            <PortletHeader
              title={"Редактирование контрагента"}
              toolbar={
                <PortletHeaderToolbar>
                  <BlockUi blocking={loader}>
                    <DropdownButton variant="outline-danger" title="Удалить">
                      <Dropdown.Item as="button" onClick={deleteAction}>
                        Да! Удалить
                      </Dropdown.Item>
                    </DropdownButton>
                  </BlockUi>
                </PortletHeaderToolbar>
              }
            />
            <BlockUi blocking={loader}>
              <CounterPartyForm onSubmit={callback} data={counterparty}/>
            </BlockUi>
          </Portlet>
        </Col>

        {id && <Col lg={6}>
          <SupSuppliersListByCp counterpartyData={{
            counterparty: id,
            amadeusCode: counterparty.amadeusCode
          }}/>
        </Col>}
      </Row>}
    </>
  );
};

export default connect(null, { GetItem, UpdateItem, DeleteItem })(
  CounterPartyEditPage
);
