import React, {useEffect, useState} from 'react';
import {Portlet, PortletBody, PortletHeader} from "../../../components/Portlet";
import {Table} from "reactstrap";
import _ from 'lodash'
import numberFormat from "../../../helpers/numberFormat";
import OsvItem from "./OsvItem";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import BlockUi from "@availity/block-ui";

const OsvTable = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const style = {textAlign:'center'}

  useEffect(() => {
    getItems()
  }, []);
  const getItems = () =>{
    setLoading(true)
    axios.post("/api/touristServices/unpaidOrders")
      .then(({data})=>{
        console.log(data)
        setData(data)
      })
      .catch(sayError).finally(()=>setLoading(false))
  }
  return (
    <Portlet>
      <PortletHeader title={'Таблица долгов по контрагентам без документов'}/>
      <BlockUi blocking={loading} >
        <PortletBody>
        <Table striped bordered hover>
          <thead>
          <tr>
            <th>Контрагент</th>
            <th>Ссылка</th>
            <th style={style}>Дата</th>
            <th style={style}>Долг</th>
            <th style={{...style, width:'100px'}}>Оплатить</th>
          </tr>
          </thead>
          <tbody>
            {data && data.map((counterparty,index)=>{
              return <React.Fragment key={index}>
                  {counterparty.orders.map((order, i) => {
                    return <tr key={i}>
                      {i === 0 && <td rowSpan={counterparty.orders.length}>
                        <b>{counterparty.name}<br/></b>
                        Всего неоплаченно: {counterparty.orders.length}<br/>
                        Сумма долга: {numberFormat(_.sumBy(counterparty.orders, 'total'))} KZT
                      </td>}
                      <OsvItem order={order} cb={getItems}/>
                    </tr>
                  })}
                {/*</tr>*/}
              </React.Fragment>
            })}
            {data && data.length === 0 && 'Долгов по тур услугам нет'}
          </tbody>
        </Table>
      </PortletBody>
      </BlockUi>
    </Portlet>
  );
};

export default OsvTable;