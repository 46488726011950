import React, {useEffect, useState} from 'react';
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import CreditCard from "../components/Card";
import DataTable from "../../../components/dataTable";
import CardSelect from "../components/cardSelect";
import {banksLogo} from "../components/banksLogo";
import numberFormat from "../../../helpers/numberFormat";
import Loader from "../../../components/loader";
import axios from "../../../helpers/axios";
import _ from 'lodash'

const CardsList = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] =useState(null)
  const [query, setQuery] = useState();
  const [refetch, setRefetch] = useState(Math.random())

  const columns = [
    {
      Header: "Карта",
      accessor: "card",
      Cell: ({ value, row }) => {
        return <div style={{display:"flex",alignItems:'center'}}>
          <div >{banksLogo[row.original.card.bank] || row.original.card.bank}</div>
          .... {row.original.card.cardNumber} {row.original.card.user}
        </div>
      }
    },
    {
      Header: "Дата транзакции",
      accessor: "transactionDate"
    },
    {
      Header: "Сумма",
      accessor: "balanceChange",
      Cell: ({ value, row }) => {
        console.log('row', row)
        return <div style={{color: Math.sign(value) === -1 ? 'red' : 'green'}}>{numberFormat(value)}</div>
      }
    },
    {
      Header: "Комментарий",
      accessor: "message"
    },
    {
      Header: "Дата создания/Кем создан",
      Cell: ({ value, row }) => {
        console.log('row', row)
        return <>{row.original.createdAt} {row.original.createdBy}</>
      }
    },


  ]
  useEffect(() => {
    axios.post('/api/bankCards/')
      .then(({data})=>{

        setData(data.items)
        setLoading(false)
      })
      .catch(e=>console.log('err',e))

  }, [refetch]);
  if(loading){
    return <Loader/>
  }

  return (
    <>
      <Portlet>
        <PortletHeader title={'Карты'} toolbar={<PortletHeaderToolbar >
          <div style={{marginRight: 10}}>Всего:</div> <b>{numberFormat(_.sumBy(data, function(c) { return c.balance; }))}</b>
        </PortletHeaderToolbar>}/>
        <PortletBody>
          <div style={{display:'flex', alignItems:'center', gap:10, flexWrap: 'wrap'}}>
            {data.map((card,index)=>{
              return <CreditCard card={card} key={index} cb={()=>setRefetch(n=>Math.random()+n)}/>
            })}
          </div>

        </PortletBody>
      </Portlet>
      <Portlet>
        <PortletHeader title={'Транзакции'} toolbar={
          <PortletHeaderToolbar>
            <CardSelect cb={(data)=>setQuery(data)} cards={data}/>
          </PortletHeaderToolbar>}/>
        <PortletBody>
          <DataTable search={query} url={"/api/bankCards/transactions/"} refetch={refetch} columns={columns} />
        </PortletBody>
      </Portlet>
    </>
  );
};

export default CardsList;

