export const SAVE_CURRENCIES_TYPE = "save_currencies_type";
export const LOAD_CURRENCIES_TYPE = "load_currencies_type";
export const LOAD_AIRPLANES_TYPE = "LOAD_AIRPLANES_TYPE";
export const SAVE_AIRPLANES_TYPE = "save_airplanes_type";
export const SET_COUNTERPARTY_IGNORE = "set_counterparty_ignore";

export const SaveCurrenciesAction = currencies => {
  return {
    type: SAVE_CURRENCIES_TYPE,
    payload: { currencies }
  };
};

export const LoadCurrenciesAction = () => {
  console.log("LOAD_CURRENCIES_TYPE", LOAD_CURRENCIES_TYPE);
  return {
    type: LOAD_CURRENCIES_TYPE
  };
};
export const SaveAirplanesAction = airplanes => {
  return {
    type: SAVE_AIRPLANES_TYPE,
    payload: { airplanes }
  };
};
export const LoadAirplanesAction = () => {
  return {
    type: LOAD_AIRPLANES_TYPE
  };
};

export const SetCounterpartyIgnore = counterparties => {
  return {
    type: SET_COUNTERPARTY_IGNORE,
    payload: counterparties
  };
};
