import React, {useState} from "react";
import axios from "../../../../helpers/axios";
import {Link} from "react-router-dom";
import {ParsersCheckCreateRoute, ParsersCheckEditRoute} from "../../../routesConstants";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../../components/Portlet";
import DataTable from "../../../../components/dataTable";
import {connect} from "react-redux";
import {DeleteItem} from "../../../../redux/actions/base";
import BlockUi from "@availity/block-ui";
import {Tab, Tabs} from "react-bootstrap";
import ListActions from "../components/listActions";

const ParsersList = (props) =>{
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [tabIndex, setTabIndex] = useState("home");

  const remove = id => {
    if(window.confirm('Вы уверены что хотите удалить?')){
      setLoading(true);
      axios
        .delete(`/api/parseCheck/${id}`)
        .then(()=>{
          setLoading(false);
          setRefetch(!refetch);
        })
        .catch((e)=>console.log('err',e))
  }};
  const columns = [
    {
      Header: "Ошибки",
      accessor: "message",
      Cell: ({ value, row }) => (
        <Link to={ParsersCheckEditRoute + row.original.id}>{value.substring(0, 20)}{value.length>20 && '...'}</Link>
      )
    },
    {
      Header: "Тип",
      accessor: "parseType"
    },
    {
      Header: "Создан",
      accessor: "createdDate",
      Cell: ({ value, row }) =>{
        return `${value} ${row.original.createdBy.email}`
      }
    },

    {
      Header: "Проверен",
      accessor: "valid",
      Cell: ({ value, row }) => (
        <>
          {value ?
            <><i className={"fa fa-check-circle text-success"} /> {row.original.lastCheckedDate !== 'Invalid date' ? row.original.lastCheckedDate : 'Не проверено'}</>
           : <><i className={"fa fa-times-circle text-danger"} />  {row.original.lastCheckedDate !== 'Invalid date' ? row.original.lastCheckedDate : 'Не проверено'}</>}
        </>
      )
    },
    {
      Header: "Обработан",
      accessor: "isHandled",
      Cell: ({ value, row })=>(
        <div style={{marginLeft:'2rem', color:'#797878'}} >
          {value ? <i className={"fa fa-check-circle text-success"} /> : <i className={"fa fa-times-circle text-danger"}/>}
        </div>
      )
    },
    {
      Header: "Удалить",
      Cell: ({value, row}) =>(
        <div style={{textAlign:'center', color:'#797878'}} >
          <i className={"fa fa-trash"} style={{cursor:'pointer'}} onClick={()=>remove(row.original.id)}/>
        </div>

      )
    }

  ];
  return (
    <>
      <Tabs
        variant="pills"
        id="controlled-tab-example"
        activeKey={tabIndex}
        onSelect={key => setTabIndex(key)}
      >
        <Tab eventKey="home" title="Список">
          <Portlet>
            <PortletHeader
              title={"Список ошибок"}
              toolbar={
                <PortletHeaderToolbar>
                  <Link
                    to={ParsersCheckCreateRoute}
                    className="btn btn-sm btn-brand btn-elevate btn-icon-sm"
                  >
                    <i className="la la-plus" />
                    Добавить
                  </Link>
                </PortletHeaderToolbar>
              }
            />
            <BlockUi blocking={loading}>
              <PortletBody>
                <DataTable url={"/api/parseCheck"}
                           columns={columns}
                           refetch={refetch}/>
              </PortletBody>
            </BlockUi>
          </Portlet>
        </Tab>
        <Tab eventKey="profile" title="Действия"  className="tab-no-background">
          <ListActions/>
        </Tab>
      </Tabs>
    </>
  );
}
export default connect(() => ({}), { DeleteItem })(ParsersList)