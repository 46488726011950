export const etcTemplate = {
  type: "etc",
  paxes: [],
  photos: [],
  documents: [],
  descriptions: [],
  timeline: []
};
export const transferTemplate = {
  type: "transfer",
  paxes: [],
  photos: [],
  documents: [],
  descriptions: [],
  transports: [],
  points: []
};
export const charterTemplate = {
  type: "charter",
  paxes: [],
  segments: [],
  documents: [],
  visibleToAccounts: []
};
export const flightTemplate = {
  type: "ticket",
  segments: []
};
export const hotelTemplate = {
  type: "hotel",
  paxes: [],
  photos: [],
  documents: [],
  descriptions: [],
  rooms: [],
  roomGroups: []
};
