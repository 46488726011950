import {call, put, takeEvery, takeLatest} from "redux-saga/effects";
import {
  CREATE_ITEM,
  DELETE_ITEM,
  GET_ITEM,
  LOAD_LIST,
  START_PROCESS,
  UPDATE_ITEM,
  SEARCH_INPUT,
  HandleError
} from "../actions/base";
import ApiService from "../../services/apiService";
import {push} from "redux-first-history";

function* baseWrapper(action, fn) {
  const {link, cb, redirect} = action.payload;
  try {
    const res = yield fn({...action}, link, cb, redirect);
    if (cb) {
      cb(res);
    }
    console.log("res from baseWrapper", res);
    if (redirect) {
      yield put(push(redirect));
    }
  } catch (e) {
    console.log(e);
    console.log(e.response);
    console.log('find')
    cb(e.response);
    throw e;
  }
}

function* searchInputRequestWorker(action) {
  yield baseWrapper(action, function* (action, link) {
    return yield ApiService.post(
      link,
      action.payload.data,
      action.payload.data
    );
  });
}

function* watchSearchInputRequested() {
  yield takeEvery(SEARCH_INPUT, searchInputRequestWorker);
}

function* startProcessRequestWorker(action) {
  yield baseWrapper(action, function* (action, link) {
    yield put(HandleError(null));
    console.log('startProcess')
    return yield ApiService.post(
      link,
      action.payload.data,
      action.payload.data
    );
  });
}

function* deleteItemRequestWorker(action) {
  yield baseWrapper(action, function* (data, link, cb, redirect) {
    return yield ApiService.delete(link);
  });
}

function* watchDeleteItemRequested() {
  yield takeEvery(DELETE_ITEM, deleteItemRequestWorker);
}

function* watchStartProcessRequested() {
  yield takeEvery(START_PROCESS, startProcessRequestWorker);
}

function* getItemRequestWorker(action) {
  yield baseWrapper(action, function* (data, link, cb, redirect) {
    return yield ApiService.get(link);
  });
}

function* watchGetItemRequested() {
  yield takeEvery(GET_ITEM, getItemRequestWorker);
}

function* createItemRequestWorker(action) {
  yield baseWrapper(action, function* (data, link, cb, redirect) {
    return yield call(ApiService.put, link, action.payload.data);
  });
}

function* watchCreateItemRequested() {
  yield takeLatest(CREATE_ITEM, createItemRequestWorker);
}

function* updateItemRequestWorker(action) {
  console.log("updater", action);
  yield baseWrapper(action, function* (action, link, cb, redirect) {
    return yield call(ApiService.patch, link, action.payload.data);
  });
}

function* watchUpdateItemRequested() {
  yield takeLatest(UPDATE_ITEM, updateItemRequestWorker);
}

function* loadListRequestWorker(action) {
  yield baseWrapper(action, function* (action, link) {
    const {page, itemsPerPage, query} = action.payload;
    const result = yield call(ApiService.post, link, {
      page,
      itemsPerPage,
      query
    });
    return result;
  });
}

function* watchLoadListRequested() {
  yield takeEvery(LOAD_LIST, loadListRequestWorker);
}

export default [
  watchLoadListRequested,
  watchCreateItemRequested,
  watchGetItemRequested,
  watchUpdateItemRequested,
  watchDeleteItemRequested,
  watchStartProcessRequested,
  watchSearchInputRequested
];
