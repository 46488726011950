import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {RailCreateRoute} from "../../../routesConstants";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../../components/Portlet";
import SearchSelect from "../../../../components/searchSelect";
import axios from "../../../../helpers/axios";
import sayError from "../../../../helpers/sayError";
import DataTable from "../../../../components/dataTable";
import _ from 'lodash'
import {Contacts} from "@material-ui/icons";
import CreateEditModal from "../../components/createEditModal";
import CreateContacts from "../../components/createContacts";
import EditModal from "../../../documents/components/editModal";
import EditContacts from "../../components/editContacts";

const ListContacts = () => {
  const [query, setQuery] = useState({counterparty:''});
  const [counterpaties, setCounterpaties] = useState()
  const [refetch, setRefetch] = useState()

  // var data = [
  //   { id: "fmgbwe45", age: 24, gender: "male"   ,first:false},
  //   { id: "kjregh23", age: 27, gender: "female" ,first:false},
  //   { id: "kjfdhg87", age: 30, gender: "male" ,first:true},
  //   { id: "lsdjfk43", age: 10, gender: "female" ,first:false},
  // ]
  //
  // data.unshift(data.splice(data.findIndex(item => item.first === true), 1)[0])
  // console.log(data)
  const columns = [
    {
      Header: 'Контрагенты',
      accessor: 'counterparties',
      Cell: ({value,row})=>(<CreateEditModal id={row.original.id}>
        {value.map((t, i) => (<div key={i}>{t}<br/></div>))}
      </CreateEditModal>)
    },
    {
      Header: 'Основной контакт',
      accessor: 'contact',
      Cell: ({value,row})=>(<>{value.name}<br/>{value.phone && <>{value.phone}<br/></>}{value.email && <>{value.email}<br/></>}</>)
    }
  ]
  useEffect(() => {
    axios.post('/api/counterparty/searchInput')
      .then(({data})=>{
        console.log('bya', data)
        setCounterpaties(data.data)
      })
      .catch(sayError)
  }, []);

  return (
    <Portlet>
      <PortletHeader title={'Список контактов'} toolbar={
        <PortletHeaderToolbar>
          <div style={{width: 250}}>
            {counterpaties &&
              <SearchSelect
              onChange={v => {
                setQuery(q => ({counterparty: v}))
              }}
              defaultValue={query.counterparty}
              style={{marginRight:'5px'}}
              label={'Контрагент'}
              array={[{value: '', label: 'Все'}, ...counterpaties]}/>}
          </div>
          <CreateEditModal className={"btn btn-sm btn-primary"}
          ><i className="la la-plus"/>
            Добавить
          </CreateEditModal>

        </PortletHeaderToolbar>}/>
      <PortletBody>
        <DataTable columns={columns} refetch={refetch} search={query} url={`/api/counterparty/contacts`}/>
      </PortletBody>
    </Portlet>
  );
};

export default ListContacts;