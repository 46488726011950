import React, { useState } from "react";
import Form from "react-bootstrap/esm/Form";
import { StartProcess } from "../../../../redux/actions/base";
import { connect } from "react-redux";
import { InputGroup, Button, Modal } from "react-bootstrap";
import ParserSendMess from "../../../parser/components/parserSendMess";
import axios from "../../../../helpers/axios";

const ParseInput = ({ StartProcess, setter ,url, keyValue, placeholder, potentialBuyers}) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [parseInput, setParseInput] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onPasteAction = event => {
    const text = event.clipboardData.getData("Text");
    if (text) {
      setParseInput(text.trim());
      parseAirBooking(text.trim());
    }
  };
  const parseAirBooking = text => {
    setLoading(true);
    axios.post(url, { [keyValue]: text.trim() })
      .then(({ data }) => {
        console.log("hfgf1234", data);
        if (data.success) {
          setter(data.converted);
          potentialBuyers && potentialBuyers(data.potentialBuyers)
        }
        setLoading(false);
        handleClose();
      })
      .catch(e=>{
        console.log('err',e)
        window.alert(e.response.data.message[0])
      })
      .finally()
    // StartProcess(
    //   url,
    //   ({ data }) => {
    //     console.log("hfgf1234", data);
    //     if (data.success) {
    //       setter(data.converted);
    //     }
    //     setLoading(false);
    //     handleClose();
    //   },
    //   { [keyValue]: text.trim() }
    // );
  };

  return (
    <div className="form-inline mr-2">
      <InputGroup>
        {placeholder === 'Парс авиа' && <ParserSendMess parseText={parseInput} type={'airBooking'} />}

        {/*<InputGroup.Prepend>*/}
        <InputGroup.Text>
          {loading ? (
            <span>
              <i className="fa fa-spinner fa-spin"></i>
            </span>
          ) : (
            <span>
              <i className="la la-barcode"></i>
            </span>
          )}
        </InputGroup.Text>
        {/*</InputGroup.Prepend>*/}
        <Form.Control
          as="textarea"
          rows="1"
          onPaste={onPasteAction}
          value={parseInput}
          placeholder={placeholder}
          // onChange={newVal => setParseInput(newVal.target.value)}
        />
        {/*<InputGroup.Append>*/}
        <Button
          variant="outline-primary"
          className="text-center"
          onClick={handleShow}
        >
          <span className="fa fa-expand-alt" />
        </Button>

        {/*</InputGroup.Append>*/}
      </InputGroup>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Парс брони</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as="textarea"
            rows="20"
            value={parseInput}
            onChange={newVal => setParseInput(newVal.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Закрыть
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              parseAirBooking(parseInput);
            }}
          >
            Разпарсить
            {loading ? (
              <span>
                <i className="fa fa-spinner fa-spin"></i>
              </span>
            ) : null}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default connect(null, { StartProcess })(ParseInput);
