import { Route, Routes } from "react-router";
import React from "react";
import { DebtorCompareRoute } from "../../routesConstants";
import ErrorHandler from "../../base/components/errorHandler";
import DebtorCompare from "./pages/debtorCompare";
import { useSelector } from "react-redux";

export default function(props) {
  const { email } = useSelector(state => state.auth.user);
  return (
    <ErrorHandler>
      <Routes>
        {(email === "ykarmakov@gmail.com" ||
          email === "unoegohh@gmail.com") && (
          <Route path={"compare"} exact={true} element={<DebtorCompare />} />
        )}
      </Routes>
    </ErrorHandler>
  );
}
