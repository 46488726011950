import React, {useEffect, useState} from 'react';
import {useFieldArray} from "react-hook-form";
import {Col, Form, Row, Tab, Tabs} from "react-bootstrap";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import _ from "lodash";
import FormHookDatePicker from "../../base/components/forms/formHookDatePicker";
import CustomDatePicker from "../../base/components/forms/customDatePicker";
import FormHookSelect from "../../air/airBooking/components/formHookSelect";
import RailwayTicketForm from "./railwayTicketForm";

const ticketTemplate = {
  ticketNumber:'',
  lastName:'',
  firstName:'',
  middleName:'',
  documentNumber:'',
  uin:'',
  departure:'',
  arrivalDate:'',
  serviceClass:'',
  trainNumber:'',
  carriageNumber:'',
  place:'',
  ticketPrice:0,
  fee:0,
  agencyFee:0,
  totalPrice:0,
  status:'',
  refund: false
}
export const checkDate = (date, setValue)=>{
  if(date === 'Invalid date'){
    setValue()
    return ''
  } else{
    return date
  }
}
const TicketsFields = ({tickets, formController, prefix}) => {
  const {control, register, formState: {errors}, setValue, watch} = formController
  const [activeTab, setActiveTab] = useState('tic0')
  const ticketsControl = useFieldArray({
    control,
    name: `${prefix}`,
    rules: {required: true},
    shouldUnregister: true,
    keyName: 'customId',
  });
  useEffect(() => {
    ticketsControl.replace(tickets)
  }, [tickets]);

  const deleteTicket = (index) => {
    if(window.confirm('Вы уверены, что хотите удалить билет?')){
      ticketsControl.remove(index);
      if (ticketsControl.fields.length - 1) {
        setActiveTab('tic0');
      }
    }

  };
  const addTicket = () => {
    ticketsControl.append({...ticketTemplate});
    setActiveTab(`tic${ticketsControl.fields.length}`);
  };
  const tabClick = (key) => {
    if (key === "add") {
      addTicket()
    } else {
      setActiveTab(key);
    }
  };


  return (
    <>
      <h6>Информация по билетам ({ticketsControl.fields.length})</h6>
      <Tabs
        variant="pills"
        className={"paxTabs"}
        id="controlled-tab-example"
        activeKey={activeTab}
        onSelect={key => tabClick(key)}
      >
        {ticketsControl.fields.map((ticket, index)=>{
          return <Tab
            eventKey={`tic${index}`}
            key={ticket.customId}
            title={`Билет №${index+1}`}
            tabClassName={
              _.get(errors, `${prefix}[${index}]`) ? "is-invalid" : ""
            }
          >
            <RailwayTicketForm formController={formController}
                               key={index}
                               ticket={ticket}
                               deleteTicket={()=>deleteTicket(index)}
                               prefix={`${prefix}[${index}]`}/>
          </Tab>
        })}
        <Tab eventKey={"add"} title={<i className={"la la-plus"}/>}/>
      </Tabs>
    </>
  )
    ;
};

export default TicketsFields;