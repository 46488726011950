import React, {useState} from 'react';
import TemplateForm from "../components/templateForm";
import {connect} from "react-redux";
import {CreateItem} from "../../../../redux/actions/base";
import {TimeTableTemplateListRoute} from "../../../routesConstants";
import VarInfoTable from "../components/varInfoTable";


const TimeTableTemplateCreatePage = ({CreateItem}) =>{

  const callback = (values, cb)=>{
    CreateItem('/api/parsers/timeTable', values, cb, TimeTableTemplateListRoute )
  }
  return <>
    <TemplateForm onSubmit={callback} title={"Создание шаблона"}/>
  </>
}

export default connect(null, {CreateItem})(TimeTableTemplateCreatePage)
