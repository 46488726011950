import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import LoadingButton from "../../base/components/loadingButton";
import axios from "../../../helpers/axios";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router";
import {SalesRegisterListRoute} from "../../routesConstants";

const DeletedBtn = ({isDeleted}) => {
  const {id} = useParams();
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate()
  const deletedAndReDeleted = (e) =>{
    e.preventDefault();
    setLoading(true)
    const send = isDeleted ? {data:{restore: true}}: {}
    axios.delete("/api/salesRegister/"+id, send)
      .then(()=>{
        isDeleted ? window.location.reload() : navigate(SalesRegisterListRoute)
      })
      .catch(e=>{
      console.log('err',e)
      window.alert(e.response.data.message[0])
    })
  }
  return (

    <div>
      <LoadingButton
        title={isDeleted ? "Восстановить удаленный":"Удалить"}
        type={'button'}
        variant={isDeleted?'success':'danger'}
        onClick={deletedAndReDeleted}
        disabled={loading}
        loading={loading}
      />
    </div>
  );
};

export default DeletedBtn;