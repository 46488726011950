import React, { useEffect } from "react";
import { Button, Col, FormLabel, Row } from "react-bootstrap";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import PhotoInput from "../trip/components/tripFormParts/photoInput";
import RemoveLink from "../../air/airBooking/components/removeLink";
import { useFieldArray } from "react-hook-form";
import FormHookDatePicker from "../../base/components/forms/formHookDatePicker";
import TimeInput from "./TimeInput";

const TripCharterSegments = ({ formController, prefix, transports }) => {
  const {
    register,
    formState: { errors },
    formState,
    control
  } = formController;

  const segmentsControl = useFieldArray({
    control,
    name: `${prefix}`
  });
  const segmentTemplate = {
    connectionTime: "00:00",
    flightTime: "00:00",
    tailNumber: "",
    carrier: "Private Jet",
    arrivalTerminal: "",
    arrivalStartDate: "",
    arrivalStartTime: "",
    arrivalAirport: "",
    departureTerminal: "",
    departureStartDate: "",
    departureStartTime: "",
    departureAirport: ""
  };
  const addSegment = () => {
    segmentsControl.append({ ...segmentTemplate });
  };
  return (
    <>
      <h5>
        Сегменты{" "}
        <Button size={"sm"} onClick={() => addSegment()}>
          <i className={"fa fa-plus"}></i>
        </Button>
      </h5>
      {segmentsControl.fields.map((segment, index) => {
        return (
          <React.Fragment key={index}>
            <Row>
              <Col xs={2}>
                <FormLabel>Вылет</FormLabel>
                <FormHookInput
                  defaultValue={segment.departureAirport}
                  register={register(`${prefix}[${index}].departureAirport`, {
                    required: true
                  })}
                  errors={errors}
                />
              </Col>
              <Col xs={3}>
                <FormLabel>Дата</FormLabel>
                <FormHookDatePicker
                  formController={formController}
                  dateRules={{ required: true }}
                  date={segment.departureStartDate}
                  dateName={`${prefix}[${index}].departureStartDate`}
                  timeRules={{ required: true }}
                  time={segment.departureStartTime}
                  timeName={`${prefix}[${index}].departureStartTime`}
                  errors={errors}
                />
              </Col>
              <Col xs={2}>
                <FormLabel>Терминал</FormLabel>
                <FormHookInput
                  defaultValue={segment.departureTerminal}
                  register={register(
                    `${prefix}[${index}].departureTerminal`,
                    {}
                  )}
                  errors={errors}
                />
              </Col>
              <Col xs={2}>
                <FormLabel>Время в пути</FormLabel>
                <TimeInput
                  control={control}
                  name={`${prefix}[${index}].flightTime`}
                  value={segment.flightTime}
                  errors={errors}
                />
              </Col>
              <Col xs={1}>
                <FormLabel>Перевзчик</FormLabel>
                <FormHookInput
                  defaultValue={segment.carrier}
                  register={register(`${prefix}[${index}].carrier`, {})}
                  errors={errors}
                />
              </Col>
              <Col xs={2}>
                <FormLabel>Номер хвоста</FormLabel>
                <FormHookInput
                  defaultValue={segment.tailNumber}
                  register={register(`${prefix}[${index}].tailNumber`, {})}
                  errors={errors}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={2}>
                <FormLabel>Прилет</FormLabel>
                <FormHookInput
                  defaultValue={segment.arrivalAirport}
                  register={register(`${prefix}[${index}].arrivalAirport`, {
                    required: true
                  })}
                  errors={errors}
                />
              </Col>
              <Col xs={3}>
                <FormLabel>Дата</FormLabel>
                <FormHookDatePicker
                  formController={formController}
                  dateRules={{ required: true }}
                  date={segment.arrivalStartDate}
                  dateName={`${prefix}[${index}].arrivalStartDate`}
                  timeRules={{ required: true }}
                  time={segment.arrivalStartTime}
                  timeName={`${prefix}[${index}].arrivalStartTime`}
                  errors={errors}

                />
              </Col>
              <Col xs={2}>
                <FormLabel>Терминал</FormLabel>
                <FormHookInput
                  defaultValue={segment.arrivalTerminal}
                  register={register(`${prefix}[${index}].arrivalTerminal`, {})}
                  errors={errors}
                />
              </Col>
              <Col xs={2}>
                <FormLabel>Время посадки</FormLabel>
                <TimeInput
                  control={control}
                  name={`${prefix}[${index}].connectionTime`}
                  value={segment.connectionTime}
                  errors={errors}
                />
              </Col>
              <Col xs={2}>
                <RemoveLink
                  onClick={() => segmentsControl.remove(index)}
                  title={"транспорт"}
                />
              </Col>
            </Row>
          </React.Fragment>
        );
      })}
      {!segmentsControl.fields.length ? (
        <>
          <input
            type={"text"}
            style={{ display: "none" }}
            defaultValue={""}
            {...register(`${prefix}ss`, { required: true })}
          />
          {formState.isSubmitted ? (
            <p className={"text-danger"}>Минимум 1 сегмент</p>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default TripCharterSegments;
