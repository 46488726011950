import moment from 'moment'
import 'moment/locale/ru';
import {
  convertDate,
  replaceIfs,
  takeParams,
  getMapNames,
  getNormalValue,
  convertTrans,
  convertBase
} from '../../baseParser';
import _ from 'lodash'

const formatTimeTable = (template,language, booking, params) => {
  const  br = "\n";

  const convertRoute= function (route,i) {
    let iti = template.routeTemplate;
    if (iti !== null) {
      iti = replaceIfs(iti, route, routeMap);

      const snaps = takeParams(iti, getMapNames(routeMap));
      for (let j = 0; j < snaps.length; j++) {
        iti = iti.replace(snaps[j].capture,getNormalValue( routeMap[snaps[j].code](route, snaps[j], i)));
      }
    } else {
      iti = "";
    }

    iti = iti.replace(/^\s+|\s+$/g, '').replace(/\n\s*\n/g, '\n');
    return iti;
  }
  // var convertLeg = function (leg, segment) {
  //   var lg = pattern.Segment;
  //   function merge_options(obj1, obj2) {
  //     var obj3 = {};
  //     for (var attrname in obj1) { obj3[attrname] = obj1[attrname]; }
  //     for (var attrname in obj2) { obj3[attrname] = obj2[attrname]; }
  //     return obj3;
  //   }
  //
  //   var map = merge_options(legMap, segmentMap);
  //   lg = InitParser.replaceIfs(lg, leg, map);
  //
  //   var snaps = InitParser.takeParams(lg, InitParser.getMapNames(legMap));
  //   for (var j = 0; j < snaps.length; j++) {
  //     lg = lg.replace(snaps[j].capture, InitParser.getNormalValue(legMap[snaps[j].code](leg, snaps[j])));
  //   }
  //   var snaps2 = InitParser.takeParams(lg, InitParser.getMapNames(segmentMap));
  //   for (var j = 0; j < snaps2.length; j++) {
  //     lg = lg.replace(snaps2[j].capture, InitParser.getNormalValue(segmentMap[snaps2[j].code](segment, snaps2[j])));
  //   }
  //   lg = lg.replace(/^\s+|\s+$/g, '').replace(/\n\s*\n/g, '\n');
  //   return lg;
  // }
  const convertSegment = (segment, i) =>  {
    let seg = template.segmentTemplate;
    seg = replaceIfs(seg, segment, segmentMap);

    const snaps = takeParams(seg, getMapNames(segmentMap));

    for (let j = 0; j < snaps.length; j++) {
      seg = seg.replace(snaps[j].capture, getNormalValue(segmentMap[snaps[j].code](segment, snaps[j])));
    }

    seg = seg.replace(/^\s+|\s+$/g, '').replace(/\n\s*\n/g, '\n');
    return seg;
  };

  const patternMap = {
    "APC": (booking)=> booking.arrivalCode ,
    "DPC": (booking)=> booking.departureCode ,
    "TTD": (booking,params)=> convertDate(booking, params, booking.date, language),
    "TT":  (booking) => {
      let routes = "";
      booking.routes.forEach((route, i)=>{
        routes += convertRoute(route, i);
        routes += br;
      });
      return routes;
    },
    "CMT": () => getNormalValue(params.comment),
    "AA": (booking) => booking.airports[booking.arrivalCode][language].name,
    AC: (booking) => booking.airports[booking.arrivalCode][language].cityName,
    "DA": (booking) => booking.airports[booking.departureCode][language].name,
    DC: (booking) => booking.airports[booking.departureCode][language].cityName,
  }
  // var legMap = {
  //
  //   "APC": function (leg) {
  //     return leg.ArrivalCode;
  //   },
  //   "DPC": function (leg) {
  //     return leg.DepartureCode;
  //   },
  //   "DT": function (itinerary, params) {
  //     return InitParser.convertDate(itinerary, params, language, itinerary.DepartureDate);
  //   },
  //   "AT": function (itinerary, params) {
  //     return InitParser.convertDate(itinerary, params, language, itinerary.ArrivalDate);
  //   },
  //   "TT": function (leg) {
  //     return leg.FlightTime;
  //   },
  //   "LT": function (leg) {
  //     return leg.LandingTime;
  //   },
  //   "APL": function (leg) {
  //     return airplaneTrans[leg.Airplane];
  //   },
  //   "AA": function (leg) {
  //     if (language == "RU") {
  //       return translations[leg.ArrivalCode].RuName;
  //     } else {
  //       return translations[leg.ArrivalCode].EngName;
  //     }
  //   },
  //   "AC": function (leg) {
  //     if (language == "RU") {
  //       return translations[leg.ArrivalCode].CityRuName;
  //     } else {
  //       return translations[leg.ArrivalCode].CityEngName;
  //     }
  //   },
  //   "DA": function (leg) {
  //     if (language == "RU") {
  //       return translations[leg.DepartureCode].RuName;
  //     } else {
  //       return translations[leg.DepartureCode].EngName;
  //     }
  //   },
  //   "DC": function (leg) {
  //     if (language == "RU") {
  //       return translations[leg.DepartureCode].CityRuName;
  //     } else {
  //       return translations[leg.DepartureCode].CityEngName;
  //     }
  //   },
  // }
  const segmentMap = {
    "ALO":  (segment) => booking.airlines[segment.operatingAirCompany],
    "ALS": (segment) => booking.airlines[segment.airline],
    "APC":  (segment)=> segment.arrival.airport,
    "DPC":  (segment)=> segment.departure.airport,
    "OAC": (segment) => segment.operatingAirCompany,
    "SAC": (segment) => segment.airline,
    // "TPT": function (segment) {
    //   return segment.LandingTime;
    // },
    //
    // "APL": function (segment) {
    //   return airplaneTrans[segment.Airplane];
    // },
    'APL': (segment) => {
      const airplane = _.find(booking.airplanes, {iataCode: segment.airplaneFsCode});
      if(airplane){
        return airplane.name? airplane.name: airplane.originalName
      }
    },
    "CTD": (segment) => moment.utc(segment.dockingTime).format('HH:mm'),

    "CT": (segment) => moment.utc(segment.connectionTime).format('HH:mm'),
    "DTR": (segment) => segment.departure.terminal,
    "ATR": (segment) => segment.arrival.terminal,
    "DT":  (segment, params) => convertDate(segment, params, segment.departure.date, language),
    "AT": (segment, params) => convertDate(segment, params,  segment.arrival.date, language),
    // "LT": function (segment) {
    //   return "";
    // },
    // "AM": function (segment) {
    //   return segment.ArrivalModifier;
    //},
    "DM": (segment) => segment.departureModifier,
    "FN": (segment, params) => {
      if (template.alignNumbers !== 'none') {
        var symbol = template.alignNumbers === 'zero' ? "0" : " ";
        var resulty = "";
        for (var i = segment.FlightNumber.length; i < 4; i++) {
          resulty += symbol;
        }
        segment.FlightNumber = resulty + segment.flightNumber;
      }
      return segment.flightNumber;
    },
    "TT": (segment) => moment.utc(segment.flightTime).format('HH:mm'),
    "AA": (segment) => booking.airports[segment.arrival.airport][language].name,
    "AC": (segment) => booking.airports[segment.arrival.airport][language].cityName,
    "DA": (segment) => booking.airports[segment.departure.airport][language].name,
    "DC": (segment) => booking.airports[segment.departure.airport][language].cityName,
  }
  const routeMap = {
    "DOF": (route, params) => {
      let res = "";
      let sep = ",";
      if (typeof params != "undefined" &&  typeof params.settings != "undefined" && typeof params.settings.sep != "undefined") {
        sep = params.settings.sep;
      }
      let format = "ddd";

      if (typeof params != "undefined" && typeof params.param != "undefined") {
        format = params.param;
      }
      if (typeof (route.flightDays) != "undefined"){
        for (var i = 0; i < route.flightDays.length; i++) {
          if (route.flightDays[i] == "7") {
            route.flightDays[i] = "0";
          }
          res += moment(route.flightDays[i], "e").locale(language).format(format);
          if (route.flightDays.length - 1 != i) {
            res += sep;
          }
        }
      }
      return res;
    },
    "SGM": (route) => {
      let SGMS = "";
      if (template.segmentTemplate != null) {
        route.segments.forEach((segment, i)=>{
          SGMS += convertSegment(segment);
          if (route.segments.length - 1 !== i) {
            SGMS += br;
          }
        })
      }
      return SGMS;
    },
    "APC": (route) =>  route.arrival.airport,
    "DPC": (route) =>  route.departure.airport,
    "DT":  (route, params) => convertDate(route, params, route.departure.date, language),
    "AT": (route, params) => convertDate(route, params,  route.arrival.date, language),
    "II": (route, params,index) => index+1,
    "TT": (route) => moment.utc(route.flightTime).format('HH:mm'),
    "AA": (route) => booking.airports[route.arrival.airport][language].name,
    "AC": (route) => booking.airports[route.arrival.airport][language].cityName,
    "DA":  (route) => booking.airports[route.departure.airport][language].name,
    "DC": (route) => booking.airports[route.departure.airport][language].cityName,
  };
  const result = convertBase( template.mainTemplate, booking, patternMap, language)
  return result;
}

export default formatTimeTable;
