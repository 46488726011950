import React from "react";
import Form from "react-bootstrap/esm/Form";
import { Col, Row, Badge, Button } from "react-bootstrap";
import { Formik, FieldArray } from "formik";
import CustomDatePicker from "../../../../components/customDatePicker";
import { mealTypes, titles } from "../../../../helpers/enums";
import RemoveLink from "./removeLink";
import FormHookSelect from "./formHookSelect";
import FormHookInput from "./formHookInput";
import _ from "lodash";

export const AirBookingPaxItemForm = ({
  pax,
  formController,
  prefix,
  deletePax
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue
  } = formController;

  return (
    <Row className="align-items-center segmentContainer">
      {/*<Col xs="1">*/}
      {/*№ {index+1} <i className={"la la-times"} onClick={()=>{arrayHelpers.remove(index)}}/>*/}
      {/*</Col>*/}

      <input
        defaultValue={pax.id}
        {...register(`${prefix}.id`)}
        type={"hidden"}
        className={"form-control"}
      />
      <Col xs="2">
        <FormHookSelect
          defaultValue={pax.title}
          label={'Обращение'}
          errors={errors}
          register={register(`${prefix}.title`, { required: true })}
        >
          {titles.map((item, i) => (
            <option value={item} key={i}>
              {item}
            </option>
          ))}
        </FormHookSelect>
      </Col>
      <Col xs="3">
        <FormHookInput
          register={register(`${prefix}.lastName`, {
            required: true,
            onChange: e => {
              e.target.value = ("" + e.target.value).toUpperCase();
            }
          })}
          defaultValue={pax.lastName}
          label={'Фамилия'}
          inputstyle={{"text-transform": "uppercase"}}
          onChange={e => {
            e.target.value = ("" + e.target.value).toUpperCase();
          }}
          errors={errors}
        />
      </Col>
      <Col xs="3">
        <FormHookInput
          onChange={e => {
            e.target.value = ("" + e.target.value).toUpperCase();
          }}
          defaultValue={pax.firstName}
          register={register(`${prefix}.firstName`, {
            required: true,
            onChange: e => {
              e.target.value = ("" + e.target.value).toUpperCase();
            }
          })}
          label={'Имя'}
          inputstyle={{"text-transform": "uppercase"}}
          errors={errors}
        />
      </Col>
      <Col xs="4">
        <RemoveLink onClick={deletePax} title={"пассажира"} />
      </Col>
    </Row>
  );
};
//
// const AirBookingPaxesForm = ({paxes, formik}) => {
//
//   const paxTemplate = {
//     lastName: '',
//     firstName: '',
//     title: titles[0]
//   };
//
//   return <div>
//     <Row>
//         <Col sm={12}>
//           <Form.Group className={'formTitle'}>
//           <FieldArray
//             name="paxes"
//             render={arrayHelpers => (
//               <>
//                 <h6>Пассажиры <Badge onClick={() => arrayHelpers.push({...paxTemplate})} variant="success"><i className={"la la-plus"}/> Добавить</Badge></h6>
//               {paxes.length?<Row className="align-items-center">
//                   <Col xs={{ span: 2, offset: 1 }}>
//                     <Form.Label>
//                       Обращение
//                     </Form.Label>
//                   </Col>
//                   <Col xs={{ span: 3 }}>
//                     <Form.Label>
//                       Имя
//                     </Form.Label>
//                   </Col>
//                   <Col xs="3">
//                     <Form.Label>
//                       Фамилия
//                     </Form.Label>
//                   </Col>
//                 </Row>:null}
//                 {paxes.map((pax, index) => <AirBookingPaxItemForm pax={pax} formik={formik} index={index} key={index} arrayHelpers={arrayHelpers}/>)}
//               </>
//               )}
//           />
//           </Form.Group>
//         </Col>
//     </Row>
//   </div>
// }
//
// export default AirBookingPaxesForm;
