import React, {useState} from 'react';
import {Col, Form, Modal, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import BlockUi from "@availity/block-ui";
import ReSaveReportButton from "./reSaveReportButton";

const UpdateRevise = ({options, cb, title, data,revComment}) => {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [updatingInfo,setUpdatingInfo] = useState()
  const [comment, setComment] = useState(revComment)
  console.log(options)

  const sendRequest = (data,file) =>{
    setLoading(true)
    setError(null)
    axios.post(options.url, data)
      .then((res)=>{
        console.log("res", res);
        cb(res.data, file);
        setUpdatingInfo({
          data: res.data,
          newFile: file
        })
      })
      .catch(e=>{
        setError(e.response.data.message[0])
      })
      .finally(()=>{
        setLoading(false);
      })
  }
  const upload = (value) =>{
    setLoading(true);
    const data = new FormData();
    value && data.append(`${options.file.registerName}`, value[0])
    data.append('targetWeek', options.targetWeek);
    data.append('targetYear', options.targetYear);
    data.append('targetMonth', options.targetMonth);

    sendRequest(data, {file: value[0], registerName: options.file.registerName, name: options.file.name})
  }
  const uploadOneC = () =>{
    const data = {
      targetWeek: options.targetWeek,
      targetYear: options.targetYear,
      targetMonth: options.targetMonth
    }
    if(options.file){
      data[options.file.registerName] = options.file.url
    }
    sendRequest(data, options.file)
  }
  return (
    <BlockUi blocking={loading}>
      <div className={'d-flex align-items-end gap-2'}>
        {options.file && <Row className={'mt-3'}>
          <Col>
            <div style={{position: 'relative'}}>
              <label className={'absolutLabel'}>{options.file.name}</label>
              <Form.Control
                type={"file"}
                disabled={loading}
                onChange={(e) => {
                  upload(e.target.files)
                }}
              />
            </div>
          </Col>
          {/*{loading && <div><i className="fa fa-spinner fa-spin"/></div>}*/}
        </Row>}
        <Button onClick={uploadOneC}
                variant={'outline-primary'}>
          {options.file ? `Обновить только ${options.oneCName} со старым файлом` : title}
        </Button>

      </div>
      {options.file && <div>Данные будут обновленны сразу после выбора файла</div>}
      {error && <div className={'text-danger'}>{error}</div>}
      <div className={'mt-3 d-flex gap-2 align-items-start'}>
        <Form.Control
          as="textarea"
          rows="1"
          style={{width:500}}
          onChange={(e)=>setComment(e.target.value)}
          value={comment}
          placeholder={'Комментарий'}
          // onChange={newVal => setParseInput(newVal.target.value)}
        />
        <ReSaveReportButton data={updatingInfo ? updatingInfo.data : data}
                            id={options.reviseId}
                            comment={comment}
                            newFile={updatingInfo ?  updatingInfo.file : undefined}
                            oldFile={options?.file?.id}
        />

      </div>

    </BlockUi>
  );
};

export default UpdateRevise;