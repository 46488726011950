import { Route, Routes } from "react-router";
import React from "react";
import {
  TripRoute,
  TripItemRoute,
  OfferRoute,
  TripUsersRoute
} from "../routesConstants";
import TripRouter from "./trip/routes";
import TripItemRouter from "./tripItem/routes";
import OfferRouter from "./offer/routes";
import UsersRouter from "./users/routes";

export default function() {
  return (
    <Routes>
      <Route path={"trip/*"} element={<TripRouter />} />
      <Route path={"offer/*"} element={<OfferRouter />} />
      <Route path={"items/*"} element={<TripItemRouter />} />
      <Route path={"users/*"} element={<UsersRouter />} />
    </Routes>
  );
}
