import React from "react";
import { Portlet, PortletHeader } from "../../../../components/Portlet";
import AirportForm from "../components/airportForm";
import { connect } from "react-redux";
import { CreateItem } from "../../../../redux/actions/base";
import { AirportListRoute } from "../../../routesConstants";

const AirportCreatePage = ({ CreateItem }) => {
  const callback = (values, cb) => {
    CreateItem("/api/locations/airports", values, cb, AirportListRoute);
  };
  return (
    <>
      <div className={"row"}>
        <div className="col-xl-6">
          <Portlet>
            <PortletHeader title={"Создание аэропорта"} />
            <AirportForm onSubmit={callback} />
          </Portlet>
        </div>
      </div>
    </>
  );
};

export default connect(null, { CreateItem })(AirportCreatePage);
