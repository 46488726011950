import {useForm} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";
import {
  Portlet,
  PortletBody,
  PortletFooter,
  PortletHeader
} from "../../../../components/Portlet";
import {Button} from "react-bootstrap";
import FormHookInput from "../../../air/airBooking/components/formHookInput";
import {connect} from "react-redux";
import {StartProcess} from "../../../../redux/actions/base";
import React, {useState} from "react";
import LoadingButton from "../../../base/components/loadingButton";
import moment from "moment";

const GenerateLoginToken = ({userId, StartProcess, url, tokens}) => {
  const [token, setToken] = useState();
  const [loading, setLoading] = useState();
  const onSubmit = () => {
    setLoading(true);
    StartProcess(
      url,
      ({data}) => {
        setLoading(false);
        if (data.success) {
          setToken(data.token);
        }
        console.log("data", data);
      },
      {id: userId}
    );
  };
  return (
    <Portlet>
      <PortletHeader title={"Генератор одноразвого входа"}/>
      <PortletBody>
        {token ? (
          <>
            <p>
              Ссылка для логина -
              <pre>
                {process.env.REACT_APP_TOURS_LINK}auth/{token}
              </pre>
            </p>
          </>
        ) : null}

        <h4>Прошлые токены</h4>
        {tokens.map((token, index) => {
          return <p
            key={index}>{process.env.REACT_APP_TOURS_LINK}auth/{token.token} - {moment(token.createdDate).fromNow()}</p>
        })}
      </PortletBody>
      <PortletFooter>
        <Row>
          <Col md={{span: 10, offset: 2}}>
            <LoadingButton
              title={"Сгенерировать"}
              onClick={onSubmit}
              loading={loading}
            />
          </Col>
        </Row>
      </PortletFooter>
    </Portlet>
  );
};

export default connect(null, {StartProcess})(GenerateLoginToken);
