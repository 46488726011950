import { LOGIN_PUT, LOGOUT } from "../actions/auth";

const initialState = {
  user: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
      console.log("logout");
      return { ...initialState };
    case LOGIN_PUT:
      return { ...state, user: action.payload };
    default:
      return state;
  }
}
