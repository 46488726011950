import React from "react";
import PhotosSelector from "../trips/components/PhotosSelector";
import CurrencySelector from "./components/forms/currencySelector";
import { useForm } from "react-hook-form";

const TestPage = () => {
  const form = useForm();
  return (
    <>
      <CurrencySelector formController={form} name={"123"} />
      <CurrencySelector formController={form} name={"123"} />
      <CurrencySelector formController={form} name={"123"} />
      <CurrencySelector formController={form} name={"123"} />
      <CurrencySelector formController={form} name={"123"} />
      <CurrencySelector formController={form} name={"123"} />
    </>
  );
};

export default TestPage;
