export const LOAD_LIST = 'load_List';
export const CREATE_ITEM = 'create_item';
export const UPDATE_ITEM = 'update_item';
export const GET_ITEM = 'get_item';
export const DELETE_ITEM = 'delete_item';
export const HANDLE_ERROR = 'handle_error';
export const START_PROCESS = 'start_process';
export const SEARCH_INPUT = 'search_input';

export const SearchInput = (link, cb, data) => {
  return {
    type: SEARCH_INPUT,
    payload: {link, cb, data}
  }
}
export const StartProcess = (link, cb, data) => {
  return {
    type: START_PROCESS,
    payload: {link, cb, data}
  }
}
export const HandleError = (status, message, errors) => {
  return {
    type: HANDLE_ERROR,
    payload: status? {status, message, errors}: null
  }
}
export const DeleteItem = (link, cb, redirect) => {
  return {
    type: DELETE_ITEM,
    payload: {link,  cb, redirect}
  }
}
export const LoadList = (link, page, itemsPerPage, query, cb) => {
  return {
    type: LOAD_LIST,
    payload: {link, page, itemsPerPage, query, cb}
  }
}
export const CreateItem = (link, data, cb, redirect) => {
  return {
    type: CREATE_ITEM,
    payload: {link, data, cb, redirect}
  }
}
export const UpdateItem = (link, data, cb, redirect) => {
  return {
    type: UPDATE_ITEM,
    payload: {link, data, cb, redirect}
  }
}
export const GetItem = (link, id, cb) => {
  return {
    type: GET_ITEM,
    payload: {link: link + "/" + id, cb}
  }
}