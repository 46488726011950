import React, {useState} from 'react';
import {Badge, Button, Col, Modal} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {useForm} from "react-hook-form";
import HookDatePicker from "../../../../components/hookDatePicker";
import FormHookInput from "./formHookInput";
import axios from "../../../../helpers/axios";
import moment from "moment";
import BlockUi from "@availity/block-ui";

const GetSegmentByFlight = ({addSegment}) => {
  const [show, setShow]=useState(false)
  const [loading, setLoading]=useState(false)
  const [error, setError]=useState()
  const formController = useForm()
  const { register,reset,handleSubmit, formState:{errors}} = formController
  const onSubmit = ({date, airline,flightNumber}) =>{
    const sendingData = {
      airline,
      flightNumber,
      date: moment(date).format('DD.MM.YYYY')
    }
    setLoading(true)
    axios.post('/api/air/airBooking/getSegmentByFlight', sendingData)
      .then(({data})=>{
        console.log('data',data)
        if(data.result.length === 0){
          setError('Перелетов не найдено')
        }else{
          data.result.forEach(segment=> {

            console.log('segment', segment)
            addSegment(segment)
            reset()
            setShow(false)
          })
        }
      })
      .catch(e=> {
        setError(e.response.data.message[0])
        console.log('err', e)
      }).finally(()=>setLoading(false))

  }
  return (
    <>
      <i className="fa fa-search ml-2" style={{cursor:'pointer'}} onClick={()=>{setShow(true)}}></i>
      <Modal show={show} onHide={()=>setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Поиск сегмента</Modal.Title>
        </Modal.Header>
        <BlockUi blocking={loading}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
              <Col>
                <Form.Label>Дата</Form.Label>
                <HookDatePicker
                  // defaultValue={}
                  name={"date"}
                  rules={{ required: true }}
                  formController={formController}
                />
              </Col>
              <Col>
                <Form.Label>Airline</Form.Label>
                <FormHookInput
                  register={register(`airline`, {
                    required: true,
                    maxLength: 2,
                    minLength: 2,
                    onChange: e =>
                      (e.target.value = ("" + e.target.value).toUpperCase())
                  })}
                  // defaultValue={segment.airline}
                  onChange={e => {
                    e.target.value = ("" + e.target.value).toUpperCase();
                  }}
                  errors={errors}
                />
              </Col>
              <Col>
                <Form.Label>Номер</Form.Label>
                <FormHookInput
                  register={register(`flightNumber`, {
                    required: true,
                    maxLength: 4,
                    minLength: 2
                  })}
                  errors={errors}
                />
              </Col>
              {error && <p className={'text-danger'}>{error}</p>}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setShow(false)}>
                Закрыть
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit(onSubmit)}
              >
                Получить сигменты
              </Button>
            </Modal.Footer>
          </form>
        </BlockUi>

      </Modal>

    </>
  );
};

export default GetSegmentByFlight;
