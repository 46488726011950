import React, { useEffect } from "react";
import { Button, Col, FormLabel, Row } from "react-bootstrap";
import FormLocation from "../../base/components/forms/formLocation";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import FormHookSelect from "../../air/airBooking/components/formHookSelect";
import { transferPointType } from "../../../helpers/enums";
import RemoveLink from "../../air/airBooking/components/removeLink";
import { useFieldArray } from "react-hook-form";

const TransferPoints = ({ formController, prefix, points }) => {
  const {
    register,
    formState: { errors },
    formState,
    control
  } = formController;

  const pointsControl = useFieldArray({
    control,
    name: `${prefix}`
  });
  useEffect(
    () =>
      !pointsControl.fields.length && points && points.length
        ? pointsControl.append(points)
        : () => {},
    []
  );

  const pointTemplate = {
    latitude: 0,
    longitude: 0,
    name: "",
    address: "",
    pointType: transferPointType[0]
  };
  const addPoint = () => {
    pointsControl.append({ ...pointTemplate });
  };
  return (
    <>
      <h5>
        Точки{" "}
        <Button size={"sm"} onClick={() => addPoint()}>
          <i className={"fa fa-plus"} style={{ marginRight: 0 }}></i>
        </Button>
        <Button
          size={"sm"}
          onClick={() => {
            //console.log("ponint", pointsControl.fields.reverse());
            pointsControl.replace(pointsControl.fields.reverse());
          }}
          style={{ marginLeft: 15 }}
        >
          <i className={"fa fa-sync"} style={{ marginRight: 0 }}></i>
        </Button>
      </h5>
      {pointsControl.fields.map((point, index) => {
        return (
          <Row key={point.id}>
            <Col xs={4}>
              <FormLabel>Координаты</FormLabel>
              <FormLocation
                formController={formController}
                prefix={`${prefix}[${index}]`}
                point={point}
                addressName={`${prefix}[${index}].address`}
              />
            </Col>
            <Col xs={2}>
              <FormLabel>Название</FormLabel>
              <FormHookInput
                defaultValue={point.name}
                register={register(`${prefix}[${index}].name`, {
                  required: true
                })}
                errors={errors}
              />
            </Col>
            <Col xs={2}>
              <FormLabel>Адрес</FormLabel>
              <FormHookInput
                defaultValue={point.address}
                register={register(`${prefix}[${index}].address`, {
                  required: true
                })}
                errors={errors}
              />
            </Col>
            <Col xs={2}>
              <FormLabel>Тип</FormLabel>
              <FormHookSelect
                defaultValue={point.pointType}
                register={register(`${prefix}[${index}].pointType`, {
                  required: true
                })}
                errors={errors}
              >
                {transferPointType.map((item, i) => (
                  <option value={item} key={i}>
                    {item}
                  </option>
                ))}
              </FormHookSelect>
            </Col>
            <Col xs={2}>
              <RemoveLink
                onClick={() => pointsControl.remove(index)}
                title={"точку"}
              />
            </Col>
          </Row>
        );
      })}
      {!pointsControl.fields.length ? (
        <>
          <input
            type={"text"}
            style={{ display: "none" }}
            defaultValue={pointsControl.fields.length}
            {...register(`${prefix}ss`, { required: true, min: 2 })}
          />
          {formState.isSubmitted ? (
            <p className={"text-danger"}>Минимум 2 точки</p>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default TransferPoints;
