import React, {useEffect, useState} from 'react';
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import ChangeDate from "./changeDate";
import BlockUi from "@availity/block-ui";
import axios from "../../../helpers/axios";
import ApiItem from "./ApiItem";
import sayError from "../../../helpers/sayError";

const ApiTestTable = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    axios.post('/api/apiTest')
      .then(({data})=>{
        setData(data)
        setLoading(false)
      })
      .catch(sayError)
  }, []);
  console.log('apitest', data)
  return (
    <Portlet>
      <BlockUi blocking={loading} >
        <PortletHeader title={'Api test'} toolbar={
          <PortletHeaderToolbar>
            {data && <>
              <div className={data.totalServices === data.workingServices ? 'text-success' : 'text-danger'}>
                Работают: {data.workingServices} из {data.totalServices}
              </div>
            </>}
          </PortletHeaderToolbar>
        }/>
        <div>
          {data && data.responses.map((r,i)=>{
            return <React.Fragment key={i}>
              <ApiItem r={r}/>
            </React.Fragment>
          })}
        </div>
      </BlockUi>
    </Portlet>
  );
};

export default ApiTestTable;