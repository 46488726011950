import React from 'react'
import {PortletBody, PortletFooter} from "../../../../components/Portlet";
import {Formik} from "formik";
import {Col, Form, Row} from "react-bootstrap";
import LoadingButton from "../../../base/components/loadingButton";
import * as yup from "yup";

const CountryForm  = ({data, onSubmit, className}) =>{
  const validationSchema = yup.object().shape({
    name: yup.string().required().default(""),
    nameEn: yup.string().required().default(""),
    iso2: yup.string().min(2).max(2).required().default(""),
    iso3: yup.string().min(3).max(3).required().default("")
  });
  const initialValues = data?data: validationSchema.cast();
  return <div className={className}>
    <Formik initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              onSubmit(values, () => {
                setSubmitting(false);
              })
            }}
    >
      {( {values,
           errors,
           touched,
           handleChange,
           handleSubmit,
           isSubmitting }) => (

        <form noValidate={true} onSubmit={handleSubmit}>

          <PortletBody>
            <Form.Group as={Row} >
              <Form.Label column sm="2">Название страны</Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  className={touched.name && errors.name ? "is-invalid" : null}
                  required
                  type="text"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} >
              <Form.Label column sm="2">Название En</Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="nameEn"
                  value={values.nameEn}
                  onChange={handleChange}
                  className={touched.nameEn && errors.nameEn ? "is-invalid" : null}
                  required
                  type="text"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">Iso2 код </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="iso2"
                  value={values.iso2}
                  onChange={handleChange}
                  className={touched.iso2 && errors.iso2 ? "is-invalid" : null}
                  required
                  type="text"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">Iso3 код</Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="iso3"
                  value={values.iso3}
                  onChange={handleChange}
                  className={touched.iso3 && errors.iso3 ? "is-invalid" : null}
                  required
                  type="text"
                />
              </Col>
            </Form.Group>
          </PortletBody>
          <PortletFooter>
            <Row>
              <Col md={{ span: 10, offset: 2 }}>
                <LoadingButton title={"Сохранить"} disabled={isSubmitting} loading={isSubmitting}/>
              </Col>
            </Row>
          </PortletFooter>
        </form>
      )}
    </Formik>
  </div>
}
export default CountryForm;