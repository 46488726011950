import React from "react";
import { PortletBody, PortletFooter } from "../../../components/Portlet";
import { Formik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import LoadingButton from "../../base/components/loadingButton";
import * as yup from "yup";
import FormRow from "../../base/components/forms/formRow";

const CounterPartyForm = ({ data, onSubmit, className }) => {
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required()
      .default(""),
    code: yup
      .string()
      .min(9)
      .max(9)
      .required()
      .default(""),
    amadeusCode: yup
      .string()
      .max(9)
      .default(""),
    clientName: yup
      .string()
      .default(""),
    isHidden: yup.boolean().default(false),
    isSupplier: yup.boolean().default(false),
    dontExport: yup.boolean().default(false)
  });
  const initialValues = data ? data : validationSchema.cast();
  return (
    <div className={className}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          onSubmit(values, () => {
            setSubmitting(false);
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <form noValidate={true} onSubmit={handleSubmit}>
            <PortletBody>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Название
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    disabled={true}
                    className={
                      touched.name && errors.name ? "is-invalid" : null
                    }
                    required
                    type="text"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Код 1с
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    name="code"
                    value={values.code}
                    onChange={handleChange}
                    disabled={true}
                    className={
                      touched.code && errors.code ? "is-invalid" : null
                    }
                    required
                    type="text"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Код Амадеуса
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    name="amadeusCode"
                    value={values.amadeusCode}
                    onChange={handleChange}
                    className={
                      touched.amadeusCode && errors.amadeusCode
                        ? "is-invalid"
                        : null
                    }
                    required
                    type="text"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  ClientName
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    name="clientName"
                    value={values.clientName}
                    onChange={handleChange}
                    className={
                      touched.name && errors.name ? "is-invalid" : null
                    }
                    required
                    type="text"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} style={{alignItems:'center'}}>
                <Form.Label column sm="3">
                  Спрятан
                </Form.Label>
                <Col sm={9}>
                  <Form.Check
                    type={"checkbox"}
                    name={"isHidden"}
                    onChange={handleChange}
                    value={true}
                    label={"Спрятан"}
                    checked={values.isHidden}
                    className={
                      touched.isHidden && errors.isHidden ? "is-invalid" : null
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} style={{alignItems:'center'}}>
                <Form.Label column sm="3">
                  Не выгружать в 1с
                </Form.Label>
                <Col sm={9}>
                  <Form.Check
                    type={"checkbox"}
                    name={"dontExport"}
                    onChange={handleChange}
                    value={true}
                    label={"Да"}
                    checked={values.dontExport}
                    className={
                      touched.dontExport && errors.dontExport
                        ? "is-invalid"
                        : null
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} style={{alignItems:'center'}}>
                <Form.Label column sm="3">
                  Поставщик ЖД билетов
                </Form.Label>
                <Col sm={9}>
                  <Form.Check
                    type={"checkbox"}
                    name={"railwaySupplier"}
                    onChange={handleChange}
                    value={true}
                    label={"Да"}
                    checked={values.railwaySupplier}
                    className={
                      touched.railwaySupplier && errors.railwaySupplier
                        ? "is-invalid"
                        : null
                    }
                  />
                </Col>
              </Form.Group><Form.Group as={Row} style={{alignItems:'center'}}>
              <Form.Label column sm="3">
                Поставщик авиа
              </Form.Label>
              <Col sm={9}>
                <Form.Check
                  type={"checkbox"}
                  name={"isSupplier"}
                  onChange={handleChange}
                  value={true}
                  label={"Да"}
                  checked={values.isSupplier}
                  className={
                    touched.isSupplier && errors.isSupplier
                      ? "is-invalid"
                      : null
                  }
                />
              </Col>
            </Form.Group>
            </PortletBody>
            <PortletFooter>
              <Row>
                {isSubmitting}
                <Col md={{ span: 9, offset: 3 }}>
                  <LoadingButton
                    title={"Сохранить"}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  />
                </Col>
              </Row>
            </PortletFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};
export default CounterPartyForm;
