import React from "react";
import {Form} from "react-bootstrap";

const PortletHeaderSearch = ({value, cb}) =>{

  return <div className="form-inline mr-2">
    <div className="kt-input-icon kt-input-icon--right">
      <Form.Control
        name={'search'}
        //value={value}
        onChange={(e)=>cb(e.target.value)}
        className={'form-control-sm'}
        type={'text'}
        placeholder="Поиск..."
      />
      <span className="kt-input-icon__icon kt-input-icon__icon--right">
        <span><i className="la la-search"></i></span>
      </span>
    </div>
  </div>
}

export default PortletHeaderSearch