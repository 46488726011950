import React, {useState} from 'react';
import {Button, Col, Form, Modal} from "react-bootstrap";
import BlockUi from "@availity/block-ui";
import {useForm} from "react-hook-form";
import _ from 'lodash'
import {Row} from "reactstrap";
import axios from "../../../helpers/axios";

const ReloadReviseButton = ({oldDocuments, currentDoc, cb, url, date}) => {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const formController = useForm()
  const {handleSubmit,
    register,
    setError,
    formState:{errors}} = formController
  console.log('oldDocuments', oldDocuments)
  const onSubmit = (values) =>{
    setLoading(true)
    const data = new FormData();
    const file = values[currentDoc.registerName][0]

    const secondOldFile = _.find(oldDocuments, (u)=>{
      return u.registerName !== currentDoc.registerName})

    data.append(`${currentDoc.registerName}`, file);
    data.append(`${secondOldFile.registerName}`, secondOldFile?.url || secondOldFile.file);
    Object.keys(date).forEach(key=>{
      data.append(`${key}`, date[key]);
    })

    console.log('file',file)
    axios.post(url, data)
      .then(({data})=> {
        console.log('res', data)
        cb(data, [secondOldFile, {file: file, registerName: currentDoc.registerName, name: currentDoc.name}])
        setShow(false)
      })
      .catch(e=> {
        console.log('err', e)
        setError(`${currentDoc.registerName}`, e.response.data.message)
      })
      .finally(()=>setLoading(false))

  }
  console.log('error',errors)
  return (
    <>
      <button className={"btn btn-outline-danger btn-sm ml-5"} onClick={()=>setShow(true)}>
        Заменить
      </button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Загрузить новый файл "{currentDoc.name}"</Modal.Title>
        </Modal.Header>
        <BlockUi blocking={loading}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
            <Modal.Body>
              <Row className={'align-items-end'}>
                <Col>
                  <Form.Label>{currentDoc.name}</Form.Label>
                  <Form.Control
                    type={"file"}
                    {...register(currentDoc.registerName, { required: true })}
                  />
                  {errors[currentDoc.registerName] && <p className={'link-danger'}>{errors[currentDoc.registerName][0]}</p>}
                </Col>

              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setShow(false)}>
                Закрыть
              </Button>
              <Button
                variant="primary"
                type={'submit'}
              >Сохранить</Button>
            </Modal.Footer>
          </form>
        </BlockUi>
      </Modal>
    </>

  );
};

export default ReloadReviseButton;