import React, { useEffect } from "react";
import { Button, Col, FormLabel, Row } from "react-bootstrap";
import FormHookInput from "../../air/airBooking/components/formHookInput";
import PhotoInput from "../trip/components/tripFormParts/photoInput";
import RemoveLink from "../../air/airBooking/components/removeLink";
import { useFieldArray } from "react-hook-form";

const TransferTransports = ({ formController, prefix, transports }) => {
  const {
    register,
    formState: { errors },
    formState,
    control
  } = formController;

  const transportsControl = useFieldArray({
    control,
    name: `${prefix}`
  });
  useEffect(
    () =>
      !transportsControl.fields.length && transports && transports.length
        ? transportsControl.append(transports)
        : () => {},
    []
  );
  const transportTemplate = {
    carType: "",
    driverPhoneNumber: "",
    driverName: "",
    photoUrl: ""
  };
  const addTransport = () => {
    console.log(transportsControl);
    console.log(prefix);
    transportsControl.append({ ...transportTemplate });
  };
  console.log("renderee", transportsControl.fields.length);
  return (
    <>
      <h5>
        Транспорт{" "}
        <Button size={"sm"} onClick={() => addTransport()}>
          <i className={"fa fa-plus"}></i>
        </Button>
      </h5>
      {transportsControl.fields.map((transport, index) => {
        return (
          <Row key={transport.id}>
            <Col xs={2}>
              <FormLabel>Транспорт</FormLabel>
              <FormHookInput
                defaultValue={transport.carType}
                register={register(`${prefix}[${index}].carType`, {
                  required: true
                })}
                errors={errors}
              />
            </Col>
            <Col xs={2}>
              <FormLabel>Номер водителя</FormLabel>
              <FormHookInput
                defaultValue={transport.driverPhoneNumber}
                register={register(`${prefix}[${index}].driverPhoneNumber`)}
                errors={errors}
              />
            </Col>
            <Col xs={3}>
              <FormLabel>Имя водителя</FormLabel>
              <FormHookInput
                defaultValue={transport.driverName}
                register={register(`${prefix}[${index}].driverName`)}
                errors={errors}
              />
            </Col>
            <Col xs={3}>
              <PhotoInput
                formController={formController}
                title={"Фото"}
                name={`${prefix}[${index}].photoUrl`}
                url={transport.photoUrl}
              />
            </Col>
            <Col xs={2}>
              <RemoveLink
                onClick={() => transportsControl.remove(index)}
                title={"транспорт"}
              />
            </Col>
          </Row>
        );
      })}
      {!transportsControl.fields.length ? (
        <>
          <input
            type={"text"}
            style={{ display: "none" }}
            defaultValue={""}
            {...register(`${prefix}ss`, { required: true })}
          />
          {formState.isSubmitted ? (
            <p className={"text-danger"}>Минимум 1 транспорт</p>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default TransferTransports;
