import { Route, Routes } from "react-router";
import TripItemListPage from "./pages/list";
import TripItemCreatePage from "./pages/create";
import React from "react";
import {
  TripEditRoute,
  TripItemCreateRoute,
  TripItemEditRoute,
  TripItemListRoute
} from "../../routesConstants";
import ErrorHandler from "../../base/components/errorHandler";
import TripEditPage from "../trip/pages/edit";
import TripItemEditPage from "./pages/edit";

export default function() {
  return (
    <ErrorHandler>
      <Routes>
        <Route path={"/"} exact={true} element={<TripItemListPage />} />
        <Route path={"create"} exact={true} element={<TripItemCreatePage />} />
        <Route path={"edit/:id"} exact={true} element={<TripItemEditPage />} />
      </Routes>
    </ErrorHandler>
  );
}
