import React from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader
} from "../../../../../components/Portlet";
import { Col, Form, FormControl, FormLabel, Row } from "react-bootstrap";
import ApiSelect from "../../../../base/components/forms/apiSelect";
import CustomDatePicker from "../../../../../components/customDatePicker";
import DescriptionsBlock from "../../../components/descriptionsBlock";
import PaxesBlock from "../../../components/paxesBlock";
import ImagesBlock from "../../../components/imagesBlock";
import FastInput from "../../../../../components/FastInput";
import HookDatePicker from "../../../../../components/hookDatePicker";
import CountriesSelect from "../../../../base/components/forms/countriesSelector";
import UsersBlock from "./usersBlock";
import DocumentsBlock from "../../../components/documentsBlock";
import FormHookDatePicker from "../../../../base/components/forms/formHookDatePicker";
import FormHookInput from "../../../../air/airBooking/components/formHookInput";

const TripMainData = ({ values, className, formController, paxArray, active }) => {
  console.log("TripMainData");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue
  } = formController;

  return (
    <>
      <Portlet className={className}>
        <PortletHeader title={"Общая информация"} />
        <PortletBody>
          <>
            {/*<MapPicker/>*/}
            <Row>
              <Col xs={6}>
                <FormLabel>Название</FormLabel>
                <FormHookInput
                  register={register("name", {
                    required: true
                  })}
                  placeholder={"Название"}
                  errors={errors}
                />
                <Row>
                  <Col xs={6}>
                    <FormLabel>Дата начала</FormLabel>
                    <FormHookDatePicker
                      formController={formController}
                      dateRules={{ required: true }}
                      date={values.startDate}
                      dateName={`startDate`}
                      timeRules={{}}
                      time={"00:00"}
                      timeName={``}
                      errors={errors}
                    />
                  </Col>

                  <Col xs={6}>
                    <FormLabel>Дата окончания</FormLabel>
                    <FormHookDatePicker
                      formController={formController}
                      dateRules={{ required: true }}
                      date={values.endDate}
                      dateName={`endDate`}
                      timeRules={{}}
                      time={"00:00"}
                      timeName={``}
                      errors={errors}
                    />
                  </Col>
                </Row>
                <Form.Check
                  type={"checkbox"}
                  {...register("setDatesManually")}
                  onChange={newVal =>
                    (values.setDatesManually = !values.setDatesManually)
                  }
                  label={"Обновлять даты поездки вручную"}
                  checked={values.setDatesManually}
                />
                <Form.Label className={'d-flex align-items-center gap-2'}>
                  <input
                    defaultChecked={values.public}
                    {...register(`public`)}
                    type={"checkbox"}
                  />{" "}
                  Публичный
                </Form.Label>
              </Col>
              <Col xs={6}>
                <UsersBlock
                  formController={formController}
                  prefix={"visibleToAccounts"}
                  type={"trips/"}
                />
              </Col>
            </Row>
            <DescriptionsBlock
              title={"поездки"}
              descriptions={values.descriptions}
              formController={formController}
              prefix={"descriptions"}
            />
            <PaxesBlock
              paxes={values.paxes}
              paxArray={paxArray}
              formController={formController}
              prefix={"paxes"}
            />

            <ImagesBlock
              formController={formController}
              prefix={`photos`}
              minLength={1}
              photos={values.photos}
              active={active}
            />
            <DocumentsBlock
              formController={formController}
              documents={values.documents}
              prefix={"documents"}
            />
          </>
        </PortletBody>
      </Portlet>
    </>
  );
};

export default TripMainData;
