/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/controllers/auth/AuthPage`, `src/controllers/home/HomePage`).
 */

import React, {useEffect} from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import ErrorsPage from "../components/errorPage";
import AuthRouter from "../modules/auth/authRouter";
import Layout from "../_metronic/layout/Layout";
import LandingPage from "../modules/landing/pages/landing";
import LocationsRouter from "../modules/locations/routes";
import UserRouter from "../modules/users/router";
import DocumentsRouter from "../modules/documents/router"
import CounterpartyRouter from "../modules/counterparty/router";
import AirRouter from "../modules/air/router";
import ParsersRoutes from "../modules/parser/routes";
import CurrencyRouter from "../modules/currencies/router";
import TripsRouter from "../modules/trips/routes";
import RacoonRouter from "../modules/racoon/routes";
import SalesRegisterRouter from "../modules/salesRegister/router"
import MarkUpPage from "../modules/landing/pages/markup";
import AdminRouter from "../modules/admin/router";
import TestPage from "../modules/base/testPage";
import AuthHelper from "../helpers/AuthHelper";
import { useLocation } from "react-router-dom";
import CardsList from "../modules/creditCards/pages/CardsList";
import RailRouter from '../modules/railways/router'
import TouristServicesRouter from '../modules/touristServices/router'
import {useNavigate} from "react-router";
import axios from "../helpers/axios";
import axiosBase from "axios";

import {setBuildId} from "../redux/reducers/buildCheck";
import {PaxActivityRoute} from "../modules/routesConstants";
import PaxActivityInfo from "../modules/paxActivity/pages/infoList";

export const AppRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const {buildId} = useSelector(state => state.buildInfo)
  useEffect(() => {
    axios.interceptors.response.use(
      response => {
        if(response.headers['build-id'] !== buildId){
          dispatch(setBuildId(response.headers['build-id']))
          window.location.reload()
          console.log('RELOAD!',response.headers['build-id'] )
        }
        return response;
      },
      error => {
        const responseErrors =
          error.response?.data?.message || error.response?.data?.errors;
        // console.log("rror.response", error.response);
        if (error?.response?.status === 401) {
          // navigate("/auth/login");
        }
        return Promise.reject(error);
      }
    );
    axiosBase.interceptors.response.use(
      response => {
        if(response.headers['build-id'] !== buildId){
          dispatch(setBuildId(response.headers['build-id']))
          window.location.reload()
          console.log('RELOADBASE!',response.headers['build-id'] )
        }
        return response;
      },
      error => {
        const responseErrors =
          error.response?.data?.message || error.response?.data?.errors;
        // console.log("rror.response", error.response);
        if (error?.response?.status === 401) {
          // setAuthToken(null);
          // navigate("/auth/login");
        }
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <>
        <Routes>
          <Route path="/error/*" element={<ErrorsPage />} />
          <Route path="/auth/*" element={<AuthRouter />} />
          <Route path={'/*'} element={<ProtectedRoutes/>} />
        </Routes>
    </>
  );
};

const ProtectedRoutes = () =>{
  return <AuthHelper>
    <Layout>
      <Routes>
        <Route path="test" element={<TestPage />} />
        <Route path={"/"} exact={true} element={<LandingPage />} />
        <Route path="markup" element={<MarkUpPage />} />
        <Route path={"locations/*"} element={<LocationsRouter />} />
        <Route path={"air/*"} element={<AirRouter />} />
        <Route path={"railways/*"} element={<RailRouter />} />
        <Route path={"touristServices/*"} element={<TouristServicesRouter/>} />
        <Route path={"users/*"} element={<UserRouter />} />
        <Route path={"documents/*"} element={<DocumentsRouter />} />
        <Route path={"currency/*"} element={<CurrencyRouter />} />
        <Route
          path={"counterparty/*"}
          element={<CounterpartyRouter />}
        />
        <Route path={"parsers/*"} element={<ParsersRoutes />} />
        <Route path={"trips/*"} element={<TripsRouter />} />
        <Route path={"admin/*"} element={<AdminRouter />} />
        <Route path={"racoon/*"} element={<RacoonRouter />} />
        <Route path={"/creditCards"}  element={<CardsList />} />
        <Route path={'/salesRegister/*'} element={<SalesRegisterRouter />} />
        <Route path={PaxActivityRoute}  element={<PaxActivityInfo />} />

      </Routes>
    </Layout>
  </AuthHelper>
}