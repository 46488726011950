import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import { Login as LoginAction } from "../../../redux/actions/auth";
import AuthButton from "../components/authButton";

function Login(props) {
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const navigate = useNavigate();

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  console.log('error', formErrors)
  return (
    <>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>Вход</h3>
          </div>

          <Formik
            initialValues={{
              email: "",
              password: ""
            }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                errors.email = "Обязательное поле";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Некорректно заполненно";
              }

              if (!values.password) {
                errors.password = "Обязательное поле";
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting, setErrors }) => {
              setSubmitting(true);
              enableLoading();
              props.LoginAction(values.email, values.password, errors => {
                disableLoading();
                setSubmitting(false);
                console.log(errors);
                setFormErrors(errors);
                if(!errors){
                  navigate("/");
                }

              });
            }}
            ы
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                <div className="form-group">
                  <div>
                    <p className="kt-font-danger kt-font-bold">{formErrors}</p>
                  </div>
                  <TextField
                    type="email"
                    label="Почта"
                    margin="normal"
                    className="kt-width-full"
                    name="email"
                    onBlur={handleBlur}
                    onChange={(e)=> {
                      e.target.value = ("" + e.target.value).toLowerCase()
                      handleChange(e)
                    }}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Пароль"
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>

                <div className="kt-login__actions">
                  <Link
                    to={`/auth/forgot-password?email=${values.email}`}
                    className="kt-link kt-login__link-forgot"
                  >
                    Забыли пароль?
                  </Link>

                  <AuthButton loading={loading} isSubmitting={isSubmitting} />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, { LoginAction })(Login));
