import React from "react";
import PaxesBlock from "../../components/paxesBlock";
import {Col, Form, Row} from "react-bootstrap";
import { useFieldArray } from "react-hook-form";
import TripHotelForm from "../../components/tripHotel";
import UsersBlock from "../../trip/components/tripFormParts/usersBlock";

const HotelBlock = props => {
  const { formController, hotel } = props;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    getValues,
    reset
  } = formController;
  const paxArray = useFieldArray({
    control,
    name: `paxes`
  });

  return (
    <>
      <Row>
        <Col xs={12}>
          <UsersBlock
            prefix={"item.visibleToAccounts"}
            formController={formController}
            type={`trips/${hotel.item.type}/`}
          />
          <PaxesBlock
            prefix={"paxes"}
            formController={formController}
            paxes={hotel.paxes}
            paxArray={paxArray}
            hideButtons={true}
          />
          <Form.Label className={'d-flex align-items-center gap-2'}>
            <input
              defaultChecked={hotel.item.public}
              {...register(`item.public`)}
              type={"checkbox"}
            />{" "}
            Публичный
          </Form.Label>
          <br />
          <TripHotelForm
            formController={formController}
            item={hotel.item}
            prefix={"item"}
            activ
          />
        </Col>
      </Row>
    </>
  );
};

export default HotelBlock;
