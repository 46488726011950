import React, {useEffect, useState} from "react";
import {Badge, Button, Col, Dropdown, Row, Tab, Tabs} from "react-bootstrap";
import Form from "react-bootstrap/esm/Form";
import {FieldArray} from "formik";
import {titles} from "../../../../helpers/enums";
import moment from "moment";
import CustomDatePicker from "../../../base/components/forms/customDatePicker";
import RemoveLink from "./removeLink";
import {useFieldArray} from "react-hook-form";
import HookDatePicker from "../../../../components/hookDatePicker";
import AirBookingTicketSegment from "./airBookingTicketSegment";
import {removeElement} from "../../../../helpers/formHelpers";
import FormHookInput from "./formHookInput";
import _ from "lodash";

import {AirBookingCreateRoute} from "../../../routesConstants";

const AirBookingTicketForm = ({
                                index,
                                prefix,
                                ticket,
                                deleteTicket,
                                copyTicket,
                                formController
                              }) => {
  const {
    register,
    watch,
    control,
    setValue,
    getValues,
    formState: {errors}
  } = formController;
  const segments = watch("segments", []);
  const segmentsControl = useFieldArray({
    control,
    name: `${prefix}.segments`,
    rules: {required: true},
    shouldUnregister: true,
    keyName: 'customId'
  });
  const emdsControl = useFieldArray({
    control,
    name: `${prefix}.emds`
  });

  const segmentTemplate = {
    segment: "",
    seat: ""
  };
  const emdTemplate = {
    emdNumber: "",
    fare: 0,
    taxes: 0,
    penalties: 0,
    airlineNumber: "",
    issuedDate: "",
    krs: 0,
    serviceComment: "",
    alternativeRoute:"",
    refund: false
  };
  const getVal = (name)=>{
    return +_.get(getValues(), `${prefix}.${name}`) ||0
  }
  const calculateTotal = () => {
    return getVal('fare') + getVal('taxes') + getVal('penalties') + getVal('krsKayar');
  };

  const calculateBuyer = () => {
    return (
      getVal('fare') +
      getVal('taxes') +
      getVal('penalties') +
      getVal('krsBuyer') +
      getVal('krsKayar')
      // getVal('commissionBuyer')
    );
  };
  const calculateSeller = () => {
    return (
      getVal('fare') +
      getVal('taxes') +
      getVal('penalties') +
      getVal('krsSeller')
      // getVal('commissionSeller')
    );
  };

  const removeEmd = index => {
    emdsControl.remove(index);
  };
  const copyEmd = index =>{
    const ticket = _.get(formController.getValues(), `${prefix}.emds[${index}]`);
    const newTicket = structuredClone(ticket)
    delete newTicket._id
    emdsControl.append(newTicket)
  }
  const removeSegment = index => {
    segmentsControl.remove(index);
  };
  const validateInputs = () =>{
    if(!ticket.refund){
      return {
        positive: v => parseInt(v) > 0}} else {
      return {}}
    }
  useEffect(() => {
    console.log('all segments',getValues().segments, segmentsControl.fields)
    segmentsControl.fields.forEach((s,index)=>{
      const haveSeg = _.find(getValues().segments, {id: s.segment}) || _.find(getValues().segments, {tempId: s.segment})
      if(!haveSeg){
        segmentsControl.remove(index)
      }
    })
  }, [watch('segments')]);
  const getNeedInfo = (ticketNumber) =>{
    let sendingInfo = {}
    getValues().paxes.forEach((p,i)=>{
      const copyTicket = _.find(p.tickets, {ticketNumber})
      if(copyTicket){
        sendingInfo.paxes = [{...p, tickets: [{...copyTicket, refund: true}]}]
      }
    })
    Object.keys(getValues()).forEach(key=>{
      if(key !== "paxes"){
        sendingInfo[key] = getValues()[key]
      }
    })
    return sendingInfo
  }
  const removeTicketToNewInv = (ticketNumber) =>{
    if(window.confirm('Вы уверены, что хотите копировать билет в новый инвойс как возврат?')){
      localStorage.setItem('ticket', JSON.stringify(getNeedInfo(ticketNumber)))
      // deleteTicket()
      // document.getElementById("submit").click();
      window.open(AirBookingCreateRoute,'_blank', 'rel=noopener noreferrer')
    }
  }
  const removeEmdAsTicket = (ticketNumber, emd, index) =>{
    if(window.confirm('Этот емд будет скопирован в новый инвойс как билет возврат.')){
      let info = getNeedInfo(ticketNumber)
      const {airlineCode,
        emdNumber,
        fare,
        issuedDate,
        krs,
        penalties,
        refund,
        serviceComment,
        alternativeRoute,
        taxes} = emd
      const {officeId, segments, ...other} =  info.paxes[0].tickets[0]
      info.paxes[0].tickets = [
        {
          airlineCode,
          ticketNumber:emdNumber,
          fare,
          issuedDate,
          krsBuyer: krs,
          penalties,
          refund:true,
          krsKayar:0,
          krsSeller:0,
          officeId,
          emds:[],
          currency:'KZT',
          segments,
          serviceComment,
          taxes
        }
      ]
      // removeEmd(index)
      localStorage.setItem('ticket', JSON.stringify(info))
      // document.getElementById("submit").click();
      window.open(AirBookingCreateRoute,'_blank', 'rel=noopener noreferrer')
    }

  }

  return (
    <>
      <Row className="align-items-center mb-3">
        <Col xs="2">
          <input
            defaultValue={ticket.id}
            type={"hidden"}
            {...register(`${prefix}.id`)}
          />
          <FormHookInput
            defaultValue={ticket.airlineCode}
            label={'Авиакомпания'}
            register={register(`${prefix}.airlineCode`, {
              required: true,
              maxLength: 3,
              minLength: 3
            })}
            errors={errors}
          />
        </Col>
        <Col xs="2">
          <FormHookInput
            defaultValue={ticket.ticketNumber}
            label={'Номер билета'}
            register={register(`${prefix}.ticketNumber`, {
              required: true,
            })}
            errors={errors}
          />
        </Col>
        <Col xs="2">
          <CustomDatePicker
            value={ticket.issuedDate}
            label={'Дата выписки'}
            name={`${prefix}.issuedDate`}
            rules={{required: true}}
            formController={formController}
            // showTimeSelect={true}
          />
        </Col>
        <Col xs="2">
          <FormHookInput
            defaultValue={ticket.officeId}
            label={'Код офиса'}
            register={register(`${prefix}.officeId`)}
            errors={errors}
          />
        </Col>

        <Col xs="4">
          <FormHookInput
            defaultValue={ticket.prevTicket}
            label={'Номер пред. билета'}
            placeholder={"000-123456789"}
            register={register(`${prefix}.prevTicket`, {
              pattern: /[0-9]{3}-[0-9]{10}/
            })}
            errors={errors}
          />
        </Col>
      </Row>
      <Row >
        <Col xs={8}>
          <Row className={'mb-3'}>
            <Col xs="3">
              <FormHookInput
                defaultValue={ticket.fare}
                label={'Тариф'}
                placeholder={"Тариф"}
                register={register(`${prefix}.fare`, {
                  required: true,
                  // validate: validateInputs()
                  })}
                type="number"
                errors={errors}
              />
            </Col>
            <Col xs="3">
              <FormHookInput
                defaultValue={ticket.taxes}
                label={'Таксы'}
                placeholder={"Таксы"}
                register={register(`${prefix}.taxes`, {required: true})}
                type="number"
                errors={errors}
              />
            </Col>
            <Col xs="3">
              <FormHookInput
                defaultValue={ticket.penalties}
                placeholder={"Штрафы"}
                label={'Штрафы'}
                type="number"
                register={register(`${prefix}.penalties`, {required: true})}
                errors={errors}
              />
            </Col>
            <Col xs="3">
              <FormHookInput
                defaultValue={ticket.currency}
                label={'Валюта'}
                placeholder={"Валюта"}
                register={register(`${prefix}.currency`, {
                  required: true,
                  pattern: /[A-Z]{3}/
                })}
                errors={errors}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <FormHookInput
                defaultValue={ticket.krsSeller}
                label={'КРС пост.'}
                register={register(`${prefix}.krsSeller`, {required: true})}
                placeholder={"КРС поставщика"}
                type="number"
                errors={errors}
              />
            </Col>
            <Col xs={3}>
              <FormHookInput
                defaultValue={ticket.krsBuyer}
                label={'КРС клиента'}
                register={register(`${prefix}.krsBuyer`, {required: true})}
                placeholder={"КРС клиента"}
                type="number"
                errors={errors}
              />
            </Col>
            <Col xs={3}>
              <FormHookInput
                defaultValue={ticket.krsKayar}
                label={'КРС Kayar'}
                register={register(`${prefix}.krsKayar`)}
                placeholder={"КРС Kayar"}
                type="number"
                errors={errors}
              />
            </Col>
          </Row>
          <Row className={"segmentContainer mt-3"}>
            <Col xs={12}>
              <FormHookInput
                defaultValue={ticket.serviceComment}
                label={'Коментарий для 1с'}
                register={register(`${prefix}.serviceComment`)}
              />
            </Col>
          </Row>
          {ticket.technicalCounterparty && <div className={'mb-3'}>Технический контрагент: {ticket.technicalCounterparty}</div>}
        </Col>

        <Col xs={4}>
          <Form.Label>
            <input
              defaultChecked={ticket.refund}
              {...register(`${prefix}.refund`)}
              type={"checkbox"}
            />{" "}
            Возврат
          </Form.Label>
          <br/>

          <div>
            <div className={'link-info pointer'}
                 onClick={copyTicket}>
              Копировать билет
            </div>
            Итого - {calculateTotal()} {ticket.currency}
            <br/>
            Итого поставщик - {calculateSeller()} {ticket.currency} <br/>
            Итого покупатель - {calculateBuyer()} {ticket.currency} <br/>
            <RemoveLink onClick={deleteTicket} title={"билет"}/>

            {/*<DropdownButton variant="outline-secondary" size="sm" title="Действия">*/}
            {/*  <Dropdown.Item as="button" type={'button'} onClick={copyTicket}>*/}
            {/*    Копировать билет*/}
            {/*  </Dropdown.Item>*/}
            {/*  <Dropdown.Item as="button" type={'button'} onClick={deleteTicket}>*/}
            {/*    Удалить билет*/}
            {/*  </Dropdown.Item>*/}
            {/*  <Dropdown.Item as="button" type={'button'} onClick={() => removeTicketToNewInv(ticket.ticketNumber)}>*/}
            {/*    Копировать как инвойс-возврат*/}
            {/*  </Dropdown.Item>*/}
            {/*</DropdownButton>*/}
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Form.Label>
            Сегменты{" "}
            <Badge
              onClick={() => segmentsControl.append({...segmentTemplate})}
              variant="success"
            >
              <i className={"la la-plus"}/> Добавить сегмент
            </Badge>
            <Badge
              onClick={() => {
                getValues().segments.forEach(seg=>{
                  console.log(seg)
                  if(!_.find(segmentsControl.fields, {segment: seg.tempId})){
                    segmentsControl.append({segment:seg.tempId, seat:''})
                  }
                })
              }}
              variant="success"
              className={'ml-2'}
            >
              <i className={"la la-plus"}/> Добавить все сегменты
            </Badge>
          </Form.Label>
          {_.get(errors, `${prefix}.segments.root`) ? (
            <p className={"text-danger"}>Минимум 1 сегмент</p>
          ) : null}
          {segmentsControl.fields.map((ticketSegment, index) => {
            return (
              <AirBookingTicketSegment
                prefix={`${prefix}.segments[${index}]`}
                key={ticketSegment.id}
                ticketSegment={ticketSegment}
                segments={segments}
                formController={formController}
                removeSegment={() => {
                  removeSegment(index);
                }}
              />
            );
          })}

        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Label className={'mb-3'}>
            Доп. сборы (EMD){" "}
            <Badge
              onClick={() => emdsControl.append({...emdTemplate})}
              variant="success"
            >
              <i className={"la la-plus"}/> Добавить EMD
            </Badge>
          </Form.Label>
          {emdsControl.fields.map((emd, index) => {
            return (
              <Row key={emd.id} className={'mb-3'}>
                <Row
                  key={index}
                  className={"segmentContainer align-items-center"}
                >
                  <Col xs={2}>
                    <FormHookInput
                      defaultValue={emd.airlineCode}
                      label={'Номер авиа'}
                      register={register(
                        `${prefix}.emds[${index}].airlineCode`,
                        {
                          required: true,
                          maxLength: 3,
                          minLength: 3
                        }
                      )}
                      // placeholder={"Авиакомпания"}
                      errors={errors}
                    />
                  </Col>
                  <Col xs={2}>
                    <FormHookInput
                      defaultValue={emd.emdNumber}
                      label={'Номер'}
                      register={register(`${prefix}.emds[${index}].emdNumber`, {
                        required: true,
                        pattern: /[0-9]{10}/
                      })}
                      // placeholder={"Номер"}
                      errors={errors}
                    />
                  </Col>
                  <Col xs={2}>
                    <CustomDatePicker
                      value={emd.issuedDate}
                      label={'Дата выписки'}
                      name={`${prefix}.emds[${index}].issuedDate`}
                      rules={{required: true}}
                      formController={formController}
                    />
                  </Col>
                  <Col xs={2}>
                    <FormHookInput
                      defaultValue={emd.fare}
                      label={'Тариф'}
                      register={register(`${prefix}.emds[${index}].fare`, {
                        required: true
                      })}
                      // placeholder={"Тариф"}
                      type="number"
                      errors={errors}
                    />
                  </Col>
                  <Col xs={2}>
                    <FormHookInput
                      defaultValue={emd.taxes}
                      label={'Таксы'}
                      register={register(`${prefix}.emds[${index}].taxes`, {
                        required: true
                      })}
                      // placeholder={"Таксы"}
                      type="number"
                      errors={errors}
                    />
                  </Col>
                  <Col xs={2}>
                    <FormHookInput
                      defaultValue={emd.penalties}
                      label={'Штрафы'}
                      register={register(`${prefix}.emds[${index}].penalties`, {
                        required: true
                      })}
                      // placeholder={"Штрафы"}
                      type="number"
                      errors={errors}
                    />
                  </Col>
                </Row>
                <Row className={"segmentContainer align-items-center"}>
                  <Col xs={2}>
                    <FormHookInput
                      defaultValue={emd.krs}
                      label={'КРС'}
                      register={register(`${prefix}.emds[${index}].krs`, {
                        required: true
                      })}
                      // placeholder={"КРС"}
                      type="number"
                      errors={errors}
                    />
                  </Col>
                  <Col xs={3}>
                    <FormHookInput
                      defaultValue={emd.serviceComment}
                      label={'Коментарий для 1с'}
                      register={register(`${prefix}.emds[${index}].serviceComment`)}
                    />
                  </Col>
                  <Col xs={3}>
                    <FormHookInput
                      defaultValue={emd.alternativeRoute}
                      label={'Альтернативный маршрут'}
                      register={register(`${prefix}.emds[${index}].alternativeRoute`)}
                    />
                  </Col>

                  <Col>
                    <Form.Label>
                      <input
                        defaultChecked={emd.refund}
                        {...register(`${prefix}.emds[${index}].refund`)}
                        type={"checkbox"}
                      />{" "}
                      Возврат
                    </Form.Label>
                    <div className={'link-info pointer'}
                         onClick={() => copyEmd(index)}>
                      Копировать EMD
                    </div>
                  </Col>
                  <Col>
                    <RemoveLink
                      onClick={() => removeEmd(index)}
                      title={"EMD"}
                    />
                  </Col>
                  {/*<Col>*/}
                  {/*  <DropdownButton variant="outline-secondary" size="sm" title="Действия">*/}
                  {/*    <Dropdown.Item as="button" type={'button'} onClick={() => copyEmd(index)}>*/}
                  {/*      Копировать EMD*/}
                  {/*    </Dropdown.Item>*/}
                  {/*    <Dropdown.Item as="button" type={'button'} onClick={() => removeEmd(index)}>*/}
                  {/*      Удалить EMD*/}
                  {/*    </Dropdown.Item>*/}
                  {/*    <Dropdown.Item as="button" type={'button'} onClick={()=>removeEmdAsTicket(ticket.ticketNumber,emd, index)}>*/}
                  {/*      В новый инвойс как билет-возврат*/}
                  {/*    </Dropdown.Item>*/}
                  {/*  </DropdownButton>*/}
                  {/*</Col>*/}
                </Row>
                {emd.technicalCounterparty && <div className={'mb-3'}>Технический контрагент: {emd.technicalCounterparty}</div>}

              </Row>
            );
          })}
        </Col>
      </Row>
    </>
  );
};

const AirBookingTicketsForm = ({pax, index, prefix, formController}) => {
  const {
    control,
    getValues,
    watch,
    formState: {errors}
  } = formController;
  const ticketsControl = useFieldArray({
    control,
    name: `${prefix}.tickets`,
    rules: {required: true},
    shouldUnregister: true,
    keyName: "customId"
  });

  const ticketTemplate = {
    ticketNumber: "",
    airline: "",
    issuedDate: moment().startOf('day').format('DD.MM.YYYY'),
    officeId: "",
    currency: "KZT",
    fare: 0,
    taxes: 0,
    penalties: 0,
    // commissionBuyer: 0,
    // commissionSeller: 0,
    krsBuyer: 0,
    krsSeller: 0,
    krsKayar: 0,
    segments: [],
    emds: [],
    refund: false
  };

  const [tabIndex, setTabIndex] = useState(null);
  useEffect(() => {
    if (!tabIndex && pax.tickets.length !== 0) {
      setTabIndex(`ticket0`);
    }
  }, [pax]);

  const ticketLength = watch(`${prefix}.tickets`, []);
  const tabOnClick = key => {
    if (key === "add") {
      const newTicket = {...ticketTemplate};
      ticketsControl.append(newTicket);
      setTabIndex(`ticket${ticketLength.length}`);
    } else {
      setTabIndex(key);
    }
  };


  const deleteTicket = index => {
    ticketsControl.remove(index);
    if (ticketLength.length - 1) {
      setTabIndex(`ticket${ticketLength.length - 2}`);
    }
  };
  const copyTicket = index =>{
    const ticket = _.get(formController.getValues(), `${prefix}.tickets[${index}]`);
    const newTicket = structuredClone(ticket)
    delete newTicket.id
    newTicket.emds = []
    ticketsControl.append(newTicket)
    setTabIndex(`ticket${ticketLength.length}`)
  }
  return (
    <>
      <Row>
        <Col sm={12}>
          <Form.Group className={"formTitle"}>
            <h6>Билеты</h6>
            <Tabs
              id="controlled-tab-example"
              activeKey={tabIndex}
              onSelect={key => tabOnClick(key)}
            >
              {ticketsControl.fields.map((ticket, index) => {
                let name = "Билет " + (index + 1);
                if (ticket.ticketNumber) {
                  name = `${ticket.airlineCode}-${ticket.ticketNumber}`;
                }

                return (
                  <Tab
                    key={ticket.customId}
                    eventKey={`ticket${index}`}
                    tabClassName={
                      _.get(errors, `${prefix}.tickets[${index}]`)
                        ? "is-invalid"
                        : ""
                    }
                    title={name}
                  >
                    <AirBookingTicketForm
                      segments={[]}
                      index={index}
                      prefix={`${prefix}.tickets[${index}]`}
                      ticket={ticket}
                      deleteTicket={() => deleteTicket(index)}
                      copyTicket={()=>copyTicket(index)}
                      key={index}
                      formController={formController}
                    />
                  </Tab>
                );
              })}
              <Tab eventKey={"add"} title={<i className={"la la-plus"}/>}/>
            </Tabs>
          </Form.Group>
        </Col>
      </Row>
      {(_.get(errors, `${prefix}.tickets`)?.root) ? (
        <p className={"text-danger"}>Минимум 1 билет</p>
      ) : null}
    </>
  );
};

export default AirBookingTicketsForm;
