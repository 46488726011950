import _ from 'lodash'


const addDateToHotel = (getValues, hotel,prefix) => {
  let oldHotel = _.get(getValues,`${prefix}`).items[0]
  console.log('oldHotel', oldHotel, prefix)
  if(oldHotel){
    return {...hotel, startDate: oldHotel.startDate,
      startTime: oldHotel.startTime,
      endDate: oldHotel.endDate,
      currency: oldHotel.currency,
      endTime: oldHotel.endTime
    }
  }
  return hotel
};

export default addDateToHotel;