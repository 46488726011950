import React, {useEffect, useState} from "react";
import AruanaKayarReviseRender from "./aruanaKayarReviseRender";
import AruanaIataReviseRender from "./aruanaIataReviseRender";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import SilkKayarReviseRender from "./silkKayarReviseRender";
import JsFileDownloader from 'js-file-downloader';
import {Button} from "reactstrap";
import CopyDownloadLink from "./CopyDownloadLink";
import ReloadReviseButton from "./reloadReviseButton";
import SiteKayarReviseRender from "./SiteKayarReviseRender";
import YanaKayarRender from "./YanaKayarRender";
import YanaKayarReviseRender from "./YanaKayarReviseRender";



const ReviseItemRender = ({ revise }) => {


  const getRender = () => {
    if (revise.reviseType === "aruana-kayar") {
      return <AruanaKayarReviseRender revise={revise}  />;
    } else if (revise.reviseType === "iata-aruana") {
      return <AruanaIataReviseRender revise={revise} />;
    } else if (revise.reviseType === "silk-kayar") {
      return <SilkKayarReviseRender revise={revise}  />;
    } else  if(revise.reviseType === "kayar-site"){
      return <SiteKayarReviseRender revise={revise} />
    } else {
      return <YanaKayarReviseRender revise={revise}/>
    }
  };

  return (
    <>
      {getRender()}
    </>
  );
};

export default ReviseItemRender;
